import { css } from "styled-components";
import { px2rem } from "./utils";
import { fonts } from "./fonts";

// Heading

const headingXXXXL = css`
  ${fonts.openSans};
  font-size: ${px2rem(48)};
  line-height: ${px2rem(60)};
  font-weight: 600;
`;

const headingXXXL = css`
  ${fonts.openSans};
  font-size: ${px2rem(40)};
  line-height: ${px2rem(50)};
  font-weight: 600;
`;

const headingXXL = css`
  ${fonts.openSans};
  font-size: ${px2rem(32)};
  line-height: ${px2rem(42)};
  font-weight: 600;
`;

const headingXL = css`
  ${fonts.openSans};
  font-size: ${px2rem(24)};
  line-height: ${px2rem(36)};
  font-weight: 600;
`;

// Body

const large = css`
  ${fonts.openSans};
  font-size: ${px2rem(20)};
  line-height: ${px2rem(30)};
  font-weight: 400;
`;

const medium = css`
  ${fonts.openSans};
  font-size: ${px2rem(16)};
  line-height: ${px2rem(24)};
  font-weight: 400;
`;

const small = css`
  ${fonts.openSans};
  font-size: ${px2rem(14)};
  line-height: ${px2rem(20)};
  font-weight: 400;
`;

const xSmall = css`
  ${fonts.openSans};
  font-size: ${px2rem(12)};
  line-height: ${px2rem(16)};
  font-weight: 400;
`;

const xxSmall = css`
  ${fonts.openSans};
  font-size: ${px2rem(10)};
  line-height: ${px2rem(16)};
  font-weight: 400;
`;

const xxxSmall = css`
  ${fonts.openSans};
  font-size: ${px2rem(8)};
  line-height: ${px2rem(14)};
  font-weight: 400;
`;

export const typography = {
  headingXXXXL,
  headingXXXL,
  headingXXL,
  headingXL,
  large,
  medium,
  small,
  xSmall,
  xxSmall,
  xxxSmall
};
