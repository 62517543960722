import { px2rem } from "@/src/ui/styles/utils";
import { css } from "styled-components";

export const borderRadius = {
  s: css`
    border-radius: ${px2rem(4)};
  `,
  m: css`
    border-radius: ${px2rem(10)};
  `
};
