import { useParams } from "react-router-dom";
import { NullIdError } from "@/src/core/app/domain/models/errors/null_id.error";

export function usePageId(componentName: string): string {
  const { id } = useParams();
  if (!id) throw new NullIdError(componentName);
  return id;
}
export function usePageReference(componentName: string): string {
  const { reference } = useParams();
  if (!reference) throw new NullIdError(componentName);
  return reference;
}