import type { CasesSlice } from "@/src/ui/pages/cases/view_models/cases.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetCasesUseCase } from "@/src/core/cases/domain/use_cases/get_cases_use_case";
import { getInitialPaginationState } from "@/src/ui/view_models/table_pagination";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";
import { selectHomePageCasesSort } from "./home_page_cases_sort.slice";
import { isEmpty } from "lodash";

const initialState = (): CasesSlice => ({
  pagination: null,
  paginationState: getInitialPaginationState(),
  loadingState: "loaded"
});

const defaultSort: SortingModel = { "order[createdAt]": "desc" };

export const getHomePageCasesThunk = createAsyncThunk("homePageCases.slice/get", async (page: number | undefined, { getState }) => {
  const selectedSort = selectHomePageCasesSort(getState() as RootState);
  const sort = isEmpty(selectedSort) ? defaultSort : selectedSort;
  
  const useCase = await locator.get<IocProvider<GetCasesUseCase>>(TYPES.GetCasesUseCase)();
  return await useCase.execute({ ...sort, page: (page ?? 0) + 1 });
});

const homePageCasesSlice = createSlice({
  name: "homePageCases.slice",
  initialState: initialState(),
  reducers: {
    resetHomePageCases: initialState
  },
  extraReducers(builder) {
    builder.addCase(getHomePageCasesThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getHomePageCasesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getHomePageCasesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.homePageCases;
}

export const selectHomePageCases = createSelector(selectBase, (slice) => slice.pagination);
export const selectHomePageCasesHasResults = createSelector(selectBase, (cases) => cases.pagination?.hasResults);
export const selectHomePageCasesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectHomePageCasesHasNextPage = createSelector(selectBase, (cases) => cases.pagination?.hasNextPage);
export const selectHomePageCasesPaginationState = createSelector(selectBase, (cases) => cases.paginationState);

export const { resetHomePageCases } = homePageCasesSlice.actions;
export default homePageCasesSlice.reducer;
