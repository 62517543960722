import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./locales/${language}/${namespace}.json`)
        .then(({ default: resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(initReactI18next)
  .init({
    ns: [
      "404",
      "alerts",
      "cases",
      "clinic_detail",
      "clinics",
      "dentists",
      "components",
      "courses",
      "errors",
      "home",
      "billing_addresses",
      "new_billing_address",
      "billing_address_detail",
      "edit_billing_address",
      "login",
      "fields",
      "navigation",
      "new_clinic",
      "new_case",
      "case_types",
      "case_detail",
      "case_comments",
      "case_viewer",
      "case_files",
      "case_documentation",
      "case_notes",
      "profile",
      "new_profile",
      "edit_profile",
      "recover_password",
      "recover_password_email_sent",
      "recover_password_success",
      "page_title",
      "pagination",
      "user_detail",
      "values",
      "select_profile",
      "dentist_detail",
      "notifications",
      "edit_dentist",
      "create_treatment",
      "edit_treatment",
      "pathology_solution",
      "role_error",
      "client_type",
      "specialities",
      "referral_sources",
      "manufacturing",
      "manufacturing_items",
      "manufacturing_lots",
      "invoices",
      "business_names",
      "case_detail_create_invoice",
      "case_detail_correct_invoice",
      "course_detail",
      "new_course",
      "course_invoice_create",
      "create_invoice",
      "case_detail_go_back",
      "faq",
      "edit_case"
    ],
    defaultNS: "errors",
    lng: "es",
    interpolation: { escapeValue: false }
  });

export default i18n;
