import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetClinicsUseCase } from "@/src/core/clinics/domain/use_cases/get_clinics_use_case";
import { selectProfileClinicId, selectProfileDentistId } from "@/src/ui/state/profile.slice";
import { selectIsClinicGroup } from "../../roles/state/roles.selectors";
import { selectClinicsPageFilters } from "./clinics_page_filters.slice";
import type { ClinicsPageSlice } from "../view_models/clinics_page.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): ClinicsPageSlice => getInitialPaginationSlice();

export const getClinicsPageThunk = createAsyncThunk("clinicsPage.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const dentist = selectProfileDentistId(state);
  const parentAndChildren = selectProfileClinicId(state);
  let search = selectClinicsPageFilters(state);
  if (dentist) {
    search = { ...search, dentist };
  } else if (selectIsClinicGroup(state) && parentAndChildren) {
    search = { ...search, parentAndChildren };
  }
  const useCase = await locator.get<IocProvider<GetClinicsUseCase>>(TYPES.GetClinicsUseCase)();
  return await useCase.execute({ ...search, page: (page ?? 0) + 1, itemsPerPage: 9 });
});

const clinicsPageSlice = createSlice({
  name: "clinicsPage.slice",
  initialState: initialState(),
  reducers: {
    resetClinicsPage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getClinicsPageThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getClinicsPageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getClinicsPageThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.clinicsPage;
}

export const selectClinicsPage = createSelector(selectBase, (slice) => slice.pagination);
export const selectClinicsPageHasResults = createSelector(selectClinicsPage, (cases) => cases?.hasResults);
export const selectClinicsPageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectClinicsPagePaginationState = createSelector(selectBase, (cases) => cases.paginationState);

export const { resetClinicsPage } = clinicsPageSlice.actions;
export default clinicsPageSlice.reducer;
