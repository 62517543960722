import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ProfileDetailSlice } from "../view_models/profile_detail.slice";
import type { GetProfileDetailUseCase } from "@/src/core/user/domain/use_cases/get_profile_detail_use_case";
import { serializeError } from "@/src/common/utils/rtk";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { selectProfile } from "@/src/ui/state/profile.slice";
import { setProfile } from "@/src/ui/state/profile.slice";

const initialState = (): ProfileDetailSlice => getInitialDetailSlice();

export const getProfileDetailThunk = createAsyncThunk(
  "profileDetail.slice/get",
  async (profileId: string, {getState, dispatch}) => {
    const useCase = await locator.get<IocProvider<GetProfileDetailUseCase>>(TYPES.GetProfileDetailUseCase)();
    const promise = useCase.execute(profileId);

    promise.then((profile) => {
      if(profile.id === selectProfile(getState() as RootState)?.id) {
        dispatch(setProfile(profile))
      }
    })

    return await promise
  },
  { serializeError }
);

const profileDetailSlice = createSlice({
  name: "profileDetail.slice",
  initialState: initialState(),
  reducers: {
    resetProfileDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getProfileDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getProfileDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getProfileDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectProfileDetailBase(state: RootState) {
  return state.profileDetail;
}

export const selectProfileDetail = createSelector(selectProfileDetailBase, (slice) => slice.detail);
export const selectProfileDetailLoadingState = createSelector(selectProfileDetailBase, (slice) => slice.loadingState);
export const selectProfileDetailIsLoading = createSelector(selectProfileDetailLoadingState, (loadingState) => loadingState === "loading");

export const { resetProfileDetail } = profileDetailSlice.actions;
export default profileDetailSlice.reducer;
