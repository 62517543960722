import { Expose, Type } from "class-transformer";
import { MoneyModel } from "@/src/core/app/domain/models/money.model";

export class InvoiceLineModel {
  @Expose() id!: string;

  @Expose() discount!: number;
  @Expose() name!: string;

  @Expose() @Type(() => MoneyModel) price!: MoneyModel;
  @Expose() @Type(() => MoneyModel) total!: MoneyModel;
}
