import styled from "styled-components";
import { colors } from "@/src/ui/styles/colors";
import { px2rem } from "@/src/ui/styles/utils";

const DotsLoader = styled.div`
  position: absolute;
  right: ${px2rem(50)};
  transform: translateY(${px2rem(-3)});
  .dot-pulse {
    position: relative;
    top: 0;
    left: ${px2rem(-9999)};
    width: ${px2rem(6)};
    height: ${px2rem(6)};
    border-radius: ${px2rem(5)};
    background-color: ${colors.brand500};
    color: ${colors.brand500};
    box-shadow: ${px2rem(9999)} 0 0 ${px2rem(-5)};
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    display: inline-block;
    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: ${px2rem(6)};
      height: ${px2rem(6)};
      border-radius: ${px2rem(5)};
      background-color: ${colors.brand500};
      color: ${colors.brand500};
    }
    &::before {
      box-shadow: ${px2rem(9984)} 0 0 ${px2rem(-5)};
      animation: dot-pulse-before 1.5s infinite linear;
      animation-delay: 0s;
    }
    &::after {
      box-shadow: ${px2rem(10014)} 0 0 ${px2rem(-5)};
      animation: dot-pulse-after 1.5s infinite linear;
      animation-delay: 0.5s;
    }
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: ${px2rem(9984)} 0 0 ${px2rem(-5)};
    }
    30% {
      box-shadow: ${px2rem(9984)} 0 0 ${px2rem(2)};
    }
    60%,
    100% {
      box-shadow: ${px2rem(9984)} 0 0 ${px2rem(-5)};
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: ${px2rem(9999)} 0 0 ${px2rem(-5)};
    }
    30% {
      box-shadow: ${px2rem(9999)} 0 0 ${px2rem(2)};
    }
    60%,
    100% {
      box-shadow: ${px2rem(9999)} 0 0 ${px2rem(-5)};
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: ${px2rem(10014)} 0 0 ${px2rem(-5)};
    }
    30% {
      box-shadow: ${px2rem(10014)} 0 0 ${px2rem(2)};
    }
    60%,
    100% {
      box-shadow: ${px2rem(10014)} 0 0 ${px2rem(-5)};
    }
  }
`;

export default { DotsLoader };
