import Translate from "../../text/translate/translate";

interface SerializedError {
  ns?: string;
  key: string;
  meta?: object;
}

export function serializeErrorMessage(s: SerializedError) {
  return JSON.stringify(s);
}

export function deserializeErrorMessage(serializedError: string) {
  try {
    return JSON.parse(serializedError) as SerializedError;
  } catch {
    return serializedError;
  }
}

interface Props {
  error: string;
}

export default function ErrorText({ error }: Props) {
  const serialized = deserializeErrorMessage(error);
  if (typeof serialized === "string") return <>{serialized}</>;
  return <Translate ns={serialized.ns ?? "errors"} i18nKey={serialized.key} values={serialized.meta} />;
}
