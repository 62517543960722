import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetClinicsUseCase } from "@/src/core/clinics/domain/use_cases/get_clinics_use_case";
import type { NewClinicSlice } from "@/src/ui/pages/clinics/new_clinic/view_models/new_clinic.slice";

const initialState = (): NewClinicSlice => ({
  vatNumber: "",
  isLoading: false,
  hasError: false
});

export const newClinicSearchThunk = createAsyncThunk("newClinic.slice/search", async (_, { getState }) => {
  const useCase = await locator.get<IocProvider<GetClinicsUseCase>>(TYPES.GetClinicsUseCase)();
  return await useCase.execute({ search: selectNewClinicVatNumber(getState() as RootState) });
});

const newClinicSlice = createSlice({
  name: "newClinic.slice",
  initialState: initialState(),
  reducers: {
    setNewClinicVatNumber(state, action: PayloadAction<string>) {
      state.vatNumber = action.payload;
    },
    resetNewClinic: initialState
  },
  extraReducers(builder) {
    builder.addCase(newClinicSearchThunk.pending, (state) => {
      state.searchResults = undefined;
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(newClinicSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResults = action.payload.results;
    });
    builder.addCase(newClinicSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.searchResults = undefined;
      state.hasError = true;
    });
  }
});

function selectnewClinicBase(state: RootState) {
  return state.newClinic;
}

export const selectNewClinicSearchResults = createSelector(selectnewClinicBase, (e) => e.searchResults);
export const selectNewClinicSearchHasNoResults = createSelector(selectNewClinicSearchResults, (results) => results && results.length === 0);
export const selectNewClinicSearchIsLoading = createSelector(selectnewClinicBase, (e) => e.isLoading);
export const selectNewClinicHasError = createSelector(selectnewClinicBase, (e) => e.hasError);
export const selectNewClinicVatNumber = createSelector(selectnewClinicBase, (e) => e.vatNumber);

export const { resetNewClinic, setNewClinicVatNumber } = newClinicSlice.actions;

export default newClinicSlice.reducer;
