import Styled from "./radio_input.styled";
interface Props {
  // A string that identifies the checkbox group
  name: string;
  // A string that uniquely identifies the checkbox in the form
  id?: string;
  value: boolean;
  onChange(value: boolean): void;
  // Text to display on the checkbox
  label: string;
  className?: string;
}
export default function RadioInput({ value, id, name, onChange, label, className }: Props) {
  id ??= name;
  return (
    <Styled.Label htmlFor={id} className={className}>
      <Styled.Input type="radio" id={id} name={name} value={id} checked={value} onChange={() => onChange(!value)} />
      <Styled.Radio checked={value}>{value && <div />}</Styled.Radio>
      <Styled.LabelText>{label}</Styled.LabelText>
    </Styled.Label>
  );
}
