import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetDentistsUseCase } from "@/src/core/dentists/domain/use_cases/get_dentists_use_case";
import type { NewUserDentistSearchSlice } from "../view_models/new_user_dentist_search.slice";

const initialState = (): NewUserDentistSearchSlice => ({
  collegiateNumber: "",
  isLoading: false,
  hasError: false,
  showForm: false
});

export const newProfileDentistSearchThunk = createAsyncThunk("newUserDentistSearch.slice/search", async (_, { getState }) => {
  const collegiateNumber = selectNewUserDentistSearchCollegiateNumber(getState() as RootState);
  const useCase = await locator.get<IocProvider<GetDentistsUseCase>>(TYPES.GetDentistsUseCase)();
  return await useCase.execute({ collegiateNumber });
});

const newUserDentistSearchSlice = createSlice({
  name: "newUserDentistSearch.slice",
  initialState: initialState(),
  reducers: {
    setNewUserDentistSearchCollegiateNumber(state, action: PayloadAction<string>) {
      state.collegiateNumber = action.payload;
    },
    setNewUserDentistSearchShowForm(state) {
      state.showForm = true;
    },
    resetNewProfileDentistSearch: initialState
  },
  extraReducers(builder) {
    builder.addCase(newProfileDentistSearchThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      state.showForm = false;
    });
    builder.addCase(newProfileDentistSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.searchResults = undefined;
      state.hasError = true;
    });
    builder.addCase(newProfileDentistSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResults = action.payload.results;
      if (!action.payload.results.length) state.showForm = true;
    });
  }
});

function selectBase(state: RootState) {
  return state.newUserDentistSearch;
}

export const selectNewUserDentistSearchResults = createSelector(selectBase, (e) => e.searchResults);
export const selectNewUserDentistSearchHasNoResults = createSelector(selectBase, (e) => e.searchResults && !e.searchResults.length);
export const selectNewUserDentistSearchIsLoading = createSelector(selectBase, (e) => e.isLoading);
export const selectNewUserDentistSearchHasError = createSelector(selectBase, (e) => e.hasError);
export const selectNewUserDentistSearchCollegiateNumber = createSelector(selectBase, (e) => e.collegiateNumber);
export const selectNewUserDentistSearchShowForm = createSelector(selectBase, (e) => e.showForm);

export const { resetNewProfileDentistSearch, setNewUserDentistSearchCollegiateNumber, setNewUserDentistSearchShowForm } =
  newUserDentistSearchSlice.actions;

export default newUserDentistSearchSlice.reducer;
