import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import type { LoaderHeight} from "@/src/ui/styles/loader_height";
import { getLoaderHeight } from "@/src/ui/styles/loader_height";

interface ContainerProps {
  height?: LoaderHeight
}
const Container = styled.div<ContainerProps>`
  height: ${({height})=> getLoaderHeight(height)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Text = styled.p`
  ${typography.small}
`;
export default {
  Container,
  Text
};
