import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import type { PropsWithChildren } from "react";
import { NoResultsMessage } from "../no_results_message/no_results_message";

interface Props extends PropsWithChildren {
  hasResults?: number | boolean;
  height?: LoaderHeight;
}

export default function NoResultsWrapper({ hasResults, height, children }: Props) {
  if (hasResults) return <>{children}</>;
  return <NoResultsMessage height={height} />;
}
