import { GlobalStyles } from "@/src/ui/styles/globals";
import ModalZone from "@/src/ui/components/modals/modal_zone/modal_zone";
import Routes from "@/src/ui/router/routes";
import { useGetRedirectSessionUrlController } from "@/src/ui/controllers/get_redirect_session_url_controller";
import { useSetupLanguage } from "@/src/ui/hooks/use_setup_language";
import { HelmetProvider } from "react-helmet-async";
import PageHead from "@/src/ui/components/page_head/page_head";
import { useAppSelector } from "./state";
import { selectResetKey } from "./state/reset.slice";

function App() {
  useGetRedirectSessionUrlController();
  useSetupLanguage();
  const resetKey = useAppSelector(selectResetKey)

  return (
    <HelmetProvider>
      <PageHead />
      <GlobalStyles />
      <ModalZone />
      <Routes key={resetKey} />
    </HelmetProvider>
  );
}

export default App;
