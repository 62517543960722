import type { ConstructorType } from "@/src/common/interfaces/constructor_type";

export class RemoveBillingAddressFromDentistInput {
  billingAddressId: string;
  dentistId: string;
  
  constructor(input: ConstructorType<RemoveBillingAddressFromDentistInput>) {
    this.billingAddressId = input.billingAddressId;
    this.dentistId = input.dentistId;
  }
}
