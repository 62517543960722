import { Helmet } from "react-helmet-async";
import i18n from "i18next";
export interface PageHeadProps {
  title?: string;
  description?: string;
}
export default function PageHead({ title: pageTitle, description }: PageHeadProps) {
  return (
    <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}>
      <title>{["SecretAligner", pageTitle].filter((t) => t).join(" - ")}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}
