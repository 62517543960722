import type { PromoterDetailSlice } from "@/src/ui/pages/promoters/view_models/promoter_detail.slice";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { serializeError } from "@/src/common/utils/rtk";
import type { GetPromoterDetailUseCase } from "@/src/core/promoters/domain/use_cases/get_promoter_detail_use_case";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";

const initialState = (): PromoterDetailSlice => getInitialDetailSlice();

export const getPromoterDetailThunk = createAsyncThunk(
  "promoterDetail.slice/get",
  async (id: string) => {
    const useCase = await locator.get<IocProvider<GetPromoterDetailUseCase>>(TYPES.GetPromoterDetailUseCase)();
    return await useCase.execute(id);
  },
  { serializeError }
);

const promoterDetailSlice = createSlice({
  name: "promoterDetail.slice",
  initialState: initialState(),
  reducers: {
    resetPromoterDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getPromoterDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getPromoterDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getPromoterDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.promoterDetail;

export const selectPromoterDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectPromoterDetailLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const { resetPromoterDetail } = promoterDetailSlice.actions;
export default promoterDetailSlice.reducer;
