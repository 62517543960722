import Styled from "./steps.styled";
import type { CustomStepModel } from "@/src/core/custom_forms/domain/models/custom_step.model";

interface Props {
  steps: CustomStepModel[];
  selected?: string;
}
export default function Steps({ steps, selected }: Props) {
  const selectedIndex = (() => {
    const index = steps.findIndex((v) => v.value === selected);
    return index === -1 ? 0 : index;
  })();

  return (
    <Styled.Wrapper>
      {steps.map((step, index) => {
        const isSelected = index <= selectedIndex;
        return (
          <Styled.Item key={step.value}>
            <Styled.Circle isSelected={isSelected}>
              {index < steps.length - 1 && <Styled.Bar isSelected={index + 1 <= selectedIndex} />}
              <Styled.CircleNumber isSelected={isSelected}>{index + 1}</Styled.CircleNumber>
            </Styled.Circle>
            <Styled.Text isSelected={isSelected}> {step.label}</Styled.Text>
          </Styled.Item>
        );
      })}
    </Styled.Wrapper>
  );
}
