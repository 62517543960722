import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetClinicDetailUseCase } from "@/src/core/clinics/domain/use_cases/get_clinic_detail_use_case";
import type { MyClinicSlice } from "../view_models/my_clinic.slice";
import type { AddClinicToClinicGroupModel } from "@/src/core/clinics/domain/models/add_clinic_to_clinic_group_model";
import type { AddClinicToClinicGroupUseCase } from "@/src/core/clinics/domain/use_cases/add_clinic_to_clinic_group_use_case";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): MyClinicSlice => ({
  detail: null,
  hasError: false,
  isLoading: false
});

export const getMyClinicThunk = createAsyncThunk("myClinic.slice/get", async (clinicId: string) => {
  const useCase = await locator.get<IocProvider<GetClinicDetailUseCase>>(TYPES.GetClinicDetailUseCase)();
  return await useCase.execute(clinicId);
});

export const addClinicToMyClinicGroupThunk = createAsyncThunk(
  "myClinic.slice/addClinicToMyClinicGroup",
  async (input: AddClinicToClinicGroupModel, { dispatch }) => {
    const useCase = await locator.get<IocProvider<AddClinicToClinicGroupUseCase>>(TYPES.AddClinicToClinicGroupUseCase)();
    const promise = useCase.execute(input);
    promise.catch((e) => {
      console.error(e);
      dispatch(showErrorAlertThunk());
    });
    promise.then(() => dispatch(showSuccessAlertThunk()));
    return await promise;
  }
);

const myClinic = createSlice({
  name: "myClinic.slice",
  initialState: initialState(),
  reducers: {
    resetMyClinic: initialState
  },
  extraReducers(builder) {
    builder.addCase(getMyClinicThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getMyClinicThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getMyClinicThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });

    builder.addCase(addClinicToMyClinicGroupThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
    });
  }
});

function selectBase(state: RootState) {
  return state.myClinic;
}

export const selectMyClinic = createSelector(selectBase, (slice) => slice.detail);
export const selectHasClinic = createSelector(selectBase, (slice) => Boolean(slice.detail));
export const selectMyClinicHasDentist = createSelector(selectBase, (slice) => Boolean(slice.detail?.dentists?.length));
export const selectMyClinicHasChildren = createSelector(selectBase, (slice) => Boolean(slice.detail?.childClinics?.length));
export const selectMyClinicError = createSelector(selectBase, (slice) => slice.hasError);
export const selectMyClinicIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetMyClinic } = myClinic.actions;
export default myClinic.reducer;
