import { colors } from "@/src/ui/styles/colors";
import { spacing } from "@/src/ui/styles/spacing";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "@/src/ui/styles/utils";
import styled from "styled-components";

const Container = styled.div`
  flex: 1 1 100%;
  min-width: 0;
`;

const LabelText = styled.h3`
  ${typography.xSmall};
  margin-bottom: ${px2rem(14)};
  color: ${colors.neutral900};
`;
const Grid = styled.div`
  display: flex;
  gap: ${spacing.xs};
  flex-wrap: wrap;
`;
export default { Container, LabelText, Grid };
