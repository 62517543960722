import { getCustomFieldDisplayEdit } from "@/src/core/custom_forms/domain/models/custom_base_field.model";
import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import { useField } from "formik";
import CustomField from "../custom_field/custom_field";

interface Props {
  field: CustomFieldModel;
}
export function CustomFieldHide({ field }: Props) {
  const display = getCustomFieldDisplayEdit(field);
  const [dependent] = useField(display?.field ?? "");
  if (!display?.values?.includes || display.values.includes(dependent.value)) return <CustomField field={field} />;
  return null;
}
