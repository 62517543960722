import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { ProfileSlice } from "@/src/ui/view_models/profile.slice";
import type { ProfileModel } from "@/src/core/user/domain/models/profile_model";

const initialState = (): ProfileSlice => ({});

const profileSlice = createSlice({
  name: "profile.slice",
  initialState: initialState(),
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileModel>) => {
      state.profile = action.payload;
    },
    resetProfile: initialState
  }
});

function selectProfileBase(state: RootState) {
  return state.profile;
}

export const selectProfile = createSelector(selectProfileBase, (slice) => slice.profile);
export const selectHasProfile = createSelector(selectProfile, (profile) => Boolean(profile));
export const selectProfileId = createSelector(selectProfile, (profile) => profile?.id);
export const selectProfileHasDentist = createSelector(selectProfile, (profile) => Boolean(profile?.dentist));
export const selectProfileDentistId = createSelector(selectProfile, (profile) => profile?.dentist?.id);
const selectProfileClinic = createSelector(selectProfile, (profile) => profile?.clinic);
export const selectProfileHasClinic = createSelector(selectProfileClinic, (clinic) => Boolean(clinic));
export const selectProfileClinicId = createSelector(selectProfileClinic, (clinic) => clinic?.id);
export const selectFullName = createSelector(selectProfile, (profile) => profile && profile.fullName);

export const { setProfile, resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
