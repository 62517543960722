import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetUserDetailUseCase } from "@/src/core/user/domain/use_cases/get_user_detail_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { UserDetailSlice } from "../view_models/user_detail.slice";
import type { ActivateUserInput } from "@/src/core/user/domain/models/activate_user_input";
import type { ActivateUserUseCase } from "@/src/core/user/domain/use_cases/activate_user_use_case";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): UserDetailSlice => ({
  hasError: false,
  isLoading: false
});

export const getUserDetailThunk = createAsyncThunk("userDetail.slice/get", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetUserDetailUseCase>>(TYPES.GetUserDetailUseCase)();
  return await useCase.execute(id);
});

export const activateUserThunk = createAsyncThunk("userDetail.slice/activate", async (input: ActivateUserInput, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<ActivateUserUseCase>>(TYPES.ActivateUserUseCase)();
    await useCase.execute(input);
    await dispatch(getUserDetailThunk(input.userId));
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk());
  }
});

const userDetailSlice = createSlice({
  name: "userDetail.slice",
  initialState: initialState(),
  reducers: {
    resetUserDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getUserDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getUserDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getUserDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
  }
});

function selectUserDetailBase(state: RootState) {
  return state.userDetail;
}

export const selectUserDetail = createSelector(selectUserDetailBase, (slice) => slice.detail);
export const selectUserDetailError = createSelector(selectUserDetailBase, (slice) => slice.hasError);
export const selectUserDetailIsLoading = createSelector(selectUserDetailBase, (slice) => slice.isLoading);
export const { resetUserDetail } = userDetailSlice.actions;
export default userDetailSlice.reducer;
