import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import { selectCanEditClinicDetail } from "../../pages/clinics/clinic_detail/state/clinic_detail_roles.selectors";
import type { PropsWithChildren } from "react";
import { usePageId } from "../../hooks/use_page_id";
import { selectClinicDetail } from "../../pages/clinics/clinic_detail/state/clinic_detail.slice";

export default function CanEditClinicMiddleware({ children }: PropsWithChildren) {
  const canEditClinic = useAppSelector(selectCanEditClinicDetail);
  const id = usePageId("CanEditClinicMiddleware");
  const detail = useAppSelector(selectClinicDetail);
  if (canEditClinic || id !== detail?.id) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
