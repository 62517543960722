import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetDentistsUseCase } from "@/src/core/dentists/domain/use_cases/get_dentists_use_case";
import { selectProfileClinicId } from "@/src/ui/state/profile.slice";
import { selectDentistsPageFilters } from "@/src/ui/pages/dentists/state/dentists_page_filters.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { DentistsSlice } from "../view_models/dentists.slice";
import { selectDentistsPageSort } from "./dentists_page_sort.slice";

const initialState = (): DentistsSlice => getInitialPaginationSlice();

export const getDentistsThunk = createAsyncThunk("dentists.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const search = selectDentistsPageFilters(state);
  const clinic = search.clinic || selectProfileClinicId(state);
  const sort = selectDentistsPageSort(state);
  const useCase = await locator.get<IocProvider<GetDentistsUseCase>>(TYPES.GetDentistsUseCase)();
  return await useCase.execute({ ...search, clinic, ...sort, page: (page ?? 0) + 1, itemsPerPage: 10 });
});

const dentistsSlice = createSlice({
  name: "dentists.slice",
  initialState: initialState(),
  reducers: {
    resetDentists: initialState
  },
  extraReducers(builder) {
    builder.addCase(getDentistsThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getDentistsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getDentistsThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.dentists;
}

export const selectDentists = createSelector(selectBase, (slice) => slice.pagination);
export const selectDentistsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectDentistsPaginationState = createSelector(selectBase, (cases) => cases.paginationState);

export const { resetDentists } = dentistsSlice.actions;
export default dentistsSlice.reducer;
