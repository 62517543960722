import { lazy } from "react";
import { useRoutes } from "react-router-dom";
const CaseDetailCreateInvoiceTreatmentsPage = lazy(
  () =>
    import(
      "../../case_detail_create_invoice/case_detail_create_invoice_treatments/components/case_detail_create_invoice_treatments_page/case_detail_create_invoice_treatments_page"
    )
);
const CaseDetailCreateInvoicePage = lazy(
  () => import("../../case_detail_create_invoice/components/case_detail_create_invoice_page/case_detail_create_invoice_page")
);
const CaseDetailCorrectInvoicePage = lazy(
  () => import("../../case_detail_correct_invoice/components/case_detail_correct_invoice_page/case_detail_correct_invoice_page")
);

export default function CaseDetailBillingPages() {
  const page = useRoutes([
    { path: "/invoices/new", element: <CaseDetailCreateInvoicePage /> },
    { path: "/invoices/new/treatments", element: <CaseDetailCreateInvoiceTreatmentsPage /> },
    { path: "/invoices/detail/:id/correct", element: <CaseDetailCorrectInvoicePage /> }
  ]);
  return <>{page}</>;
}
