import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from ".";

const initialState = () => ({
  language: "es"
});
const languageSlice = createSlice({
  name: "language.slice",
  initialState: initialState(),
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }
  }
});

const selectBase = (state: RootState) => state.language;

export const selectLanguage = createSelector(selectBase, (slice) => slice.language);

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
