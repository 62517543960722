import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { UsersPageSortSlice } from "../view_models/users_page_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): UsersPageSortSlice => ({ value: {} });

const usersPageSortSlice = createSlice({
  name: "usersPageSort.slice",
  initialState: initialState(),
  reducers: {
    resetUsersPageSort: initialState,
    setUsersPageSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.usersPageSort;
}

export const selectUsersPageSort = createSelector(selectBase, (slice) => slice.value);

export const { setUsersPageSort, resetUsersPageSort } = usersPageSortSlice.actions;
export default usersPageSortSlice.reducer;

export const usersPageSortColumnNames = ["order[email]", "order[createdAt]", "order[updatedAt]"];
