import { store } from "@/src/ui/state";
import {
  casePreviousStepThunk,
  selectCaseDetailPreviousStep,
  selectCaseDetailReference
} from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { UpdateProductCaseUseCase } from "@/src/core/cases/domain/use_cases/update_product_case_use_case";

export const publishCasePreviousStepController = async () => {
  const state = store.getState();
  const reference = selectCaseDetailReference(state);
  if (reference) {
    const useCase = await locator.get<IocProvider<UpdateProductCaseUseCase>>(TYPES.UpdateProductCaseUseCase)();
    useCase.execute({ reference, meta: { step: selectCaseDetailPreviousStep(store.getState()) } });
  }
  store.dispatch(casePreviousStepThunk());
};
