import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCourseDetailUseCase } from "@/src/core/courses/domain/use_cases/get_course_detail_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { CourseDetailSlice } from "../view_models/course_detail.slice";
import type { CourseDetailModel } from "@/src/core/courses/domain/models/course_detail.model";

const initialState = (): CourseDetailSlice => ({
  hasError: false,
  isLoading: false,
  detail: null
});

export const getCourseDetailThunk = createAsyncThunk("courseDetail.slice/get", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetCourseDetailUseCase>>(TYPES.GetCourseDetailUseCase)();
  return await useCase.execute(id);
});

const courseDetailSlice = createSlice({
  name: "courseDetail.slice",
  initialState: initialState(),
  reducers: {
    resetCourseDetail: initialState,
    setCourseDetail(state, action: PayloadAction<CourseDetailModel>) {
      state.detail = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getCourseDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCourseDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCourseDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.courseDetail;

export const selectCourseDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectCourseDetailId = createSelector(selectBase, (slice) => slice.detail?.id);
export const selectCourseDetailError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCourseDetailIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetCourseDetail, setCourseDetail } = courseDetailSlice.actions;
export default courseDetailSlice.reducer;
