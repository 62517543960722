import type { PayloadAction } from "@reduxjs/toolkit";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetCourseAttendeesUseCase } from "@/src/core/courses/attendees/domain/use_cases/get_course_attendees_use_case";
import type { CourseAttendeesSlice } from "../view_models/course_attendees.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { PaginationState } from "@tanstack/react-table";
import { serializeError } from "@/src/common/utils/rtk";

const initialState = (): CourseAttendeesSlice => ({
  ...getInitialPaginationSlice(),
  id: null
});

export interface IGetCourseAttendeesThunk {
  courseId: string;
  page?: number;
}
export const getCourseAttendeesThunk = createAsyncThunk(
  "courseAttendees.slice/get",
  async (input: IGetCourseAttendeesThunk) => {
    const useCase = await locator.get<IocProvider<GetCourseAttendeesUseCase>>(TYPES.GetCourseAttendeesUseCase)();
    return await useCase.execute({ ...input, page: (input.page ?? 0) + 1, itemsPerPage: 10 });
  },
  { serializeError }
);

const courseAttendeesSlice = createSlice({
  name: "courseAttendees.slice",
  initialState: initialState(),
  reducers: {
    resetCourseAttendees: initialState,
    setCourseAttendeesPaginationState(state, action: PayloadAction<Partial<PaginationState>>) {
      state.paginationState = { ...state.paginationState, ...action.payload };
    }
  },
  extraReducers(builder) {
    builder.addCase(getCourseAttendeesThunk.pending, (state, action) => {
      state.id = action.meta.arg.courseId;
      state.paginationState.pageIndex = action.meta.arg.page ?? 0;
      state.loadingState = "loading";
    });
    builder.addCase(getCourseAttendeesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCourseAttendeesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.courseAttendees;

export const selectCourseAttendees = createSelector(selectBase, (slice) => slice.pagination);
export const selectCourseAttendeesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectCourseAttendeesCourseId = createSelector(selectBase, (slice) => slice.id);

export const selectCourseAttendeesPaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export const { resetCourseAttendees, setCourseAttendeesPaginationState } = courseAttendeesSlice.actions;
export default courseAttendeesSlice.reducer;
