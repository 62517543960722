import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { roleGroupModelToOption } from "@/src/core/roles/domain/models/role_group_option";
import type { GetRoleGroupsUseCase } from "@/src/core/roles/domain/use_cases/get_role_groups_use_case";
import type { RoleGroupsSlice } from "../view_models/role_groups.slice";

const initialState = (): RoleGroupsSlice => getInitialPaginationSlice();

export const getRoleGroupsThunk = createAsyncThunk("roleGroups.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetRoleGroupsUseCase>>(TYPES.GetRoleGroupsUseCase)();
  let pagination = await useCase.execute({});
  while (pagination.hasNextPage) {
    pagination = pagination.combine(await useCase.execute({ page: pagination.nextPage }));
  }
  return pagination;
});

const roleGroupsSlice = createSlice({
  name: "roleGroups.slice",
  initialState: initialState(),
  reducers: {
    resetRoleGroups: initialState
  },
  extraReducers(builder) {
    builder.addCase(getRoleGroupsThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getRoleGroupsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getRoleGroupsThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.roleGroups;
}

export const selectRoleGroups = createSelector(selectBase, (slice) => slice.pagination);
export const selectRoleGroupOptions = createSelector(selectRoleGroups, (pagination) => pagination?.results.map(roleGroupModelToOption));
export const selectRoleGroupsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectPromoterRoleGroup = createSelector(selectBase, (slice) => slice.pagination?.results.find((role) => role.name === "PROMOTER"));

export const { resetRoleGroups } = roleGroupsSlice.actions;
export default roleGroupsSlice.reducer;
