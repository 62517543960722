import { Expose, Transform } from "class-transformer";
import type { CurrencyModel } from "./currency.model";

export interface IMoneyModel {
  amount: number;
  currency: CurrencyModel;
}

export class MoneyModel implements IMoneyModel {
  @Expose() @Transform(({ value }) => Number(value)) amount!: number;
  @Expose() currency!: CurrencyModel;

  formatWithComma() {
    return (this.amount / 100).toString();
  }
}

export function sortMoney(a: MoneyModel, b: MoneyModel) {
  return a.amount - b.amount;
}
