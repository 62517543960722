import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import type { CaseStageSlice } from "../view_models/case_stage.slice";
import type { GetCaseStageInputModel } from "@/src/core/cases/domain/models/get_case_stage_input_model";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCaseStageUseCase } from "@/src/core/cases/domain/use_cases/get_case_stage_use_case";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { getCustomFormSchema } from "../../../custom_forms/presenters/get_custom_form_schema";
import { groupCustomFormFields } from "../../../custom_forms/presenters/group_custom_form_fields";
import { getCustomFieldInitialValue } from "../../../custom_forms/presenters/get_custom_field_initial_value";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): CaseStageSlice => getInitialDetailSlice();

export const getCaseStageThunk = createAsyncThunk("caseStage.slice/get", async (input: GetCaseStageInputModel) => {
  const useCase = await locator.get<IocProvider<GetCaseStageUseCase>>(TYPES.GetCaseStageUseCase)();
  return await useCase.execute(input);
});

const caseStageSlice = createSlice({
  name: "caseStage.slice",
  initialState: initialState(),
  reducers: {
    resetCaseStage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCaseStageThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCaseStageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCaseStageThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.caseStage;

export const selectCaseStage = createSelector(selectBase, (slice) => slice.detail);
export const selectCaseStageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectCaseDetailTransitionFormFields = createSelector(selectCaseStage, (stage) => stage?.fields ?? []);
export const selectCaseDetailTransitionFormSchema = createSelector(selectCaseDetailTransitionFormFields, getCustomFormSchema);
export const selectCaseDetailTransitionFormGroupedFields = createSelector(selectCaseDetailTransitionFormFields, groupCustomFormFields);
export const selectCaseDetailTransitionFormInitialValues = createSelector(selectCaseDetailTransitionFormFields, (fields) =>
  fields.reduce((prev, f) => ({ ...prev, [f.name]: getCustomFieldInitialValue(f) }), {})
);

export default caseStageSlice.reducer;
