import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { CoursesSlice } from "@/src/ui/pages/courses/view_models/courses.slice";
import type { GetCoursesUseCase } from "@/src/core/courses/domain/use_cases/get_courses_use_case";
import type { GetLatestCourseUseCase } from "@/src/core/courses/domain/use_cases/get_latest_course_use_case";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { selectCoursesPageFilters } from "./courses_page_filters.slice";
import type { PaginationFiltersModel } from "@/src/core/app/domain/models/pagination_filters.model";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): CoursesSlice => ({
  ...getInitialPaginationSlice(),
  latestCourse: undefined
});

export const getCoursesThunk = createAsyncThunk("courses.slice/get", async (props: PaginationFiltersModel | undefined, { getState }) => {
  const state = getState() as RootState;
  const filters = selectCoursesPageFilters(state);
  const useCase = await locator.get<IocProvider<GetCoursesUseCase>>(TYPES.GetCoursesUseCase)();
  return await useCase.execute({ ...filters, page: (props?.page ?? 0) + 1, itemsPerPage: props?.itemsPerPage ?? 10 });
});

export const getLatestCourseThunk = createAsyncThunk(
  "courses.slice/getLatestCourse",
  async (props: PaginationFiltersModel | undefined, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const filters = selectCoursesPageFilters(state);
      const useCase = await locator.get<IocProvider<GetLatestCourseUseCase>>(TYPES.GetLatestCourseUseCase)();
      return await useCase.execute({ ...filters, page: 1, itemsPerPage: 1 });
    } catch (e) {
      console.error(e);
      dispatch(showErrorAlertThunk());
    }
  }
);

const coursesSlice = createSlice({
  name: "courses.slice",
  initialState: initialState(),
  reducers: {
    resetCourses: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCoursesThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg?.page ?? 0;
    });
    builder.addCase(getCoursesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCoursesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
    builder.addCase(getLatestCourseThunk.fulfilled, (state, action) => {
      state.latestCourse = action?.payload?.results[0];
    });
  }
});

function selectBase(state: RootState) {
  return state.courses;
}
export const selectCourses = createSelector(selectBase, (slice) => slice.pagination);
export const selectLatestCourse = createSelector(selectBase, (slice) => slice.latestCourse);
export const selectCoursesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectCoursesPaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export const { resetCourses } = coursesSlice.actions;
export default coursesSlice.reducer;
