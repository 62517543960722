import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import CanEditDentistMiddleware from "@/src/ui/router/middlewares/can_edit_dentist_middleware";
import IsNotDentistMiddleware from "@/src/ui/router/middlewares/is_not_dentist_middleware";
const EditDentistPage = lazy(() => import("../../edit_dentist/components/edit_dentist_page/edit_dentist_page"));
const DentistDetailPage = lazy(() => import("../../dentist_detail/components/dentist_detail_page/dentist_detail_page"));
const AddDentistPage = lazy(() => import("../../add_dentist/components/add_dentist_page/add_dentist_page"));
const DentistsPage = lazy(() => import("@/src/ui/pages/dentists/components/dentists_page/dentists_page"));

export default function DentistPages() {
  const page = useRoutes([
    {
      index: true,
      element: (
        <IsNotDentistMiddleware>
          <DentistsPage />
        </IsNotDentistMiddleware>
      )
    },
    {
      path: "add",
      element: (
        <HasRoleMiddleware profileRole={["CLINICS_EDIT", "CLINICS_EDIT_OWN"]}>
          <AddDentistPage />
        </HasRoleMiddleware>
      )
    },
    {
      path: "detail/:id",
      element: <DentistDetailPage />
    },
    {
      path: "detail/:id/edit",
      element: (
        <CanEditDentistMiddleware>
          <EditDentistPage />
        </CanEditDentistMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole={["DENTISTS_VIEW", "DENTISTS_VIEW_OWN"]}>{page}</HasRoleMiddleware>;
}
