import Add from "./add.svg";
import Minus from "./minus.svg";
import ChevronDown from "./chevron_down.svg";
import ChevronLeft from "./chevron_left.svg";
import ChevronRight from "./chevron_right.svg";
import ChevronUp from "./chevron_up.svg";
import Badge from "./badge.svg";
import Bell from "./bell.svg";
import Board from "./board.svg";
import Bookmark from "./bookmark.svg";
import Bullet from "./bullet.svg";
import Card from "./card.svg";
import Close from "./close.svg";
import Erp from "./erp.svg";
import Edit from "./edit.svg";
import Gear from "./gear.svg";
import Globe from "./globe.svg";
import Info from "./info.svg";
import InfoVariant from "./info_i.svg";
import Logout from "./logout.svg";
import QuestionCircle from "./question_circle.svg";
import Settings from "./settings.svg";
import Search from "./search.svg";
import Smartphone from "./smartphone.svg";
import ThreeDots from "./three_dots.svg";
import Tip from "./tip.svg";
import SortAsc from "./sort_asc.svg";
import Users from "./users.svg";
import Chat from "./chat.svg";
import Upload from "./upload.svg";
import Calendar from "./calendar.svg";
import ArrowRight from "./arrow_right.svg";
import Statistics from "./statistics.svg";
import Trash from "./trash.svg";
import Download from "./download.svg";
import File from "./file.svg";
import Save from "./save.svg";
import Eye from "./eye.svg";
import EyeClosed from "./eye_closed.svg";
import ArrowDown from "./arrow_down.svg";
import Camera from "./camera.svg";

export {
  Add,
  Minus,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ArrowRight,
  Badge,
  Bell,
  Board,
  Bookmark,
  Bullet,
  Calendar,
  Card,
  Close,
  Edit,
  Erp,
  Eye,
  EyeClosed,
  Gear,
  Globe,
  Info,
  InfoVariant,
  Logout,
  QuestionCircle,
  Search,
  Settings,
  Smartphone,
  SortAsc,
  ThreeDots,
  Tip,
  Users,
  Upload,
  Chat,
  Statistics,
  Trash,
  Download,
  File,
  Save,
  ArrowDown,
  Camera
};
