import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { ManufacturingLotItemsSlice } from "../view_models/manufacturing_lot_items.slice";

export const initialState = (): ManufacturingLotItemsSlice => ({
  selected: new Set()
});

const manufacturingLotItemsSlice = createSlice({
  name: "manufacturingLotItems.slice",
  initialState: initialState(),
  reducers: {
    resetManufacturingLotItems: initialState,
    setManufacturingLotItemSelected(state, action: PayloadAction<string>) {
      state.selected.add(action.payload);
    },
    setManufacturingLotItemUnselected(state, action: PayloadAction<string>) {
      state.selected.delete(action.payload);
    },
    setManufacturingLotItemsSelected(state, action: PayloadAction<string[]>) {
      state.selected = new Set(action.payload);
    },
    setAllManufacturingLotItemsUnselected(state) {
      state.selected.clear();
    }
  },
});

export const {
  resetManufacturingLotItems,
  setManufacturingLotItemsSelected,
  setAllManufacturingLotItemsUnselected,
  setManufacturingLotItemSelected,
  setManufacturingLotItemUnselected,
} = manufacturingLotItemsSlice.actions;

const selectBase = (state: RootState) => state.manufacturingLotItems;

export const selectManufacturingLotItemsSelected = createSelector(selectBase, (slice) => slice.selected);
export const selectManufacturingLotItemsSelectedIsEmpty = createSelector(selectManufacturingLotItemsSelected, (set) => !set.size);
export const selectManufacturingLotItemsSelectedArray = createSelector(selectManufacturingLotItemsSelected, (set) => Array.from(set.values()));

export default manufacturingLotItemsSlice.reducer;
