import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";
import type { CasesByStateStatsSlice } from "../view_models/cases_by_state_stats.slice";
import type { GetCasesByStateStatsUseCase } from "@/src/core/cases/domain/use_cases/get_cases_by_state_stats_use_case";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";

const initialState = (): CasesByStateStatsSlice => getInitialDetailSlice();

export const getCasesByStateStatsThunk = createAsyncThunk("casesByStateStats.slice/getCasesByStateStats", async (_, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetCasesByStateStatsUseCase>>(TYPES.GetCasesByStateStatsUseCase)();
    return await useCase.execute();
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

const casesByStateStatsSlice = createSlice({
  name: "casesByStateStats.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCasesByStateStatsThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCasesByStateStatsThunk.rejected, (state) => {
      state.loadingState = "error";
    });
    builder.addCase(getCasesByStateStatsThunk.fulfilled, (state, action) => {
      state.loadingState = "loaded";
      state.detail = action.payload;
    });
  }
});

function selectBase(state: RootState) {
  return state.casesByStateStats;
}

export const selectCasesByStateStats = createSelector(selectBase, (slice) => slice.detail);
export const selectCasesByStateStatsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export default casesByStateStatsSlice.reducer;
