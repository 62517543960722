import { locator } from "@/src/core/app/ioc";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetManufacturingItemsModel } from "@/src/core/manufacturing_items/domain/models/get_manufacturing_items.model";
import type { GetManufacturingItemsUseCase } from "@/src/core/manufacturing_items/domain/use_cases/get_manufacturing_items_use_case";
import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import type { ManufacturingItemsSlice } from "../view_models/manufacturing_items.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";

export const initialState = (): ManufacturingItemsSlice => ({
  ...getInitialPaginationSlice(),
  reference: null,
  treatment: null,
  selected: new Set()
});

export const getManufacturingItemsThunk = createAsyncThunk("manufacturingItems.slice/get", async (input: GetManufacturingItemsModel) => {
  const useCase = await locator.get<IocProvider<GetManufacturingItemsUseCase>>(TYPES.GetManufacturingItemsUseCase)();
  return await useCase.execute({ ...input, page: (input.page ?? 0) + 1, itemsPerPage: 10 });
});

const manufacturingItemsSlice = createSlice({
  name: "manufacturingItems.slice",
  initialState: initialState(),
  reducers: {
    resetManufacturingItems: initialState,
    setManufacturingItemSelected(state, action: PayloadAction<string>) {
      state.selected.add(action.payload);
    },
    setManufacturingItemUnselected(state, action: PayloadAction<string>) {
      state.selected.delete(action.payload);
    },
    setAllManufacturingItemsSelected(state) {
      state.selected = new Set(state.pagination?.results.map((r) => r.id));
    },
    setAllManufacturingItemsUnselected(state) {
      state.selected.clear();
    }
  },
  extraReducers(builder) {
    builder.addCase(getManufacturingItemsThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg.page ?? 0;
    });
    builder.addCase(getManufacturingItemsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getManufacturingItemsThunk.fulfilled, (state, action) => {
      state.reference = action.meta.arg.reference;
      state.treatment = action.meta.arg.treatment;
      state.pagination = action.payload;
      const itemIds = new Set(action.payload.results.map((i) => i.id));
      state.selected = new Set(Array.from(state.selected.values()).filter((id) => itemIds.has(id)));
      state.loadingState = "loaded";
    });
  }
});

export const {
  resetManufacturingItems,
  setAllManufacturingItemsSelected,
  setAllManufacturingItemsUnselected,
  setManufacturingItemSelected,
  setManufacturingItemUnselected
} = manufacturingItemsSlice.actions;

const selectBase = (state: RootState) => state.manufacturingItems;

export const selectManufacturingItems = createSelector(selectBase, (slice) => slice.pagination);
export const selectManufacturingItemsTreatment = createSelector(selectBase, (slice) => slice.treatment);
export const selectManufacturingItemsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectManufacturingItemsSelected = createSelector(selectBase, (slice) => slice.selected);
export const selectManufacturingItemsSelectedArray = createSelector(selectManufacturingItemsSelected, (set) => Array.from(set.values()));
export const selectManufacturingItemsAllSelected = createSelector(selectManufacturingItems, selectManufacturingItemsSelected, (items, selected) =>
  items?.results.every((i) => selected.has(i.id))
);
// is true if some selected items already have a lot
export const selectManufacturingItemsSelectedAlreadyHaveLot = createSelector(
  selectManufacturingItems,
  selectManufacturingItemsSelected,
  (items, selected) => items?.results.filter((i) => i.lot).some((i) => selected.has(i.id))
);

export const selectManufacturingItemsPaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export default manufacturingItemsSlice.reducer;
