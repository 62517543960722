import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { TeethSlice } from "../view_models/teeth.slice";
import type { GetTeethUseCase } from "@/src/core/teeth/domain/use_cases/get_teeth_use_case";

const initialState = (): TeethSlice => ({
  results: [],
  hasError: false,
  isLoading: false
});

export const getTeethThunk = createAsyncThunk("teeth.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetTeethUseCase>>(TYPES.GetTeethUseCase)();
  return await useCase.execute();
});

const teethSlice = createSlice({
  name: "teeth.slice",
  initialState: initialState(),
  reducers: {
    resetTeeth: initialState
  },
  extraReducers(builder) {
    builder.addCase(getTeethThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getTeethThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getTeethThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.teeth;

export const selectTeeth = createSelector(selectBase, (slice) => slice.results);
export const selectTeethHasResults = createSelector(selectTeeth, (results) => results.length > 0);
export const selectTeethError = createSelector(selectBase, (slice) => slice.hasError);
export const selectTeethIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export default teethSlice.reducer;
