import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { colors } from "@/src/ui/styles/colors";
import { px2rem } from "@/src/ui/styles/utils";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { truncateText } from "@/src/ui/styles/utils";

export interface InputSizeProps {
  inputsize?: "l" | "m";
  icon?: string;
}

const DateField = styled.div<InputSizeProps>`
  input {
    ${typography.small};
    height: ${({ inputsize = "m" }) => px2rem(inputsize === "m" ? 38 : 40)};
    display: block;
    width: 100%;
    padding: ${({ inputsize = "m" }) => px2rem(inputsize === "m" ? 7 : 11)} ${px2rem(48)}
      ${({ inputsize = "m" }) => px2rem(inputsize === "m" ? 7 : 11)} ${px2rem(16)};
    border-radius: ${px2rem(4)} ${px2rem(4)} 0 0;
    background-color: ${colors.neutral200};
    border-top-style: unset;
    border-left-style: unset;
    border-right-style: unset;
    border-bottom: solid 1px ${colors.neutral700};
    box-shadow: 0 1px 0 ${colors.neutral700};
    position: relative;
    ${truncateText};
    ::placeholder {
      color: ${colors.neutral500};
    }
    :focus {
      outline: none;
      border-top-style: unset;
      border-left-style: unset;
      border-right-style: unset;
      border-bottom: solid 1px ${colors.brand500};
      box-shadow: 0 1px 0 ${colors.brand500};
    }
    :disabled {
      pointer-events: none;
      color: ${colors.neutral400};
      background-color: ${colors.neutral200};
      border-bottom: solid 1px ${colors.neutral400};
      box-shadow: 0 1px 0 ${colors.neutral400};
    }
  }
  .react-datepicker__input-container {
    &:after {
      content: "";
      background-image: url("data:image/svg+xml,${(props) => props.icon}");
      width: ${px2rem(18)};
      height: ${px2rem(18)};
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
  .react-datepicker__close-icon {
    &:after {
      background-color: transparent;
      color: ${colors.brand500};
      font-size: 16px;
      padding-right: ${px2rem(30)};
    }
  }
  .react-datepicker__year-dropdown,
  .react-datepicker {
    background-color: ${colors.neutral200};
    ${borderRadius.s}
    border: 1px solid ${colors.neutral300};
    box-shadow: 0 4px 20px rgba(235, 239, 243, 0.6);
    padding: ${px2rem(10)};
  }
  .react-datepicker__year-dropdown {
    padding: unset;
  }
  .react-datepicker__navigation--years {
    position: relative;
    &:after {
      content: "";
      border-style: solid;
      border-width: 3px 3px 0 0;
      display: block;
      height: 9px;
      width: 9px;
      color: ${colors.brand500};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
    &.react-datepicker__navigation--years-upcoming::after {
      transform: translate3d(-50%, 0, 0) rotate(-45deg);
    }
    &.react-datepicker__navigation--years-previous::after {
      transform: translate3d(-50%, -50%, 0) rotate(135deg);
    }
  }
  .react-datepicker__year-option {
    &:hover {
      background-color: ${colors.brand500};
      color: white;
      .react-datepicker__navigation--years::after,
      .react-datepicker__year-option--selected {
        color: white;
      }
    }
  }
  .react-datepicker__year-read-view {
    &:hover {
      .react-datepicker__year-read-view--down-arrow {
        border-top-color: ${colors.brand500};
      }
    }
  }
  .react-datepicker__current-month {
    padding-bottom: ${px2rem(10)};
  }
  .react-datepicker__navigation-icon {
    top: ${px2rem(10)};
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${colors.brand500};
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: ${colors.neutral200}!important;
  }
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: ${px2rem(20)};
  }
  .react-datepicker__month-container {
    .react-datepicker__month,
    .react-datepicker__header {
      background-color: ${colors.neutral200};
      border-bottom: unset;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: ${colors.brand500};
      color: white;
    }
  }
  .react-datepicker__header--time {
    display: none;
  }
  &&& {
    .react-datepicker__time {
      background-color: ${colors.neutral200};
    }
  }
  .react-datepicker__time-container {
    border-left: unset;
  }
  &&& {
    .react-datepicker__time-list-item--selected {
      background-color: ${colors.brand500}!important;
      color: white !important;
      padding-top: ${px2rem(8)};
      ${borderRadius.s}
    }
  }
`;

export default { DateField };
