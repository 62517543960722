import type { PropsWithChildren } from "react";
import Styled from "./no_results_message.styled";
import { useTranslation } from "react-i18next";
import type { LoaderHeight } from "@/src/ui/styles/loader_height";

export interface NoResultsMessageProps extends PropsWithChildren {
  className?: string;
  height?: LoaderHeight;
}

export const NoResultsMessage = ({ children, ...props }: NoResultsMessageProps) => {
  const { t } = useTranslation("errors");
  return (
    <Styled.Container {...props}>
      <Styled.Text>{children ?? t("noResults.general")}</Styled.Text>
    </Styled.Container>
  );
};
