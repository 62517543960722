import type { DistributorDetailSlice } from "@/src/ui/pages/distributors/view_models/distributor_detail.slice";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { serializeError } from "@/src/common/utils/rtk";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";
import type { GetDistributorDetailUseCase } from "@/src/core/distributors/domain/use_cases/get_distributor_detail_use_case";

const initialState = (): DistributorDetailSlice => getInitialDetailSlice();

export const getDistributorDetailThunk = createAsyncThunk(
  "distributorDetail.slice/get",
  async (id: string) => {
    const useCase = await locator.get<IocProvider<GetDistributorDetailUseCase>>(TYPES.GetDistributorDetailUseCase)();
    return await useCase.execute(id);
  },
  { serializeError }
);

const distributorDetailSlice = createSlice({
  name: "distributorDetail.slice",
  initialState: initialState(),
  reducers: {
    resetDistributorDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getDistributorDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getDistributorDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getDistributorDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.distributorDetail;

export const selectDistributorDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectDistributorDetailLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const { resetDistributorDetail } = distributorDetailSlice.actions;
export default distributorDetailSlice.reducer;
