import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { InvoicesPageSortSlice } from "../view_models/invoices_page_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): InvoicesPageSortSlice => ({ value: {} });

const invoicesPageSortSlice = createSlice({
  name: "invoicesPageSort.slice",
  initialState: initialState(),
  reducers: {
    resetInvoicesPageSort: initialState,
    setInvoicesPageSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.invoicesPageSort;
}

export const selectInvoicesPageSort = createSelector(selectBase, (slice) => slice.value);

export const { setInvoicesPageSort, resetInvoicesPageSort } = invoicesPageSortSlice.actions;
export default invoicesPageSortSlice.reducer;

export const invoicesPageSortColumnNames = [
  "order[invoiceNumber]",
  "order[businessName]",
  "order[total]",
  "order[paid]",
  "order[expiration]",
  "order[createdAt]"
];
