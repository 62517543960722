import BasicErrorMessage from "@/src/ui/components/error_displayers/error_messages/basic_error_message/basic_error_message";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";
import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import { useCallback } from "react";
import { getTeethThunk, selectTeethError, selectTeethHasResults, selectTeethIsLoading } from "../../state/teeth.slice";
import { selectTeethIdToNameMap, selectTeethNameToIdMap } from "../../state/teeth_name_map.selectors";
import type { TeethSelectProps } from "../teeth_select/teeth_select";
import TeethSelect from "../teeth_select/teeth_select";
import { useTranslation } from "react-i18next";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";

export default function TeethSelectFieldLoader(p: Omit<TeethSelectProps, "teethNameToIdMap" | "teethIdToNameMap">) {
  const {t} = useTranslation("teeth")
  
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectTeethIsLoading);
  const hasError = useAppSelector(selectTeethError);
  const hasResults = useAppSelector(selectTeethHasResults);
  const teethNameToIdMap = useAppSelector(selectTeethNameToIdMap);
  const teethIdToNameMap = useAppSelector(selectTeethIdToNameMap);

  const reload = useCallback(() => dispatch(getTeethThunk()), [dispatch]);
  useEffectRunOnce(() => {
    if (!hasResults) reload();
  }, [hasResults, reload]);

  if (isLoading) return <BasicSpinner ariaLabel={t("loading")} />;
  if (hasError) return <BasicErrorMessage retry={reload}>{t("error")}</BasicErrorMessage>;
  return <TeethSelect {...p} teethNameToIdMap={teethNameToIdMap} teethIdToNameMap={teethIdToNameMap} />;
}
