import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { ClinicsPageFiltersSlice } from "@/src/ui/pages/clinics/view_models/clinics_page_filters.slice";
import type { ClinicFiltersModel } from "@/src/core/clinics/domain/models/clinic_filters_model";

const initialState = (): ClinicsPageFiltersSlice => ({
  show: false,
  filters: {
    name: "",
    dentist: [],
    province: "",
    country: ""
  }
});

const clinicsSlice = createSlice({
  name: "clinicsPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetClinicsPageFilters: initialState,
    cleanClinicsPageFilters(state) {
      state.filters = initialState().filters;
    },
    setClinicsPageFilters(state, action: PayloadAction<Partial<ClinicFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleClinicsPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectClinicsPageFiltersBase(state: RootState) {
  return state.clinicsPageFilters;
}

export const selectShowClinicsPageFilters = createSelector(selectClinicsPageFiltersBase, (slice) => slice.show);
export const selectClinicsPageFilters = createSelector(selectClinicsPageFiltersBase, (slice) => slice.filters);
export const selectClinicsPageFiltersName = createSelector(selectClinicsPageFilters, (filters) => filters.name);
export const { resetClinicsPageFilters, cleanClinicsPageFilters, setClinicsPageFilters, toggleClinicsPageFilters } = clinicsSlice.actions;
export default clinicsSlice.reducer;
