import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetNotificationsUseCase } from "@/src/core/notifications/domain/use_cases/get_notifications_use_case";
import type { NotificationsSlice } from "@/src/ui/pages/notifications/view_models/notifications.slice";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";
import { store } from "@/src/ui/state";
import { createElement } from "react";
import Translate from "@/src/ui/components/text/translate/translate";

const initialState = (): NotificationsSlice => ({
  notifications: [],
  hasError: false,
  isLoading: true
});

export const getNotificationsThunk = createAsyncThunk("notifications.slice/getNotifications", async () => {
  const useCase = await locator.get<IocProvider<GetNotificationsUseCase>>(TYPES.GetNotificationsUseCase)();
  const promise = useCase.execute();
  promise.catch((e) => {
    console.error(e);
    store.dispatch(showErrorAlertThunk(createElement(Translate, { ns: "notifications", i18nKey: "error" })));
  });
  return await promise;
});

const notificationsSlice = createSlice({
  name: "notifications.slice",
  initialState: initialState(),
  reducers: {
    resetNotifications: initialState
  },
  extraReducers(builder) {
    builder.addCase(getNotificationsThunk.pending, (state) => {
      state.hasError = false;
      state.isLoading = true;
    });
    builder.addCase(getNotificationsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getNotificationsThunk.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.isLoading = false;
    });
  }
});

function selectNotificationsBase(state: RootState) {
  return state.notifications;
}

export const selectNotifications = createSelector(selectNotificationsBase, (slice) => slice.notifications);
export const selectHasUnreadNotifications = createSelector(selectNotifications, (notifications) =>
  Boolean(notifications?.filter((notification) => !notification.isRead).length)
);
export const selectNotificationsDoNotExist = createSelector(selectNotifications, (notifications) => Boolean(notifications.length <= 0));
export const selectNotificationsIsLoading = createSelector(selectNotificationsBase, (slice) => slice.isLoading);
export const selectNotificationsHasError = createSelector(selectNotificationsBase, (slice) => slice.hasError);
export const { resetNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
