import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import ProfileIsNotClientMiddleware from "@/src/ui/router/middlewares/profile_is_not_client_middleware";
const CreateTreatmentPage = lazy(() => import("../../create_treatment/components/create_treatment_page/create_treatment_page"));

export default function TreatmentsPages() {
  const page = useRoutes([
    {
      path: "/new",
      element: (
        <ProfileIsNotClientMiddleware>
          <CreateTreatmentPage />
        </ProfileIsNotClientMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <>{page}</>;
}
