import type { ILanguageService } from "@/src/core/app/domain/interfaces/i_language_service";
import { injectable } from "inversify";

@injectable()
export class LanguageService implements ILanguageService {
  readonly key = "language";
  readonly languages = ["en", "es", "fr"];
  private _language = "es";

  get language() {
    return this._language;
  }

  init(): void {
    const locale = navigator.language;
    const localStorageLanguage = localStorage.getItem(this.key);
    const language = localStorageLanguage ?? this.languages.find((l) => locale.includes(l)) ?? this.languages[0];
    this.setLanguage(language);
  }

  setLanguage(language: string): void {
    if (!this.languages.includes(language)) {
      throw new Error(language + " is not a valid language");
    }
    localStorage.setItem(this.key, language);
    this._language = language;
  }
}
