import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CourseTableSortSlice } from "@/src/ui/pages/courses/view_models/course_table_sort";

const initialState = (): CourseTableSortSlice => ({});

const courseTableSortSlice = createSlice({
  name: "courseTableSort.slice",
  initialState: initialState(),
  reducers: {
    resetCourseTableSort: initialState,
    setCourseTableSort(state, action: PayloadAction<Partial<string>>) {
      state.key = action.payload;
    }
  }
});

function selectCourseTableSort(state: RootState) {
  return state.courseTableSort;
}

export const selectSort = createSelector([selectCourseTableSort], (slice) => slice.key);
export const { setCourseTableSort, resetCourseTableSort } = courseTableSortSlice.actions;
export default courseTableSortSlice.reducer;
