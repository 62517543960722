import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetCourseAttendeeUseCase } from "@/src/core/courses/attendees/domain/use_cases/get_course_attendee_use_case";
import type { CourseInvoiceSlice } from "@/src/ui/pages/courses/course_detail/view_models/course_invoice.slice";
import type { GetCourseAttendeeInputModel } from "@/src/core/courses/attendees/domain/models/get_course_attendee_input_model";
import {
  selectCourseDetail,
  selectCourseDetailError,
  selectCourseDetailIsLoading
} from "@/src/ui/pages/courses/course_detail/state/course_detail.slice";
import { showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import type { CreateCourseInvoiceThunkInput } from "@/src/ui/pages/courses/course_detail/view_models/course_invoice.slice";
import type { CreateCourseAttendeeInvoiceUseCase } from "@/src/core/courses/attendees/domain/use_cases/create_course_attendee_invoice_use_case";

const initialState = (): CourseInvoiceSlice => ({
  attendee: null,
  loading: false,
  error: false
});

export const getCourseAttendeeThunk = createAsyncThunk("courseInvoice.slice/attendee", async (input: GetCourseAttendeeInputModel) => {
  const useCase = await locator.get<IocProvider<GetCourseAttendeeUseCase>>(TYPES.GetCourseAttendeeUseCase)();
  const attendee = await useCase.execute(input);
  if (attendee.profile.dentist) {
    return attendee;
  } else {
    throw new Error("Missing dentist for course invoice generation");
  }
});

export const createCourseInvoiceThunk = createAsyncThunk(
  "courseInvoice.slice/create",
  async ({ expiredAt, ...thunkInput }: CreateCourseInvoiceThunkInput, { dispatch, getState }) => {
    const state = getState() as RootState;
    const courseId = state.courseDetail.detail?.id;
    const attendeeId = state.courseInvoice.attendee?.id;
    if (attendeeId && courseId && expiredAt) {
      const useCase = await locator.get<IocProvider<CreateCourseAttendeeInvoiceUseCase>>(TYPES.CreateCourseAttendeeInvoiceUseCase)();
      await useCase.execute({ invoice: { expiredAt, ...thunkInput }, courseId, attendeeId });
      dispatch(showSuccessAlertThunk());
    } else {
      throw Error("Missing attendee id, course id or expiration date");
    }
  }
);

const courseInvoiceSlice = createSlice({
  name: "courseInvoice.slice",
  initialState: initialState(),
  reducers: {
    reset: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCourseAttendeeThunk.fulfilled, (state, action) => {
      state.attendee = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getCourseAttendeeThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCourseAttendeeThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loading = false;
      state.error = true;
    });
  }
});

const selectBase = (state: RootState) => state.courseInvoice;

export const selectCourseInvoiceAttendee = createSelector(selectBase, (slice) => slice.attendee);
export const selectCourseInvoiceLoading = createSelector(
  [selectBase, selectCourseDetailIsLoading],
  (slice, detailLoading) => slice.loading || detailLoading
);
export const selectCourseInvoiceError = createSelector([selectBase, selectCourseDetailError], (slice, detailError) => slice.error || detailError);

export const selectCourseInvoiceLine = createSelector(selectCourseDetail, (courseDetail) => {
  return {
    name: courseDetail?.name ?? "",
    price: courseDetail?.attendeePrice.amount.toString() ?? "0",
    units: "1",
    discount: "0",
    total: "0"
  };
});
export const { reset: resetCourseInvoiceSlice } = courseInvoiceSlice.actions;
export default courseInvoiceSlice.reducer;
