import Styled from "./basic_error_message.styled";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import type { LoaderHeight } from "@/src/ui/styles/loader_height";

export interface BasicErrorMessageProps extends PropsWithChildren {
  className?: string;
  retry?(): void;
  height?: LoaderHeight;
}

export default function BasicErrorMessage({ children, retry, ...props }: BasicErrorMessageProps) {
  const { t } = useTranslation("errors", { keyPrefix: "basicErrorMessage" });

  return (
    <Styled.Container {...props}>
      <Styled.Text>
        {children ?? t("general")}
        {retry && ". "}
        {retry && <Styled.Retry onClick={retry}>{t("retry")}</Styled.Retry>}
      </Styled.Text>
    </Styled.Container>
  );
}
