import styled from "styled-components";
import { spacing } from "@/src/ui/styles/spacing";
import type { FormRowProps } from "@/src/ui/components/spacing/form_row/form_row";

const FormRow = styled.div<FormRowProps>`
  min-width: 0;
  display: flex;
  margin-bottom: ${({ marginBottom = "xs" }) => spacing[marginBottom]};
  > * {
    flex: 1 1 100%;
    min-width: 0;
    &:not(:last-child) {
      margin-right: ${spacing.xs};
    }
  }
`;

export default { FormRow };
