import { instanceToPlain, plainToInstance } from "class-transformer";
import type { ClassConstructor } from "class-transformer/types/interfaces";
import type { ClassTransformOptions } from "class-transformer/types/interfaces";

const options: ClassTransformOptions = { excludeExtraneousValues: true };

export function toData<T>(object: T) {
  return instanceToPlain(object, options);
}

export function toDomain<T, V>(cls: ClassConstructor<T>, plain: V) {
  return plainToInstance(cls, plain, options);
}

export function copyClass<T, V>(cls: ClassConstructor<T>, object: V) {
  return toDomain(cls, toData(object));
}

export function listToDomain<T, V>(cls: ClassConstructor<T>, plain: V[]) {
  return plain.map((p) => toDomain(cls, p));
}
