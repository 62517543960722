import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CaseStatsSlice } from "@/src/ui/pages/cases/view_models/case_stats.slice";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCaseStatsUseCase } from "@/src/core/cases/domain/use_cases/get_case_stats_use_case";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): CaseStatsSlice => ({});

export const getCaseStatsThunk = createAsyncThunk("caseStats.slice/getCaseStats", async (_, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetCaseStatsUseCase>>(TYPES.GetCaseStatsUseCase)();
    return await useCase.execute();
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk());
  }
});

const caseStatsSlice = createSlice({
  name: "caseStats.slice",
  initialState: initialState(),
  reducers: {
    clear: (state) => {
      state.stats = undefined;
    }
  },
  extraReducers(builder) {
    builder.addCase(getCaseStatsThunk.fulfilled, (state, action) => {
      state.stats = action.payload;
    });
  }
});

function selectBase(state: RootState) {
  return state.caseStats;
}

export const selectCaseStats = createSelector(selectBase, (slice) => slice.stats);
export const { clear } = caseStatsSlice.actions;
export default caseStatsSlice.reducer;
