import "reflect-metadata";
import { Container, decorate, injectable } from "inversify";
import { TYPES } from "./types";
import { EnvVars } from "@/src/core/app/domain/models/env_vars";
import { bindDynamicModule, bindSingletonDynamicModule } from "./utils";
import { LanguageService } from "@/src/core/app/data/services/language_service";
import { FileService } from "@/src/common/services/file_service";

decorate(injectable(), FileService);

const locator = new Container();
locator.bind(TYPES.IEnvVars).to(EnvVars);
locator.bind(TYPES.ILanguageService).to(LanguageService).inSingletonScope();
locator.bind(TYPES.IFileService).to(FileService).inSingletonScope();
bindDynamicModule(TYPES.SecretAlignerApiService, () => import("../data/services/secret_aligner_api_service"));
bindSingletonDynamicModule(TYPES.IAuthTokenService, () => import("../data/services/auth_token_service"));

// User
bindDynamicModule(TYPES.IAuthRepository, () => import("../../user/data/repositories/auth_repository"));
bindDynamicModule(TYPES.IUsersRepository, () => import("../../user/data/repositories/users_repository"));
bindDynamicModule(TYPES.IProfilesRepository, () => import("../../user/data/repositories/profiles_repository"));
bindDynamicModule(TYPES.LoginUseCase, () => import("../../user/domain/use_cases/login_use_case"));
bindDynamicModule(TYPES.LogoutUseCase, () => import("../../user/domain/use_cases/logout_use_case"));
bindDynamicModule(TYPES.RefreshSessionUseCase, () => import("../../user/domain/use_cases/refresh_session_use_case"));
bindDynamicModule(TYPES.RecoverPasswordUseCase, () => import("../../user/domain/use_cases/recover_password_use_case"));
bindDynamicModule(TYPES.RecoverPasswordChangeUseCase, () => import("../../user/domain/use_cases/recover_password_change_use_case"));
bindDynamicModule(TYPES.GetMyUserUseCase, () => import("../../user/domain/use_cases/get_my_user_use_case"));
bindDynamicModule(TYPES.GetUsersUseCase, () => import("../../user/domain/use_cases/get_users_use_case"));
bindDynamicModule(TYPES.GetUserDetailUseCase, () => import("../../user/domain/use_cases/get_user_detail_use_case"));
bindDynamicModule(TYPES.ValidateRedirectSessionUrlUseCase, () => import("../../user/domain/use_cases/validate_redirect_session_url_use_case"));
bindDynamicModule(TYPES.CreateProfileUseCase, () => import("../../user/domain/use_cases/create_profile_use_case"));
bindDynamicModule(TYPES.GetMyProfilesUseCase, () => import("../../user/domain/use_cases/get_my_profiles_use_case"));
bindDynamicModule(TYPES.EditProfileUseCase, () => import("../../user/domain/use_cases/edit_profile_use_case"));
bindDynamicModule(TYPES.SwitchProfileUseCase, () => import("../../user/domain/use_cases/switch_profile_use_case"));
bindDynamicModule(TYPES.GetProfileDetailUseCase, () => import("../../user/domain/use_cases/get_profile_detail_use_case"));
bindDynamicModule(TYPES.GetProfilesUseCase, () => import("../../user/domain/use_cases/get_profiles_use_case"));
bindDynamicModule(TYPES.RemoveSessionTokenUseCase, () => import("../../user/domain/use_cases/remove_session_token_use_case"));
bindDynamicModule(TYPES.ActivateUserUseCase, () => import("../../user/domain/use_cases/activate_user_use_case"));

// Cases
bindDynamicModule(TYPES.ICasesRepository, () => import("../../cases/data/repositories/cases_repository"));
bindDynamicModule(TYPES.ICaseFilesRepository, () => import("../../cases/data/repositories/case_files_repository"));
bindDynamicModule(TYPES.GetCaseStatsUseCase, () => import("../../cases/domain/use_cases/get_case_stats_use_case"));
bindDynamicModule(TYPES.GetCasesUseCase, () => import("../../cases/domain/use_cases/get_cases_use_case"));
bindDynamicModule(TYPES.GetCaseDetailUseCase, () => import("../../cases/domain/use_cases/get_case_detail_use_case"));
bindDynamicModule(TYPES.CreateCaseUseCase, () => import("../../cases/domain/use_cases/create_case_use_case"));
bindDynamicModule(TYPES.UpdateProductCaseUseCase, () => import("../../cases/domain/use_cases/update_product_case_use_case"));
bindDynamicModule(TYPES.GetCaseFilesUseCase, () => import("../../cases/domain/use_cases/get_case_files_use_case"));
bindDynamicModule(TYPES.GetCaseFileDetailUseCase, () => import("../../cases/domain/use_cases/get_case_file_detail_use_case"));
bindDynamicModule(TYPES.UploadCaseFileUseCase, () => import("../../cases/domain/use_cases/upload_case_file_use_case"));
bindDynamicModule(TYPES.DeleteCaseFileUseCase, () => import("../../cases/domain/use_cases/delete_case_file_use_case"));
bindDynamicModule(TYPES.UploadCaseFileDocumentationUseCase, () => import("../../cases/domain/use_cases/upload_case_file_documentation_use_case"));
bindDynamicModule(TYPES.ICaseDocumentationRepository, () => import("../../cases/data/repositories/case_documentation_repository"));
bindDynamicModule(TYPES.GetCaseDocumentsUseCase, () => import("../../cases/domain/use_cases/get_case_documents_use_case"));
bindDynamicModule(TYPES.MarkProductCaseAsUrgentUseCase, () => import("../../cases/domain/use_cases/mark_product_case_as_urgent_use_case"));
bindDynamicModule(TYPES.CancelProductCaseUseCase, () => import("../../cases/domain/use_cases/cancel_product_case_use_case"));
bindDynamicModule(TYPES.GetCaseStageUseCase, () => import("../../cases/domain/use_cases/get_case_stage_use_case"));
bindDynamicModule(TYPES.TransferCaseUseCase, () => import("../../cases/domain/use_cases/transfer_case_use_case"));
bindDynamicModule(TYPES.FinishCaseUseCase, () => import("../../cases/domain/use_cases/finish_case_use_case"));
bindDynamicModule(TYPES.GetCasesByStateStatsUseCase, () => import("../../cases/domain/use_cases/get_cases_by_state_stats_use_case"));

// Activities
bindDynamicModule(TYPES.IActivityLogsRepository, () => import("../../cases/activities/data/repositories/activity_logs_repository"));
bindDynamicModule(TYPES.GetActivityLogsByCaseUseCase, () => import("../../cases/activities/domain/use_cases/get_activity_logs_by_case_use_case"));

// Case notes
bindDynamicModule(TYPES.ICaseNotesRepository, () => import("../../cases/case_notes/data/repositories/case_notes_repository"));
bindDynamicModule(TYPES.CreateCaseNoteUseCase, () => import("../../cases/case_notes/domain/use_cases/create_case_note_use_case"));
bindDynamicModule(TYPES.DeleteCaseNoteUseCase, () => import("../../cases/case_notes/domain/use_cases/delete_case_note_use_case"));
bindDynamicModule(TYPES.GetCaseNoteUseCase, () => import("../../cases/case_notes/domain/use_cases/get_case_note_use_case"));
bindDynamicModule(TYPES.GetCaseNotesUseCase, () => import("../../cases/case_notes/domain/use_cases/get_case_notes_use_case"));
bindDynamicModule(TYPES.EditCaseNoteUseCase, () => import("../../cases/case_notes/domain/use_cases/edit_case_note_use_case"));

// Case comments
bindDynamicModule(TYPES.ICaseCommentsRepository, () => import("../../cases/case_comments/data/repositories/case_comments_repository"));
bindDynamicModule(TYPES.GetCaseCommentsUseCase, () => import("../../cases/case_comments/domain/use_cases/get_case_comments_use_case"));
bindDynamicModule(TYPES.CreateCaseCommentUseCase, () => import("../../cases/case_comments/domain/use_cases/create_case_comment_use_case"));

// Courses
bindDynamicModule(TYPES.ICoursesRepository, () => import("../../courses/data/repositories/courses_repository"));
bindDynamicModule(TYPES.GetCourseDetailUseCase, () => import("../../courses/domain/use_cases/get_course_detail_use_case"));
bindDynamicModule(TYPES.GetCoursesUseCase, () => import("../../courses/domain/use_cases/get_courses_use_case"));
bindDynamicModule(TYPES.GetLatestCourseUseCase, () => import("../../courses/domain/use_cases/get_latest_course_use_case"));
bindDynamicModule(TYPES.CreateCourseUseCase, () => import("../../courses/domain/use_cases/create_course_use_case"));
bindDynamicModule(TYPES.EditCourseUseCase, () => import("../../courses/domain/use_cases/edit_course_use_case"));
bindDynamicModule(TYPES.DeleteCourseUseCase, () => import("../../courses/domain/use_cases/delete_course_use_case"));

// Course attendees
bindDynamicModule(TYPES.ICourseAttendeesRepository, () => import("../../courses/attendees/data/repositories/course_attendees_repository"));
bindDynamicModule(TYPES.GetCourseAttendeesUseCase, () => import("../../courses/attendees/domain/use_cases/get_course_attendees_use_case"));
bindDynamicModule(TYPES.EditCourseAttendeeUseCase, () => import("../../courses/attendees/domain/use_cases/edit_course_attendee_use_case"));
bindDynamicModule(TYPES.CreateCourseAttendeeUseCase, () => import("../../courses/attendees/domain/use_cases/create_course_attendee_use_case"));
bindDynamicModule(TYPES.GetCourseAttendeeDetailUseCase, () => import("../../courses/attendees/domain/use_cases/get_course_attendee_detail_use_case"));
bindDynamicModule(TYPES.GetCourseAttendeeUseCase, () => import("../../courses/attendees/domain/use_cases/get_course_attendee_use_case"));
bindDynamicModule(
  TYPES.CreateCourseAttendeeInvoiceUseCase,
  () => import("../../courses/attendees/domain/use_cases/create_course_attendee_invoice_use_case")
);

// Surgery
bindDynamicModule(TYPES.ISurgeryRepository, () => import("../../surgery/data/repositories/surgery_repository"));
bindDynamicModule(TYPES.GetSurgeryUrlUseCase, () => import("../../surgery/domain/use_cases/get_surgery_url_use_case"));

// Dentists
bindDynamicModule(TYPES.IDentistsRepository, () => import("../../dentists/data/repositories/dentists_repository"));
bindDynamicModule(TYPES.CreateDentistUseCase, () => import("../../dentists/domain/use_cases/create_dentist_use_case"));
bindDynamicModule(TYPES.EditDentistUseCase, () => import("../../dentists/domain/use_cases/edit_dentist_use_case"));
bindDynamicModule(TYPES.GetDentistsUseCase, () => import("../../dentists/domain/use_cases/get_dentists_use_case"));
bindDynamicModule(TYPES.AddDentistToClinicUseCase, () => import("../../clinics/domain/use_cases/add_dentist_to_clinic_use_case"));
bindDynamicModule(TYPES.AddClinicToDentistUseCase, () => import("../../dentists/domain/use_cases/add_clinic_to_dentist_use_case"));
bindDynamicModule(TYPES.GetDentistDetailUseCase, () => import("../../dentists/domain/use_cases/get_dentist_detail_use_case"));
bindDynamicModule(TYPES.RemoveClinicFromDentistUseCase, () => import("../../dentists/domain/use_cases/remove_clinic_from_dentist_use_case"));
bindDynamicModule(
  TYPES.RemoveBillingAddressFromDentistUseCase,
  () => import("../../dentists/domain/use_cases/remove_billing_address_from_dentist_use_case")
);

// Options
bindDynamicModule(TYPES.IOptionsRepository, () => import("../../options/data/repositories/options_repository"));
bindDynamicModule(TYPES.GetGenderOptionsUseCase, () => import("../../options/domain/use_cases/get_gender_options_use_case"));
bindDynamicModule(TYPES.GetReferralSourceOptionsUseCase, () => import("../../options/domain/use_cases/get_referral_source_options_use_case"));
bindDynamicModule(TYPES.GetClientTypesOptionsUseCase, () => import("../../options/domain/use_cases/get_client_types_options_use_case"));
bindDynamicModule(TYPES.GetPhaseOptionsUseCase, () => import("../../options/domain/use_cases/get_phase_options_use_case"));
bindDynamicModule(TYPES.GetStatusOptionsUseCase, () => import("../../options/domain/use_cases/get_status_options_use_case"));
bindDynamicModule(TYPES.GetBusinessNameOptionsUseCase, () => import("../../options/domain/use_cases/get_business_name_options_use_case"));
bindDynamicModule(TYPES.GetPaymentTypeOptionsUseCase, () => import("../../options/domain/use_cases/get_payment_type_options_use_case"));
bindDynamicModule(
  TYPES.GetPaymentTypeChildrenOptionsUseCase,
  () => import("../../options/domain/use_cases/get_payment_type_children_options_use_case")
);
bindDynamicModule(TYPES.GetRangeAgeOptionsUseCase, () => import("../../options/domain/use_cases/get_range_age_options_use_case"));

//Countries
bindDynamicModule(TYPES.ICountriesRepository, () => import("../../countries/data/repository/countries_repository"));
bindDynamicModule(TYPES.GetCountriesUseCase, () => import("../../countries/domain/use_cases/get_countries_use_case"));
bindDynamicModule(TYPES.GetCountryDetailUseCase, () => import("../../countries/domain/use_cases/get_country_detail_use_case"));

//Province
bindDynamicModule(TYPES.IProvincesRepository, () => import("../../provinces/data/repositories/provinces_repository"));
bindDynamicModule(TYPES.GetProvincesUseCase, () => import("../../provinces/domain/use_cases/get_provinces_use_case"));
bindDynamicModule(TYPES.GetProvinceDetailUseCase, () => import("../../provinces/domain/use_cases/get_province_detail_use_case"));

//Province
bindDynamicModule(TYPES.ICitiesRepository, () => import("../../cities/data/repositories/cities_repository"));
bindDynamicModule(TYPES.GetCitiesUseCase, () => import("../../cities/domain/use_cases/get_cities_use_case"));

// Clinics
bindDynamicModule(TYPES.IClinicsRepository, () => import("../../clinics/data/repositories/clinics_repository"));
bindDynamicModule(TYPES.CreateClinicUseCase, () => import("../../clinics/domain/use_cases/create_clinic_use_case"));
bindDynamicModule(TYPES.GetClinicsUseCase, () => import("../../clinics/domain/use_cases/get_clinics_use_case"));
bindDynamicModule(TYPES.GetClinicDetailUseCase, () => import("../../clinics/domain/use_cases/get_clinic_detail_use_case"));
bindDynamicModule(TYPES.EditClinicUseCase, () => import("../../clinics/domain/use_cases/edit_clinic_use_case"));
bindDynamicModule(TYPES.DeleteClinicUseCase, () => import("../../clinics/domain/use_cases/delete_clinic_use_case"));
bindDynamicModule(TYPES.AddClinicToClinicGroupUseCase, () => import("../../clinics/domain/use_cases/add_clinic_to_clinic_group_use_case"));
bindDynamicModule(TYPES.GetClinicsByProfileUseCase, () => import("../../clinics/domain/use_cases/get_clinics_by_profile_use_case"));
bindDynamicModule(TYPES.RemoveClinicFromClinicGroupUseCase, () => import("../../clinics/domain/use_cases/remove_clinic_from_clinic_group_use_case"));
bindDynamicModule(TYPES.RemoveDentistFromClinicUseCase, () => import("../../clinics/domain/use_cases/remove_dentist_from_clinic_use_case"));
bindDynamicModule(
  TYPES.RemoveBillingAddressFromClinicUseCase,
  () => import("../../clinics/domain/use_cases/remove_billing_address_from_clinic_use_case")
);

// Clinic opening hours
bindDynamicModule(
  TYPES.IClinicOpeningHoursRepository,
  () => import("../../clinics/clinic_opening_hours/data/repositories/clinic_opening_hours_repository")
);
bindDynamicModule(
  TYPES.GetClinicOpeningHoursUseCase,
  () => import("../../clinics/clinic_opening_hours/domain/use_cases/get_clinic_opening_hours_use_case")
);
bindDynamicModule(
  TYPES.SetClinicOpeningHoursUseCase,
  () => import("../../clinics/clinic_opening_hours/domain/use_cases/set_clinic_opening_hours_use_case")
);

// Roles
bindDynamicModule(TYPES.IRoleGroupsRepository, () => import("../../roles/data/repositories/role_groups_repository"));
bindDynamicModule(TYPES.GetRoleGroupsUseCase, () => import("../../roles/domain/use_cases/get_role_groups_use_case"));

// Billing
bindDynamicModule(TYPES.IBillingAddressesRepository, () => import("../../billing/data/repositories/billing_addresses_repository"));
bindDynamicModule(TYPES.CreateBillingAddressUseCase, () => import("../../billing/domain/use_cases/create_billing_address_use_case"));
bindDynamicModule(TYPES.GetBillingAddressesUseCase, () => import("../../billing/domain/use_cases/get_billing_addresses_use_case"));
bindDynamicModule(
  TYPES.GetBillingAddressesByDentistUseCase,
  () => import("../../billing/domain/use_cases/get_billing_addresses_by_dentist_use_case")
);
bindDynamicModule(TYPES.GetBillingAddressDetailUseCase, () => import("../../billing/domain/use_cases/get_billing_address_detail_use_case"));
bindDynamicModule(TYPES.EditBillingAddressUseCase, () => import("../../billing/domain/use_cases/edit_billing_address_use_case"));
bindDynamicModule(TYPES.AddClinicToBillingAddressUseCase, () => import("../../billing/domain/use_cases/add_clinic_to_billing_address_use_case"));
bindDynamicModule(TYPES.DeleteBillingAddressUseCase, () => import("../../billing/domain/use_cases/delete_billing_address_use_case"));

// Invoices
bindDynamicModule(TYPES.IInvoicesRepository, () => import("../../billing/invoices/data/repositories/invoices_repository"));
bindDynamicModule(TYPES.CreateInvoiceUseCase, () => import("../../billing/invoices/domain/use_cases/create_invoice_use_case"));
bindDynamicModule(TYPES.GetInvoicesUseCase, () => import("../../billing/invoices/domain/use_cases/get_invoices_use_case"));
bindDynamicModule(TYPES.GetInvoicesByCaseUseCase, () => import("../../billing/invoices/domain/use_cases/get_invoices_by_case_use_case"));
bindDynamicModule(TYPES.GetInvoiceDetailUseCase, () => import("../../billing/invoices/domain/use_cases/get_invoice_detail_use_case"));
bindDynamicModule(TYPES.GetInvoicePdfUseCase, () => import("../../billing/invoices/domain/use_cases/get_invoice_pdf_use_case"));
bindDynamicModule(TYPES.EditInvoiceUseCase, () => import("../../billing/invoices/domain/use_cases/edit_invoice_use_case"));
bindDynamicModule(TYPES.PayInvoiceUseCase, () => import("../../billing/invoices/domain/use_cases/pay_invoice_use_case"));

// Custom forms
bindDynamicModule(TYPES.ICustomFormsRepository, () => import("../../custom_forms/data/repositories/custom_forms_repository"));
bindDynamicModule(TYPES.GetCaseCustomFormUseCase, () => import("../../custom_forms/domain/use_cases/get_new_case_custom_form_use_case"));

// Case stages
bindDynamicModule(TYPES.ICaseStagesRepository, () => import("../../case_stages/data/repositories/case_stages_repository"));
bindDynamicModule(TYPES.TransitionCaseUseCase, () => import("../../case_stages/domain/use_cases/transition_case_use_case"));

// Treatments
bindDynamicModule(TYPES.ITreatmentsRepository, () => import("../../treatments/data/repositories/treatments_repository"));
bindDynamicModule(TYPES.CreateTreatmentUseCase, () => import("../../treatments/domain/use_cases/create_treatment_use_case"));
bindDynamicModule(TYPES.EditTreatmentUseCase, () => import("../../treatments/domain/use_cases/edit_treatment_use_case"));
bindDynamicModule(TYPES.GetTreatmentsUseCase, () => import("../../treatments/domain/use_cases/get_treatments_use_case"));
bindDynamicModule(TYPES.ApproveTreatmentUseCase, () => import("../../treatments/domain/use_cases/approve_treatment_use_case"));
bindDynamicModule(TYPES.GetTreatmentUseCase, () => import("../../treatments/domain/use_cases/get_treatment_use_case"));
bindDynamicModule(TYPES.GetTreatmentViewerContentUseCase, () => import("../../treatments/domain/use_cases/get_treatment_viewer_content_use_case"));
bindDynamicModule(TYPES.GetTreatmentsHistoryUseCase, () => import("../../treatments/domain/use_cases/get_treatments_history_use_case"));
bindDynamicModule(TYPES.GetTreatmentButtonsUseCase, () => import("../../treatments/domain/use_cases/get_treatment_buttons_use_case"));
bindDynamicModule(TYPES.CreateTreatmentRefinementUseCase, () => import("../../treatments/domain/use_cases/create_treatment_refinement_use_case"));
bindDynamicModule(TYPES.CreateTreatmentReplanningUseCase, () => import("../../treatments/domain/use_cases/create_treatment_replanning_use_case"));
bindDynamicModule(TYPES.CreateTreatmentReplacementUseCase, () => import("../../treatments/domain/use_cases/create_treatment_replacement_use_case"));
bindDynamicModule(
  TYPES.RequestNextPhaseOfTreatmentUseCase,
  () => import("../../treatments/domain/use_cases/request_next_phase_of_treatment_use_case")
);

// Teeth
bindDynamicModule(TYPES.ITeethRepository, () => import("../../teeth/data/repositories/teeth_repository"));
bindDynamicModule(TYPES.GetTeethUseCase, () => import("../../teeth/domain/use_cases/get_teeth_use_case"));

// Notifications
bindDynamicModule(TYPES.INotificationsRepository, () => import("../../notifications/data/repositories/notifications_repository"));
bindDynamicModule(TYPES.GetNotificationsUseCase, () => import("../../notifications/domain/use_cases/get_notifications_use_case"));
bindDynamicModule(TYPES.DeleteNotificationsUseCase, () => import("../../notifications/domain/use_cases/delete_notifications_use_case"));

// Dentist Specialities
bindDynamicModule(TYPES.IDentistSpecialitiesRepository, () => import("../../dentist_specialities/data/repositories/dentist_specialities_repository"));
bindDynamicModule(TYPES.GetDentistSpecialitiesUseCase, () => import("../../dentist_specialities/domain/use_cases/get_dentist_specialities_use_case"));
bindDynamicModule(TYPES.DeleteDentistUseCase, () => import("../../dentists/domain/use_cases/delete_dentist_use_case"));

// Manufacturing lots
bindDynamicModule(TYPES.IManufacturingLotsRepository, () => import("../../manufacturing_lots/data/repositories/manufacturing_lots_repository"));
bindDynamicModule(TYPES.GetManufacturingLotsUseCase, () => import("../../manufacturing_lots/domain/use_cases/get_manufacturing_lots_use_case"));
bindDynamicModule(
  TYPES.GetManufacturingLotDetailUseCase,
  () => import("../../manufacturing_lots/domain/use_cases/get_manufacturing_lot_detail_use_case")
);
bindDynamicModule(TYPES.CreateManufacturingLotUseCase, () => import("../../manufacturing_lots/domain/use_cases/create_manufacturing_lot_use_case"));
bindDynamicModule(TYPES.UpdateManufacturingLotUseCase, () => import("../../manufacturing_lots/domain/use_cases/update_manufacturing_lot_use_case"));
bindDynamicModule(
  TYPES.TransitionManufacturingLotUseCase,
  () => import("../../manufacturing_lots/domain/use_cases/transition_manufacturing_lot_use_case")
);
bindDynamicModule(
  TYPES.AssignManufacturingItemsToLotUseCase,
  () => import("../../manufacturing_lots/domain/use_cases/assign_manufacturing_items_to_lot_use_case")
);

// Manufacturing items
bindDynamicModule(TYPES.IManufacturingItemsRepository, () => import("../../manufacturing_items/data/repositories/manufacturing_items_repository"));
bindDynamicModule(TYPES.GetManufacturingItemsUseCase, () => import("../../manufacturing_items/domain/use_cases/get_manufacturing_items_use_case"));
bindDynamicModule(
  TYPES.GetSentManufacturingItemsUseCase,
  () => import("../../manufacturing_items/domain/use_cases/get_sent_manufacturing_items_use_case")
);

// Promoters
bindDynamicModule(TYPES.IPromotersRepository, () => import("../../promoters/data/repositories/promoters_repository"));
bindDynamicModule(TYPES.GetPromotersUseCase, () => import("../../promoters/domain/use_cases/get_promoters_use_case"));
bindDynamicModule(TYPES.GetPromoterDetailUseCase, () => import("../../promoters/domain/use_cases/get_promoter_detail_use_case"));

// Distributors
bindDynamicModule(TYPES.IDistributorsRepository, () => import("../../distributors/data/repositories/distributors_repository"));
bindDynamicModule(TYPES.GetDistributorsUseCase, () => import("../../distributors/domain/use_cases/get_distributors_use_case"));
bindDynamicModule(TYPES.GetDistributorDetailUseCase, () => import("../../distributors/domain/use_cases/get_distributor_detail_use_case"));

export { locator };
