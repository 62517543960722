import styled, { css } from "styled-components";
import { colors } from "../../../styles/colors";
import type { ButtonProps } from "@/src/ui/components/buttons/button/button";
import { px2rem, truncateText } from "@/src/ui/styles/utils";
import { typography } from "@/src/ui/styles/typography";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { Link } from "react-router-dom";

export const buttonSizesToPadding = {
  s: `0 ${px2rem(14)}`,
  m: `0 ${px2rem(20)}`,
  l: `0 ${px2rem(35)}`,
  "40": `0 ${px2rem(20)}`
};
export const buttonSizesToIconPadding = {
  s: px2rem(4),
  m: px2rem(8),
  l: px2rem(12),
  "40": px2rem(8)
};
export const buttonSizesToHeight = {
  s: px2rem(36),
  m: px2rem(46),
  l: px2rem(46),
  "40": px2rem(40)
};

export const buttonColor = {
  primary: `${colors.brand500}`,
  secondary: `${colors.neutral300}`,
  link: "transparent",
  linkSecondary: "transparent",
  error: `${colors.error700}`
};

export const buttonTextColor = {
  primary: `${colors.white}`,
  secondary: `${colors.neutral900}`,
  link: `${colors.brand700}`,
  linkSecondary: `${colors.neutral900}`,
  error: `${colors.white}`
};

export const buttonHoverColor = {
  primary: `${colors.brand700}`,
  secondary: `${colors.neutral400}`,
  link: `${colors.neutral300}`,
  linkSecondary: `${colors.neutral300}`,
  error: `${colors.error700}`
};

export const buttonDisabledBackgroundColor = {
  primary: `${colors.neutral200}`,
  secondary: `${colors.neutral200}`,
  link: "transparent",
  linkSecondary: "transparent",
  error: `${colors.error600}`
};

export const buttonDisabledTextColor = {
  primary: `${colors.neutral500}`,
  secondary: `${colors.neutral500}`,
  link: `${colors.neutral500}`,
  linkSecondary: `${colors.neutral500}`,
  error: `${colors.white}`
};

const TooltipBox = styled.div`
  ${typography.xxxSmall}
  width: auto;
  text-align: center;
  min-width: 150%;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  visibility: hidden;
  background-color: ${colors.neutral900};
  color: ${colors.white};
  padding: ${px2rem(12)} ${px2rem(8)} ${px2rem(12)} ${px2rem(8)};
  border-radius: ${px2rem(4)};
  &:before {
    content: "";
    width: 0;
    height: 0;
    bottom: ${px2rem(-10)};
    position: absolute;
    border: 5px solid ${colors.neutral900};
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
`;

const buttonStyles = css<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${px2rem(14)};
  background-color: ${({ disabled, color = "primary" }) => (disabled ? buttonDisabledBackgroundColor[color] : buttonColor[color])};
  border: none;
  padding: ${({ size = "m" }) => buttonSizesToPadding[size]};
  position: relative;
  ${({ iconposition, size = "m" }) =>
    iconposition === "left" &&
    css`
      padding-left: ${buttonSizesToIconPadding[size]};
    `}
  ${({ iconposition, size = "m" }) =>
    iconposition === "right" &&
    css`
      padding-right: ${buttonSizesToIconPadding[size]};
    `}
  ${({ hidetext, size = "m" }) =>
    hidetext &&
    css`
      padding-right: ${buttonSizesToIconPadding[size]};
      padding-left: ${buttonSizesToIconPadding[size]};
    `}
  height: ${({ size = "m" }) => buttonSizesToHeight[size]};
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
  ${borderRadius.s}

  :hover {
    background-color: ${({ disabled, color = "primary" }) => (disabled ? buttonDisabledBackgroundColor[color] : buttonHoverColor[color])};
    cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
    & ${TooltipBox} {
      visibility: visible;
    }
  }
  flex-flow: ${({ iconposition = "left" }) => iconposition === "right" && "row-reverse"};
  svg {
    flex: 0 0 ${px2rem(26)};
    height: ${px2rem(26)};
    width: ${px2rem(26)};
    margin: ${px2rem(5)};
    * {
      color: ${({ disabled, color = "primary" }) => (disabled ? buttonDisabledTextColor[color] : buttonTextColor[color])};
    }
  }
`;

const Button = styled.button<ButtonProps>`
  ${buttonStyles};
`;

const LinkButton = styled(Link)<ButtonProps>`
  ${buttonStyles}
`;
const AButton = styled.a<ButtonProps>`
  ${buttonStyles}
`;

export const ButtonText = styled.div<ButtonProps>`
  ${typography.small}
  min-width: 0;
  ${truncateText}
  color: ${({ disabled, color = "primary" }) => (disabled ? buttonDisabledTextColor[color] : buttonTextColor[color])};
`;

export default { AButton, Button, LinkButton, ButtonText, TooltipBox };
