import { useField } from "formik";
import type { RadioFieldProps } from "@/src/ui/components/fields/radio_field/radio_field";
import RadioField from "@/src/ui/components/fields/radio_field/radio_field";

export type RadioFieldFormikProps = Omit<RadioFieldProps<string>, "value" | "onChange" | "error">;

export default function RadioFieldFormik({ name, ...props }: RadioFieldFormikProps) {
  const [field, meta, helpers] = useField(name);
  return <RadioField value={field.value} onChange={helpers.setValue} name={name} error={meta.touched ? meta.error : undefined} {...props} />;
}
