import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import {
  deleteCaseFileThunk,
  getCaseFileDetailThunk,
  selectCaseFileDetailHasError,
  selectCaseFileDetailIsLoading,
  uploadCaseFileThunk
} from "@/src/ui/pages/cases/case_files/state/case_files.slice";
import { useCallback, useEffect, useMemo } from "react";
import type { UploadCaseFileModel } from "@/src/core/cases/domain/models/upload_case_file_model";
import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import { debounce } from "lodash";

export function useCaseFileController(reference: string, caseFile: CaseFileModel) {
  const { key, status } = caseFile;
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => selectCaseFileDetailIsLoading(state, key));
  const hasError = useAppSelector((state) => selectCaseFileDetailHasError(state, key));

  const deleteFile = async () => {
    await dispatch(deleteCaseFileThunk({ reference, key }));
  };
  const reload = useCallback(async () => {
    await dispatch(getCaseFileDetailThunk({ reference, key }));
  }, [dispatch, key, reference]);
  const uploadFile = async (data: Omit<UploadCaseFileModel, "reference" | "key">) => {
    await dispatch(uploadCaseFileThunk({ ...data, reference, key }));
  };
  const debouncedReload = useMemo(() => debounce(reload, 2000), [reload]);
  useEffect(() => {
    if (status === "uploading" && !isLoading) {
      void debouncedReload();
    }
  }, [debouncedReload, isLoading, status]);

  return { isLoading, hasError, uploadFile, reload, deleteFile };
}
