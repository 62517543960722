import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import type { GetBillingAddressDetailUseCase } from "@/src/core/billing/domain/use_cases/get_billing_address_detail_use_case";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { BillingAddressDetailSlice } from "@/src/ui/pages/billing/billing_addresses/billing_address_detail/view_models/billing_address_detail.slice";
import type { BillingAddressModel } from "@/src/core/billing/domain/models/billing_address_model";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { serializeError } from "@/src/common/utils/rtk";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): BillingAddressDetailSlice => getInitialDetailSlice();

export const getBillingAddressDetailThunk = createAsyncThunk(
  "billingAddressDetail.slice/get",
  async (billingAddressId: string) => {
    const useCase = await locator.get<IocProvider<GetBillingAddressDetailUseCase>>(TYPES.GetBillingAddressDetailUseCase)();
    return await useCase.execute(billingAddressId);
  },
  { serializeError }
);

const billingAddressDetailSlice = createSlice({
  name: "billingAddressDetail.slice",
  initialState: initialState(),
  reducers: {
    resetBillingAddressDetail: initialState,
    setBillingAddressDetail(state, action: PayloadAction<BillingAddressModel>) {
      state.detail = action.payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(getBillingAddressDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getBillingAddressDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getBillingAddressDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBillingAddressDetailBase(state: RootState) {
  return state.billingAddressDetail;
}

export const selectBillingAddressDetail = createSelector(selectBillingAddressDetailBase, (slice) => slice.detail);
export const selectBillingAddressDetailLoadingState = createSelector(selectBillingAddressDetailBase, (slice) => slice.loadingState);
export const selectBillingAddressDetailIsOwn = createSelector(selectBillingAddressDetail, (detail) => Boolean(detail?.isOwn));
export const { resetBillingAddressDetail, setBillingAddressDetail } = billingAddressDetailSlice.actions;
export default billingAddressDetailSlice.reducer;
