import ErrorBoundary70 from "@/src/ui/components/error_displayers/error_messages/error_boundary_70/error_boundary_70";
import PageHorizontalPadding from "@/src/ui/components/layouts/page_horizontal_padding/page_horizontal_padding";
import ContentMaxWidth from "@/src/ui/components/spacing/content_max_width/content_max_width";
import PageVerticalPadding from "@/src/ui/components/spacing/page_vertical_padding/page_vertical_padding";
import VerticalSpacer from "@/src/ui/components/spacing/vertical_spacer/vertical_spacer";
import { useAppSelector } from "@/src/ui/state";
import { Formik, Form } from "formik";
import NewCaseFormFields from "../../../publish_case/components/publish_case_form_fields/new_case_form_fields";
import NewCaseFormSteps from "../../../publish_case/components/publish_case_form_steps/new_case_form_steps";
import { selectNewCaseFormValues, selectNewCaseCustomFormSchema } from "../../../publish_case/state/new_case_publish_form.selectors";
import Styled from "./edit_case_form.styled";
import { useEditCaseFormController } from "./edit_case_form_controller";
import EditCaseFormActions from "../edit_case_form_actions/edit_case_form_actions";

export default function EditCaseForm() {
  const onSubmit = useEditCaseFormController();
  const initialValues = useAppSelector(selectNewCaseFormValues);
  const schema = useAppSelector(selectNewCaseCustomFormSchema);

  return (
    <PageVerticalPadding>
      <PageHorizontalPadding>
        <ContentMaxWidth width="large">
          <Styled.Card>
            <ErrorBoundary70>
              <NewCaseFormSteps />
              <VerticalSpacer height="xl" />
              <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
                <Form>
                  <ErrorBoundary70>
                    <NewCaseFormFields />
                  </ErrorBoundary70>
                  <VerticalSpacer />
                  <EditCaseFormActions />
                </Form>
              </Formik>
            </ErrorBoundary70>
          </Styled.Card>
        </ContentMaxWidth>
      </PageHorizontalPadding>
    </PageVerticalPadding>
  );
}
