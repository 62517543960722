import type { contentMaxWidth } from "@/src/ui/styles/sizes";
import Styled from "./content_max_width.styled";
import type { PropsWithChildren } from "react";

export interface ContentMaxWidthProps {
  width?: keyof typeof contentMaxWidth;
  className?: string
}
const ContentMaxWidth = (p: PropsWithChildren<ContentMaxWidthProps>) => <Styled.ContentMaxWidth {...p} />;

export default ContentMaxWidth;
