import { AxiosError } from "axios";
import type { LoadingState } from "../view_models/loading_state";
import type { SerializedError } from "@reduxjs/toolkit";

export function errorToLoadingState(error: Error | SerializedError): LoadingState {
  if (error instanceof AxiosError) {
    if (error.response?.status === 403) {
      return "permissionError";
    } else if (error.response?.status === 404) {
      return "notFound";
    }
  }
  return "error";
}
