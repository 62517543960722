import type { RootState } from "./index";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RedirectSessionSlice } from "@/src/ui/view_models/redirect_session.slice";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ValidateRedirectSessionUrlUseCase } from "@/src/core/user/domain/use_cases/validate_redirect_session_url_use_case";
import type { GetSurgeryUrlUseCase } from "@/src/core/surgery/domain/use_cases/get_surgery_url_use_case";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";
import { timeout } from "@front_web_mrmilu/utils";
import Translate from "../components/text/translate/translate";

const initialState = (): RedirectSessionSlice => ({});

export const setRedirectSessionUrlThunk = createAsyncThunk(
  "redirectSession.slice/setRedirectSessionUrl",
  async (redirectUrl: string, { dispatch }) => {
    const url = new URL(decodeURIComponent(redirectUrl));
    try {
      const useCase = await locator.get<IocProvider<ValidateRedirectSessionUrlUseCase>>(TYPES.ValidateRedirectSessionUrlUseCase)();
      await useCase.execute(url);
      return url;
    } catch (e) {
      console.error(e);
      dispatch(showErrorAlertThunk(<Translate ns="navigation" i18nKey="redirectSession.errors.invalidUrl" values={{ url: url.origin }} />));
    }
  }
);

export const redirectSessionThunk = createAsyncThunk("redirectSession.slice/redirectSession", async (_, { getState, dispatch }) => {
  const {
    session: { token },
    redirectSession: { redirectUrl }
  } = getState() as RootState;
  if (!redirectUrl || !token) return;
  try {
    redirectUrl.searchParams.append("authToken", btoa(token));
    window.location.replace(redirectUrl);
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk(<Translate ns="navigation" i18nKey="redirectSession.errors.general" values={{ url: redirectUrl.origin }} />));
  }
});

export const redirectSessionToSurgeryThunk = createAsyncThunk("redirectSession.slice/redirectSessionToSurgery", async (_, { getState, dispatch }) => {
  const {
    session: { token }
  } = getState() as RootState;
  if (!token) return;
  try {
    const useCase = await locator.get<IocProvider<GetSurgeryUrlUseCase>>(TYPES.GetSurgeryUrlUseCase)();
    const surgeryUrl = await useCase.execute(token);
    window.location.assign(surgeryUrl);
    await timeout(3000); // Show loding message until website changes.
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk(<Translate ns="navigation" i18nKey="redirectToSurgery.error" />));
  }
});

const sessionSlice = createSlice({
  name: "redirectSession.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setRedirectSessionUrlThunk.fulfilled, (state, action) => {
      state.redirectUrl = action.payload;
    });
    builder.addCase(setRedirectSessionUrlThunk.rejected, (state) => {
      state.redirectUrl = undefined;
    });
    builder.addCase(redirectSessionThunk.fulfilled, (state) => {
      state.redirectUrl = undefined;
    });
    builder.addCase(redirectSessionThunk.rejected, (state) => {
      state.redirectUrl = undefined;
    });
  }
});

export default sessionSlice.reducer;
