import { getI18n } from "react-i18next";
import { useEffectStrictMode } from "@front_web_mrmilu/hooks";
import { locator } from "@/src/core/app/ioc";
import type { ILanguageService } from "@/src/core/app/domain/interfaces/i_language_service";
import { TYPES } from "@/src/core/app/ioc/types";
import { store } from "../state";
import { setLanguage } from "../state/language.slice";

export function useSetupLanguage() {
  useEffectStrictMode(() => {
    const i18n = getI18n();
    const service = locator.get<ILanguageService>(TYPES.ILanguageService);
    service.init();
    void i18n.changeLanguage(service.language);
    store.dispatch(setLanguage(service.language))
  });
}
