import { Bullet } from "@/src/ui/assets/icons";
import type { ReactNode } from "react";
import Styled from "./checkbox.styled";

export interface CheckboxProps {
  // A string that identifies the checkbox group
  name: string;
  // A string that uniquely identifies the checkbox in the form
  id?: string;
  value: boolean;
  onChange(value: boolean): void;
  // Text to display on the checkbox
  label: ReactNode;
  className?: string;
}

export default function Checkbox({ value, id, name, onChange, label, className }: CheckboxProps) {
  id ??= name;
  return (
    <Styled.Label htmlFor={id} className={className}>
      <Styled.Input type="checkbox" id={id} name={name} value={id} checked={value} onChange={() => onChange(!value)} />
      <Styled.Checkbox checked={value}>{value && <Bullet />}</Styled.Checkbox>
      <Styled.LabelText checked={value}>{label}</Styled.LabelText>
    </Styled.Label>
  );
}
