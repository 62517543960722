import type { spacing } from "@/src/ui/styles/spacing";
import type { PropsWithChildren } from "react";
import Styled from "./form_row.styled";

export interface FormRowProps extends PropsWithChildren {
  marginBottom?: keyof typeof spacing;
  className?: string;
}
const FormRow = (p: FormRowProps) => <Styled.FormRow {...p} />;

export default FormRow;
