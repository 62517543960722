import type { Option } from "@/src/core/options/domain/models/option";
import type { RoleGroupModel } from "@/src/core/roles/domain/models/role_group_model";

export interface RoleGroupOption extends Option {
  roles: string[];
}

export function roleGroupModelToOption(r: RoleGroupModel): RoleGroupOption {
  return {
    label: r.name,
    value: r.id,
    roles: r.roles
  };
}
