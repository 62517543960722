import { useTranslation } from "react-i18next";
import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "../../../assets/icons";
import Button from "../button/button";

interface Props {
  to: To;
}
export default function GoBackButton({ to }: Props) {
  const { t } = useTranslation("components", { keyPrefix: "goBackButton" });
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate((history.length <= 2 ? to : -1) as To)} color="secondary" icon={<ChevronLeft />}>
      {t("goBack")}
    </Button>
  );
}
