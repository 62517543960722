import { createSelector } from "@reduxjs/toolkit";
import { selectRoles } from "../../../roles/state/roles.selectors";
import { selectDentistDetailIsOwn } from "./dentist_detail.slice";

export const selectCanEditDentistDetail = createSelector(
  selectRoles,
  selectDentistDetailIsOwn,
  (roles, isOwn) => roles.has("DENTISTS_EDIT") || (isOwn && roles.has("DENTISTS_EDIT_OWN"))
);
export const selectCanDeleteDentistDetail = createSelector(
  selectRoles,
  selectDentistDetailIsOwn,
  (roles, isOwn) => roles.has("DENTISTS_DELETE") || (isOwn && roles.has("DENTISTS_DELETE_OWN"))
);
