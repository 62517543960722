import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CasesSortSlice } from "../view_models/cases_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): CasesSortSlice => ({ value: {} });

const homePageCasesSortSlice = createSlice({
  name: "homePageCasesSort.slice",
  initialState: initialState(),
  reducers: {
    resetHomePageCasesSort: initialState,
    setHomePageCasesSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.homePageCasesSort;
}

export const selectHomePageCasesSort = createSelector(selectBase, (slice) => slice.value);

export const { setHomePageCasesSort, resetHomePageCasesSort } = homePageCasesSortSlice.actions;
export default homePageCasesSortSlice.reducer;

export const homePageCasesSortColumnNames = ["order[patient.name]", "order[updatedAt]", "order[productType]", "order[status]", null];
