import type { CustomFileFieldModel } from "@/src/core/custom_forms/domain/models/fields/custom_file_field.model";
import CaseFieldInput from "../../../cases/case_files/components/case_file_field/case_file_field";
import { fileFieldToDomain } from "../../transformers/custom_file_field_to_domain";
interface Props {
  field: CustomFileFieldModel;
}

export default function CustomFileField({ field }: Props) {
  return <CaseFieldInput file={fileFieldToDomain(field)} reference={field.name} mime={field.validation.mime?.toString() || ""} />;
}
