import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { NewBillingAddressSlice } from "@/src/ui/pages/billing/billing_addresses/new_billing_address/view_models/new_billing_address.slice";
import type { GetBillingAddressesUseCase } from "@/src/core/billing/domain/use_cases/get_billing_addresses_use_case";

const initialState = (): NewBillingAddressSlice => ({
  vatNumber: "",
  isLoading: false,
  hasError: false
});

export const newBillingAddressSearchThunk = createAsyncThunk("newBillingAddress.slice/search", async (_, { getState }) => {
  const {
    newBillingAddress: { vatNumber }
  } = getState() as RootState;

  const useCase = await locator.get<IocProvider<GetBillingAddressesUseCase>>(TYPES.GetBillingAddressesUseCase)();
  return await useCase.execute({ vatNumber });
});

const newBillingAddressSlice = createSlice({
  name: "newBillingAddress.slice",
  initialState: initialState(),
  reducers: {
    setNewBillingAddressVatNumber(state, action: PayloadAction<string>) {
      state.vatNumber = action.payload;
    },
    resetNewBillingAddress: initialState
  },
  extraReducers(builder) {
    builder.addCase(newBillingAddressSearchThunk.pending, (state) => {
      state.searchResults = undefined;
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(newBillingAddressSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResults = action.payload.results;
    });
    builder.addCase(newBillingAddressSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.searchResults = undefined;
      state.hasError = true;
    });
  }
});

function selectnewBillingAddressBase(state: RootState) {
  return state.newBillingAddress;
}

export const selectNewBillingAddressSearchResults = createSelector(selectnewBillingAddressBase, (e) => e.searchResults);
export const selectNewBillingAddressSearchHasNoResults = createSelector(
  selectNewBillingAddressSearchResults,
  (results) => results && results.length === 0
);
export const selectNewBillingAddressSearchIsLoading = createSelector(selectnewBillingAddressBase, (e) => e.isLoading);
export const selectNewBillingAddressHasError = createSelector(selectnewBillingAddressBase, (e) => e.hasError);
export const selectNewBillingAddressVatNumber = createSelector(selectnewBillingAddressBase, (e) => e.vatNumber);

export const { resetNewBillingAddress, setNewBillingAddressVatNumber } = newBillingAddressSlice.actions;

export default newBillingAddressSlice.reducer;
