import type { AxiosError } from "axios";

interface Violation {
  propertyPath: string;
  message: string;
}

interface Data {
  violations: Violation[];
}

function violationsToFormErrors(violations: Violation[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = {};
  for (const v of violations) {
    let pointer = formData;
    const paths = (v.propertyPath.replaceAll(/[\[\]]/g, "") || "globalError").split(".");
    for (let i = 0; i < paths.length - 1; i++) {
      if (!pointer[paths[i]]) {
        pointer[paths[i]] = {};
      }
      pointer = pointer[paths[i]];
    }
    pointer[paths[paths.length - 1]] = v.message;
  }
  return formData;
}

export function axiosErrorToFormErrors(e: AxiosError<Data>) {
  try {
    const violations = e.response?.data?.violations;
    if (Array.isArray(violations)) {
      return violationsToFormErrors(violations);
    }
  } catch (e) {
    console.error(e);
  }
}
