import type { PropsWithChildren } from "react";
import { Component } from "react";
import type { BaseError } from "@/src/core/app/domain/models/base_error";
import * as Sentry from "@sentry/react";
import { FullscreenErrorMessage } from "@/src/ui/components/error_displayers/error_messages/basic_error_message/fullscreen_error_message";

interface Props extends PropsWithChildren {
  retryFunction?(): void;
  className?: string;
}

interface State {
  hasError: boolean;
}

const initialState: State = {
  hasError: false
};

export class FullscreenErrorBoundary extends Component<Props> {
  state = initialState;
  constructor(props: PropsWithChildren<Record<string, unknown>>) {
    super(props);
    this.retry = this.retry.bind(this);
  }

  static getDerivedStateFromError(error: BaseError) {
    return { errorMsg: error.message, hasError: true };
  }

  componentDidCatch(error: BaseError) {
    this.setState({
      hasError: true
    });
    console.error(error);
    Sentry.captureException(error);
  }

  private retry() {
    this.props.retryFunction?.();
    this.setState(initialState);
  }

  render() {
    const { children, className, retryFunction } = this.props;
    if (this.state.hasError) return <FullscreenErrorMessage className={className} retry={retryFunction && this.retry} />;
    return children;
  }
}
