import { useSelector } from "react-redux";
import type { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { selectHasUser } from "../../state/user.slice";
import { selectHasProfile } from "../../state/profile.slice";

export default function ProfileIsNotSelectedMiddleware({ children }: PropsWithChildren) {
  const hasUser = useSelector(selectHasUser);
  const hasProfile = useSelector(selectHasProfile);
  if (hasUser && hasProfile) return <Navigate to="/" replace />;
  return <>{children}</>;
}
