import { useFormikContext } from "formik";
import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import {
  selectCaseDetailIsFirstStep,
  selectCaseDetailPublishError,
  setCaseDetailSaveAsDraft
} from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { publishCasePreviousStepController } from "@/src/ui/pages/cases/publish_case/controllers/publish_case_previous_step_controller";
import type { CustomFormValues } from "@/src/ui/pages/custom_forms/view_models/custom_form_values";
import { useTranslation } from "react-i18next";
import Styled from "./edit_case_form_actions.styled";
import { ChevronLeft, Save, ChevronRight } from "@/src/ui/assets/icons";
import Button from "@/src/ui/components/buttons/button/button";
import ContentMaxWidth from "@/src/ui/components/spacing/content_max_width/content_max_width";
import VerticalSpacer from "@/src/ui/components/spacing/vertical_spacer/vertical_spacer";
import GoBackButton from "@/src/ui/components/buttons/go_back_button/go_back_button";

export default function EditCaseFormActions() {
  const isFirstStep = useAppSelector(selectCaseDetailIsFirstStep);
  const { t } = useTranslation("edit_case");
  const dispatch = useAppDispatch();
  const form = useFormikContext<CustomFormValues>();
  const handleSave = () => {
    dispatch(setCaseDetailSaveAsDraft());
    form.submitForm();
  };
  const disable = form.isSubmitting;
  const onPrevious = isFirstStep ? undefined : publishCasePreviousStepController;
  const error = useAppSelector(selectCaseDetailPublishError);

  return (
    <Styled.Section>
      <ContentMaxWidth width="small">
        <VerticalSpacer height="m" />
        <Styled.Buttons>
          {onPrevious ? (
            <Button color="secondary" disabled={disable} onClick={onPrevious} icon={<ChevronLeft />}>
              {t("actions.previous")}
            </Button>
          ) : (
            <GoBackButton to=".." />
          )}
          <Button color="secondary" disabled={disable} type="button" onClick={handleSave} icon={<Save />}>
            {t("actions.save")}
          </Button>
          <Button disabled={disable} type="submit" icon={<ChevronRight />} iconposition="right">
            {t("actions.next")}
          </Button>
        </Styled.Buttons>
        {error && <VerticalSpacer height="xs" />}
        {error && <Styled.ErrorMessage>{t("errors.saveCase")}</Styled.ErrorMessage>}
      </ContentMaxWidth>
    </Styled.Section>
  );
}
