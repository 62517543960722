import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import CustomFormFieldsRow from "@/src/ui/pages/custom_forms/components/custom_form_fields_row/custom_form_fields_row";

interface Props {
  groupedFields: CustomFieldModel[][];
}
export default function CustomFormFields({ groupedFields }: Props) {
  return (
    <>
      {groupedFields.map((fields) => (
        <CustomFormFieldsRow key={fields[0].name} fields={fields} />
      ))}
    </>
  );
}
