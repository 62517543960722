import { createGlobalStyle } from "styled-components";
import { ResetCSS } from "@/src/ui/styles/reset";
import { colors } from "./colors";
import { fonts } from "./fonts";
import { typography } from "@/src/ui/styles/typography";
import { px2rem, withOpacity } from "@/src/ui/styles/utils";
import { borderRadius } from "./border_radius";
import DisableScroll from "@/src/ui/components/modals/disable_scroll/disable_scroll";

const Styles = createGlobalStyle`
  body {
    background-color: ${colors.neutral200};
  }

  * {
    ${fonts.openSans};
    color: ${colors.neutral900};

    &:focus-visible {
      outline: ${px2rem(3)} solid ${withOpacity(colors.brand300, 80)};
      outline-offset: ${px2rem(2)};
    } 
  }

  h2 {
    ${typography.headingXXL}
  }

  h3 {
    ${typography.headingXL}
  }

  h4 {
    ${typography.large}
  }

  p {
    ${typography.medium}
  }

  small {
    ${typography.xSmall}
  }
  
  a {
    text-decoration: none;
    ${borderRadius.s}
  }
`;

export const GlobalStyles = () => (
  <>
    <Styles />
    <ResetCSS />
    <DisableScroll />
  </>
);
