import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import type { PropsWithChildren } from "react";
import { usePageId } from "../../hooks/use_page_id";
import { selectCanEditBillingAddressDetail } from "../../pages/billing/billing_addresses/billing_address_detail/state/billing_address_detail_roles.selectors";
import { selectBillingAddressDetail } from "../../pages/billing/billing_addresses/billing_address_detail/state/billing_address_detail.slice";

export default function CanEditBillingAddressMiddleware({ children }: PropsWithChildren) {
  const canEditBillingAddress = useAppSelector(selectCanEditBillingAddressDetail);
  const id = usePageId("CanEditBillingAddressMiddleware");
  const detail = useAppSelector(selectBillingAddressDetail);
  if (canEditBillingAddress || id !== detail?.id) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
