import StyledSelect from "../select_field/select_field.styled";
import Styled from "./select_field_multiple.styled";
import type { Option } from "@/src/core/options/domain/models/option";
import Select from "react-select";
import { useState } from "react";
import { emptyFunction } from "@/src/common/utils/empty_function";
import { ChevronDown } from "@/src/ui/assets/icons";
import DotsLoader from "@/src/ui/components/loaders/dots_loader/dots_loader";
import { useTranslation } from "react-i18next";
import ErrorText from "../../error_displayers/error_text/error_text";

export interface SelectFieldMultipleProps<T> {
  name: string;
  label?: string;
  error?: string;
  options?: Option<T>[];
  value: T[];
  isDisabled?: boolean;
  onChange(value: T[]): void;
  placeholder?: string;
  onMenuScrollToBottom?(): void
}

export default function SelectFieldMultiple<T>({
  name,
  options,
  value,
  label,
  onChange,
  placeholder,
  error,
  isDisabled = false,
  onMenuScrollToBottom
}: SelectFieldMultipleProps<T>) {
  const { t } = useTranslation("components", { keyPrefix: "selectField" });
  const [text, setText] = useState("");
  return (
    <StyledSelect.Label htmlFor={name}>
      {label && <StyledSelect.LabelText>{label}</StyledSelect.LabelText>}
      <Styled.SelectMultipleInput isDisabled={isDisabled}>
        <Select
          placeholder={placeholder ?? t("placeholder")}
          classNamePrefix="Select"
          onChange={(option) => onChange(option.map((o) => o.value))}
          onInputChange={setText}
          isMulti
          inputValue={text}
          onMenuOpen={emptyFunction}
          onMenuClose={emptyFunction}
          options={options}
          isLoading={!options}
          value={options?.filter((o) => value.includes(o.value)) ?? []}
          onMenuScrollToBottom={onMenuScrollToBottom}
          noOptionsMessage={() => t("noOptions")} 
          loadingMessage={() => t("loading")}
          components={{
            DropdownIndicator: () => <ChevronDown />,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => <DotsLoader />,
            ClearIndicator: () => null
          }}
        />
      </Styled.SelectMultipleInput>
      {error && (
        <StyledSelect.ErrorText>
          <ErrorText error={error} />
        </StyledSelect.ErrorText>
      )}
    </StyledSelect.Label>
  );
}
