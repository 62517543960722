import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RemoveSessionTokenUseCase } from "@/src/core/user/domain/use_cases/remove_session_token_use_case";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createElement } from "react";
import Translate from "../components/text/translate/translate";
import { showErrorAlertThunk } from "./alerts.slice";

export const removeSessionTokenThunk = createAsyncThunk("session.slice/removeSessionToken", async (_, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<RemoveSessionTokenUseCase>>(TYPES.RemoveSessionTokenUseCase)();
    await useCase.execute();
  } catch (e) {
    dispatch(showErrorAlertThunk());
    console.error(e);
  }
});

export const handleExpiredSessionThunk = createAsyncThunk("session.slice/handleExpiredSession", async (_, { dispatch }) => {
  dispatch(showErrorAlertThunk(createElement(Translate, { ns: "alerts", i18nKey: "expiredSession" })));
  dispatch(removeSessionTokenThunk());
});