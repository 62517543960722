import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import Styled from "./case_stl_field_input.styled";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { Upload, File } from "@/src/ui/assets/icons";
import { useCaseFileController } from "@/src/ui/pages/cases/case_files/controllers/case_file_controller";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface CaseStlFieldInputProps {
  file: CaseFileModel;
  reference: string;
}
export default function CaseStlFieldInput({ file, reference }: CaseStlFieldInputProps) {
  const { uploadFile, isLoading, hasError } = useCaseFileController(reference, file);
  const { t } = useTranslation("components", { keyPrefix: "stlField" });
  const { t: te } = useTranslation("errors");
  const [error, setError] = useState("");

  const onDropAccepted = (files: File[]) => {
    setError("");
    const file = files[0];
    uploadFile({
      file,
      totalSize: file.size,
      originalName: file.name,
      mimeType: "model/stl"
    });
  };

  const onDropRejected = (rejections: FileRejection[]) => {
    const rejection = rejections.at(0);

    if (!rejection) return;
    const error = rejection.errors.at(0);
    if (!error) return;
    setError(`${te("file.general", { name: rejection.file.name })}`);
  };

  const dropzoneOptions = {
    multiple: false,
    accept: { "model/stl": [`.stl`] },
    onDropAccepted,
    onDropRejected,
    maxSize: parseInt(file.validation.maxSize) * Math.pow(1000, 2)
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  return (
    <Styled.Wrapper>
      {file.label && <Styled.Label>{file.label}</Styled.Label>}
      {isLoading && (
        <Styled.Dropzone>
          <Styled.Spinner ariaLabel={t("loading")} />
        </Styled.Dropzone>
      )}
      {!isLoading && (
        <Styled.Dropzone hasFile {...getRootProps()}>
          <input {...getInputProps()} />
          <Styled.DropzoneHint isDragActive={isDragActive}>
            {!isDragActive && (
              <>
                <Styled.DropzoneUploadIcon>{file.status === "empty" ? <Upload /> : <File />}</Styled.DropzoneUploadIcon>
                <Styled.DropzoneCta>{file.status === "empty" ? t("hint") : file.originalName}</Styled.DropzoneCta>
                {file.validation.maxSize && (
                  <Styled.DropzoneMaxSize>
                    {file.status === "empty" ? t("max", { max: file.validation.maxSize }) : file.totalSize}
                  </Styled.DropzoneMaxSize>
                )}
              </>
            )}
            {isDragActive && <Styled.DropzoneCta>{t("drop")}</Styled.DropzoneCta>}
          </Styled.DropzoneHint>
        </Styled.Dropzone>
      )}
      {error && <Styled.Error>{error}</Styled.Error>}
      {hasError && <Styled.Error>{te("basicErrorMessage.general")}</Styled.Error>}
    </Styled.Wrapper>
  );
}
