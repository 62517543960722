import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import Styled from "./case_file_card.styled";
import CaseFileCardMenu from "@/src/ui/pages/cases/case_files/components/case_file_card_menu/case_file_card_menu";
import CaseFileCardBottomText from "@/src/ui/pages/cases/case_files/components/case_file_card_bottom_text/case_file_card_bottom_text";
import { useCaseFileController } from "@/src/ui/pages/cases/case_files/controllers/case_file_controller";
import placeholderFace from "@/src/ui/assets/images/placeholer_face.png";

interface Props {
  reference: string;
  caseFile: CaseFileModel;
}
export default function CaseFileCard({ caseFile, reference }: Props) {
  const { uploadFile, isLoading, hasError, reload, deleteFile } = useCaseFileController(reference, caseFile);
  return (
    <div>
      <Styled.Image src={caseFile.url} alt={caseFile.label} placeholderSrc={placeholderFace} />
      <Styled.MainRow>
        <Styled.Title>{caseFile.label}</Styled.Title>
        <CaseFileCardMenu caseFile={caseFile} uploadFile={uploadFile} deleteFile={deleteFile} />
      </Styled.MainRow>
      <CaseFileCardBottomText caseFile={caseFile} isLoading={isLoading} hasError={hasError} reload={reload} reloadDelete={deleteFile} />
    </div>
  );
}
