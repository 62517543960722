import Styled from "./basic_spinner.styled";
import { BarLoader } from "react-spinners";
import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import { useTranslation } from "react-i18next";
export interface BasicSpinnerProps {
  ariaLabel?: string;
  className?: string;
  height?: LoaderHeight;
}
export default function BasicSpinner({ ariaLabel, ...props }: BasicSpinnerProps) {
  const { t } = useTranslation("components");
  return (
    <Styled.Container {...props}>
      <BarLoader aria-label={ariaLabel ?? t("basicSpinner.loading")} color="#94A3B8" speedMultiplier={0.7} />
    </Styled.Container>
  );
}
