import type { CasesSlice } from "@/src/ui/pages/cases/view_models/cases.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetCasesUseCase } from "@/src/core/cases/domain/use_cases/get_cases_use_case";
import { selectCasesPageFilters } from "./cases_page_filters.slice";
import { getInitialPaginationState } from "@/src/ui/view_models/table_pagination";
import { selectCasesPageSort } from "./cases_page_sort.slice";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";
import { isEmpty } from "lodash";

const initialState = (): CasesSlice => ({
  loadingState: "loaded",
  pagination: null,
  paginationState: getInitialPaginationState()
});

const defaultSort: SortingModel = { "order[createdAt]": "desc" };

export const getCasesPageThunk = createAsyncThunk("casespage.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const filters = selectCasesPageFilters(state);
  const selectedSort = selectCasesPageSort(state);
  const sort = isEmpty(selectedSort) ? defaultSort : selectedSort;
  
  const useCase = await locator.get<IocProvider<GetCasesUseCase>>(TYPES.GetCasesUseCase)();
  return await useCase.execute({ ...filters, ...sort, page: (page ?? 0) + 1, itemsPerPage: 10 });
});

const casesPageSlice = createSlice({
  name: "casesPage.slice",
  initialState: initialState(),
  reducers: {
    resetCasesPage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCasesPageThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getCasesPageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCasesPageThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.casesPage;
}

export const selectCasesPage = createSelector(selectBase, (slice) => slice.pagination);
export const selectCasesPageHasResults = createSelector(selectBase, (cases) => cases.pagination?.hasResults);
export const selectCasesPageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectCasesPagePaginationState = createSelector(selectBase, (cases) => cases.paginationState);

export const { resetCasesPage } = casesPageSlice.actions;
export default casesPageSlice.reducer;
