import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import { useTranslation } from "react-i18next";
import Styled from "./404_message.styled";

interface Props {
  height?: LoaderHeight;
}

export default function Message404({ height = "fullscreen" }: Props) {
  const { t } = useTranslation("404");

  return (
    <Styled.Container height={height}>
      <Styled.Title>{t("title")}</Styled.Title>
      <Styled.Paragraph>{t("paragraph")}</Styled.Paragraph>
      <Styled.Link to="/">{t("goBack")}</Styled.Link>
    </Styled.Container>
  );
}
