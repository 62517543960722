import { useEffectStrictMode } from "@front_web_mrmilu/hooks";
import { useAppDispatch } from "@/src/ui/state";
import { setRedirectSessionUrlThunk } from "@/src/ui/state/redirect_session.slice";

export function useGetRedirectSessionUrlController() {
  const dispatch = useAppDispatch();
  useEffectStrictMode(function () {
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get("redirect");
    if (redirectUrl) {
      dispatch(setRedirectSessionUrlThunk(redirectUrl));
    }
  });
}
