import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import type { CourseAttendeeDetailSlice } from "../view_models/course_attendee_detail.slice";
import type { GetCourseAttendeeDetailModel } from "@/src/core/courses/attendees/domain/models/get_course_attendee_detail.model";
import type { GetCourseAttendeeDetailUseCase } from "@/src/core/courses/attendees/domain/use_cases/get_course_attendee_detail_use_case";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): CourseAttendeeDetailSlice => getInitialDetailSlice();

export const getCourseAttendeeDetailThunk = createAsyncThunk("courseAttendeeDetail.slice/get", async (input: GetCourseAttendeeDetailModel) => {
  const useCase = await locator.get<IocProvider<GetCourseAttendeeDetailUseCase>>(TYPES.GetCourseAttendeeDetailUseCase)();
  return await useCase.execute(input);
});
const courseAttendeeDetailSlice = createSlice({
  name: "courseAttendeeDetail.slice",
  initialState: initialState(),
  reducers: {
    resetCourseAttendeeDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCourseAttendeeDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCourseAttendeeDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCourseAttendeeDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.courseAttendeeDetail;
export const selectCourseAttendeeDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectCourseAttendeeDetailLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export default courseAttendeeDetailSlice.reducer;
