import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import { selectCanEditDentistDetail } from "../../pages/dentists/dentist_detail/state/dentist_detail_roles.selectors";
import type { PropsWithChildren } from "react";
import { selectDentistDetail } from "../../pages/dentists/dentist_detail/state/dentist_detail.slice";
import { usePageId } from "../../hooks/use_page_id";

export default function CanEditDentistMiddleware({ children }: PropsWithChildren) {
  const canEditDentist = useAppSelector(selectCanEditDentistDetail);
  const id = usePageId("CanEditDentistMiddleware");
  const detail = useAppSelector(selectDentistDetail);
  if (canEditDentist || id !== detail?.id) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
