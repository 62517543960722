import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { GetDentistsUseCase } from "@/src/core/dentists/domain/use_cases/get_dentists_use_case";
import type { ClinicDetailDentistsSlice } from "../view_models/clinic_detail_dentists.slice";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import type { RemoveDentistFromClinicUseCase } from "@/src/core/clinics/domain/use_cases/remove_dentist_from_clinic_use_case";

const initialState = (): ClinicDetailDentistsSlice => ({
  ...getInitialPaginationSlice(),
  clinicId: null
});

export const getClinicDetailDentistsThunk = createAsyncThunk("clinicDetailDentists.slice/get", async (clinicId: string) => {
  const useCase = await locator.get<IocProvider<GetDentistsUseCase>>(TYPES.GetDentistsUseCase)();
  return await useCase.execute({ clinic: clinicId, itemsPerPage: 100 });
});

export const removeDentistFromClinicThunk = createAsyncThunk(
  "clinicDetailDentists.slice/removeDentistFromClinic",
  async (dentistId: string, { dispatch, getState }) => {
    try {
      const clinicId = selectClinicDetailDentistsId(getState() as RootState);
      if (!clinicId) throw new Error("Clinic ID not found");

      const useCase = await locator.get<IocProvider<RemoveDentistFromClinicUseCase>>(TYPES.RemoveDentistFromClinicUseCase)();
      await useCase.execute({ clinic: clinicId, dentist: dentistId });
      
      await dispatch(getClinicDetailDentistsThunk(clinicId));
      dispatch(showSuccessAlertThunk());
    } catch (error) {
      console.error(error);
      dispatch(showErrorAlertThunk());
    }
  }
);

const clinicDetailDentistsSlice = createSlice({
  name: "clinicDetailDentists.slice",
  initialState: initialState(),
  reducers: {
    resetClinicDetailDentists: initialState
  },
  extraReducers(builder) {
    builder.addCase(getClinicDetailDentistsThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getClinicDetailDentistsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = "error";
    });
    builder.addCase(getClinicDetailDentistsThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.clinicId = action.meta.arg;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.clinicDetailDentists;

export const selectClinicDetailDentists = createSelector(selectBase, (slice) => slice.pagination?.results ?? []);
export const selectClinicDetailDentistsId = createSelector(selectBase, (slice) => slice.clinicId);
export const selectClinicDetailDentistsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const { resetClinicDetailDentists } = clinicDetailDentistsSlice.actions;
export default clinicDetailDentistsSlice.reducer;
