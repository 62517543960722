import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { TYPES } from "@/src/core/app/ioc/types";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { locator } from "@/src/core/app/ioc";
import type { CaseViewerState } from "@/src/ui/pages/cases/case_viewer/view_models/case_viewer_state";
import type { GetTreatmentInputModel } from "@/src/core/treatments/domain/models/get_treatment_input_model";
import type { GetTreatmentViewerContentUseCase } from "@/src/core/treatments/domain/use_cases/get_treatment_viewer_content_use_case";
import type { TreatmentViewerInputModel } from "@/src/core/treatments/domain/models/treatment_viewer_input_model";
import { getCaseFilesThunk } from "@/src/ui/pages/cases/case_files/state/case_files.slice";
import { getCaseDetailThunk } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { getTreatmentDetailThunk } from "../../../treatments/treatment_detail/state/treatment_detail.slice";
import { serializeError } from "@/src/common/utils/rtk";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): CaseViewerState => ({
  loadingState: "loaded",
  panelOpen: false,
  viewerContentUrl: undefined
});

export const getViewerThunk = createAsyncThunk(
  "caseViewer.slice/getViewer",
  async (input: TreatmentViewerInputModel) => {
    const useCase = await locator.get<IocProvider<GetTreatmentViewerContentUseCase>>(TYPES.GetTreatmentViewerContentUseCase)();
    return await useCase.execute(input);
  },
  { serializeError }
);
export const initCaseViewerThunk = createAsyncThunk("caseViewer.slice/init", async (input: GetTreatmentInputModel, { dispatch }) => {
  dispatch(getTreatmentDetailThunk(input));
  dispatch(getCaseFilesThunk(input.caseReference));
  dispatch(getCaseDetailThunk(input.caseReference));
  dispatch(getViewerThunk(input));
});

const caseViewerSlice = createSlice({
  name: "caseViewer.slice",
  initialState: initialState(),
  reducers: {
    togglePanel(state) {
      state.panelOpen = !state.panelOpen;
    }
  },
  extraReducers(builder) {
    builder.addCase(getViewerThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getViewerThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getViewerThunk.fulfilled, (state, action) => {
      state.viewerContentUrl = action.payload;
      state.loadingState = "loaded";
    });
  }
});
const selectCaseViewerBase = (state: RootState) => state.caseViewer;
const selectCaseFilesBase = (state: RootState) => state.caseFiles;

export const selectCaseViewerFilesImages = createSelector(selectCaseFilesBase, (slice) =>
  slice.files.filter((file) => file.isPhoto && file.isUploaded)
);

export const selectCaseViewerPanelOpen = createSelector(selectCaseViewerBase, (slice) => slice.panelOpen);
export const selectCaseViewerLoadingState = createSelector(selectCaseViewerBase, (slice) => slice.loadingState);
export const selectCaseViewerContent = createSelector(selectCaseViewerBase, (slice) => slice.viewerContentUrl);

export const { togglePanel } = caseViewerSlice.actions;
export default caseViewerSlice.reducer;
