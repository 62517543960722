import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import { hideModalThunk, selectModal, selectShowModal } from "@/src/ui/state/modal.slice";
import { useEffect, useState } from "react";
import Styled from "./modal_zone.styled";

function ModalContent() {
  return <>{useAppSelector(selectModal)}</>;
}

export default function ModalZone() {
  const showModal = useAppSelector(selectShowModal);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [transparent, setTransparent] = useState(true);

  useEffect(() => {
    if (showModal) {
      setShow(true);
      setTimeout(() => setTransparent(false), 50);
    } else {
      setTransparent(true);
      setTimeout(() => setShow(false), 600);
    }
  }, [showModal]);

  if (!show) return null;
  return (
    <Styled.Zone>
      <Styled.Overlay aria-label="Hide modal" transparent={transparent} onClick={() => dispatch(hideModalThunk())} />
      <ModalContent />
    </Styled.Zone>
  );
}
