import { useRoutes } from "react-router-dom";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import { useAppSelector } from "@/src/ui/state";
import { selectIsNotClient } from "@/src/ui/pages/roles/state/roles.selectors";
import { lazy } from "react";
const InvoicesPage = lazy(() => import("@/src/ui/pages/billing/invoices/components/invoices_page/invoices_page"));
const InvoiceDetailPage = lazy(() => import("../../invoice_detail/components/invoice_detail_page/invoice_detail_page"));
const EditInvoicePage = lazy(() => import("../../edit_invoice/components/edit_invoice_page/edit_invoice_page"));

export default function InvoicesPages() {
  const isNotClient = useAppSelector(selectIsNotClient);
  const page = useRoutes([
    { index: true, element: <InvoicesPage /> },
    { path: "/detail/:id", element: <InvoiceDetailPage /> },
    { path: "/detail/:id/edit", element: <EditInvoicePage /> },
    { path: "*", element: <Page404 /> }
  ]);
  return (
    <HasRoleMiddleware profileRole={["BILLING_ADDRESSES_VIEW", "BILLING_ADDRESSES_VIEW_OWN"]}>{isNotClient ? page : <Page404 />}</HasRoleMiddleware>
  );
}
