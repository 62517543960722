import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";

export function groupCustomFormFields(fields: CustomFieldModel[]): CustomFieldModel[][] {
  let length = 0;
  const rows: Record<number, CustomFieldModel[]> = {};
  fields.forEach((field, index) => {
    const row = field.display ? ("row" in field.display ? field.display.row : field.display.edit.row) : index;
    if (rows[row]) {
      rows[row].push(field);
    } else {
      rows[row] = [field];
      length = Math.max(length, row + 1);
    }
  });
  return Array.from<CustomFieldModel[]>({ ...rows, length }).filter((r) => r);
}
