import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { ModalSlice } from "../view_models/modal.slice";
import type { ReactNode } from "react";
import { timeout } from "@front_web_mrmilu/utils";
import { clearAlerts } from "./alerts.slice";

const initialState = (): ModalSlice => ({
  show: false,
  modal: null
});

export const showModalThunk = createAsyncThunk("modal.slice/show", async (modalContent: ReactNode, { dispatch }) => {
  dispatch(clearAlerts());
  dispatch(showModal(modalContent));
});

export const hideModalThunk = createAsyncThunk("modal.slice/hide", async (_, { dispatch }) => {
  dispatch(hideModal());
  await timeout(600);
});

const modalSlice = createSlice({
  name: "modal.slice",
  initialState: initialState(),
  reducers: {
    showModal: (state, action: PayloadAction<ReactNode>) => {
      state.show = true;
      state.modal = action.payload;
    },
    hideModal: (state) => {
      state.show = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(hideModalThunk.fulfilled, (state) => {
      state.modal = null;
    });
  }
});

function selectModalBase(state: RootState) {
  return state.modal;
}

export const selectShowModal = createSelector([selectModalBase], (slice) => slice.show);
export const selectModal = createSelector([selectModalBase], (slice) => slice.modal);

const { hideModal, showModal } = modalSlice.actions;
export default modalSlice.reducer;
