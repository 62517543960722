import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { Link as RouterLink } from "react-router-dom";
import { px2rem, truncateText } from "@/src/ui/styles/utils";
import { colors } from "../../../styles/colors";
import type { LoaderHeight} from "@/src/ui/styles/loader_height";
import { getLoaderHeight } from "@/src/ui/styles/loader_height";

interface ContainerProps {
  height?: LoaderHeight;
}
const Container = styled.div<ContainerProps>`
  height: ${({ height }) => getLoaderHeight(height)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    text-align: center;
    min-width: 0;
    ${truncateText}
  }
  width: 100%;
`;
const Title = styled.h2`
  ${typography.headingXL}
  margin-bottom: ${px2rem(12)};
`;
const Paragraph = styled.p`
  ${typography.small}
  margin-bottom: ${px2rem(8)};
`;
const Link = styled(RouterLink)`
  ${typography.small}
  color: ${colors.neutral500}
`;
export default { Container, Title, Paragraph, Link };
