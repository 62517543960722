import { useRoutes } from "react-router-dom";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import { lazy } from "react";
const CourseDetailPage = lazy(() => import("../../course_detail/components/course_detail_page/course_detail_page"));
const CourseInvoiceCreatePage = lazy(() => import("../../views/course_invoice_create/course_invoice_create_page"));
const CoursesPage = lazy(() => import("@/src/ui/pages/courses/components/courses_page/courses_page"));

export default function CoursesPages() {
  const page = useRoutes([
    {
      path: "on-site",
      element: <CoursesPage />
    },
    {
      path: "detail/:id",
      element: (
        <HasRoleMiddleware profileRole="COURSES_EDIT">
          <CourseDetailPage />
        </HasRoleMiddleware>
      )
    },
    {
      path: "detail/:id/invoice/:attendee",
      element: (
        <HasRoleMiddleware profileRole="COURSES_EDIT">
          <CourseInvoiceCreatePage />
        </HasRoleMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole="COURSES_VIEW">{page}</HasRoleMiddleware>;
}
