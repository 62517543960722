import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { shadows } from "@/src/ui/styles/shadows";

export interface LabelTextProps {
  isDisabled?: boolean;
}

const Label = styled.label`
  display: block;
  width: 100%;
`;
const LabelText = styled.p<LabelTextProps>`
  ${typography.xSmall}
  margin-bottom: ${px2rem(4)};
  color: ${(p) => (p.isDisabled ? colors.neutral400 : colors.neutral900)};
`;
const ErrorText = styled.p`
  ${typography.xSmall}
  margin-top: ${px2rem(4)};
  color: ${colors.alert700};
`;

const SelectInput = styled.div<LabelTextProps>`
  .Select {
    &__control {
      background-color: ${colors.neutral200};
      border-radius: ${px2rem(4)} ${px2rem(4)} 0 0;
      border-top-style: unset;
      border-left-style: unset;
      border-right-style: unset;
      border-bottom: solid 2px ${colors.neutral700};
      cursor: pointer;
      &--is-focused {
        outline: none;
        border-top-style: unset;
        border-left-style: unset;
        border-right-style: unset;
        border-bottom: solid 2px ${colors.brand500};
        box-shadow: none;
      }
      &:hover {
        border-bottom: solid 2px ${colors.neutral700};
      }
    }
    &__control--is-disabled {
      pointer-events: none;
      color: ${colors.neutral400};
      background-color: ${colors.neutral200};
      border-bottom: solid 1px ${colors.neutral400};
      box-shadow: 0 1px 0 ${colors.neutral400};
    }
    &__single-value--is-disabled {
      color: ${colors.neutral400}!important;
    }
    &__placeholder {
      ${typography.small}
      color: ${colors.neutral500};
    }
    &__indicators {
      width: ${px2rem(26)};
      > svg {
        width: ${px2rem(20)};
      }
    }
    &__menu {
      ${borderRadius.s};
      overflow: hidden;
      background-color: ${colors.neutral200};
      box-shadow: unset;
      color: ${colors.neutral900};
      border: solid 1px ${colors.neutral500};
      padding-top: 0;
      ${shadows.soft}
      z-index: 150;
      &-list {
        padding: 0;
      }
    }
    &__single-value,
    &__option {
      ${typography.small}
      color: ${colors.neutral900};
      cursor: pointer;
      &--is-selected {
        color: ${colors.white};
        background-color: ${colors.brand500};
      }
      &--is-focused {
        color: ${colors.white};
        background-color: ${colors.brand700};
      }
      :active {
        background-color: ${colors.brand500};
      }
    }
  }
`;

export default { Label, LabelText, ErrorText, SelectInput };
