import { minWidth } from "@/src/ui/styles/breakpoints";
import { px2rem } from "@/src/ui/styles/utils";
import type { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

const Padding = styled.div`
  padding: 0 ${px2rem(17)};
  ${minWidth(
    "sm",
    css`
      padding: 0 ${px2rem(36)};
    `
  )}
`;

export default function PageHorizontalPadding({ children }: PropsWithChildren) {
  return <Padding>{children}</Padding>;
}
