import { useField } from "formik";
import type { CheckboxProps } from "@/src/ui/components/fields/checkbox/checkbox";
import CheckboxField from "@/src/ui/components/fields/checkbox_field/checkbox_field";

type CheckboxFieldFormikProps = Omit<CheckboxProps, "onChange" | "value">;
export default function CheckboxFieldFormik(props: CheckboxFieldFormikProps) {
  const [field, meta, helpers] = useField(props.name);

  return <CheckboxField value={field.value} error={meta.touched ? meta.error : undefined} onChange={helpers.setValue} {...props} />;
}
