import Styled from "./case_file_card_bottom_text.styled";
import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import type { CaseFileErrorType } from "@/src/ui/pages/cases/case_files/view_models/case_files.slice";
import { useTranslation } from "react-i18next";

interface Props {
  caseFile: CaseFileModel;
  isLoading: boolean;
  hasError: CaseFileErrorType;
  reload(): void;
  reloadDelete(): void;
}
export default function CaseFileCardBottomText({ caseFile, isLoading, hasError, reload, reloadDelete }: Props) {
  const { t } = useTranslation("case_files");
  if (isLoading) return <Styled.InfoText>{t("loading.file")}</Styled.InfoText>;
  if (hasError === "delete")
    return (
      <Styled.ErrorText>
        {t("errors.delete") + ". "}

        <Styled.Retry onClick={reloadDelete}>{t("errors.retry")}</Styled.Retry>
      </Styled.ErrorText>
    );
  if (hasError === "upload") return <Styled.ErrorText>{t("errors.upload")}</Styled.ErrorText>;
  if (hasError === "get")
    return (
      <Styled.ErrorText>
        {t("errors.get") + ". "}
        <Styled.Retry onClick={reload}>{t("errors.retry")}</Styled.Retry>
      </Styled.ErrorText>
    );
  const updateDate = caseFile.updatedAt || caseFile.createdAt;
  if (updateDate)
    return (
      <Styled.InfoText>
        {t("card.updatedAt")} {updateDate.toLocaleDateString()}
      </Styled.InfoText>
    );
  return <Styled.InfoText>{t("card.noFile")}</Styled.InfoText>;
}
