import { serializeError } from "@/src/common/utils/rtk";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { CreateManufacturingLotModel } from "@/src/core/manufacturing_lots/domain/models/create_manufacturing_lot_model";
import type { GetManufacturingLotsModel } from "@/src/core/manufacturing_lots/domain/models/get_manufacturing_lots_model";
import type { CreateManufacturingLotUseCase } from "@/src/core/manufacturing_lots/domain/use_cases/create_manufacturing_lot_use_case";
import type { GetManufacturingLotsUseCase } from "@/src/core/manufacturing_lots/domain/use_cases/get_manufacturing_lots_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { manufacturingLotToOption } from "../components/presenters/manufacturing_lot_to_option";
import type { ManufacturingLotsSlice } from "../view_models/manufacturing_lots.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";

export const initialState = (): ManufacturingLotsSlice => ({
  ...getInitialPaginationSlice(),
  reference: null,
  treatment: null
});

const itemsPerPage = 100;
export const getManufacturingLotsThunk = createAsyncThunk("manufacturingLots.slice/get", async (input: GetManufacturingLotsModel) => {
  const useCase = await locator.get<IocProvider<GetManufacturingLotsUseCase>>(TYPES.GetManufacturingLotsUseCase)();

  let pagination = await useCase.execute({...input, itemsPerPage });
  while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({...input, page: pagination.nextPage, itemsPerPage }));

  return pagination;
});

export const createManufacturingLotThunk = createAsyncThunk(
  "manufacturingLots.slice/create",
  async (input: CreateManufacturingLotModel) => {
    const useCase = await locator.get<IocProvider<CreateManufacturingLotUseCase>>(TYPES.CreateManufacturingLotUseCase)();
    return await useCase.execute(input);
  },
  { serializeError }
);

const manufacturingLotsSlice = createSlice({
  name: "manufacturingLots.slice",
  initialState: initialState(),
  reducers: {
    resetManufacturingLots: initialState
  },
  extraReducers(builder) {
    builder.addCase(getManufacturingLotsThunk.pending, (state, action) => {
      state.paginationState.pageIndex = action.meta.arg.page ?? 0;
      state.loadingState = "loading";
    });
    builder.addCase(getManufacturingLotsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getManufacturingLotsThunk.fulfilled, (state, action) => {
      state.reference = action.meta.arg.reference;
      state.treatment = action.meta.arg.treatment;
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });

    builder.addCase(createManufacturingLotThunk.fulfilled, (state, action) => {
      state.pagination?.results.unshift(action.payload);
      state.pagination = state.pagination?.copy() ?? null;
    });
  }
});

const selectBase = (state: RootState) => state.manufacturingLots;

export const selectManufacturingLots = createSelector(selectBase, (slice) => slice.pagination);
export const selectManufacturingLotsReference = createSelector(selectBase, (slice) => slice.reference);
export const selectManufacturingLotsTreatment = createSelector(selectBase, (slice) => slice.treatment);
export const selectManufacturingLotsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectManufacturingLotOptions = createSelector(selectManufacturingLots, (p) => p?.results.map(manufacturingLotToOption) ?? []);

export const { resetManufacturingLots } = manufacturingLotsSlice.actions;

export default manufacturingLotsSlice.reducer;
