export class ArraySet {
  private set = new Set<string>();

  private formatArray(array: string[]) {
    return array.join(".");
  }

  add(array: string[]) {
    this.set.add(this.formatArray(array));
  }
  has(array: string[]) {
    return this.set.has(this.formatArray(array));
  }
  delete(array: string[]) {
    this.set.delete(this.formatArray(array));
  }
}
