import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetBillingAddressesUseCase } from "@/src/core/billing/domain/use_cases/get_billing_addresses_use_case";
import type { BillingAddressesPageSlice } from "@/src/ui/pages/billing/billing_addresses/view_models/billing_addresses_page.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { selectBillingAddressesPageFilters } from "./billing_addresses_page_filters.slice";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";

const initialState = (): BillingAddressesPageSlice => getInitialPaginationSlice();

export const getBillingAddressesPageThunk = createAsyncThunk("billingAddressesPage.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const filters = selectBillingAddressesPageFilters(state);
  const useCase = await locator.get<IocProvider<GetBillingAddressesUseCase>>(TYPES.GetBillingAddressesUseCase)();
  return await useCase.execute({ ...filters, page: (page ?? 0) + 1, itemsPerPage: 9 });
});

const billingAddressesPageSlice = createSlice({
  name: "billingAddressesPage.slice",
  initialState: initialState(),
  reducers: {
    resetBillingAddressesPage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getBillingAddressesPageThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getBillingAddressesPageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getBillingAddressesPageThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.billingAddressesPage;
}

export const selectBillingAddressesPage = createSelector(selectBase, (slice) => slice.pagination);
export const selectBillingAddressesPageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectBillingAddressesPagePaginationState = createSelector(selectBase, (slice) => slice.paginationState);
export const { resetBillingAddressesPage } = billingAddressesPageSlice.actions;
export default billingAddressesPageSlice.reducer;
