import type { Option } from "@/src/core/options/domain/models/option";
import Styled from "./radio_field.styled";
import RadioInput from "@/src/ui/components/fields/radio_input/radio_input";
import VerticalSpacer from "../../spacing/vertical_spacer/vertical_spacer";
import ErrorText from "../../error_displayers/error_text/error_text";

export interface RadioFieldProps<T extends string> {
  name: string;
  label?: string;
  helpText?: string;
  error?: string;
  options?: Option<T>[];
  value: T;
  onChange(value: T): void;
  className?: string;
  loadingText?: string;
  loadingError?: string | false;
  gridLayout?: string;
}
export default function RadioField<T extends string>({
  name,
  label,
  helpText,
  error,
  options,
  value,
  onChange,
  className,
  loadingText,
  loadingError,
  gridLayout
}: RadioFieldProps<T>) {
  function renderOptions() {
    if (loadingError) {
      return <Styled.LoadingError>{loadingError}</Styled.LoadingError>;
    }
    if (!options) {
      return <Styled.Loader ariaLabel={loadingText ?? "Cargando opciones…"} />;
    }
    return (
      <>
        <Styled.Radios optionsCount={options?.length} gridLayout={gridLayout}>
          {options.map((o) => {
            const isChecked = value === o.value;
            return (
              <RadioInput
                key={o.value}
                name={name}
                id={name + "-" + o.value}
                value={isChecked}
                onChange={() => !isChecked && onChange(o.value)}
                label={o.label}
              />
            );
          })}
        </Styled.Radios>
        {error && (
          <Styled.ErrorText>
            <ErrorText error={error} />
          </Styled.ErrorText>
        )}
      </>
    );
  }

  return (
    <Styled.Fieldset className={className}>
      {label && <Styled.Legend>{label}</Styled.Legend>}
      {helpText && <Styled.HelpText>{helpText}</Styled.HelpText>}
      {label && <VerticalSpacer height="xs" />}
      {renderOptions()}
    </Styled.Fieldset>
  );
}
