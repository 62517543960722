import { selectTreatmentIds } from "@/src/ui/pages/treatments/state/treatments.slice";
import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";

interface ManufacturingSelectedTreatmentSlice {
  value: string | null;
}
const initialState = (): ManufacturingSelectedTreatmentSlice => ({
  value: null
});

const manufacturingSelectedTreatmentSlice = createSlice({
  name: "manufacturingSelectedTreatment.slice",
  initialState: initialState(),
  reducers: {
    setManufacturingSelectedTreatment(state, action: PayloadAction<string | null>) {
      state.value = action.payload;
    }
  }
});

export const { setManufacturingSelectedTreatment } = manufacturingSelectedTreatmentSlice.actions;

const selectBase = (state: RootState) => state.manufacturingSelectedTreatment;
const selectValue = createSelector(selectBase, (slice) => slice.value);

export const selectManufacturingSelectedTreatment = createSelector(selectValue, selectTreatmentIds, (value, treatments) =>
  value && treatments.includes(value) ? value : treatments[0] ?? null
);
export default manufacturingSelectedTreatmentSlice.reducer;
