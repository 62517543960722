import styled from "styled-components";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { spacing } from "@/src/ui/styles/spacing";

const BasicCard = styled.div`
  background-color: white;
  padding: ${spacing.m};
  ${borderRadius.m};
`;

export default BasicCard;
