import CaseFilesSection from "@/src/ui/pages/cases/case_files/components/case_files_section/case_files_section";
import { useAppSelector } from "@/src/ui/state";
import { selectCaseDetailReference } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { NullError } from "@/src/core/app/domain/models/errors/null.error";

export default function NewCaseFormFiles() {
  const reference = useAppSelector(selectCaseDetailReference);
  if (!reference) throw new NullError("NewCaseFormFiles", "reference");
  return <CaseFilesSection caseReference={reference} />;
}
