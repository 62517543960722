import type { CaseFilesSlice } from "@/src/ui/pages/cases/case_files/view_models/case_files.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCaseFilesUseCase } from "@/src/core/cases/domain/use_cases/get_case_files_use_case";
import type { GetCaseFileDetailModel } from "@/src/core/cases/domain/models/get_case_file_detail_model";
import type { GetCaseFileDetailUseCase } from "@/src/core/cases/domain/use_cases/get_case_file_detail_use_case";
import type { UploadCaseFileModel } from "@/src/core/cases/domain/models/upload_case_file_model";
import type { UploadCaseFileUseCase } from "@/src/core/cases/domain/use_cases/upload_case_file_use_case";
import type { DeleteCaseFileUseCase } from "@/src/core/cases/domain/use_cases/delete_case_file_use_case";

const initialState = (): CaseFilesSlice => ({
  reference: null,
  files: [],
  isLoading: false,
  hasError: false,
  isLoadingDetail: {},
  hasErrorDetail: {}
});

export const getCaseFilesThunk = createAsyncThunk("caseFiles.slice/get", async (reference: string) => {
  const useCase = await locator.get<IocProvider<GetCaseFilesUseCase>>(TYPES.GetCaseFilesUseCase)();
  return await useCase.execute(reference);
});

export const getCaseFileDetailThunk = createAsyncThunk("caseFiles.slice/getDetail", async (input: GetCaseFileDetailModel) => {
  const useCase = await locator.get<IocProvider<GetCaseFileDetailUseCase>>(TYPES.GetCaseFileDetailUseCase)();
  return await useCase.execute(input);
});
export const uploadCaseFileThunk = createAsyncThunk("caseFiles.slice/upload", async (input: UploadCaseFileModel) => {
  const useCase = await locator.get<IocProvider<UploadCaseFileUseCase>>(TYPES.UploadCaseFileUseCase)();
  return await useCase.execute(input);
});
export const deleteCaseFileThunk = createAsyncThunk("caseFiles.slice/delete", async (input: GetCaseFileDetailModel) => {
  const useCase = await locator.get<IocProvider<DeleteCaseFileUseCase>>(TYPES.DeleteCaseFileUseCase)();
  return await useCase.execute(input);
});

const caseFilesSlice = createSlice({
  name: "caseFiles.slice",
  initialState: initialState(),
  reducers: { resetCaseFiles: initialState },
  extraReducers(builder) {
    builder.addCase(getCaseFilesThunk.pending, (state) => {
      state.hasError = false;
      state.isLoading = true;
      state.isLoadingDetail = {};
      state.hasErrorDetail = {};
    });
    builder.addCase(getCaseFilesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCaseFilesThunk.fulfilled, (state, action) => {
      state.files = action.payload;
      state.isLoading = false;
      state.reference = action.meta.arg;
    });

    builder.addCase(getCaseFileDetailThunk.pending, (state, action) => {
      const { key } = action.meta.arg;
      state.isLoadingDetail[key] = true;
      delete state.hasErrorDetail[key];
    });
    builder.addCase(getCaseFileDetailThunk.rejected, (state, action) => {
      const { key } = action.meta.arg;
      console.error(action.error);
      state.isLoadingDetail[key] = false;
      state.hasErrorDetail[key] = "get";
    });
    builder.addCase(getCaseFileDetailThunk.fulfilled, (state, action) => {
      const { key } = action.meta.arg;
      if (state.files.length) {
        const index = state.files.findIndex((f) => f.key === key);
        if (index !== -1) state.files[index] = action.payload;
      }
      state.isLoadingDetail[key] = false;
    });

    builder.addCase(uploadCaseFileThunk.pending, (state, action) => {
      const { key } = action.meta.arg;
      state.isLoadingDetail[key] = true;
      delete state.hasErrorDetail[key];
    });
    builder.addCase(uploadCaseFileThunk.rejected, (state, action) => {
      const { key } = action.meta.arg;
      console.error(action.error);
      state.isLoadingDetail[key] = false;
      state.hasErrorDetail[key] = "upload";
    });
    builder.addCase(uploadCaseFileThunk.fulfilled, (state, action) => {
      const { key } = action.meta.arg;
      if (state.files.length) {
        const index = state.files.findIndex((f) => f.key === key);
        if (index !== -1) state.files[index] = action.payload;
      }
      state.isLoadingDetail[key] = false;
    });

    builder.addCase(deleteCaseFileThunk.pending, (state, action) => {
      const { key } = action.meta.arg;
      state.isLoadingDetail[key] = true;
      delete state.hasErrorDetail[key];
    });
    builder.addCase(deleteCaseFileThunk.rejected, (state, action) => {
      const { key } = action.meta.arg;
      console.error(action.error);
      state.isLoadingDetail[key] = false;
      state.hasErrorDetail[key] = "delete";
    });
    builder.addCase(deleteCaseFileThunk.fulfilled, (state, action) => {
      const { key } = action.meta.arg;
      if (state.files.length) {
        const index = state.files.findIndex((f) => f.key === key);
        if (index !== -1) state.files[index] = state.files[index].empty();
      }
      state.isLoadingDetail[key] = false;
    });
  }
});

const selectCaseFilesBase = (state: RootState) => state.caseFiles;

export const selectCaseFiles = createSelector(selectCaseFilesBase, (slice) => slice.files);
export const selectCaseFilesReference = createSelector(selectCaseFilesBase, (slice) => slice.reference);
export const selectCaseFilesIsLoading = createSelector(selectCaseFilesBase, (slice) => slice.isLoading);
export const selectCaseFilesHasError = createSelector(selectCaseFilesBase, (slice) => slice.hasError);
export const selectCaseFileDetailIsLoading = createSelector(
  selectCaseFilesBase,
  (state: RootState, key: string) => key,
  (slice, key) => slice.isLoadingDetail[key] ?? false
);
export const selectCaseFileDetailHasError = createSelector(
  selectCaseFilesBase,
  (state: RootState, key: string) => key,
  (slice, key) => slice.hasErrorDetail[key] ?? false
);

export const { resetCaseFiles } = caseFilesSlice.actions;

export default caseFilesSlice.reducer;
