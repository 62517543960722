export const colors = {
  // basic
  white: "#FFFFFF",
  yellow500: "#E6AD2E",
  orange500: "#FB8500",

  // Brand Palette
  brand900: "#023047",
  brand700: "#126782",
  brand500: "#219EBC",
  brand300: "#65C5DD",
  brand200: "#99CAD7",
  brand100: "#D1E7ED",

  // Neutral Palette
  neutral900: "#202634",
  neutral800: "#4B5769",
  neutral700: "#64748B",
  neutral500: "#94A3B8",
  neutral400: "#AFBDCE",
  neutral300: "#DFE5EC",
  neutral200: "#EBEFF3",
  neutral100: "#F8FAFC",

  // Error
  error700: "#B42318",
  error600: "#ce5a52",
  error200: "#FECDCA",
  error100: "#FEE4E2",
  error50: "#FEF3F2",

  // Alert
  alert700: "#B54708",
  alert200: "#FEDF89",
  alert100: "#FEF0C7",
  alert50: "#FFFAEB",

  // Success
  success700: "#027A48",
  success200: "#A6F4C5",
  success100: "#D1FADF",
  success50: "#ECFDF3",

  // Information
  info700: "#175CD3",
  info200: "#B2DDFF",
  info100: "#D1E9FF",
  info50: "#EFF8FF",

  // Linear gradient
  gradientFrom: "#0E5268",
  gradientTo: "#1E5E72",
  gradientBackground: "#346D80"
};
