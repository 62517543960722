import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { spacing } from "@/src/ui/styles/spacing";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";

const Section = styled.section`
  border-top: 1px solid ${colors.neutral300};
  padding: 0 ${spacing.s};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  > * {
    margin-left: ${px2rem(10)};
  }
`;
const ErrorMessage = styled.p`
  ${typography.small}
  color: red;
  text-align: right;
`;

export default { Section, Buttons, ErrorMessage };
