import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { CaseNoteDetailSlice } from "../view_models/case_note_detail.slice";
import type { GetCaseNoteUseCase } from "@/src/core/cases/case_notes/domain/use_cases/get_case_note_use_case";
import type { GetCaseNoteModel } from "@/src/core/cases/case_notes/domain/models/get_case_note_model";
import type { CaseNoteModel } from "@/src/core/cases/case_notes/domain/models/case_note_model";

const initialState = (): CaseNoteDetailSlice => ({
  hasError: false,
  isLoading: false,
  detail: null
});

export const getCaseNoteDetailThunk = createAsyncThunk("caseNoteDetail.slice/get", async (input: GetCaseNoteModel) => {
  const useCase = await locator.get<IocProvider<GetCaseNoteUseCase>>(TYPES.GetCaseNoteUseCase)();
  return await useCase.execute(input);
});

const caseNoteDetailSlice = createSlice({
  name: "caseNoteDetail.slice",
  initialState: initialState(),
  reducers: {
    resetCaseNoteDetail: initialState,
    setCaseNoteDetail(_, action: PayloadAction<CaseNoteModel>) {
      return {
        hasError: false,
        isLoading: false,
        detail: action.payload
      };
    }
  },

  extraReducers(builder) {
    builder.addCase(getCaseNoteDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCaseNoteDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCaseNoteDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
  }
});

function selectCaseNoteDetailBase(state: RootState) {
  return state.caseNoteDetail;
}

export const selectCaseNoteDetail = createSelector(selectCaseNoteDetailBase, (slice) => slice.detail);
export const selectCaseNoteDetailError = createSelector(selectCaseNoteDetailBase, (slice) => slice.hasError);
export const selectCaseNoteDetailIsLoading = createSelector(selectCaseNoteDetailBase, (slice) => slice.isLoading);
export const { resetCaseNoteDetail, setCaseNoteDetail } = caseNoteDetailSlice.actions;
export default caseNoteDetailSlice.reducer;
