import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import Styled from "./role_error_message.styled";
import { useTranslation } from "react-i18next";

interface Props {
  height?: LoaderHeight;
}
export default function RoleErrorMessage({ height = "fullscreen" }: Props) {
  const { t } = useTranslation("role_error");

  return (
    <Styled.Container height={height}>
      <Styled.Title>{t("title")}</Styled.Title>
      <Styled.Paragraph>{t("paragraph")}</Styled.Paragraph>
      <Styled.Link to="/">{t("goBack")}</Styled.Link>
    </Styled.Container>
  );
}
