import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetDentistDetailUseCase } from "@/src/core/dentists/domain/use_cases/get_dentist_detail_use_case";
import type { MyDentistSlice } from "../view_models/my_dentist.slice";
import type { AddClinicToDentistModel } from "@/src/core/dentists/domain/models/add_clinic_to_dentist_model";
import type { AddClinicToDentistUseCase } from "@/src/core/dentists/domain/use_cases/add_clinic_to_dentist_use_case";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): MyDentistSlice => ({
  detail: null,
  hasError: false,
  isLoading: false
});

export const getMyDentistThunk = createAsyncThunk("myDentist.slice/get", async (dentistId: string) => {
  const useCase = await locator.get<IocProvider<GetDentistDetailUseCase>>(TYPES.GetDentistDetailUseCase)();
  return await useCase.execute(dentistId);
});
export const addClinicToMyDentistThunk = createAsyncThunk("myDentist.slice/addClinic", async (input: AddClinicToDentistModel, { dispatch }) => {
  const useCase = await locator.get<IocProvider<AddClinicToDentistUseCase>>(TYPES.AddClinicToDentistUseCase)();
  const promise = useCase.execute(input);
  promise.catch((e) => {
    console.error(e);
    dispatch(showErrorAlertThunk());
  });
  promise.then(() => dispatch(showSuccessAlertThunk()));
  return await promise;
});

const myDentistSlice = createSlice({
  name: "myDentist.slice",
  initialState: initialState(),
  reducers: {
    resetMyDentist: initialState
  },
  extraReducers(builder) {
    builder.addCase(getMyDentistThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getMyDentistThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getMyDentistThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });

    builder.addCase(addClinicToMyDentistThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
    });
  }
});

function selectDentistDetailBase(state: RootState) {
  return state.myDentist;
}

export const selectMyDentistHasClinics = createSelector(selectDentistDetailBase, (slice) => Boolean(slice.detail?.profile.clinics.length));
export const selectMyDentist = createSelector(selectDentistDetailBase, (slice) => slice.detail);
export const selectMyDentistError = createSelector(selectDentistDetailBase, (slice) => slice.hasError);
export const selectMyDentistIsLoading = createSelector(selectDentistDetailBase, (slice) => slice.isLoading);

export const { resetMyDentist } = myDentistSlice.actions;

export default myDentistSlice.reducer;
