import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { InvoicesPageFiltersSlice } from "@/src/ui/pages/billing/invoices/view_models/invoices_page_filters.slice";
import type { InvoiceFiltersModel } from "@/src/core/billing/invoices/domain/models/invoice_filters_model";

const initialState = (): InvoicesPageFiltersSlice => ({
  show: false,
  filters: {
    query: "",
    businessName: "",
    dentist: [],
    clinic: [],
    "createdAt[before]": null,
    "createdAt[after]": null,
    paymentDate: null,
    expiresAt: null,
    "amount[min]": null,
    "amount[max]": null,
    country: "",
    province: [],
    paymentType: [],
    promoter: [],
    distributor: [],
    type: "all"
  }
});

const invoicesSlice = createSlice({
  name: "invoicesPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetInvoicesPageFilters: initialState,
    cleanInvoicesPageFilters(state) {
      state.filters = initialState().filters;
    },
    setInvoicesPageFilters(state, action: PayloadAction<Partial<InvoiceFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleInvoicesPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectInvoicesPageFiltersBase(state: RootState) {
  return state.invoicesPageFilters;
}

export const selectShowInvoicesPageFilters = createSelector(selectInvoicesPageFiltersBase, (slice) => slice.show);
export const selectInvoicesPageFilters = createSelector(selectInvoicesPageFiltersBase, (slice) => slice.filters);
export const selectInvoicesPageFiltersQuery = createSelector(selectInvoicesPageFilters, (filters) => filters.query);
export const selectInvoicesPageFiltersType = createSelector(selectInvoicesPageFilters, (filters) => filters.type ?? "all");
export const { resetInvoicesPageFilters, cleanInvoicesPageFilters, setInvoicesPageFilters, toggleInvoicesPageFilters } = invoicesSlice.actions;
export default invoicesSlice.reducer;
