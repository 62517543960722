import type { IdName } from "../models/id_name";
import type { IdNamePrice } from "@/src/core/options/data/models/id_name_price";
import type { Option } from "../../domain/models/option";
import { formatEuros } from "@/src/ui/presenters/format_money";

export function idNameToOption({ name, id }: IdName): Option {
  return { label: name, value: id };
}

export function idNameToOptionPrice({ name, id, maxPrice }: IdNamePrice): Option {
  return { label: `${name} - ${formatEuros(maxPrice.amount)}`, value: id };
}
