import { store } from "@/src/ui/state";
import { resetNewProfileClinicSearch } from "@/src/ui/pages/profile/new_profile/new_profile_clinic/state/new_profile_clinic_search.slice";
import { resetCaseDetail } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { resetNewClinic } from "@/src/ui/pages/clinics/new_clinic/state/new_clinic.slice";
import { resetMyProfiles } from "@/src/ui/pages/auth/switch_profile/state/my_profiles.slice";
import { resetCaseFiles } from "@/src/ui/pages/cases/case_files/state/case_files.slice";
import { resetClinicDetail } from "@/src/ui/pages/clinics/clinic_detail/state/clinic_detail.slice";
import { resetHomePageCases } from "@/src/ui/pages/cases/state/home_page_cases.slice";
import { resetNewBillingAddress } from "@/src/ui/pages/billing/billing_addresses/new_billing_address/state/new_billing_address.slice";
import { resetUserDetail } from "../pages/users/views/user_detail/state/user_detail.slice";
import { resetNewProfileDentistSearch } from "../pages/profile/new_profile/new_profile_dentist/state/new_user_dentist_search.slice";
import { resetCasesPage } from "../pages/cases/state/cases_page.slice";
import { resetCasesPageFilters } from "../pages/cases/state/cases_page_filters.slice";
import { resetDentists } from "../pages/dentists/state/dentists.slice";
import { resetMyClinic } from "../pages/clinics/my_clinic/state/my_clinic.slice";
import { resetMyDentist } from "../pages/dentists/my_dentist/state/my_dentist.slice";
import { resetNewProfileClinicGroupSearch } from "../pages/profile/new_profile/new_profile_clinic_group/state/new_profile_clinic_group_search.slice";
import { resetBillingAddressesPageFilters } from "@/src/ui/pages/billing/billing_addresses/state/billing_addresses_page_filters.slice";
import { resetBillingAddressesPage } from "@/src/ui/pages/billing/billing_addresses/state/billing_addresses_page.slice";
import { resetClinicsPageFilters } from "@/src/ui/pages/clinics/state/clinics_page_filters.slice";
import { resetClinicsPage } from "@/src/ui/pages/clinics/state/clinics_page.slice";
import { resetCaseNotes } from "../pages/case_notes/state/case_notes.slice";
import { resetCaseNoteDetail } from "../pages/case_notes/case_note_detail/state/case_note_detail.slice";
import { resetApp } from "../state/reset.slice";
import { resetProfile } from "../state/profile.slice";
import { resetUser } from "../state/user.slice";

// Reset the state of the app to the initial state but preserve the user and profile
export function resetController() {
  const { dispatch } = store;
  dispatch(resetNewProfileClinicSearch());
  dispatch(resetNewClinic());
  dispatch(resetNewProfileDentistSearch());
  dispatch(resetNewProfileClinicGroupSearch());
  dispatch(resetHomePageCases());
  dispatch(resetCasesPage());
  dispatch(resetCasesPageFilters());
  dispatch(resetCaseDetail());
  dispatch(resetCaseFiles());
  dispatch(resetMyProfiles());
  dispatch(resetMyClinic());
  dispatch(resetMyDentist());
  dispatch(resetDentists());
  dispatch(resetNewBillingAddress());
  dispatch(resetClinicDetail());
  dispatch(resetCaseDetail());
  dispatch(resetUserDetail());
  dispatch(resetBillingAddressesPageFilters());
  dispatch(resetBillingAddressesPage());
  dispatch(resetClinicsPageFilters());
  dispatch(resetClinicsPage());
  dispatch(resetCaseNotes());
  dispatch(resetCaseNoteDetail());
  dispatch(resetApp());
}

// Reset the state of the app to the initial state
export function fullResetController() {
  const { dispatch } = store;
  dispatch(resetUser());
  dispatch(resetMyProfiles());
  dispatch(resetProfile());
  resetController();
}
