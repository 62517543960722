import styled from "styled-components";
import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import { getLoaderHeight } from "@/src/ui/styles/loader_height";

interface ContainerProps {
  height?: LoaderHeight
}
const Container = styled.div<ContainerProps>`
  height: ${({height})=> getLoaderHeight(height)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default { Container };
