import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetInvoiceDetailUseCase } from "@/src/core/billing/invoices/domain/use_cases/get_invoice_detail_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { InvoiceDetailSlice } from "../view_models/invoice_detail.slice";

const initialState = (): InvoiceDetailSlice => ({
  hasError: false,
  isLoading: false,
  detail: null
});

export const getCorrectedInvoiceDetailThunk = createAsyncThunk("correctedInvoiceDetail.slice/get", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetInvoiceDetailUseCase>>(TYPES.GetInvoiceDetailUseCase)();
  return await useCase.execute(id);
});

const correctedInvoiceDetailSlice = createSlice({
  name: "correctedInvoiceDetail.slice",
  initialState: initialState(),
  reducers: {
    resetCorrectedInvoiceDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCorrectedInvoiceDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCorrectedInvoiceDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCorrectedInvoiceDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.correctedInvoiceDetail;

export const selectCorrectedInvoiceDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectCorrectedInvoiceDetailError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCorrectedInvoiceDetailIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetCorrectedInvoiceDetail } = correctedInvoiceDetailSlice.actions;
export default correctedInvoiceDetailSlice.reducer;
