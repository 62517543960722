import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetCaseCommentsUseCase } from "@/src/core/cases/case_comments/domain/use_cases/get_case_comments_use_case";
import type { CaseCommentsSlice } from "@/src/ui/pages/case_comments/view_models/case_comments.slice";

const initialState = (): CaseCommentsSlice => ({
  results: [],
  hasError: false,
  isLoading: false
});

const itemsPerPage = 100;
export const getCaseCommentsThunk = createAsyncThunk("caseComments.slice/get", async (caseReference: string) => {
  const useCase = await locator.get<IocProvider<GetCaseCommentsUseCase>>(TYPES.GetCaseCommentsUseCase)();

  let pagination = await useCase.execute({ caseReference, itemsPerPage });
  while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({ caseReference, page: pagination.nextPage, itemsPerPage }));

  return pagination.results;
});

const caseCommentsSlice = createSlice({
  name: "caseComments.slice",
  initialState: initialState(),
  reducers: {
    resetCaseComments: initialState,
    addPublishedCommentToList(state, action) {
      state.results.push(action.payload);
    }
  },
  extraReducers(builder) {
    builder.addCase(getCaseCommentsThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCaseCommentsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCaseCommentsThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

function selectBase(state: RootState) {
  return state.caseComments;
}

export const selectCaseComments = createSelector(selectBase, (slice) => slice.results);
export const selectCaseCommentsDoNotExist = createSelector(selectCaseComments, (comments) => !comments.length);
export const selectCaseCommentsHasError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCaseCommentsIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetCaseComments, addPublishedCommentToList } = caseCommentsSlice.actions;
export default caseCommentsSlice.reducer;
