import styled from "styled-components";
import BasicCard from "@/src/ui/components/cards/basic_card/basic_card";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";

const Card = styled(BasicCard)`
  background-color: ${colors.neutral300};
  height: ${px2rem(279)};
  padding-top: ${px2rem(73)};
  > svg {
    max-width: 100%;
    display: block;
    margin: auto;
  }
`;
export default { Card };
