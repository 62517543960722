import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import CanEditBillingAddressMiddleware from "@/src/ui/router/middlewares/can_edit_billing_address_middleware";
const BillingAddressesPage = lazy(() => import("@/src/ui/pages/billing/billing_addresses/components/billing_addresses_page/billing_addresses_page"));
const NewBillingAddressPage = lazy(
  () => import("@/src/ui/pages/billing/billing_addresses/new_billing_address/components/new_billing_address_page/new_billing_address_page")
);
const BillingAddressDetailPage = lazy(
  () => import("@/src/ui/pages/billing/billing_addresses/billing_address_detail/components/billing_address_detail_page/billing_address_detail_page")
);
const EditBillingAddressPage = lazy(
  () => import("@/src/ui/pages/billing/billing_addresses/edit_billing_address/components/edit_billing_address_page/edit_billing_address_page")
);

export default function BillingAddressesPages() {
  const page = useRoutes([
    {
      index: true,
      element: <BillingAddressesPage />
    },
    {
      path: "detail/:id",
      element: <BillingAddressDetailPage />
    },
    {
      path: "detail/:id/edit",
      element: (
        <CanEditBillingAddressMiddleware>
          <EditBillingAddressPage />
        </CanEditBillingAddressMiddleware>
      )
    },
    {
      path: "new",
      element: (
        <HasRoleMiddleware profileRole="BILLING_ADDRESSES_CREATE">
          <NewBillingAddressPage />
        </HasRoleMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole={["BILLING_ADDRESSES_VIEW", "BILLING_ADDRESSES_VIEW_OWN"]}>{page}</HasRoleMiddleware>;
}
