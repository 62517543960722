import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import Styled from "./case_file_field.styled";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { Upload, File } from "@/src/ui/assets/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCaseFileDocumentationController } from "../../controllers/case_file_documentation_controller";

interface CaseFieldInputProps {
  file: CaseFileModel;
  reference: string;
  mime: string
}
export default function CaseFieldInput({ file, reference, mime }: CaseFieldInputProps) {
  const { uploadFile, isLoading, hasError } = useCaseFileDocumentationController(reference, file);
  const { t } = useTranslation("components", { keyPrefix: "fileField" });
  const { t: te } = useTranslation("errors");
  const [error, setError] = useState("");

  const getMimesAccepted = () => {
    let validationAccept = {};
    const mime = file?.validation?.mime;
    if (mime) {
      mime.forEach((key, index) => {
        validationAccept = { [key]: file.validation.extension[index], ...validationAccept };
      });
      return validationAccept
    }
  };

  const onDropAccepted = (files: File[]) => {
    files.map((file) => {
      uploadFile({
        file,
        totalSize: file.size,
        originalName: file.name,
        mimeType: mime
      });
    });
  };

  const onDropRejected = (rejections: FileRejection[]) => {
    const rejection = rejections.at(0);

    if (!rejection) return;
    const error = rejection.errors.at(0);
    if (!error) return;
    setError(`${te("file.general", { name: rejection.file.name })}`);
  };

  const dropzoneOptions = {
    multiple: false,
    accept: getMimesAccepted(),
    onDropAccepted,
    onDropRejected,
    maxSize: parseInt(file.validation.maxSize) * Math.pow(1000, 2)
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  return (
    <Styled.Wrapper>
      {file.label && <Styled.Label>{file.label}</Styled.Label>}
      {file.status === "empty" && !isLoading && (
        <Styled.Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Styled.DropzoneHint isDragActive={isDragActive}>
            {!isDragActive && (
              <>
                <Styled.DropzoneUploadIcon>
                  <Upload />
                </Styled.DropzoneUploadIcon>
                <Styled.DropzoneCta>{t("hint")}</Styled.DropzoneCta>
                {file.validation.maxSize && <Styled.DropzoneMaxSize>{t("max", { max: file.validation.maxSize })}</Styled.DropzoneMaxSize>}
              </>
            )}
            {isDragActive && <Styled.DropzoneCta>{t("drop")}</Styled.DropzoneCta>}
          </Styled.DropzoneHint>
        </Styled.Dropzone>
      )}
      {isLoading && (
        <Styled.Dropzone>
          <Styled.Spinner ariaLabel={t("loading")} />
        </Styled.Dropzone>
      )}
      {file.status !== "empty" && !isLoading && (
        <Styled.Dropzone hasFile {...getRootProps()}>
          <input {...getInputProps()} />
          <Styled.DropzoneHint isDragActive={isDragActive}>
            {!isDragActive && (
              <>
                <Styled.DropzoneUploadIcon>
                  <File />
                </Styled.DropzoneUploadIcon>
                <Styled.DropzoneCta>{file.originalName}</Styled.DropzoneCta>
                {file.validation.maxSize && <Styled.DropzoneMaxSize>{file.totalSize}</Styled.DropzoneMaxSize>}
              </>
            )}
            {isDragActive && <Styled.DropzoneCta>{t("drop")}</Styled.DropzoneCta>}
          </Styled.DropzoneHint>
        </Styled.Dropzone>
      )}
      {isLoading && <Styled.LoadingMessage>{t("loading")}</Styled.LoadingMessage>}
      {error && <Styled.Error>{error}</Styled.Error>}
      {hasError && <Styled.Error>{te("basicErrorMessage.general")}</Styled.Error>}
    </Styled.Wrapper>
  );
}
