import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { UserSlice } from "@/src/ui/view_models/user.slice";
import type { UserModel } from "@/src/core/user/domain/models/user_model";

const initialState = (): UserSlice => ({});

const userSlice = createSlice({
  name: "user.slice",
  initialState: initialState(),
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    resetUser: initialState
  }
});

function selectUserBase(state: RootState) {
  return state.user;
}

export const selectUser = createSelector(selectUserBase, (slice) => slice.user);
export const selectHasUser = createSelector(selectUser, (user) => Boolean(user));
export const selectUserId = createSelector(selectUser, (user) => user?.id);
export const { setUser, resetUser } = userSlice.actions;
export default userSlice.reducer;
