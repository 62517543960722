import type { ArcadeTypeModel } from "@/src/core/teeth/domain/models/arcade_type_model";
import { useField } from "formik";
import TeethSelectField from "../teeth_select_field/teeth_select_field";

interface Props {
  name: string;
  label: string;
  paragraph: string;
  arcade?: ArcadeTypeModel | null;
}
export default function TeethSelectFieldFormik(props: Props) {
  const [field, , helpers] = useField(props.name);

  return <TeethSelectField value={field.value} onChange={helpers.setValue} {...props} />;
}
