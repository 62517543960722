import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import type { UploadCaseFileModel } from "@/src/core/cases/domain/models/upload_case_file_model";
import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import {
  selectCaseFileDocumentation,
  selectCaseFileDocumentationHasError,
  selectCaseFileDocumentationIsLoading,
  uploadCaseFileDocumentationThunk
} from "../state/case_file_documentation";
import { selectCaseDetailReference } from "../../case_detail/state/case_detail.slice";

export function useCaseFileDocumentationController(reference: string, caseFile: CaseFileModel) {
  const { key } = caseFile;
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => selectCaseFileDocumentationIsLoading(state));
  const hasError = useAppSelector((state) => selectCaseFileDocumentationHasError(state));
  const file = useAppSelector((state) => selectCaseFileDocumentation(state));
  const caseId = useAppSelector((state) => selectCaseDetailReference(state));

  const uploadFile = async (data: Omit<UploadCaseFileModel, "reference" | "key">) => {
    await dispatch(uploadCaseFileDocumentationThunk({ ...data, reference: caseId || "", key }));
  };

  return { isLoading, hasError, uploadFile, file };
}
