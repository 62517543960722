import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { truncateText } from "@/src/ui/styles/utils";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { colors } from "@/src/ui/styles/colors";
import { px2rem } from "@/src/ui/styles/utils";

interface RadioProps {
  checked?: boolean;
}

const Label = styled.label`
  min-width: 0;
  flex: 1 1 100%;
  display: flex;
  cursor: pointer;
`;
const LabelText = styled.div`
  ${typography.medium}
  ${truncateText}
  min-width: 0;
  line-height: ${px2rem(19)};
`;

const Input = styled.input`
  height: ${px2rem(1)};
  width: ${px2rem(1)};
  opacity: 0;
  position: absolute;
`;
const Radio = styled.div<RadioProps>`
  flex: 0 0 ${px2rem(16)};
  background-color: ${(props) => (props.checked ? colors.brand500 : colors.white)};
  border: ${(props) => (props.checked ? "unset" : `1px solid ${colors.neutral400}`)};
  height: ${px2rem(16)};
  width: ${px2rem(16)};
  border-radius: ${px2rem(16)};
  margin-right: ${px2rem(7)};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    height: ${px2rem(6)};
    width: ${px2rem(6)};
    background-color: ${colors.white};
    ${borderRadius.m}
  }
`;
export default { Label, LabelText, Input, Radio };
