import type { PathologySolutionOptionModel } from "@/src/core/pathology_solutions/domain/models/pathology_solution_option_model";
import type { PathologySolutionValueModel } from "@/src/core/pathology_solutions/domain/models/pathology_solution_value_model";
import { sortPathologySolutionValue } from "@/src/core/pathology_solutions/domain/models/pathology_solution_value_model";
import { setPathologySolutionValueOption } from "@/src/core/pathology_solutions/domain/models/pathology_solution_value_model";
import { setPathologySolutionValueComments } from "@/src/core/pathology_solutions/domain/models/pathology_solution_value_model";
import {
  containsPathologySolutionValue,
  createPathologySolutionValue,
  removePathologySolutionValue
} from "@/src/core/pathology_solutions/domain/models/pathology_solution_value_model";
import { Bullet } from "@/src/ui/assets/icons";
import Checkbox from "@/src/ui/components/fields/checkbox/checkbox";
import SelectField from "@/src/ui/components/fields/select_field/select_field";
import { Textarea } from "@/src/ui/components/fields/textarea/textarea";
import { useTranslation } from "react-i18next";
import { objectToOptions } from "../../presenters/object_to_options";
import Styled from "./pathology_solution_field.styled";

export interface PathologySolutionFieldProps {
  name: string;
  label: string;
  helpText?: string;
  value: PathologySolutionValueModel[];
  onChange(value: PathologySolutionValueModel[]): void;
  options: PathologySolutionOptionModel[];
}
export function PathologySolutionField({ value = [], name, onChange, options, label, helpText }: PathologySolutionFieldProps) {
  const { t } = useTranslation("pathology_solution");
  return (
    <Styled.Container>
      <Styled.Title>{label}</Styled.Title>
      {helpText && <Styled.HelpText>{helpText}</Styled.HelpText>}
      <Styled.Checkboxes>
        {options.map((option) => {
          const { name: key, label } = option;
          const contains = containsPathologySolutionValue(value, key);
          return (
            <Checkbox
              key={key}
              name={name + key}
              label={label}
              value={contains}
              onChange={() =>
                onChange(
                  contains
                    ? removePathologySolutionValue(value, key)
                    : value.concat(createPathologySolutionValue(option)).sort(sortPathologySolutionValue)
                )
              }
            />
          );
        })}
      </Styled.Checkboxes>
      {!value.length && <Styled.NoResults>{t("noResults")}</Styled.NoResults>}
      {value.map((section) => {
        const option = options.find((o) => o.name === section.key);
        if (!option) return null;
        return (
          <Styled.Section key={section.key}>
            <Styled.TitleBox>
              <Styled.TitleIcon>
                <Bullet />
              </Styled.TitleIcon>
              <Styled.SectionTitle>{option.label}</Styled.SectionTitle>
            </Styled.TitleBox>
            <Styled.SectionFieldsBox>
              <SelectField
                label={t("solution")}
                name={name + section.key + "option"}
                onChange={(v) => {
                  if (v !== "other" && section.comments) {
                    value = setPathologySolutionValueComments(value, section.key, "");
                  }
                  onChange(setPathologySolutionValueOption(value, section.key, v as string));
                }}
                value={section.option}
                options={objectToOptions(option.options)}
              />
              {section.option === "other" && (
                <>
                  <Styled.Spacer10 />
                  <Textarea
                    placeholder={t("comments")}
                    name={name + section.key + "comments"}
                    onChange={(v) => onChange(setPathologySolutionValueComments(value, section.key, v))}
                    value={section.comments}
                  />
                </>
              )}
            </Styled.SectionFieldsBox>
          </Styled.Section>
        );
      })}
    </Styled.Container>
  );
}
