import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { colors } from "@/src/ui/styles/colors";

const InfoText = styled.p`
  ${typography.xxSmall}
  color: ${colors.neutral500};
`;
const ErrorText = styled.p`
  ${typography.xxSmall}
  color: red;
`;
const Retry = styled.button`
  all: unset;
  :hover,
  :focus {
    text-decoration: underline;
  }
  ${typography.xxSmall}
  display: inline;
  color: red;
  cursor: pointer;
`;

export default { InfoText, ErrorText, Retry };
