import type { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { selectHasProfile } from "@/src/ui/state/profile.slice";
import { FullscreenBasicSpinner } from "@/src/ui/components/loaders/basic_spinner/fullscreen_basic_spinner";
import { useAppSelector } from "../../state";
import { selectIsClient } from "../../pages/roles/state/roles.selectors";

export default function ProfileIsNotClientMiddleware({ children }: PropsWithChildren) {
  const hasProfile = useAppSelector(selectHasProfile);
  const isClient = useAppSelector(selectIsClient);

  if (!hasProfile) return <FullscreenBasicSpinner ariaLabel="Logging in…" />;

  return isClient ? <Navigate to="/role-error" replace /> : <>{children}</>;
}