import { css } from "styled-components";
import { colors } from "./colors";

export const px2rem = (target: string | number): string => {
  typeof target === "string" && (target = target.replace("px", ""));
  const fontSizeBase = 16;
  const remSize = Number(target) / fontSizeBase;
  return `${remSize}rem`;
};

export const resetTable = css`
  display: block;
  > thead,
  > tbody,
  > tfoot {
    display: block;
    > tr {
      display: grid;
      > th {
        font-weight: normal;
        text-align: left;
      }
      > th,
      td {
        min-width: 0;
      }
    }
  }
`;

export const truncateText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const resetButton = css`
  all: unset;
  box-sizing: border-box;
  cursor: ${({ disabled }: { disabled?: boolean }) => (disabled ? "normal" : "pointer")};
  display: block;
  &:focus-visible {
    outline: ${px2rem(3)} solid ${withOpacity(colors.brand300, 80)};
  }
`;

/**
 * Adds opacity to color
 * @param opacity Number from 0 to 100, where 0 is transparent and 100 is opaque.
 */
export function withOpacity(hexColor: string, opacity: number) {
  return hexColor + Math.ceil((opacity * 256) / 100).toString(16);
}
