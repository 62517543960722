import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { nameValueToOption } from "@/src/core/options/data/transformers/name_value_to_option";
import type { GetDentistSpecialitiesUseCase } from "@/src/core/dentist_specialities/domain/use_cases/get_dentist_specialities_use_case";
import type { DentistSpecialitiesSlice } from "../view_models/dentist_specialities.slice";

const initialState = (): DentistSpecialitiesSlice => ({
  results: [],
  hasError: false,
  isLoading: false
});

export const getDentistSpecialitiesThunk = createAsyncThunk("dentistSpecialities.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetDentistSpecialitiesUseCase>>(TYPES.GetDentistSpecialitiesUseCase)();
  return await useCase.execute();
});

const dentistSpecialitiesSlice = createSlice({
  name: "dentistSpecialities.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getDentistSpecialitiesThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getDentistSpecialitiesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getDentistSpecialitiesThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

function selectBase(state: RootState) {
  return state.dentistSpecialities;
}

export const selectDentistSpecialities = createSelector(selectBase, (slice) => slice.results);
export const selectDentistSpecialitiesOptions = createSelector(selectDentistSpecialities, (dentistSpecialities) =>
  dentistSpecialities.map(nameValueToOption)
);
export const selectDentistSpecialitiesError = createSelector(selectBase, (slice) => slice.hasError);
export const selectDentistSpecialitiesIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export default dentistSpecialitiesSlice.reducer;
