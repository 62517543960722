import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { CaseNotesSlice } from "../view_models/case_notes.slice";
import type { GetCaseNotesUseCase } from "@/src/core/cases/case_notes/domain/use_cases/get_case_notes_use_case";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): CaseNotesSlice => ({
  results: [],
  loadingState: "loaded"
});

export const getCaseNotesThunk = createAsyncThunk("caseNotes.slice/get", async (caseReference: string, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetCaseNotesUseCase>>(TYPES.GetCaseNotesUseCase)();
    return await useCase.execute(caseReference);
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

const caseNotesSlice = createSlice({
  name: "caseNotes.slice",
  initialState: initialState(),
  reducers: {
    resetCaseNotes: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCaseNotesThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCaseNotesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCaseNotesThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.caseNotes;
}

export const selectCaseNotes = createSelector(selectBase, (slice) => slice.results);
export const selectCaseNotesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const { resetCaseNotes } = caseNotesSlice.actions;
export default caseNotesSlice.reducer;
