import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CourseFiltersModel } from "@/src/core/courses/domain/models/course_filters_model";
import type { CoursePageFiltersSlice } from "@/src/ui/pages/courses/view_models/course_page_filters.slice";

const initialState = (): CoursePageFiltersSlice => ({
  show: false,
  filters: {
    name: "",
    city: "",
    "startsAt[before]": null,
    "startsAt[after]": null
  }
});

const coursesSlice = createSlice({
  name: "coursesPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetCoursesPageFilters: initialState,
    cleanCoursesPageFilters(state) {
      state.filters = initialState().filters;
    },
    setCoursesPageFilters(state, action: PayloadAction<Partial<CourseFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleCoursesPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectBase(state: RootState) {
  return state.coursesPageFilters;
}

export const selectShowCoursesPageFilters = createSelector(selectBase, (slice) => slice.show);
export const selectCoursesPageFilters = createSelector(selectBase, (slice) => slice.filters);
export const selectCoursesPageFiltersName = createSelector(selectCoursesPageFilters, (filters) => filters.name);

export const { resetCoursesPageFilters, cleanCoursesPageFilters, setCoursesPageFilters, toggleCoursesPageFilters } = coursesSlice.actions;
export default coursesSlice.reducer;
