import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { ResetSlice } from "../view_models/reset.slice";

const initialState = (): ResetSlice => ({key: 0});

const resetSlice = createSlice({
  name: "user.slice",
  initialState: initialState(),
  reducers: {
    resetApp: (state) => {
      state.key = state.key ? 0 : 1;
    },
  }
});

function selectBase(state: RootState) {
  return state.reset;
}

export const selectResetKey = createSelector(selectBase, (slice) => slice.key);
export const { resetApp } = resetSlice.actions;
export default resetSlice.reducer;
