import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCountriesUseCase } from "@/src/core/countries/domain/use_cases/get_countries_use_case";
import { getInitialPaginationState } from "@/src/ui/view_models/table_pagination";
import { countryDataToOption } from "@/src/core/options/data/transformers/country_data_to_option";
import type { CountriesSlice } from "../view-models/countries.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";

const initialState = (): CountriesSlice => getInitialPaginationSlice();

const itemsPerPage = 100;
export const getCountriesThunk = createAsyncThunk("countries.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetCountriesUseCase>>(TYPES.GetCountriesUseCase)();
  let pagination = await useCase.execute({ itemsPerPage });
  while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({ page: pagination.nextPage, itemsPerPage }));

  return pagination;
});

const countriesSlice = createSlice({
  name: "countries.slice",
  initialState: initialState(),
  reducers: {
    resetCountries: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCountriesThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCountriesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCountriesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
      state.paginationState = getInitialPaginationState();
    });
  }
});

function selectBase(state: RootState) {
  return state.countries;
}

export const selectCountries = createSelector(selectBase, (slice) => slice.pagination);
export const selectCountryOptions = createSelector(selectCountries, (pagination) => pagination?.results.map(countryDataToOption));
export const selectCountriesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const { resetCountries } = countriesSlice.actions;
export default countriesSlice.reducer;
