import CaseDetailFormLoader from "../../../case_detail/components/case_detail_form_loader/case_detail_form_loader";
import CaseDetailLoader from "../../../case_detail/components/case_detail_loader/case_detail_loader";
import EditCaseForm from "../edit_case_form/edit_case_form";

export default function EditCasePage() {
  return (
    <CaseDetailLoader>
      <CaseDetailFormLoader>
        <EditCaseForm />
      </CaseDetailFormLoader>
    </CaseDetailLoader>
  );
}
