import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { DistributorsSlice } from "@/src/ui/pages/distributors/view_models/distributors.slice";
import { distributorToOption } from "@/src/ui/pages/distributors/presenters/distributor_to_option";
import type { GetDistributorsUseCase } from "@/src/core/distributors/domain/use_cases/get_distributors_use_case";

const initialState = (): DistributorsSlice => ({
  results: [],
  hasError: false,
  isLoading: true
});

export const getDistributorsThunk = createAsyncThunk("distributors.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetDistributorsUseCase>>(TYPES.GetDistributorsUseCase)();
  return await useCase.execute();
});

const distributorsSlice = createSlice({
  name: "distributors.slice",
  initialState: initialState(),
  reducers: {
    resetDistributors: initialState
  },
  extraReducers(builder) {
    builder.addCase(getDistributorsThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getDistributorsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getDistributorsThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

function selectDistributorsBase(state: RootState) {
  return state.distributors;
}

export const selectDistributors = createSelector(selectDistributorsBase, (slice) => slice.results);
export const selectDistributorOptions = createSelector(selectDistributors, (distributors) => distributors.map(distributorToOption));
export const selectDistributorsError = createSelector(selectDistributorsBase, (slice) => slice.hasError);
export const selectDistributorsIsLoading = createSelector(selectDistributorsBase, (slice) => slice.isLoading);
export const { resetDistributors } = distributorsSlice.actions;
export default distributorsSlice.reducer;
