import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import { yup } from "@front_web_mrmilu/utils";
import type {
  CustomBooleanValidations,
  CustomDateValidations,
  CustomNumberValidations,
  CustomOptionsValidations,
  CustomOptionValidations,
  CustomStringValidations
} from "@/src/core/custom_forms/domain/models/custom_validations.model";
import { CustomError } from "@/src/core/app/domain/models/errors/custom.error";
import { t } from "i18next";
import { getCustomFieldDisplayEdit } from "@/src/core/custom_forms/domain/models/custom_base_field.model";

function getStringSchema(validation?: CustomStringValidations) {
  let schema = yup.string().typeError(t("general.required", { ns: "errors" }));
  if (!validation) return schema;
  const { required, max, min, email } = validation;
  if (required) schema = schema.required(t("general.required", { ns: "errors" }));
  if (max) schema = schema.max(max, t("general.maxLengthString", { max, ns: "errors" }));
  if (min) schema = schema.min(min, t("general.minLengthString", { min, ns: "errors" }));
  if (email) schema = schema.email(t("general.email", { ns: "errors" }));
  return schema;
}

function getNumberSchema(validation?: CustomNumberValidations) {
  let schema = yup.number().typeError(t("general.required", { ns: "errors" }));
  if (!validation) return schema;
  const { required, max, min } = validation;
  if (required) schema = schema.required(t("general.required", { ns: "errors" }));
  if (max) schema = schema.max(max, t("general.maxNumber", { max, ns: "errors" }));
  if (min) schema = schema.min(min, t("general.minNumber", { min, ns: "errors" }));
  return schema;
}

function getOptionSchema(validation?: CustomOptionValidations) {
  const schema = yup.string().typeError(t("general.required", { ns: "errors" }));
  if (!validation?.required) return schema.nullable();
  return schema.required(t("general.required", { ns: "errors" }));
}

function getOptionsSchema(validation?: CustomOptionsValidations) {
  let schema = yup.array().typeError(t("general.required", { ns: "errors" }));
  if (!validation) return schema;
  const { max, min } = validation;
  if (max) schema = schema.max(max, t("general.maxNumber", { max, ns: "errors" }));
  if (min) schema = schema.min(min, t("general.minNumber", { min, ns: "errors" }));
  return schema;
}

function getBooleanValidations(validation?: CustomBooleanValidations) {
  const schema = yup.boolean().typeError(t("general.required", { ns: "errors" }));
  return validation?.required ? schema.isTrue(t("general.required", { ns: "errors" })) : schema;
}

function getCheckboxUrlValidations() {
  return yup
    .boolean()
    .typeError(t("general.required", { ns: "errors" }))
    .isTrue(t("general.required", { ns: "errors" }));
}

function getDateValidations(validation?: CustomDateValidations) {
  const schema = yup.date().typeError(t("general.required", { ns: "errors" }));
  if (!validation?.required) return schema.nullable();
  return schema.required(t("general.required", { ns: "errors" }));
}

function getCustomFieldSchemaByType(field: CustomFieldModel) {
  switch (field.type) {
    case "text":
    case "textarea":
      return getStringSchema(field.validation);
    case "number":
      return getNumberSchema(field.validation);
    case "select":
    case "radio":
      return getOptionSchema(field.validation);
    case "selectMultiple":
    case "checkboxes":
    case "teeth":
      return getOptionsSchema(field.validation);
    case "checkbox":
      return getBooleanValidations(field.validation);
    case "checkbox-url":
      return getCheckboxUrlValidations();
    case "date":
      return getDateValidations(field.validation);
  }
  return null;
}

function getCustomFieldSchema(field: CustomFieldModel) {
  const schema = getCustomFieldSchemaByType(field);
  const display = getCustomFieldDisplayEdit(field);
  if (display?.field) {
    return (schema as yup.AnySchema)?.when(display.field, {
      is: (value: unknown) => display.values?.includes(value),
      then: (schema) => schema,
      otherwise: (schema) => schema.nullable().notRequired()
    });
  }
  return schema;
}

export function getCustomFormSchema(fields: CustomFieldModel[]) {
  if (!Array.isArray(fields)) throw new CustomError(`Expected "fields" to be an array, but got ${typeof fields}`);

  return yup
    .object()
    .shape(
      fields.filter((f) => !["title", "files"].includes(f.type)).reduce((acc, field) => ({ ...acc, [field.name]: getCustomFieldSchema(field) }), {})
    );
}
