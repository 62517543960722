import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetManufacturingLotDetailModel } from "@/src/core/manufacturing_lots/domain/models/get_manufacturing_lot_detail.model";
import type { GetManufacturingLotDetailUseCase } from "@/src/core/manufacturing_lots/domain/use_cases/get_manufacturing_lot_detail_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { ManufacturingLotDetailSlice } from "../view_models/manufacturing_lot_detail.slice";
import { setAllManufacturingLotItemsUnselected } from "./manufacturing_lot_items.slice";

const initialState = (): ManufacturingLotDetailSlice => ({
  hasError: false,
  isLoading: false,
  detail: null
});

export const getManufacturingLotDetailThunk = createAsyncThunk(
  "manufacturingLotDetail.slice/get",
  async (input: GetManufacturingLotDetailModel, { dispatch }) => {
    const useCase = await locator.get<IocProvider<GetManufacturingLotDetailUseCase>>(TYPES.GetManufacturingLotDetailUseCase)();
    const promise = useCase.execute(input);
    promise.then(() => dispatch(setAllManufacturingLotItemsUnselected()));
    return promise;
  }
);

const manufacturingLotDetailSlice = createSlice({
  name: "manufacturingLotDetail.slice",
  initialState: initialState(),
  reducers: {
    resetManufacturingLotDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getManufacturingLotDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getManufacturingLotDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getManufacturingLotDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.manufacturingLotDetail;

export const selectManufacturingLotDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectManufacturingLotDetailId = createSelector(selectManufacturingLotDetail, (detail) => detail?.id);
export const selectManufacturingLotDetailError = createSelector(selectBase, (slice) => slice.hasError);
export const selectManufacturingLotDetailIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetManufacturingLotDetail } = manufacturingLotDetailSlice.actions;
export default manufacturingLotDetailSlice.reducer;
