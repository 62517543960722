import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { colors } from "@/src/ui/styles/colors";
import { px2rem } from "@/src/ui/styles/utils";

export interface InputSizeProps {
  inputsize?: "l" | "m";
  icon?: string;
}

export interface LabelTextProps {
  disabled?: boolean;
}

const Label = styled.label`
  display: block;
  width: 100%;
`;
const LabelText = styled.p<LabelTextProps>`
  ${typography.xSmall};
  margin-bottom: ${px2rem(4)};
  color: ${(p) => (p.disabled ? colors.neutral400 : colors.neutral900)};
`;
const ErrorText = styled.p`
  ${typography.xSmall};
  margin-top: ${px2rem(4)};
  color: ${colors.alert700};
`;

export const Input = styled.input<InputSizeProps>`
  ${typography.small};
  height: ${({ inputsize = "m" }) => px2rem(inputsize === "m" ? 38 : 40)};
  display: block;
  width: 100%;
  padding: ${({ inputsize = "m" }) => px2rem(inputsize === "m" ? 7 : 11)} ${px2rem(16)};
  border-radius: ${px2rem(4)} ${px2rem(4)} 0 0;
  background-color: ${colors.neutral200};
  border-top-style: unset;
  border-left-style: unset;
  border-right-style: unset;
  border-bottom: solid 1px ${colors.neutral700};
  box-shadow: 0 1px 0 ${colors.neutral700};
  position: relative;
  ::placeholder {
    color: ${colors.neutral500};
  }
  :focus {
    outline: none;
    border-top-style: unset;
    border-left-style: unset;
    border-right-style: unset;
    border-bottom: solid 1px ${colors.brand500};
    box-shadow: 0 1px 0 ${colors.brand500};
  }
  :disabled {
    pointer-events: none;
    color: ${colors.neutral400};
    background-color: ${colors.neutral200};
    border-bottom: solid 1px ${colors.neutral400};
    box-shadow: 0 1px 0 ${colors.neutral400};
  }
`;

export default { LabelText, ErrorText, Label, Input };
