import { useField } from "formik";
import type { TextareaProps } from "@/src/ui/components/fields/textarea/textarea";
import { Textarea } from "@/src/ui/components/fields/textarea/textarea";

type InputFormikProps = Omit<TextareaProps, "onChange" | "value">;

export default function TextareaFormik({ label, name, placeholder, className, height, autoComplete }: InputFormikProps) {
  const [field, meta, helpers] = useField({ name });
  return (
    <Textarea
      label={label}
      placeholder={placeholder}
      className={className}
      name={name}
      height={height}
      value={field.value}
      onChange={helpers.setValue}
      error={meta.touched ? meta.error : undefined}
      autoComplete={autoComplete}
    />
  );
}
