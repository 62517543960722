import type { RootState } from "./index";
import type { SessionSlice } from "@/src/ui/view_models/session.slice";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { LogoutUseCase } from "@/src/core/user/domain/use_cases/logout_use_case";
import type { RefreshSessionUseCase } from "@/src/core/user/domain/use_cases/refresh_session_use_case";
import { setUser } from "@/src/ui/state/user.slice";
import { redirectSessionThunk } from "@/src/ui/state/redirect_session.slice";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";
import type { GetMyUserUseCase } from "@/src/core/user/domain/use_cases/get_my_user_use_case";
import { isTokenExpired } from "@/src/common/utils/jwt";
import { AxiosError } from "axios";
import { setProfile } from "@/src/ui/state/profile.slice";
import { timeout } from "@front_web_mrmilu/utils";
import { getNotificationsThunk } from "@/src/ui/pages/notifications/state/notifications.slice";
import { handleExpiredSessionThunk, removeSessionTokenThunk } from "./handle_expired_session.thunk";

const initialState = (): SessionSlice => ({});

// Check that session has not expired, get user and redirect / refresh session if necessary
export const checkSessionThunk = createAsyncThunk("session.slice/checkSession", async (_, { dispatch, getState }) => {
  const { session } = getState() as RootState;
  if (!session.token) return;

  try {
    if (isTokenExpired(session.token, new Date())) throw new Error("Session token expired");
  } catch (e) {
    console.error(e);
    dispatch(handleExpiredSessionThunk());
    return;
  }

  // Retry on server timeout error
  let retries = 20;
  while (true) {
    try {
      const useCase = await locator.get<IocProvider<GetMyUserUseCase>>(TYPES.GetMyUserUseCase)();
      const { user, profile } = await useCase.execute();
      dispatch(redirectSessionThunk());
      dispatch(setUser(user));
      dispatch(setProfile(profile));
      dispatch(getNotificationsThunk());
      dispatch(refreshSessionThunk());
      return;
    } catch (e) {
      console.error(e);
      // If JWT token is invalid or expired, backend will return HTTP 401 code
      if (!retries || (e instanceof AxiosError && [401, 500].includes(e.response?.status as number))) {
        dispatch(showErrorAlertThunk());
        dispatch(removeSessionTokenThunk());
        return;
      }
      await timeout(1000);
      retries--;
    }
  }
});

// Checks that session token expires in less than 3 days. If so, refreshes token.
export const refreshSessionThunk = createAsyncThunk("session.slice/refreshSession", async (_, { getState }) => {
  const { session } = getState() as RootState;
  if (!session.token) return;

  try {
    if (!isTokenExpired(session.token, new Date(Date.now() + 3 * 24 * 60 * 60 * 1000))) return;
    const useCase = await locator.get<IocProvider<RefreshSessionUseCase>>(TYPES.RefreshSessionUseCase)();
    return await useCase.execute();
  } catch (e) {
    console.error(e);
  }
});

export const logoutThunk = createAsyncThunk("session.slice/logout", async () => {
  const useCase = await locator.get<IocProvider<LogoutUseCase>>(TYPES.LogoutUseCase)();
  await useCase.execute();
});

const sessionSlice = createSlice({
  name: "session.slice",
  initialState: initialState(),
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(refreshSessionThunk.fulfilled, (state, action) => {
      state.token = action.payload?.token ?? state.token;
    });
    builder.addCase(removeSessionTokenThunk.fulfilled, (state) => {
      state.token = undefined;
    });
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.token = undefined;
    });
    builder.addCase(logoutThunk.rejected, (_, action) => {
      console.error(action.error);
    });
  }
});

function selectSessionBase(state: RootState) {
  return state.session;
}

export const selectIsLogged = createSelector(selectSessionBase, (slice) => Boolean(slice.token));
export const { login } = sessionSlice.actions;
export default sessionSlice.reducer;
