import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";

export function customFieldValueToFormValue(value: unknown, field?: CustomFieldModel) {
  if (field?.type === "teeth") {
    if (!value) return [];
    if (Array.isArray(value)) return value;
    const parsed = JSON.parse(value as string);
    return Array.isArray(parsed) ? parsed : [];
  }

  if (field?.type === "date") return value && new Date(value as string);
  return value;
}

const initialValueMap: Record<CustomFieldModel["type"], unknown> = {
  "checkbox-url": false,
  checkbox: false,
  checkboxes: [],
  date: null,
  files: null,
  file: null,
  "files-stl": null,
  number: null,
  radio: null,
  select: null,
  selectMultiple: [],
  teeth: [],
  text: "",
  textarea: "",
  title: null,
  pathologySolution: []
};

export function getCustomFieldInitialValue(field?: CustomFieldModel) {
  if (!field) return null;
  if (field.value) return customFieldValueToFormValue(field.value, field);
  if (field.default) return customFieldValueToFormValue(field.default, field);
  return initialValueMap[field.type] ?? null;
}
