import { checkSessionThunk, selectIsLogged } from "@/src/ui/state/session.slice";
import { useLocation, Navigate } from "react-router-dom";
import type { PropsWithChildren } from "react";
import { useAppDispatch, useAppSelector } from "../../state";
import { selectUser } from "../../state/user.slice";
import { useEffectStrictMode } from "@front_web_mrmilu/hooks";
import { FullscreenBasicSpinner } from "../../components/loaders/basic_spinner/fullscreen_basic_spinner";
import { useTranslation } from "react-i18next";

export function LoggedInMiddleware({ children }: PropsWithChildren) {
  const { t } = useTranslation("components", { keyPrefix: "loggedInMiddleware" });

  const location = useLocation();
  const dispatch = useAppDispatch();

  const isLogged = useAppSelector(selectIsLogged);
  const user = useAppSelector(selectUser);

  useEffectStrictMode(() => {
    if (!user) {
      dispatch(checkSessionThunk());
    }
  });

  if (!isLogged) {
    return <Navigate to={"/auth/login" + location.search} replace />;
  }
  if (!user) {
    return <FullscreenBasicSpinner ariaLabel={t("loading")} />;
  }
  return <>{children}</>;
}

export function LoggedOutMiddleware({ children }: PropsWithChildren) {
  const location = useLocation();
  const isLogged = useAppSelector(selectIsLogged);
  if (isLogged) {
    return <Navigate to={"/" + location.search} replace />;
  }
  return <>{children}</>;
}
