import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetClinicsUseCase } from "@/src/core/clinics/domain/use_cases/get_clinics_use_case";
import type { NewProfileClinicGroupSearchSlice } from "../view_models/new_profile_clinic_group_search.slice";

const initialState = (): NewProfileClinicGroupSearchSlice => ({
  vatNumber: "",
  isLoading: false,
  hasError: false,
  showForm: false
});

export const newProfileClinicGroupSearchThunk = createAsyncThunk("newProfileClinicGroupSearch.slice/search", async (_, { getState }) => {
  const useCase = await locator.get<IocProvider<GetClinicsUseCase>>(TYPES.GetClinicsUseCase)();
  return await useCase.execute({ type: "group", search: selectNewProfileClinicGroupSearchVatNumber(getState() as RootState) });
});

const newProfileClinicGroupSearchSlice = createSlice({
  name: "newProfileClinicGroupSearch.slice",
  initialState: initialState(),
  reducers: {
    setNewProfileClinicGroupSearchVatNumber(state, action: PayloadAction<string>) {
      state.vatNumber = action.payload;
    },
    setNewProfileClinicGroupSearchSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
    setNewProfileClinicGroupSearchShowForm(state) {
      state.showForm = true;
    },
    resetNewProfileClinicGroupSearch: initialState
  },
  extraReducers(builder) {
    builder.addCase(newProfileClinicGroupSearchThunk.pending, (state) => {
      state.selected = undefined;
      state.isLoading = true;
      state.hasError = false;
      state.showForm = false;
    });
    builder.addCase(newProfileClinicGroupSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.results = undefined;
      state.hasError = true;
    });
    builder.addCase(newProfileClinicGroupSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.results = action.payload.results;
      if (!action.payload.results.length) state.showForm = true;
    });
  }
});

const selectBase = (state: RootState) => state.newProfileClinicGroupSearch;

export const selectNewProfileClinicGroupSearchResults = createSelector(selectBase, (e) => e.results);
export const selectNewProfileClinicGroupSearchHasNoResults = createSelector(
  selectNewProfileClinicGroupSearchResults,
  (results) => results && !results.length
);
export const selectNewProfileClinicGroupSearchIsLoading = createSelector(selectBase, (e) => e.isLoading);
export const selectNewProfileClinicGroupSearchHasError = createSelector(selectBase, (e) => e.hasError);
export const selectNewProfileClinicGroupSearchVatNumber = createSelector(selectBase, (e) => e.vatNumber);
export const selectNewProfileClinicGroupSearchSelected = createSelector(selectBase, (e) => e.selected);
export const selectNewProfileClinicGroupSearchShowForm = createSelector(selectBase, (e) => e.showForm);

export const {
  resetNewProfileClinicGroupSearch,
  setNewProfileClinicGroupSearchVatNumber,
  setNewProfileClinicGroupSearchSelected,
  setNewProfileClinicGroupSearchShowForm
} = newProfileClinicGroupSearchSlice.actions;

export default newProfileClinicGroupSearchSlice.reducer;
