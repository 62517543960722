import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { spacing } from "@/src/ui/styles/spacing";
import { colors } from "@/src/ui/styles/colors";

const FormTitle = styled.h3`
  ${typography.small}
  text-transform: uppercase;
  color: ${colors.neutral500};
  margin-bottom: ${spacing.xs};
`;

export default { FormTitle };
