import type { PropsWithChildren } from "react";
import { FullscreenBasicSpinner } from "@/src/ui/components/loaders/basic_spinner/fullscreen_basic_spinner";
import { Suspense } from "react";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";

interface Props extends PropsWithChildren {
  ariaLabel?: string;
  className?: string;
}

export const FullscreenSuspenseLoader = ({ children, className, ariaLabel = "Loading" }: Props) => {
  return <Suspense fallback={<FullscreenBasicSpinner ariaLabel={ariaLabel} className={className} />}>{children}</Suspense>;
};
export const SuspenseLoader = ({ children, className, ariaLabel = "Loading" }: Props) => {
  return <Suspense fallback={<BasicSpinner ariaLabel={ariaLabel} className={className} />}>{children}</Suspense>;
};
