import type { IFileService } from "@/src/common/interfaces/file_service";

export class FileService implements IFileService {
  async size(url: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open("HEAD", url);
      http.onload = function (event) {
        resolve(event.total);
      };
      http.onerror = function () {
        reject(new Error("Error getting file size"));
      };
      http.send();
    });
  }
}
