import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { CaseActivityLogsSlice } from "../view_models/case_activity_logs.slice";
import type { GetActivityLogsByCaseUseCase } from "@/src/core/cases/activities/domain/use_cases/get_activity_logs_by_case_use_case";

const initialState = (): CaseActivityLogsSlice => ({
  results: [],
  hasError: false,
  isLoading: false
});

export const getCaseActivityLogsThunk = createAsyncThunk("caseActivityLogs.slice/get", async (reference: string) => {
  const useCase = await locator.get<IocProvider<GetActivityLogsByCaseUseCase>>(TYPES.GetActivityLogsByCaseUseCase)();
  return await useCase.execute(reference);
});

const caseActivityLogsSlice = createSlice({
  name: "caseActivityLogs.slice",
  initialState: initialState(),
  reducers: {
    resetCaseActivityLogs: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCaseActivityLogsThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCaseActivityLogsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCaseActivityLogsThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.caseActivityLogs;

export const selectCaseActivityLogs = createSelector(selectBase, (slice) => slice.results);
export const selectCaseActivityLogsHasResults = createSelector(selectCaseActivityLogs, (results) => Boolean(results.length));
export const selectCaseActivityLogsError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCaseActivityLogsIsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const { resetCaseActivityLogs } = caseActivityLogsSlice.actions;
export default caseActivityLogsSlice.reducer;
