import { configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import language from "./language.slice";
import modal from "./modal.slice";
import alerts from "./alerts.slice";
import session from "./session.slice";
import user from "./user.slice";
import profile from "./profile.slice";
import caseStats from "@/src/ui/pages/cases/state/case_stats.slice";
import homePageCases from "@/src/ui/pages/cases/state/home_page_cases.slice";
import casesPage from "@/src/ui/pages/cases/state/cases_page.slice";
import casesPageFilters from "@/src/ui/pages/cases/state/cases_page_filters.slice";
import caseFiles from "@/src/ui/pages/cases/case_files/state/case_files.slice";
import courses from "@/src/ui/pages/courses/state/courses.slice";
import options from "@/src/ui/pages/options/state/options.slice";
import clinicDetail from "@/src/ui/pages/clinics/clinic_detail/state/clinic_detail.slice";
import profileDetail from "@/src/ui/pages/profile/profile_detail/state/profile_detail.slice";
import myClinic from "@/src/ui/pages/clinics/my_clinic/state/my_clinic.slice";
import clinicsPageFilters from "@/src/ui/pages/clinics/state/clinics_page_filters.slice";
import clinicsPage from "@/src/ui/pages/clinics/state/clinics_page.slice";
import dentists from "@/src/ui/pages/dentists/state/dentists.slice";
import billingAddressDetail from "@/src/ui/pages/billing/billing_addresses/billing_address_detail/state/billing_address_detail.slice";
import newBillingAddress from "@/src/ui/pages/billing/billing_addresses/new_billing_address/state/new_billing_address.slice";
import billingAddressesPage from "@/src/ui/pages/billing/billing_addresses/state/billing_addresses_page.slice";
import billingAddressesPageFilters from "@/src/ui/pages/billing/billing_addresses/state/billing_addresses_page_filters.slice";
import redirectSession from "./redirect_session.slice";
import newUserDentistSearch from "@/src/ui/pages/profile/new_profile/new_profile_dentist/state/new_user_dentist_search.slice";
import newUserClinicSearch from "@/src/ui/pages/profile/new_profile/new_profile_clinic/state/new_profile_clinic_search.slice";
import newProfileClinicGroupSearch from "@/src/ui/pages/profile/new_profile/new_profile_clinic_group/state/new_profile_clinic_group_search.slice";
import newClinic from "@/src/ui/pages/clinics/new_clinic/state/new_clinic.slice";
import caseDetail from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import casesPageSort from "@/src/ui/pages/cases/state/cases_page_sort.slice";
import caseActivityLogs from "@/src/ui/pages/cases/case_detail/case_detail_activities/state/case_activity_logs.slice";
import myProfiles from "@/src/ui/pages/auth/switch_profile/state/my_profiles.slice";
import caseCustomForm from "@/src/ui/pages/cases/case_forms/state/case_forms.slice";
import addDentist from "@/src/ui/pages/dentists/add_dentist/state/add_dentist.slice";
import userDetail from "@/src/ui/pages/users/views/user_detail/state/user_detail.slice";
import myDentist from "@/src/ui/pages/dentists/my_dentist/state/my_dentist.slice";
import dentistDetail from "@/src/ui/pages/dentists/dentist_detail/state/dentist_detail.slice";
import caseNotes from "@/src/ui/pages/case_notes/state/case_notes.slice";
import caseNoteDetail from "@/src/ui/pages/case_notes/case_note_detail/state/case_note_detail.slice";
import teeth from "@/src/ui/pages/teeth/state/teeth.slice";
import treatments from "@/src/ui/pages/treatments/state/treatments.slice";
import notifications from "@/src/ui/pages/notifications/state/notifications.slice";
import dentistSpecialities from "@/src/ui/pages/dentists/specialities/state/dentist_specialities.slice";
import caseComments from "@/src/ui/pages/case_comments/state/case_comments.slice";
import manufacturingSelectedTreatment from "../pages/cases/case_detail/case_detail_manufacturing/state/manufacturing_selected_treatment.slice";
import manufacturingLots from "../pages/manufacturing_lots/state/manufacturing_lots.slice";
import manufacturingItems from "../pages/manufacturing_items/state/manufacturing_items.slice";
import caseInvoices from "@/src/ui/pages/cases/case_detail/case_detail_billing/state/case_invoices.slice";
import invoicesPage from "@/src/ui/pages/billing/invoices/state/invoices_page.slice";
import invoicesPageFilters from "@/src/ui/pages/billing/invoices/state/invoices_page_filters.slice";
import invoiceDetail from "@/src/ui/pages/billing/invoices/invoice_detail/state/invoice_detail.slice";
import correctedInvoiceDetail from "@/src/ui/pages/billing/invoices/invoice_detail/state/corrected_invoice_detail.slice";
import manufacturingLotDetail from "../pages/manufacturing_lots/manufacturing_lot_detail/state/manufacturing_lot_detail.slice";
import caseFileDocumentation from "@/src/ui/pages/cases/case_files/state/case_file_documentation";
import dentistBillingAddresses from "../pages/dentists/dentist_detail/state/dentist_billing_addresses.slice";
import promoters from "../pages/promoters/state/promoters.slice";
import distributors from "../pages/distributors/state/distributors.slice";
import dentistsPageFilters from "../pages/dentists/state/dentists_page_filters.slice";
import manufacturingLotTransition from "../pages/manufacturing_lots/state/manufacturing_lot_transition.slice";
import manufacturingLotItems from "../pages/manufacturing_lots/manufacturing_lot_detail/state/manufacturing_lot_items.slice";
import caseViewer from "@/src/ui/pages/cases/case_viewer/state/case_viewer.slice";
import usersPageFilters from "../pages/users/views/users_page/state/users_page_filters.slice";
import usersPage from "../pages/users/views/users_page/state/users_page.slice";
import clinicOpeningHours from "../pages/clinics/state/clinic_opening_hours.slice";
import courseTableSort from "@/src/ui/pages/courses/state/course_table_sort";
import coursesPageFilters from "@/src/ui/pages/courses/state/courses_page_filters.slice";
import treatmentsHistory from "../pages/treatments/state/treatments_history.slice";
import reset from "./reset.slice";
import courseDetail from "../pages/courses/course_detail/state/course_detail.slice";
import courseAttendees from "../pages/courses/course_detail/state/course_attendees.slice";
import courseAttendeesSort from "../pages/courses/course_detail/state/course_attendees_sort.slice";
import countries from "../pages/options/countries/state/countries.slice";
import roleGroups from "../pages/options/role_groups/state/role_groups.slice";
import provinces from "../pages/options/provinces/state/provinces.slice";
import cities from "../pages/options/cities/state/cities.slice";
import coursesPageSort from "../pages/courses/state/courses_page_sort.slice";
import usersPageSort from "../pages/users/views/users_page/state/users_page_sort.slice";
import dentistsPageSort from "../pages/dentists/state/dentists_page_sort.slice";
import invoicesPageSort from "@/src/ui/pages/billing/invoices/state/invoices_page_sort.slice";
import courseInvoice from "@/src/ui/pages/courses/views/course_invoice_create/state/course_invoice.slice";
import courseAttendeeDetail from "../pages/courses/course_detail/state/course_attendee_detail.slice";
import caseStage from "../pages/cases/case_detail/state/case_stage.slice";
import homePageCasesSort from "../pages/cases/state/home_page_cases_sort.slice";
import treatmentDetail from "../pages/treatments/treatment_detail/state/treatment_detail.slice";
import sentManufacturingItems from "@/src/ui/pages/manufacturing_items/state/sent_manufacturing_items.slice";
import clinicDetailDentists from "../pages/clinics/clinic_detail/state/clinic_detail_dentists.slice";
import clinicDetailBillingAddresses from "../pages/clinics/clinic_detail/state/clinic_detail_billing_addresses.slice";
import promoterDetail from "../pages/promoters/state/promoter_detail.slice";
import distributorDetail from "../pages/distributors/state/distributor_detail.slice";
import casesByStateStats from "../pages/cases/state/cases_by_state_stats.slice";

import { loadInitialState } from "@/src/ui/state/load_initial_state";
import { enableMapSet } from "immer";

enableMapSet();
export const store = configureStore({
  reducer: {
    alerts,
    homePageCases,
    casesPage,
    caseFiles,
    caseStats,
    courses,
    courseTableSort,
    coursesPageFilters,
    coursesPageSort,
    options,
    newClinic,
    myClinic,
    clinicDetail,
    clinicsPageFilters,
    clinicsPage,
    dentists,
    billingAddressDetail,
    newBillingAddress,
    billingAddressesPage,
    billingAddressesPageFilters,
    profile,
    redirectSession,
    session,
    user,
    usersPageFilters,
    usersPage,
    myProfiles,
    newUserDentistSearch,
    newUserClinicSearch,
    newProfileClinicGroupSearch,
    caseDetail,
    caseActivityLogs,
    caseCustomForm,
    caseComments,
    language,
    modal,
    addDentist,
    userDetail,
    myDentist,
    dentistDetail,
    casesPageFilters,
    caseNotes,
    caseNoteDetail,
    teeth,
    treatments,
    treatmentsHistory,
    notifications,
    dentistSpecialities,
    dentistsPageFilters,
    profileDetail,
    caseFileDocumentation,
    casesPageSort,
    manufacturingSelectedTreatment,
    manufacturingLots,
    manufacturingItems,
    sentManufacturingItems,
    caseInvoices,
    invoicesPage,
    invoicesPageFilters,
    invoicesPageSort,
    invoiceDetail,
    correctedInvoiceDetail,
    manufacturingLotDetail,
    promoters,
    promoterDetail,
    distributors,
    distributorDetail,
    dentistBillingAddresses,
    manufacturingLotTransition,
    manufacturingLotItems,
    caseViewer,
    reset,
    clinicOpeningHours,
    courseDetail,
    courseAttendees,
    courseAttendeesSort,
    countries,
    roleGroups,
    provinces,
    cities,
    usersPageSort,
    dentistsPageSort,
    courseInvoice,
    courseAttendeeDetail,
    caseStage,
    homePageCasesSort,
    treatmentDetail,
    clinicDetailDentists,
    clinicDetailBillingAddresses,
    casesByStateStats
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  preloadedState: loadInitialState()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
