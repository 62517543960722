import { createSelector } from "@reduxjs/toolkit";
import { groupCustomFormFields } from "@/src/ui/pages/custom_forms/presenters/group_custom_form_fields";
import { selectCaseDetail, selectCaseDetailFormFields, selectCaseDetailStep } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { getCustomFormSchema } from "@/src/ui/pages/custom_forms/presenters/get_custom_form_schema";
import { getCustomFieldStep } from "@/src/core/custom_forms/domain/models/custom_base_field.model";
import { keyValuesToForm } from "../../../custom_forms/presenters/key_values_to_form";

export const selectNewCaseCustomFormCurrentFields = createSelector(selectCaseDetailFormFields, selectCaseDetailStep, (fields, step) =>
  !step ? fields : fields.filter((f) => getCustomFieldStep(f) === step)
);
export const selectNewCaseCustomFormCurrentFieldNames = createSelector(selectNewCaseCustomFormCurrentFields, (fields) =>
  fields.map((field) => field.name)
);
export const selectNewCaseCustomFormSchema = createSelector(selectNewCaseCustomFormCurrentFields, getCustomFormSchema);
export const selectNewCaseCustomFormGroupedFields = createSelector(selectNewCaseCustomFormCurrentFields, groupCustomFormFields);
export const selectNewCaseFormValues = createSelector(selectCaseDetail, selectCaseDetailFormFields, (c, formFields) =>
  keyValuesToForm(c?.fields ?? [], formFields)
);
