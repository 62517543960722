import type { NewCaseSlice } from "@/src/ui/pages/cases/case_detail/view_models/case_detail.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { TYPES } from "@/src/core/app/ioc/types";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { locator } from "@/src/core/app/ioc";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import { formToKeyValues } from "@/src/ui/pages/custom_forms/presenters/key_values_to_form";
import type { UpdateProductCaseUseCase } from "@/src/core/cases/domain/use_cases/update_product_case_use_case";
import type { GetCaseDetailUseCase } from "@/src/core/cases/domain/use_cases/get_case_detail_use_case";
import type { CustomFormValues } from "@/src/ui/pages/custom_forms/view_models/custom_form_values";
import { createElement } from "react";
import { NullError } from "@/src/core/app/domain/models/errors/null.error";
import { selectCaseFormTypeMap } from "@/src/ui/pages/cases/case_forms/state/case_forms.slice";
import Translate from "@/src/ui/components/text/translate/translate";
import type { CancelProductCaseUseCase } from "@/src/core/cases/domain/use_cases/cancel_product_case_use_case";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { serializeError } from "@/src/common/utils/rtk";
import type { FinishCaseUseCase } from "@/src/core/cases/domain/use_cases/finish_case_use_case";
import { hideModalThunk } from "@/src/ui/state/modal.slice";
import { AxiosError } from "axios";
import { getCaseActivityLogsThunk } from "../case_detail_activities/state/case_activity_logs.slice";

const initialState = (): NewCaseSlice => ({
  loadingState: "loaded",
  saveAsDraft: false,
  publishCaseError: false
});

export const getCaseDetailThunk = createAsyncThunk(
  "caseDetail.slice/get",
  async (reference: string, { dispatch }) => {
    try {
      const useCase = await locator.get<IocProvider<GetCaseDetailUseCase>>(TYPES.GetCaseDetailUseCase)();
      return await useCase.execute(reference);
    } catch (e) {
      dispatch(showErrorAlertThunk());
      throw e;
    }
  },
  { serializeError }
);

export const publishCaseThunk = createAsyncThunk(
  "caseDetail.slice/publish",
  async (payload: CustomFormValues, { getState }) => {
    const state = getState() as RootState;
    const reference = selectCaseDetailReference(state);
    if (!reference) throw new NullError("publishNewCaseThunk", "reference");
    const isDraft = selectCaseDetailSaveAsDraft(state);
    const isLastStep = selectCaseDetailIsLastStep(state);

    const useCase = await locator.get<IocProvider<UpdateProductCaseUseCase>>(TYPES.UpdateProductCaseUseCase)();
    return await useCase.execute({
      reference,
      fields: formToKeyValues(payload),
      meta: isDraft ? undefined : { step: selectCaseDetailNextStep(state) },
      status: isLastStep && !isDraft ? "published" : undefined
    });
  },
  { serializeError }
);

export const cancelCaseThunk = createAsyncThunk("caseDetail.slice/cancel", async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const reference = selectCaseDetailReference(state);
  if (!reference) return;
  try {
    const useCase = await locator.get<IocProvider<CancelProductCaseUseCase>>(TYPES.CancelProductCaseUseCase)();
    await useCase.execute(reference);
    dispatch(showSuccessAlertThunk(createElement(Translate, { ns: "cases", i18nKey: "alerts.cancel.published" })));
    dispatch(getCaseDetailThunk(reference));
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk(createElement(Translate, { ns: "cases", i18nKey: "alerts.cancel.error" })));
  }
});

export const casePreviousStepThunk = createAsyncThunk("caseDetail.slice/previousStep", async (_, { getState }) => {
  return selectCaseDetailPreviousStep(getState() as RootState);
});

export const finishCaseThunk = createAsyncThunk("caseDetail.slice/finish", async (_, { getState, dispatch }) => {
  const reference = selectCaseDetailReference(getState() as RootState);
  if (!reference) return;
  try {
    const useCase = await locator.get<IocProvider<FinishCaseUseCase>>(TYPES.FinishCaseUseCase)();
    await useCase.execute(reference);

    await Promise.all([dispatch(getCaseDetailThunk(reference)), dispatch(getCaseActivityLogsThunk(reference))]);

    dispatch(hideModalThunk());
    dispatch(showSuccessAlertThunk());
  } catch (e) {
    console.error(e);
    if (e instanceof AxiosError) {
      dispatch(showErrorAlertThunk(e.response?.data?.detail));
    } else dispatch(showErrorAlertThunk());
  }
});

const newCaseSlice = createSlice({
  name: "caseDetail.slice",
  initialState: initialState(),
  reducers: {
    resetCaseDetail: initialState,
    setCaseDetailSaveAsDraft(state) {
      state.saveAsDraft = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(getCaseDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getCaseDetailThunk.rejected, (state, action) => {
      const { error } = action;
      console.error(error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCaseDetailThunk.fulfilled, (state, action) => {
      state.case = action.payload;
      state.step = action.payload.meta?.step;
      state.publishCaseError = false;
      state.loadingState = "loaded";
    });

    builder.addCase(publishCaseThunk.pending, (state) => {
      state.publishCaseError = false;
    });
    builder.addCase(publishCaseThunk.rejected, (state, action) => {
      console.error(action.error);
      state.publishCaseError = true;
      state.saveAsDraft = false;
    });
    builder.addCase(publishCaseThunk.fulfilled, (state, action) => {
      state.case = action.payload;
      state.step = action.payload.meta?.step;
      state.saveAsDraft = false;
    });

    builder.addCase(casePreviousStepThunk.fulfilled, (state, action) => {
      state.step = action.payload;
    });
  }
});

const selectCaseDetailBase = (state: RootState) => state.caseDetail;

export const selectCaseDetailSaveAsDraft = createSelector(selectCaseDetailBase, (slice) => slice.saveAsDraft);
export const selectCaseDetailLoadingState = createSelector(selectCaseDetailBase, (slice) => slice.loadingState);
export const selectCaseDetail = createSelector(selectCaseDetailBase, (slice) => slice.case);
export const selectCaseDetailHasBillingAddress = createSelector(selectCaseDetail, (c) => Boolean(c?.billingAddress));
export const selectCaseDetailIsUrgent = createSelector(selectCaseDetail, (c) => Boolean(c?.urgent));
export const selectCaseDetailReference = createSelector(selectCaseDetail, (c) => c?.reference);
export const selectCaseDetailType = createSelector(selectCaseDetail, (c) => c?.productType);
export const selectCaseDetailStatus = createSelector(selectCaseDetail, (c) => c?.status);
export const selectCaseDetailIsDraft = createSelector(selectCaseDetailStatus, (status) => status === "draft");
export const selectCaseDetailStatusIsDesigned = createSelector(selectCaseDetailStatus, (status) => status === "planning-designed");
export const selectCaseDetailStatusIsFinished = createSelector(selectCaseDetailStatus, (status) => status === "finished");
export const selectCaseDetailStatusIsPending = createSelector(selectCaseDetailStatus, (status) => status === "pending");
export const selectCaseDetailIsOwn = createSelector(selectCaseDetail, (detail) => Boolean(detail?.isOwn));
export const selectCaseDetailStageHasBeenManufacturing = createSelector(selectCaseDetail, (c) =>
  c?.stages.some((s) => s.identifier === "manufacturing")
);
export const selectCaseDetailPatientArchs = createSelector(
  selectCaseDetail,
  (detail) => detail?.fields.find((field) => field.key === "patient-archs")?.value
);

export const { resetCaseDetail, setCaseDetailSaveAsDraft } = newCaseSlice.actions;

export default newCaseSlice.reducer;

// Form selectors

export const selectCaseDetailForm = createSelector(selectCaseFormTypeMap, selectCaseDetailType, (form, caseType) => caseType && form?.[caseType]);

export const selectCaseDetailSteps = createSelector(selectCaseDetailForm, (form) => form?.formSteps ?? []);
export const selectCaseDetailFormFields = createSelector(selectCaseDetailForm, (form) => form?.fields ?? []);

export const selectCaseDetailStep = createSelector(
  selectCaseDetailBase,
  selectCaseDetailSteps,
  (slice, steps) => steps.find((step) => step.value === slice.step)?.value ?? steps[0]?.value
);
export const selectCaseDetailStepPosition = createSelector(selectCaseDetailStep, selectCaseDetailSteps, (step, steps) =>
  Math.max(steps?.findIndex((s) => s.value === step) ?? 0, 0)
);
export const selectCaseDetailIsFirstStep = createSelector(selectCaseDetailStepPosition, (position) => position === 0);
export const selectCaseDetailIsLastStep = createSelector(
  selectCaseDetailStepPosition,
  selectCaseDetailSteps,
  (position, steps) => position === steps.length - 1
);
export const selectCaseDetailPreviousStep = createSelector(
  selectCaseDetailStepPosition,
  selectCaseDetailSteps,
  (position, steps) => steps[Math.max(position - 1, 0)]?.value
);
export const selectCaseDetailNextStep = createSelector(
  selectCaseDetailStepPosition,
  selectCaseDetailSteps,
  (position, steps) => steps[Math.min(position + 1, steps.length - 1)]?.value
);

export const selectCaseDetailPublishError = createSelector(selectCaseDetailBase, (slice) => slice.publishCaseError);
