import Styled from "./select_field.styled";
import type { Option } from "@/src/core/options/domain/models/option";
import Select from "react-select";
import { useState } from "react";
import { emptyFunction } from "@/src/common/utils/empty_function";
import { ChevronDown } from "@/src/ui/assets/icons";
import DotsLoader from "@/src/ui/components/loaders/dots_loader/dots_loader";
import { useTranslation } from "react-i18next";
import ErrorText from "../../error_displayers/error_text/error_text";

export interface SelectFieldProps<T> {
  name: string;
  label?: string;
  error?: string;
  isClearable?: boolean;
  options?: Option<T>[];
  value: T | null;
  onChange(option: T | null): void;
  className?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onMenuScrollToBottom?(): void;
}

export default function SelectField<T>({
  name,
  options,
  value,
  label,
  onChange,
  error,
  isClearable,
  className,
  isDisabled = false,
  placeholder,
  onMenuScrollToBottom
}: SelectFieldProps<T>) {
  const { t } = useTranslation("components", { keyPrefix: "selectField" });
  const [text, setText] = useState("");
  const findOption = (v: T | null) => options?.find((o) => o.value === v);
  const foundOption = findOption(value);
  return (
    <Styled.Label htmlFor={name} className={className}>
      {label && <Styled.LabelText isDisabled={isDisabled}>{label}</Styled.LabelText>}
      <Styled.SelectInput isDisabled={isDisabled}>
        <Select
          key={`select__${foundOption?.value}`}
          classNamePrefix="Select"
          placeholder={placeholder ?? t("placeholder")}
          onChange={(option) => onChange(findOption((option?.value as T) ?? null)?.value ?? null)}
          onInputChange={setText}
          value={foundOption}
          inputValue={text}
          onMenuOpen={emptyFunction}
          onMenuClose={emptyFunction}
          options={options}
          isLoading={!options}
          isClearable={isClearable}
          isDisabled={isDisabled}
          onMenuScrollToBottom={onMenuScrollToBottom}
          noOptionsMessage={() => t("noOptions")}
          loadingMessage={() => t("loading")}
          components={{
            DropdownIndicator: () => <ChevronDown />,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => <DotsLoader />
          }}
        />
      </Styled.SelectInput>
      {error && (
        <Styled.ErrorText>
          <ErrorText error={error} />
        </Styled.ErrorText>
      )}
    </Styled.Label>
  );
}
