import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "../../../styles/utils";
import { colors } from "../../../styles/colors";
import { borderRadius } from "../../../styles/border_radius";

interface IsSelectedProps {
  isSelected: boolean;
}
const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Item = styled.li`
  flex: 0 0 ${px2rem(140)};
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Circle = styled.div<IsSelectedProps>`
  position: relative;
  height: ${px2rem(34)};
  width: ${px2rem(34)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${px2rem(6)};
  ${borderRadius.s}
  background-color: ${({ isSelected }) => colors[isSelected ? "brand500" : "neutral300"]};
  transition: background-color 1000ms ease-in-out;
`;
const Bar = styled.div<IsSelectedProps>`
  position: absolute;
  right: ${px2rem(-106)};
  width: ${px2rem(106)};
  height: ${px2rem(3)};
  background-color: ${({ isSelected }) => colors[isSelected ? "brand500" : "neutral300"]};
  transition: background-color 1000ms ease-in-out;
`;
const CircleNumber = styled.div<IsSelectedProps>`
  font-style: normal;
  font-weight: 400;
  ${typography.large}
  line-height: ${px2rem(30)};
  color: ${({ isSelected }) => colors[isSelected ? "white" : "neutral500"]};
  transition: color 1000ms ease-in-out;
`;
const Text = styled.p<IsSelectedProps>`
  ${typography.xxSmall}
  font-weight: bold;
  color: ${({ isSelected }) => colors[isSelected ? "brand500" : "neutral500"]};
`;

export default { Wrapper, Item, Circle, CircleNumber, Text, Bar };
