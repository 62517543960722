import type { IFieldKeyValueModel } from "@/src/core/custom_forms/domain/models/product_case_field_model";
import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import type { CustomFormValues } from "@/src/ui/pages/custom_forms/view_models/custom_form_values";
import { customFieldValueToFormValue, getCustomFieldInitialValue } from "./get_custom_field_initial_value";


export function keyValuesToForm(keyValues: IFieldKeyValueModel[], fields: CustomFieldModel[]): CustomFormValues {
  return keyValues.reduce((prev, { key, value }) => {
    const field = fields.find((f) => f.name === key);
    return { ...prev, [key]: customFieldValueToFormValue(value, field) ?? getCustomFieldInitialValue(field) };
  }, {});
}

export function formToKeyValues(values: CustomFormValues): IFieldKeyValueModel[] {
  return Object.entries(values).map(([key, value]) => ({ key, value }));
}
