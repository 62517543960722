import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { CaseInvoicesSlice } from "../view_models/case_invoices.slice";
import type { GetInvoicesByCaseUseCase } from "@/src/core/billing/invoices/domain/use_cases/get_invoices_by_case_use_case";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): CaseInvoicesSlice => ({
  ...getInitialPaginationSlice(),
  reference: null
});

interface IGetCaseInvoicesThunk {
  reference: string;
  page?: number;
}

export const getCaseInvoicesThunk = createAsyncThunk("caseInvoices.slice/get", async (input: IGetCaseInvoicesThunk, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetInvoicesByCaseUseCase>>(TYPES.GetInvoicesByCaseUseCase)();
    return await useCase.execute({ ...input, page: (input.page ?? 0) + 1, itemsPerPage: 10 });
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

const caseInvoicesSlice = createSlice({
  name: "caseInvoices.slice",
  initialState: initialState(),
  reducers: {
    resetCaseInvoices: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCaseInvoicesThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg.page ?? 0;
    });
    builder.addCase(getCaseInvoicesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getCaseInvoicesThunk.fulfilled, (state, action) => {
      state.reference = action.meta.arg.reference;
      state.loadingState = "loaded";
      state.pagination = action.payload;
    });
  }
});

function selectBase(state: RootState) {
  return state.caseInvoices;
}

export const selectCaseInvoices = createSelector(selectBase, (slice) => slice.pagination);
export const selectCaseHasInvoices = createSelector(selectCaseInvoices, (p) => p?.hasResults);
export const selectCaseInvoicesPaginationState = createSelector(selectBase, (slice) => slice.paginationState);
export const selectCaseInvoicesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectCaseInvoicesReference = createSelector(selectBase, (slice) => slice.reference);

export const { resetCaseInvoices } = caseInvoicesSlice.actions;
export default caseInvoicesSlice.reducer;
