import styled, { css } from "styled-components";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { typography } from "@/src/ui/styles/typography";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";
import { truncateText } from "@/src/ui/styles/utils";

interface DropzoneProps {
  hasFile?: boolean;
}
const Dropzone = styled.label<DropzoneProps>`
  flex: 0 0 ${px2rem(105)};
  display: block;
  height: ${px2rem(148)};
  width: ${px2rem(148)};
  position: relative;
  overflow: hidden;
  ${borderRadius.m}
  cursor: pointer;
  background-color: ${colors.neutral200};
  border: dashed 1px ${({ hasFile }) => (hasFile ? colors.brand500 : colors.neutral700)};
  &:focus {
    outline: unset;
  }
`;

interface DropzoneHintProps {
  isDragActive: boolean;
}
const DropzoneHint = styled.div<DropzoneHintProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${px2rem(148)};
  width: ${px2rem(148)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      z-index: 2;
      background-color: rgba(51, 65, 85, 0.6);
      > figure {
        color: ${colors.white};
        overflow: auto;
        text-overflow: unset;
        white-space: unset;
      }
    `}
`;
const DropzoneUploadIcon = styled.figure`
  height: ${px2rem(40)};
  width: ${px2rem(40)};
  > svg {
    height: ${px2rem(40)};
    width: ${px2rem(40)};
    * {
      color: ${colors.neutral700};
      stroke: ${colors.neutral700};
    }
  }
`;

const Wrapper = styled.div`
  display: block;
`;

const DropzoneCta = styled.figure`
  ${typography.xSmall}
  color: ${colors.neutral900};
  ${typography.xSmall}
  text-align: center;
  padding: 0 ${px2rem(16)};
  margin-bottom: ${px2rem(-4)};
  width: inherit;
  ${truncateText};
`;

const DropzoneMaxSize = styled.figure`
  ${typography.xxSmall}
  color: ${colors.neutral900};
`;

const Label = styled.p`
  ${typography.xxSmall}
  margin-bottom: ${px2rem(8)};
`;

const Spinner = styled(BasicSpinner)`
  height: 100%;
`;

const Error = styled.p`
  ${typography.small};
  margin-top: ${px2rem(4)};
  color: ${colors.alert700};
`;

const LoadingMessage = styled.p`
  ${typography.small};
  margin-top: ${px2rem(4)};
`;

export default { Dropzone, DropzoneHint, DropzoneUploadIcon, DropzoneCta, DropzoneMaxSize, Label, Spinner, Error, Wrapper, LoadingMessage };
