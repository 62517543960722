import styled, { css } from "styled-components";
import SafeImage from "@/src/ui/components/images/safe_img";
import { borderRadius } from "@/src/ui/styles/border_radius";
import { typography } from "@/src/ui/styles/typography";
import { px2rem, truncateText } from "@/src/ui/styles/utils";
import { spacing } from "@/src/ui/styles/spacing";
import { colors } from "@/src/ui/styles/colors";

const Image = styled(SafeImage)`
  width: ${px2rem(148)};
  height: ${px2rem(148)};
  object-fit: cover;
  ${borderRadius.m}
`;

const MainRow = styled.div`
  display: flex;
`;
const Title = styled.h4`
  ${typography.small}
  flex: 1 1 100%;
  min-width: 0;
  margin-top: ${px2rem(4)};
  ${truncateText}
`;
const IconButton = styled.button`
  all: unset;

  cursor: pointer;
  height: ${px2rem(26)};
  width: ${px2rem(26)};
  > svg {
    height: ${px2rem(26)};
    width: ${px2rem(26)};
    > * {
      color: ${colors.brand900};
    }
  }
`;

const MenuWrapper = styled.div`
  flex: 0 0 ${px2rem(26)};
  position: relative;
`;

const Menu = styled.section`
  position: absolute;
  left: 0;
  bottom: ${px2rem(26)};
  border: 1px solid ${colors.neutral400};
  background: ${colors.white};
  width: ${px2rem(127)};
  padding: ${spacing.xs};
  ${borderRadius.m}
`;

const menuLinkStyles = css`
  all: unset;
  :hover,
  :focus {
    outline: none;
    text-decoration: underline;
  }
  cursor: pointer;
  color: ${colors.brand900};
  display: block;
  :not(:last-child) {
    margin-bottom: ${px2rem(7)};
  }
  ${typography.xSmall}
  ${truncateText}
`;
const MenuButton = styled.button`
  ${menuLinkStyles}
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: ${px2rem(1)};
`;

export default { Image, MainRow, Title, IconButton, Menu, MenuButton, MenuWrapper, FileInput };
