import styled from "styled-components";

interface ColumnSpanProps {
  columnSpan?: number;
}

const ColumnSpan = styled.div<ColumnSpanProps>`
  flex: 1 1 100%;
  min-width: 0;
  flex-grow: ${({ columnSpan = 1 }) => columnSpan};
  display: flex;
`;
export default { ColumnSpan };
