import { selectProfile } from "@/src/ui/state/profile.slice";
import { createSelector } from "@reduxjs/toolkit";

export const selectRoleGroup = createSelector(selectProfile, (profile) => profile?.roleGroup);

export const selectIsNotClient = createSelector(selectRoleGroup, (roleGroup) => !roleGroup?.isClient);
export const selectIsClient = createSelector(selectRoleGroup, (roleGroup) => roleGroup?.isClient);

export const selectRoleGroupName = createSelector(selectRoleGroup, (r) => r?.name);
export const selectRoles = createSelector(selectRoleGroup, (roleGroup) => new Set(roleGroup?.roles));

// Employee role groups
export const selectIsDentist = createSelector(selectRoleGroupName, (name) => name === "DENTIST");
export const selectIsClinic = createSelector(selectRoleGroupName, (name) => name === "CLINIC");
export const selectIsClinicGroup = createSelector(selectRoleGroupName, (name) => name === "CLINIC_GROUP");

export const selectIsSuperadmin = createSelector(selectRoleGroupName, (name) => name === "SUPERADMIN");
