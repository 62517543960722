import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { getInitialCachedPaginationSlice } from "@/src/ui/view_models/slices";
import type { ProvincesSlice } from "../view_models/provinces.slice";
import type { GetProvincesUseCase } from "@/src/core/provinces/domain/use_cases/get_provinces_use_case";

const initialState = (): ProvincesSlice => getInitialCachedPaginationSlice();

export const getProvincesThunk = createAsyncThunk("provinces.slice/get", async (country: string) => {
  const useCase = await locator.get<IocProvider<GetProvincesUseCase>>(TYPES.GetProvincesUseCase)();
  const itemsPerPage = 200;
  let pagination = await useCase.execute({ country, page: 1, itemsPerPage });
  while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({ country, page: pagination.nextPage, itemsPerPage }));
  return pagination;
});

const provincesSlice = createSlice({
  name: "provinces.slice",
  initialState: initialState(),
  reducers: {
    resetProvinces: initialState
  },
  extraReducers(builder) {
    builder.addCase(getProvincesThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getProvincesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getProvincesThunk.fulfilled, (state, action) => {
      state.pagination[action.meta.arg] = action.payload;
      state.isLoading = false;
    });
  }
});

function selectBase(state: RootState) {
  return state.provinces;
}

export const selectProvinces = createSelector(selectBase, (slice) => slice.pagination);
export const selectProvincesError = createSelector(selectBase, (slice) => slice.hasError);
export const selectProvincesIsLoading = createSelector(selectBase, (slice) => slice.isLoading);

export const { resetProvinces } = provincesSlice.actions;
export default provincesSlice.reducer;
