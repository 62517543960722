import styled from "styled-components";
import { spacing } from "@/src/ui/styles/spacing";
import { withOpacity, resetButton } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";

const Zone = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs};
`;
interface OverlayProps {
  transparent: boolean;
}
const Overlay = styled.button<OverlayProps>`
  ${resetButton}
  position: fixed;
  height: 100vh;
  width: 100vw;
  transition: opacity ease-in-out 500ms;
  background-color: ${withOpacity(colors.neutral900, 75)};
  opacity: ${({ transparent }) => (transparent ? 0 : 1)};
`;

export default { Zone, Overlay };
