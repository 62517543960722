import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { UploadCaseFileModel } from "@/src/core/cases/domain/models/upload_case_file_model";
import type { CaseFileDocumentationSlice } from "../view_models/case_file_documentation.slice";
import type { UploadCaseFileDocumentationUseCase } from "@/src/core/cases/domain/use_cases/upload_case_file_documentation_use_case";
import type { GetCaseDocumentsUseCase } from "@/src/core/cases/domain/use_cases/get_case_documents_use_case";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): CaseFileDocumentationSlice => ({
  reference: null,
  file: null,
  files: [],
  isLoading: false,
  hasError: false,
  isLoadingDetail: false,
  hasErrorDetail: {}
});

export const getCaseDocumentationFilesThunk = createAsyncThunk("caseFileDocumentation.slice/get", async (reference: string, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetCaseDocumentsUseCase>>(TYPES.GetCaseDocumentsUseCase)();
    return await useCase.execute(reference);
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

export const uploadCaseFileDocumentationThunk = createAsyncThunk("caseFileDocumentation.slice/upload", async (input: UploadCaseFileModel) => {
  const useCase = await locator.get<IocProvider<UploadCaseFileDocumentationUseCase>>(TYPES.UploadCaseFileDocumentationUseCase)();
  return await useCase.execute(input, input.reference);
});

const caseFileDocumentationSlice = createSlice({
  name: "caseFileDocumentation.slice",
  initialState: initialState(),
  reducers: { resetCaseFiles: initialState },
  extraReducers(builder) {
    builder.addCase(getCaseDocumentationFilesThunk.pending, (state) => {
      state.hasError = false;
      state.isLoading = true;
    });
    builder.addCase(getCaseDocumentationFilesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCaseDocumentationFilesThunk.fulfilled, (state, action) => {
      state.files = action.payload;
      state.isLoading = false;
    });
    builder.addCase(uploadCaseFileDocumentationThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadCaseFileDocumentationThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });
    builder.addCase(uploadCaseFileDocumentationThunk.fulfilled, (state, action) => {
      state.file = action.payload;
      state.file.status = "processed";
      state.isLoading = false;
    });
  }
});

const selectCaseFileDocumentationBase = (state: RootState) => state.caseFileDocumentation;

export const selectCaseFileDocumentation = createSelector(selectCaseFileDocumentationBase, (slice) => slice.file);
export const selectCaseDocumentationFiles = createSelector(selectCaseFileDocumentationBase, (slice) => slice.files);
export const selectCaseFileDocumentationIsLoading = createSelector(selectCaseFileDocumentationBase, (slice) => slice.isLoading);
export const selectCaseFileDocumentationHasError = createSelector(selectCaseFileDocumentationBase, (slice) => slice.hasError);

export const { resetCaseFiles } = caseFileDocumentationSlice.actions;

export default caseFileDocumentationSlice.reducer;
