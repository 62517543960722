import { store } from "@/src/ui/state";
import { selectCaseDetailIsLastStep, publishCaseThunk, selectCaseDetailSaveAsDraft } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";
import { createElement } from "react";
import type { CustomFormValues } from "@/src/ui/pages/custom_forms/view_models/custom_form_values";
import { showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import type { FormikHelpers } from "formik";
import { selectNewCaseCustomFormCurrentFieldNames } from "../../../publish_case/state/new_case_publish_form.selectors";
import { pick } from "lodash";
import Translate from "@/src/ui/components/text/translate/translate";
import { handleFormErrors } from "@/src/ui/controllers/handle_form_errors";
import { useNavigate } from "react-router-dom";

export function useEditCaseFormController() {
  const navigate = useNavigate();
  return async (values: CustomFormValues, h: FormikHelpers<CustomFormValues>) => {
    try {
      const { dispatch, getState } = store;
      const state = getState();
      const isLastStep = selectCaseDetailIsLastStep(state);
      const saveAsDraft = selectCaseDetailSaveAsDraft(state);
      const currentFieldNames = selectNewCaseCustomFormCurrentFieldNames(state);
      await dispatch(publishCaseThunk(pick(values, currentFieldNames))).unwrap();

      dispatch(showSuccessAlertThunk(createElement(Translate, { ns: "edit_case", i18nKey: "alerts.save" })));
      if (isLastStep && !saveAsDraft) {
        navigate("..");
      }
    } catch (e) {
      handleFormErrors(e, h);
    }
  };
}
