import type { CaseFileStatusTypeModel } from "@/src/core/cases/domain/models/case_file_status_type_model";
import { Expose, Type } from "class-transformer";
import { toDomain } from "@/src/common/utils/transformers";
import { CaseFileValidationModel } from "./case_file_validation_model";
import type { CaseFileTypeModel } from "./case_file_type_model";

export class CaseFileModel {
  @Expose() key!: string; // file id
  @Expose() label!: string;
  @Expose() status!: CaseFileStatusTypeModel;
  @Expose() type!: CaseFileTypeModel;
  @Expose() url?: string;
  @Expose() originalName?: string;
  @Expose() totalSize?: number; // in bytes
  @Expose() checksum?: string;
  @Expose() helpText?: string;
  @Expose() @Type(() => CaseFileValidationModel) validation!: CaseFileValidationModel;

  @Expose() @Type(() => Date) createdAt?: Date;
  @Expose() @Type(() => Date) updatedAt?: Date;

  get isUploaded() {
    return this.status === "uploaded";
  }

  get isPhoto() {
    return this.type === "photo";
  }

  get isRadio() {
    return this.type === "radio";
  }

  get isStl() {
    return this.type === "stl";
  }

  empty(): CaseFileModel {
    return toDomain(CaseFileModel, {
      key: this.key,
      type: this.type,
      status: "empty",
      label: this.label,
      helpText: this.helpText,
      validation: this.validation
    });
  }
}
