import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CasesSortSlice } from "../view_models/cases_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): CasesSortSlice => ({ value: {} });

const casesPageSortSlice = createSlice({
  name: "casesPageSort.slice",
  initialState: initialState(),
  reducers: {
    resetCasesPageSort: initialState,
    setCasesPageSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.casesPageSort;
}

export const selectCasesPageSort = createSelector(selectBase, (slice) => slice.value);

export const { setCasesPageSort, resetCasesPageSort } = casesPageSortSlice.actions;
export default casesPageSortSlice.reducer;

export const casesPageSortColumnNames = [
  null,
  "order[patient.name]",
  "order[age]",
  null,
  "order[productType]",
  "order[status]",
  null,
  "order[createdAt]",
  "order[updatedAt]"
];
