import type { CustomTeethFieldModel } from "@/src/core/custom_forms/domain/models/fields/custom_teeth_field.model";
import type { ArcadeTypeModel } from "@/src/core/teeth/domain/models/arcade_type_model";
import TeethSelectFieldFormik from "@/src/ui/pages/teeth/components/teeth_select_field_formik/teeth_select_field_formik";
import { useField } from "formik";

interface Props {
  field: CustomTeethFieldModel;
}
export default function CustomTeethField({ field }: Props) {
  const [f] = useField<ArcadeTypeModel>("patient-archs");
  return <TeethSelectFieldFormik name={field.name} label={field.label} paragraph={field.helpText ?? ""} arcade={f?.value} />;
}
