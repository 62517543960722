import { px2rem } from "@/src/ui/styles/utils";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1 1 100%;
  min-width: 0;
`;
const Section = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-row-gap: ${px2rem(26)};
  grid-column-gap: ${px2rem(73)};
  grid-template-columns: repeat(auto-fill, 148px);
  place-content: center;
  width: 100%;
`;

export default { Wrapper, Section };
