import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { UsersPageFiltersSlice } from "@/src/ui/pages/users/views/users_page/view_models/users_page_filters.slice";
import type { UserFiltersModel } from "@/src/core/user/domain/models/user_filters_model";

const initialState = (): UsersPageFiltersSlice => ({
  show: false,
  filters: {
    name: "",
    email: "",
    telephone: "",
    rol: ""
  }
});

const usersSlice = createSlice({
  name: "usersPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetUsersPageFilters: initialState,
    cleanUsersPageFilters(state) {
      state.filters = initialState().filters;
    },
    setUsersPageFilters(state, action: PayloadAction<Partial<UserFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleUsersPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectUsersPageFiltersBase(state: RootState) {
  return state.usersPageFilters;
}

export const selectUsersPageFilters = createSelector(selectUsersPageFiltersBase, (slice) => slice.filters);
export const selectUsersPageFiltersEmail = createSelector(selectUsersPageFilters, (filters) => filters.email);
export const selectShowUsersPageFilters = createSelector(selectUsersPageFiltersBase, (slice) => slice.show);
export const { resetUsersPageFilters, setUsersPageFilters, cleanUsersPageFilters, toggleUsersPageFilters } = usersSlice.actions;
export default usersSlice.reducer;
