import { getCustomFieldInitialValue } from "@/src/ui/pages/custom_forms/presenters/get_custom_field_initial_value";
import { getCustomFormSchema } from "@/src/ui/pages/custom_forms/presenters/get_custom_form_schema";
import { groupCustomFormFields } from "@/src/ui/pages/custom_forms/presenters/group_custom_form_fields";
import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { selectCaseDetailForm } from "../../cases/case_detail/state/case_detail.slice";
import type { ManufacturingLotTransitionSlice } from "../view_models/manufacturing_lot_transition.slice";

const initialState = (): ManufacturingLotTransitionSlice => ({ stage: null });

const manufacturingLotTransitionSlice = createSlice({
  initialState: initialState(),
  name: "manufacturingLotTransition.slice",
  reducers: {
    setManufacturingLotTransitionInitialStage(state) {
      state.stage = null;
    },
    setManufacturingLotTransitionStage(state, action: PayloadAction<string>) {
      state.stage = action.payload;
    }
  }
});

const selectBase = (state: RootState) => state.manufacturingLotTransition;
export const selectManufacturingLotTransitionStageName = createSelector(selectBase, (slice) => slice.stage);

export const selectManufacturingLotStages = createSelector(selectCaseDetailForm, (form) => form?.manufacturing.stages ?? []);
export const selectManufacturingLotStageTranslationMap = createSelector(selectManufacturingLotStages, (stages) => new Map(stages.map(stage => [stage.name, stage.label])));

export const selectManufacturingLotTransitionStage = createSelector(selectCaseDetailForm, selectManufacturingLotTransitionStageName, (form, stage) =>
  form?.manufacturing.stages.find((s) => s.name == (stage ?? form.manufacturing.initialStage))
);
export const selectManufacturingLotTransitionFormFields = createSelector(selectManufacturingLotTransitionStage, (stage) => stage?.fields ?? []);
export const selectManufacturingLotTransitionFormSchema = createSelector(selectManufacturingLotTransitionFormFields, getCustomFormSchema);
export const selectManufacturingLotTransitionFormGroupedFields = createSelector(selectManufacturingLotTransitionFormFields, groupCustomFormFields);
export const selectManufacturingLotTransitionFormInitialValues = createSelector(selectManufacturingLotTransitionFormFields, (fields) =>
  fields.reduce((prev, f) => ({ ...prev, [f.name]: getCustomFieldInitialValue(f) }), {})
);

export const { setManufacturingLotTransitionStage, setManufacturingLotTransitionInitialStage } = manufacturingLotTransitionSlice.actions;
export default manufacturingLotTransitionSlice.reducer;
