import styled from "styled-components";
import { contentMaxWidth } from "@/src/ui/styles/sizes";
import type { ContentMaxWidthProps } from "@/src/ui/components/spacing/content_max_width/content_max_width";

const ContentMaxWidth = styled.div<ContentMaxWidthProps>`
  margin: auto;
  max-width: ${({ width = "medium" }) => contentMaxWidth[width]};
`;

export default { ContentMaxWidth };
