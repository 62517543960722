import { useAppSelector } from "@/src/ui/state";
import { useTranslation } from "react-i18next";
import type { PropsWithChildren } from "react";
import { useCallback, useEffect } from "react";
import { selectCaseFormsError, selectCaseFormsLoading } from "@/src/ui/pages/cases/case_forms/state/case_forms.slice";
import { FullscreenBasicSpinner } from "@/src/ui/components/loaders/basic_spinner/fullscreen_basic_spinner";
import { FullscreenErrorMessage } from "@/src/ui/components/error_displayers/error_messages/basic_error_message/fullscreen_error_message";
import { selectLanguage } from "@/src/ui/state/language.slice";
import { getCaseFormController } from "../../../case_forms/controllers/get_case_form_controller";
import { selectCaseDetailForm, selectCaseDetailType } from "../../state/case_detail.slice";
import { NoResultsMessage } from "@/src/ui/components/loaders/no_results_message/no_results_message";

export default function CaseDetailFormLoader({ children }: PropsWithChildren) {
  const { t } = useTranslation("case_detail");

  const caseType = useAppSelector(selectCaseDetailType);
  const language = useAppSelector(selectLanguage);
  const getForm = useCallback(() => caseType && getCaseFormController({ language, caseType }), [caseType, language]);
  useEffect(getForm, [getForm]);

  const isLoading = useAppSelector(selectCaseFormsLoading);
  const hasError = useAppSelector(selectCaseFormsError);
  const hasForm = useAppSelector((state) => Boolean(selectCaseDetailForm(state)));

  if (hasForm) return <>{children}</>;
  if (caseType && isLoading.has([language, caseType])) return <FullscreenBasicSpinner ariaLabel={t("loading")} />;
  if (hasError) return <FullscreenErrorMessage retry={getForm}>{t("error")}</FullscreenErrorMessage>;
  return <NoResultsMessage height="fullscreen" />;
}
