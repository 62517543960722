import { useSelector } from "react-redux";
import type { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { selectHasProfile } from "@/src/ui/state/profile.slice";
import { FullscreenBasicSpinner } from "@/src/ui/components/loaders/basic_spinner/fullscreen_basic_spinner";
import { useHasRole } from "../../pages/roles/hooks/use_has_role";
import type { RoleModel } from "@/src/core/user/domain/models/role_model";

interface Props extends PropsWithChildren {
  profileRole: RoleModel | RoleModel[];
}
export default function HasRoleMiddleware({ profileRole, children }: Props) {
  const hasProfile = useSelector(selectHasProfile);
  const hasRole = useHasRole(profileRole);
  if (!hasProfile) {
    return <FullscreenBasicSpinner ariaLabel="Logging in…" />;
  }
  if (hasRole) {
    return <>{children}</>;
  }
  return <Navigate to="/role-error" replace />;
}
