import type { ArcadeTypeModel } from "@/src/core/teeth/domain/models/arcade_type_model";
import { useCallback } from "react";

export function useToothBelongsToArcade(arcade: ArcadeTypeModel | null) {
  return useCallback(
    (toothName: string) => {
      if (!arcade || arcade === "both-arcade-treat") return true;
      const isUpper = parseInt(toothName) <= 28;
      return arcade === "upper-arcade-treat" ? isUpper : !isUpper;
    },
    [arcade]
  );
}
