import { store } from "@/src/ui/state";
import { getCaseFormsThunk, selectCaseFormsLoading, selectCaseFormLanguageMap } from "../state/case_forms.slice";
import type { GetCaseFormsInput } from "../view_models/get_case_forms_input";

export function getCaseFormController(input: GetCaseFormsInput) {
  const state = store.getState();
  const loading = selectCaseFormsLoading(state);

  const { caseType, language } = input;
  if (loading.has([language, caseType]) || selectCaseFormLanguageMap(state)[input.language]?.[input.caseType]) return;
  store.dispatch(getCaseFormsThunk(input));
}
