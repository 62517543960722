import { createSelector } from "@reduxjs/toolkit";
import { selectRoles } from "../../../../roles/state/roles.selectors";
import { selectBillingAddressDetailIsOwn } from "./billing_address_detail.slice";

export const selectCanEditBillingAddressDetail = createSelector(
  selectRoles,
  selectBillingAddressDetailIsOwn,
  (roles, isOwn) => roles.has("BILLING_ADDRESSES_EDIT") || (isOwn && roles.has("BILLING_ADDRESSES_EDIT_OWN"))
);
export const selectCanDeleteBillingAddressDetail = createSelector(
  selectRoles,
  selectBillingAddressDetailIsOwn,
  (roles, isOwn) => roles.has("BILLING_ADDRESSES_DELETE") || (isOwn && roles.has("BILLING_ADDRESSES_DELETE_OWN"))
);
