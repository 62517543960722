import type { FormikHelpers } from "formik";
import { store } from "../state";
import { AxiosError } from "axios";
import { axiosErrorToFormErrors } from "../presenters/axios_error_to_form_errors";
import { showErrorAlertThunk } from "../state/alerts.slice";

export function handleFormErrors<T>(error: unknown, helpers: FormikHelpers<T>) {
  console.error(error);
  if (error instanceof AxiosError) {
    const formErrors = axiosErrorToFormErrors(error);
    if (formErrors) {
      helpers.setErrors(formErrors);
      return formErrors;
    }
  }
  store.dispatch(showErrorAlertThunk());
}
