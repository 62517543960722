import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { PromotersSlice } from "@/src/ui/pages/promoters/view_models/promoters.slice";
import type { GetPromotersUseCase } from "@/src/core/promoters/domain/use_cases/get_promoters_use_case";
import { promoterToOption } from "@/src/ui/pages/promoters/presenters/promoter_to_option";

const initialState = (): PromotersSlice => ({
  results: [],
  hasError: false,
  isLoading: true
});

export const getPromotersThunk = createAsyncThunk("promoters.slice/get", async () => {
  const useCase = await locator.get<IocProvider<GetPromotersUseCase>>(TYPES.GetPromotersUseCase)();
  return await useCase.execute({});
});

const promotersSlice = createSlice({
  name: "promoters.slice",
  initialState: initialState(),
  reducers: {
    resetPromoters: initialState
  },
  extraReducers(builder) {
    builder.addCase(getPromotersThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getPromotersThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getPromotersThunk.fulfilled, (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    });
  }
});

function selectPromotersBase(state: RootState) {
  return state.promoters;
}

export const selectPromoters = createSelector(selectPromotersBase, (slice) => slice.results);
export const selectPromoterDistributorMap = createSelector(selectPromoters, (promoters) => {
  return new Map(promoters.map((p) => [p.id, p.distributor.id]));
});
export const selectPromoterOptions = createSelector(selectPromoters, (promoters) => promoters.map(promoterToOption));
export const selectPromotersError = createSelector(selectPromotersBase, (slice) => slice.hasError);
export const selectPromotersIsLoading = createSelector(selectPromotersBase, (slice) => slice.isLoading);
export const { resetPromoters } = promotersSlice.actions;
export default promotersSlice.reducer;
