import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { px2rem, truncateText } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";
import { borderRadius } from "@/src/ui/styles/border_radius";

const Label = styled.label`
  min-width: 0;
  flex: 1 1 100%;
  display: flex;
  cursor: pointer;
`;
const LabelText = styled.div<{ checked: boolean }>`
  ${typography.small}
  ${truncateText}
  min-width: 0;
  line-height: ${px2rem(20)};
  color: ${({ checked }) => (checked ? colors.brand500 : colors.neutral700)};
`;

const Input = styled.input`
  height: ${px2rem(1)};
  width: ${px2rem(1)};
  opacity: 0;
  position: absolute;
`;
const Checkbox = styled.div<{ checked: boolean }>`
  border: ${({ checked }) => !checked && "1px solid " + colors.neutral500};
  ${borderRadius.s};
  flex: 0 0 ${px2rem(18)};
  background-color: ${({ checked }) => (checked ? colors.brand500 : colors.white)};
  height: ${px2rem(18)};
  width: ${px2rem(18)};
  margin-right: ${px2rem(7)};
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    height: ${px2rem(16)};
    width: ${px2rem(16)};
    * {
      color: white;
    }
  }
`;
export default { Label, LabelText, Input, Checkbox };
