import { Expose, Transform, Type } from "class-transformer";
import { MoneyModel } from "@/src/core/app/domain/models/money.model";
import { BillingAddressModel } from "../../../domain/models/billing_address_model";
import { InvoiceLineModel } from "./invoice_line_model";

export class InvoiceDetailModel {
  @Expose() id!: string;

  @Expose() @Type(() => BillingAddressModel) billingAddress?: BillingAddressModel;
  @Expose() businessName!: string;
  @Expose() invoiceNumber!: string;

  @Expose() isCancelled!: boolean;
  @Expose() paid!: boolean;

  @Expose() paymentMethod!: string;
  @Expose() paymentType!: string;
  @Expose() paymentTypeOption!: string;
  @Expose() @Type(() => MoneyModel) total!: MoneyModel;
  @Expose() @Type(() => MoneyModel) totalDiscount!: MoneyModel;

  @Expose() @Type(() => InvoiceDetailModel) correctedInvoiceId!: InvoiceDetailModel;
  @Expose() vat!: number;
  @Expose() notes!: string;

  @Expose() @Type(() => InvoiceLineModel) invoiceLines!: InvoiceLineModel[];

  @Expose() @Type(() => Date) expiresAt!: Date;
  @Expose() @Type(() => Date) paymentDate?: Date;

  @Expose() @Type(() => Date) createdAt!: Date;
  @Expose() @Transform(({ value, obj }) => new Date(value ?? obj.createdAt)) updatedAt!: Date;
}
