import { css } from "styled-components";
import { px2rem } from "@/src/ui/styles/utils";

export const shadows = {
  soft: css`
    box-shadow: 0 ${px2rem(4)} ${px2rem(20)} rgba(235, 239, 243, 0.6);
  `,
  medium: css`
    box-shadow: 0 ${px2rem(4)} ${px2rem(50)} rgba(223, 229, 236, 0.8);
  `,
  dark: css`
    box-shadow: 0 ${px2rem(4)} ${px2rem(50)} rgba(86, 86, 86, 0.3);
  `
};
