import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { GetBillingAddressesUseCase } from "@/src/core/billing/domain/use_cases/get_billing_addresses_use_case";
import type { ClinicDetailBillingAddressesSlice } from "../view_models/clinic_detail_billing_addresses.slice";
import { NullError } from "@/src/core/app/domain/models/errors/null.error";
import { RemoveBillingAddressFromClinicInput } from "@/src/core/clinics/domain/models/remove_billing_address_from_clinic_input";
import type { RemoveBillingAddressFromClinicUseCase } from "@/src/core/clinics/domain/use_cases/remove_billing_address_from_clinic_use_case";
import { showSuccessAlertThunk, showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): ClinicDetailBillingAddressesSlice => ({
  ...getInitialPaginationSlice(),
  clinicId: null
});

export const getClinicDetailBillingAddressesThunk = createAsyncThunk("clinicDetailBillingAddresses.slice/get", async (clinicId: string) => {
  const useCase = await locator.get<IocProvider<GetBillingAddressesUseCase>>(TYPES.GetBillingAddressesUseCase)();
  return await useCase.execute({ clinic: [clinicId], itemsPerPage: 100 });
});

export const removeBillingAddressFromClinicThunk = createAsyncThunk(
  "clinicBillingAddresses.slice/removeBillingAddressFromClinic",
  async (billingAddressId: string, { dispatch, getState }) => {
    try {
      const clinicId = selectClinicDetailBillingAddressesId(getState() as RootState);
      if (!clinicId) throw new NullError("removeBillingAddressFromClinicThunk", "clinicId");

      const useCase = await locator.get<IocProvider<RemoveBillingAddressFromClinicUseCase>>(TYPES.RemoveBillingAddressFromClinicUseCase)();
      await useCase.execute(new RemoveBillingAddressFromClinicInput({ clinicId, billingAddressId }));

      await dispatch(getClinicDetailBillingAddressesThunk(clinicId));
      dispatch(showSuccessAlertThunk());
    } catch (error) {
      console.error(error);
      dispatch(showErrorAlertThunk());
    }
  }
);

const clinicDetailBillingAddressesSlice = createSlice({
  name: "clinicDetailBillingAddresses.slice",
  initialState: initialState(),
  reducers: {
    resetClinicDetailBillingAddresses: initialState
  },
  extraReducers(builder) {
    builder.addCase(getClinicDetailBillingAddressesThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getClinicDetailBillingAddressesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = "error";
    });
    builder.addCase(getClinicDetailBillingAddressesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.clinicId = action.meta.arg;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.clinicDetailBillingAddresses;

export const selectClinicDetailBillingAddresses = createSelector(selectBase, (slice) => slice.pagination?.results ?? []);
export const selectClinicDetailBillingAddressesId = createSelector(selectBase, (slice) => slice.clinicId);
export const selectClinicDetailBillingAddressesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const { resetClinicDetailBillingAddresses } = clinicDetailBillingAddressesSlice.actions;
export default clinicDetailBillingAddressesSlice.reducer;
