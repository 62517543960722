import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { spacing } from "@/src/ui/styles/spacing";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";
import BasicErrorMessage from "@/src/ui/components/error_displayers/error_messages/basic_error_message/basic_error_message";
import { colors } from "@/src/ui/styles/colors";
import { px2rem } from "@/src/ui/styles/utils";

const Fieldset = styled.fieldset`
  all: unset;
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${px2rem(10)};
`;
const Legend = styled.legend`
  all: unset;
  display: block;
  box-sizing: border-box;
  width: 100%;
  ${typography.small}
  color: ${colors.neutral500};
  text-transform: uppercase;
`;
const HelpText = styled.p`
  ${typography.small}
  color: ${colors.neutral700}
`;

interface RadiosProps {
  optionsCount?: number;
  gridLayout?: string;
}
const Radios = styled.div<RadiosProps>`
  display: grid;
  grid-template-columns: ${({ optionsCount = 0, gridLayout }) => {
    if (gridLayout) return gridLayout;
    return `repeat(${optionsCount > 3 ? 2 : 1}, 1fr)`;
  }};
  grid-gap: ${spacing.xs};
`;
const ErrorText = styled.p`
  ${typography.xSmall};
  margin-top: ${px2rem(4)};
  color: ${colors.alert700};
`;
const Loader = styled(BasicSpinner)`
  height: ${px2rem(80)};
`;
const LoadingError = styled(BasicErrorMessage)`
  height: ${px2rem(80)};
`;

export default { Fieldset, Legend, HelpText, Radios, ErrorText, Loader, LoadingError };
