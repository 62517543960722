import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { spacing } from "@/src/ui/styles/spacing";
import { colors } from "@/src/ui/styles/colors";

const Field = styled.div``;
const ErrorText = styled.p`
  ${typography.small}
  margin-top:  ${spacing.xs};
  color: ${colors.alert700};
`;
export default { Field, ErrorText };
