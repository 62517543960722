import styled from "styled-components";
import { spacing } from "@/src/ui/styles/spacing";
import { typography } from "@/src/ui/styles/typography";
import { resetButton } from "@/src/ui/styles/utils";
import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import { getLoaderHeight } from "@/src/ui/styles/loader_height";

interface ContainerProps {
  height?: LoaderHeight;
}
const Container = styled.div<ContainerProps>`
  height: ${({ height }) => getLoaderHeight(height)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.m};
  width: 100%;
`;
const Text = styled.p`
  ${typography.small}
  text-align: center;
  color: red;
`;
const Retry = styled.button`
  ${resetButton};
  :hover,
  :focus {
    text-decoration: underline;
  }
  ${typography.small}
  display: inline;
  color: red;
`;

export default { Container, Text, Retry };
