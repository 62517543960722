import type { Option } from "@/src/core/options/domain/models/option";
import Styled from "./checkboxes_field.styled";
import Checkbox from "@/src/ui/components/fields/checkbox/checkbox";
import ErrorText from "../../error_displayers/error_text/error_text";

export interface CheckboxesFieldProps<T extends string> {
  name: string;
  label?: string;
  error?: string;
  options?: Option<T>[];
  value: T[];
  onChange(value: T[] | null): void;
  className?: string;
  loadingText?: string;
  loadingError?: string | false;
}
export default function CheckboxesField<T extends string>({
  name,
  label,
  error,
  options,
  value,
  onChange,
  className,
  loadingText,
  loadingError
}: CheckboxesFieldProps<T>) {
  const addOption = (v: T, list: T[]) => onChange(list.concat([v]));
  const removeOption = (v: T, list: T[]) => onChange(list.filter((lv) => lv !== v));

  function renderOptions() {
    if (loadingError) {
      return <Styled.LoadingError>{loadingError}</Styled.LoadingError>;
    }
    if (!options) {
      return <Styled.Loader ariaLabel={loadingText ?? "Cargando opciones…"} />;
    }
    return (
      <>
        <Styled.Checkboxes>
          {options.map((o) => {
            const isChecked = value.includes(o.value);
            return (
              <Checkbox
                key={o.value}
                name={name}
                id={name + "-" + o.value}
                value={isChecked}
                onChange={() => (isChecked ? removeOption : addOption)(o.value, value)}
                label={o.label}
              />
            );
          })}
        </Styled.Checkboxes>
        {error && (
          <Styled.ErrorText>
            <ErrorText error={error} />
          </Styled.ErrorText>
        )}
      </>
    );
  }

  return (
    <Styled.Fieldset className={className}>
      {label && <Styled.Legend>{label}</Styled.Legend>}
      {renderOptions()}
    </Styled.Fieldset>
  );
}
