import type { PropsWithChildren } from "react";
import styled from "styled-components";
import { spacing } from "../../../styles/spacing";

const Padding = styled.div`
  padding: ${spacing.xxxl} 0;
`;
export default function PageVerticalPadding({ children }: PropsWithChildren) {
  return <Padding>{children}</Padding>;
}
