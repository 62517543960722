import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import type { LoadingState } from "@/src/ui/view_models/loading_state";
import type { PropsWithChildren } from "react";
import BasicErrorMessage from "../../error_displayers/error_messages/basic_error_message/basic_error_message";
import RoleErrorMessage from "@/src/ui/pages/roles/role_error/components/role_error_message/role_error_message";
import Message404 from "../../error_displayers/404_message/404_message";

interface Props extends PropsWithChildren {
  loadingState?: LoadingState;
  height?: LoaderHeight;
  recoverFromError?(): void;
}

export default function LoadingStateErrorWrapper({ loadingState, height, recoverFromError, children }: Props) {
  if (loadingState === "error") return <BasicErrorMessage height={height} retry={recoverFromError} />;
  if (loadingState === "notFound") return <Message404 height={height} />;
  if (loadingState === "permissionError") return <RoleErrorMessage height={height} />;
  return <>{children}</>;
}
