import { NoResultsMessage } from "@/src/ui/components/loaders/no_results_message/no_results_message";
import CaseFileCard from "@/src/ui/pages/cases/case_files/components/case_file_card/case_file_card";
import Styled from "./case_files_section.styled";
import { useTranslation } from "react-i18next";
import BasicSpinner70 from "@/src/ui/components/loaders/basic_spinner_70/basic_spinner_70";
import BasicErrorMessage70 from "@/src/ui/components/error_displayers/error_messages/basic_error_message_70/basic_error_message_70";
import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import { useCallback } from "react";
import {
  selectCaseFiles,
  getCaseFilesThunk,
  selectCaseFilesHasError,
  selectCaseFilesIsLoading,
  selectCaseFilesReference
} from "../../state/case_files.slice";
import FormTitle from "@/src/ui/components/text/form_title/form_title";
import VerticalSpacer from "@/src/ui/components/spacing/vertical_spacer/vertical_spacer";
import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";

interface Props {
  caseReference: string;
}
export default function CaseFilesSection({ caseReference }: Props) {
  const { t } = useTranslation("case_files");

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectCaseFilesIsLoading);
  const hasError = useAppSelector(selectCaseFilesHasError);
  const results = useAppSelector(selectCaseFiles);
  const filesReference = useAppSelector(selectCaseFilesReference);

  const loadFiles = useCallback((caseReference: string) => dispatch(getCaseFilesThunk(caseReference)), [dispatch]);
  useEffectRunOnce(() => {
    if (caseReference !== filesReference && !isLoading) loadFiles(caseReference);
  }, [caseReference, filesReference, isLoading, loadFiles]);
  const reload = () => loadFiles(caseReference);

  const mapResultToFileCard = (result: CaseFileModel) => (
    <CaseFileCard key={`${result.key}_${result.checksum ?? 0}`} caseFile={result} reference={caseReference} />
  );

  if (results.length && filesReference && filesReference === caseReference)
    return (
      <Styled.Wrapper>
        <FormTitle>{t("titles.photography")}</FormTitle>
        <VerticalSpacer height="xs" />
        <Styled.Section>{results.filter((c) => c.isPhoto).map(mapResultToFileCard)}</Styled.Section>
        <VerticalSpacer height="xl" />
        <FormTitle>{t("titles.radiography")}</FormTitle>
        <VerticalSpacer height="xs" />
        <Styled.Section>{results.filter((c) => c.isRadio).map(mapResultToFileCard)}</Styled.Section>
      </Styled.Wrapper>
    );

  if (isLoading) return <BasicSpinner70 ariaLabel={t("loading.files")} />;
  if (hasError) return <BasicErrorMessage70 retry={reload}>{t("errors.loading")}</BasicErrorMessage70>;

  return <NoResultsMessage />;
}
