import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import CanEditClinicMiddleware from "@/src/ui/router/middlewares/can_edit_clinic_middleware";
const ClinicsPage = lazy(() => import("@/src/ui/pages/clinics/components/clinics_page/clinics_page"));
const NewClinicPage = lazy(() => import("@/src/ui/pages/clinics/new_clinic/components/new_clinic_page/new_clinic_page"));
const ClinicDetailPage = lazy(() => import("@/src/ui/pages/clinics/clinic_detail/components/clinic_detail_page/clinic_detail_page"));
const EditClinicPage = lazy(() => import("@/src/ui/pages/clinics/edit_clinic/components/edit_clinic_page/edit_clinic_page"));

export default function ClinicsPages() {
  const page = useRoutes([
    {
      index: true,
      element: <ClinicsPage />
    },
    {
      path: "new",
      element: (
        <HasRoleMiddleware profileRole={["CLINICS_CREATE", "CLINICS_CREATE_OWN"]}>
          <NewClinicPage />
        </HasRoleMiddleware>
      )
    },
    {
      path: "detail/:id",
      element: <ClinicDetailPage />
    },
    {
      path: "detail/:id/edit",
      element: (
        <CanEditClinicMiddleware>
          <EditClinicPage />
        </CanEditClinicMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole={["CLINICS_VIEW", "CLINICS_VIEW_OWN"]}>{page}</HasRoleMiddleware>;
}
