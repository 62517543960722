import DateFieldFormik from "@/src/ui/components/fields/date_field/fast_date_field.formik";
import type { CustomDateFieldModel } from "@/src/core/custom_forms/domain/models/fields/custom_date_field.model";

interface Props {
  field: CustomDateFieldModel;
}
export default function CustomDateField({ field }: Props) {
  const { name, label, placeholder, validation = {} } = field;
  const { min, max } = validation;
  const maxDate = max && new Date(max === "now" ? Date.now() : max);
  const minDate = min && new Date(min === "now" ? Date.now() : min);
  return <DateFieldFormik name={name} label={label} placeholder={placeholder} max={maxDate} min={minDate} />;
}
