import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { spacing } from "@/src/ui/styles/spacing";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";
import BasicErrorMessage from "@/src/ui/components/error_displayers/error_messages/basic_error_message/basic_error_message";
import { colors } from "@/src/ui/styles/colors";

const Fieldset = styled.fieldset`
  all: unset;
  display: block;
  box-sizing: border-box;
  width: 100%;
`;
const Legend = styled.legend`
  all: unset;
  display: block;
  box-sizing: border-box;
  width: 100%;
  ${typography.small}
  color: ${colors.neutral500};
  text-transform: uppercase;
  margin-bottom: ${spacing.xs};
`;

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${spacing.xs};
`;
const ErrorText = styled.p`
  ${typography.small}
  margin-top:  ${spacing.xs};
  color: red;
`;
const Loader = styled(BasicSpinner)`
  height: ${spacing.xxl};
`;
const LoadingError = styled(BasicErrorMessage)`
  height: ${spacing.xxl};
`;

export default { Fieldset, Legend, Checkboxes, ErrorText, Loader, LoadingError };
