import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { DentistBillingAddressesSlice } from "../view_models/dentist_billing_addresses.slice";
import type { GetBillingAddressesByDentistUseCase } from "@/src/core/billing/domain/use_cases/get_billing_addresses_by_dentist_use_case";
import { billingAddressToOption } from "../../../billing/billing_addresses/presenters/billing_address_to_option";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import { NullError } from "@/src/core/app/domain/models/errors/null.error";
import type { RemoveBillingAddressFromDentistUseCase } from "@/src/core/dentists/domain/use_cases/remove_billing_address_from_dentist_use_case";
import { RemoveBillingAddressFromDentistInput } from "@/src/core/dentists/domain/models/remove_billing_address_from_dentist_input";

const initialState = (): DentistBillingAddressesSlice => ({
  ...getInitialPaginationSlice(),
  dentistId: null
});

export const getDentistBillingAddressesThunk = createAsyncThunk("dentistBillingAddresses.slice/get", async (dentist: string, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetBillingAddressesByDentistUseCase>>(TYPES.GetBillingAddressesByDentistUseCase)();
    return await useCase.execute({ dentist, itemsPerPage: 100 });
  } catch (error) {
    console.error(error);
    dispatch(showErrorAlertThunk());
    throw error;
  }
});

export const removeBillingAddressFromDentistThunk = createAsyncThunk(
  "dentistBillingAddresses.slice/removeBillingAddressFromDentist",
  async (billingAddressId: string, { dispatch, getState }) => {
    try {
      const dentistId = selectDentistBillingAddressesId(getState() as RootState);
      if (!dentistId) throw new NullError("removeBillingAddressFromDentistThunk", "dentistId");

      const useCase = await locator.get<IocProvider<RemoveBillingAddressFromDentistUseCase>>(TYPES.RemoveBillingAddressFromDentistUseCase)();
      await useCase.execute(new RemoveBillingAddressFromDentistInput({ dentistId, billingAddressId }));

      await dispatch(getDentistBillingAddressesThunk(dentistId));
      dispatch(showSuccessAlertThunk());
    } catch (error) {
      console.error(error);
      dispatch(showErrorAlertThunk());
    }
  }
);

const dentistBillingAddressesSlice = createSlice({
  name: "dentistBillingAddresses.slice",
  initialState: initialState(),
  reducers: {
    resetDentistBillingAddresses: initialState
  },
  extraReducers(builder) {
    builder.addCase(getDentistBillingAddressesThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getDentistBillingAddressesThunk.rejected, (state) => {
      state.loadingState = "error";
    });
    builder.addCase(getDentistBillingAddressesThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
      state.dentistId = action.meta.arg;
    });
  }
});

const selectBase = (state: RootState) => state.dentistBillingAddresses;

export const selectDentistBillingAddresses = createSelector(selectBase, (slice) => slice.pagination?.results ?? []);
export const selectDentistBillingAddressesOptions = createSelector(selectDentistBillingAddresses, (billingAddresses) =>
  billingAddresses.map(billingAddressToOption)
);
export const selectDentistBillingAddressesId = createSelector(selectBase, (slice) => slice.dentistId);
export const selectDentistBillingAddressesLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectDentistBillingAddressesError = createSelector(selectBase, (slice) => slice.loadingState === "error");
export const selectDentistBillingAddressesIsLoading = createSelector(selectBase, (slice) => slice.loadingState === "loading");

export const { resetDentistBillingAddresses } = dentistBillingAddressesSlice.actions;
export default dentistBillingAddressesSlice.reducer;
