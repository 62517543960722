import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";

const UsersPage = lazy(() => import("@/src/ui/pages/users/views/users_page/users_page"));
const UserDetailPage = lazy(() => import("@/src/ui/pages/users/views/user_detail/user_detail_page"));
const NewProfilePageLayout = lazy(() => import("@/src/ui/pages/profile/new_profile/components/new_profile_page_layout/new_profile_page_layout"));

export default function UsersPages() {
  const routes = useRoutes([
    { index: true, element: <UsersPage /> },
    { path: "/detail/:id", element: <UserDetailPage /> },
    {
      path: "/new/*",
      element: (
        <HasRoleMiddleware profileRole="USERS_CREATE">
          <NewProfilePageLayout />
        </HasRoleMiddleware>
      )
    },
    {
      path: "/detail/:id/profiles/new/*",
      element: (
        <HasRoleMiddleware profileRole="PROFILES_CREATE">
          <NewProfilePageLayout />
        </HasRoleMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole="USERS_VIEW">{routes}</HasRoleMiddleware>;
}
