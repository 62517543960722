import type { MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import Styled from "@/src/ui/components/buttons/button/button.styled";
import type { CypressProps } from "@/src/ui/view_models/cypress";
import type { To } from "react-router-dom";

export interface ButtonSizesProps {
  size?: "m" | "s" | "l" | "40";
}

export interface ButtonProps extends CypressProps, PropsWithChildren, ButtonSizesProps {
  onClick?: MouseEventHandler;
  type?: "submit" | "reset" | "button";
  color?: "primary" | "secondary" | "link" | "linkSecondary" | "error";
  disabled?: boolean;
  className?: string;
  icon?: ReactNode;
  iconposition?: "right" | "left";
  to?: To;
  href?: string;
  tooltip?: string;
  hidetext?: boolean;
  ariaLabel?: string;
}

export default function Button({
  children,
  icon,
  "data-cy": dataCy,
  type = "button",
  to,
  disabled,
  onClick,
  iconposition,
  href,
  className,
  tooltip,
  hidetext,
  ...props
}: ButtonProps) {
  const key = (to && !disabled) ? "LinkButton" : "Button";
  const Component = Styled[href ? "AButton" : key] as typeof Styled.Button;
  return (
    <Component
      to={to}
      data-cy={dataCy}
      type={type}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      iconposition={iconposition ?? (icon ? "left" : undefined)}
      href={href}
      className={className}
      hidetext={hidetext}
      {...props}>
      {tooltip && <Styled.TooltipBox>{tooltip}</Styled.TooltipBox>}
      {icon}
      {!hidetext && (
        <Styled.ButtonText disabled={disabled} {...props}>
          {children}
        </Styled.ButtonText>
      )}
    </Component>
  );
}
