import { usePageReference } from "@/src/ui/hooks/use_page_id";
import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import type { PropsWithChildren } from "react";
import { useCallback } from "react";
import { selectCaseDetail, getCaseDetailThunk, selectCaseDetailLoadingState } from "../../state/case_detail.slice";
import LoadingStateErrorWrapper from "@/src/ui/components/loaders/loading_state_error_wrapper/loading_state_error_wrapper";
import LoadingStateLoadingWrapper from "@/src/ui/components/loaders/loading_state_loading_wrapper/loading_state_loading_wrapper";
import NoResultsWrapper from "@/src/ui/components/loaders/no_results_wrapper/no_results_wrapper";
import { useEffectRunOnce } from "@front_web_mrmilu/hooks";

export default function CaseDetailLoader({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const reference = usePageReference("CaseDetailPageLoader");
  const detail = useAppSelector(selectCaseDetail);
  const loadingState = useAppSelector(selectCaseDetailLoadingState);

  const loadDetail = useCallback(() => {
    dispatch(getCaseDetailThunk(reference));
  }, [reference, dispatch]);
  useEffectRunOnce(loadDetail, [loadDetail]);

  if (detail && reference === detail.reference) return <>{children}</>;
  return (
    <LoadingStateLoadingWrapper height="fullscreen" loadingState={loadingState}>
      <LoadingStateErrorWrapper height="fullscreen" loadingState={loadingState} recoverFromError={loadDetail}>
        <NoResultsWrapper height="fullscreen" hasResults={Boolean(detail && reference === detail.reference)}>
          {children}
        </NoResultsWrapper>
      </LoadingStateErrorWrapper>
    </LoadingStateLoadingWrapper>
  );
}
