import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { DentistDetailSlice } from "../view_models/dentist_detail.slice";
import type { GetDentistDetailUseCase } from "@/src/core/dentists/domain/use_cases/get_dentist_detail_use_case";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { serializeError } from "@/src/common/utils/rtk";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): DentistDetailSlice => getInitialDetailSlice();

export const getDentistDetailThunk = createAsyncThunk(
  "dentistDetail.slice/get",
  async (id: string, { dispatch }) => {
    try {
      const useCase = await locator.get<IocProvider<GetDentistDetailUseCase>>(TYPES.GetDentistDetailUseCase)();
      return await useCase.execute(id);
    } catch (error) {
      console.error(error);
      dispatch(showErrorAlertThunk());
      throw error;
    }
  },
  { serializeError }
);

const dentistDetailSlice = createSlice({
  name: "dentistDetail.slice",
  initialState: initialState(),
  reducers: {
    resetDentistDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getDentistDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getDentistDetailThunk.rejected, (state, action) => {
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getDentistDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.dentistDetail;

export const selectDentistDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectDentistDetailLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectDentistDetailIsOwn = createSelector(selectDentistDetail, (detail) => Boolean(detail?.isOwn));
export const { resetDentistDetail } = dentistDetailSlice.actions;
export default dentistDetailSlice.reducer;
