import { Expose, Transform, Type } from "class-transformer";
import { CountryModel } from "@/src/core/countries/domain/models/country_model";
import { CityModel } from "@/src/core/cities/domain/models/city_model";
import { ProvinceModel } from "@/src/core/provinces/domain/models/province_model";

export class BillingAddressModel {
  @Expose() id!: string;
  @Expose() @Type(() => CountryModel) country?: CountryModel;
  @Expose() @Type(() => ProvinceModel) province?: ProvinceModel;
  @Expose() @Type(() => CityModel) city?: CityModel;
  @Expose() name!: string;
  @Expose() vatNumber!: string;
  @Expose() address!: string;
  @Expose() email!: string;
  @Expose() telephone!: string;
  @Expose() iban!: string;
  @Expose() addressNumber!: string;
  @Expose() addressFloor!: string;
  @Expose() postalCode!: string;
  @Expose() clinic!: string;
  @Expose() clinicGroup?: string;
  @Expose() dentist?: string;

  @Expose() @Type(() => Date) createdAt!: Date;
  @Expose() @Transform(({value, obj}) => new Date(value ?? obj.createdAt)) updatedAt!: Date;

  @Expose() isOwn!: boolean;
}
