import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import type { PropsWithChildren } from "react";
import { selectIsDentist } from "../../pages/roles/state/roles.selectors";

export default function IsNotDentistMiddleware({ children }: PropsWithChildren) {
  const isDentist = useAppSelector(selectIsDentist);
  if (!isDentist) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
