import { createSelector } from "@reduxjs/toolkit";
import { selectRoles } from "../../../roles/state/roles.selectors";
import { selectClinicDetailIsOwn } from "./clinic_detail.slice";

export const selectCanEditClinicDetail = createSelector(
  selectRoles,
  selectClinicDetailIsOwn,
  (roles, isOwn) => roles.has("CLINICS_EDIT") || (isOwn && roles.has("CLINICS_EDIT_OWN"))
);
export const selectCanDeleteClinicDetail = createSelector(
  selectRoles,
  selectClinicDetailIsOwn,
  (roles, isOwn) => roles.has("CLINICS_DELETE") || (isOwn && roles.has("CLINICS_DELETE_OWN"))
);
