import { useAppSelector } from "@/src/ui/state";
import { selectShowModal } from "@/src/ui/state/modal.slice";
import { createGlobalStyle, css } from "styled-components";

interface IDisableScroll {
  disableScroll: boolean;
}
const DisableScrollStyles = createGlobalStyle<IDisableScroll>`
    ${({ disableScroll }) =>
      disableScroll &&
      css`
        body {
          overflow-y: hidden;
        }
      `}
`;

/** Disables the scroll of the page when a modal is visible */
export default function DisableScroll() {
  const showModal = useAppSelector(selectShowModal);
  return <DisableScrollStyles disableScroll={showModal} />;
}
