import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import { selectCanEditCaseDetail } from "../../pages/cases/case_detail/state/case_detail_roles.selectors";
import type { PropsWithChildren } from "react";
import { usePageReference } from "../../hooks/use_page_id";
import { selectCaseDetail } from "../../pages/cases/case_detail/state/case_detail.slice";

export default function CanEditCaseMiddleware({ children }: PropsWithChildren) {
  const canEditCase = useAppSelector(selectCanEditCaseDetail);
  const reference = usePageReference("CanEditCaseMiddleware");
  const detail = useAppSelector(selectCaseDetail);
  if (canEditCase || reference !== detail?.reference) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
