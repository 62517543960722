import type { CustomFieldDisplayModel, CustomFieldDisplayOptionsModel } from "@/src/core/custom_forms/domain/models/custom_field_display.model";

export interface CustomBaseFieldModel {
  name: string;
  label: string;
  placeholder?: string;
  helpText?: string;
  // Si se omite definir view y edit se tomara las opciones definidas en display para ambos modos.
  display?: CustomFieldDisplayOptionsModel | CustomFieldDisplayModel;
  default?: unknown;
}

export function getCustomFieldDisplayEdit(c: CustomBaseFieldModel) {
  if (!c.display) return null;
  return ("row" in c.display ? c.display : c.display?.edit) ?? null;
}

export function getCustomFieldStep(c: CustomBaseFieldModel) {
  return getCustomFieldDisplayEdit(c)?.step ?? null;
}
