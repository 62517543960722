import { locator } from "@/src/core/app/ioc";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetManufacturingItemsModel } from "@/src/core/manufacturing_items/domain/models/get_manufacturing_items.model";
import type { RootState } from "@/src/ui/state";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import type { SentManufacturingItemsSlice } from "@/src/ui/pages/manufacturing_items/view_models/sent_manufacturing_items.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { GetSentManufacturingItemsUseCase } from "@/src/core/manufacturing_items/domain/use_cases/get_sent_manufacturing_items_use_case";

export const initialState = (): SentManufacturingItemsSlice => ({
  ...getInitialPaginationSlice(),
  reference: null,
  treatment: null,
  selected: new Set()
});

export const getSentManufacturingItemsThunk = createAsyncThunk("sentManufacturingItems.slice/get", async (input: GetManufacturingItemsModel) => {
  const useCase = await locator.get<IocProvider<GetSentManufacturingItemsUseCase>>(TYPES.GetSentManufacturingItemsUseCase)();
  return await useCase.execute({ ...input, page: (input.page ?? 0) + 1, itemsPerPage: 10 });
});

const sentManufacturingItemsSlice = createSlice({
  name: "sentManufacturingItems.slice",
  initialState: initialState(),
  reducers: {
    resetSentManufacturingItems: initialState,
    setSentManufacturingItemSelected(state, action: PayloadAction<string>) {
      state.selected.add(action.payload);
    },
    setSentManufacturingItemUnselected(state, action: PayloadAction<string>) {
      state.selected.delete(action.payload);
    },
    setAllSentManufacturingItemsSelected(state) {
      state.selected = new Set(state.pagination?.results.map((r) => r.id));
    },
    setAllSentManufacturingItemsUnselected(state) {
      state.selected.clear();
    }
  },
  extraReducers(builder) {
    builder.addCase(getSentManufacturingItemsThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg.page ?? 0;
    });
    builder.addCase(getSentManufacturingItemsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getSentManufacturingItemsThunk.fulfilled, (state, action) => {
      state.reference = action.meta.arg.reference;
      state.treatment = action.meta.arg.treatment;
      state.pagination = action.payload;
      const itemIds = new Set(action.payload.results.map((i) => i.id));
      state.selected = new Set(Array.from(state.selected.values()).filter((id) => itemIds.has(id)));
      state.loadingState = "loaded";
    });
  }
});

export const {
  resetSentManufacturingItems,
  setAllSentManufacturingItemsSelected,
  setAllSentManufacturingItemsUnselected,
  setSentManufacturingItemSelected,
  setSentManufacturingItemUnselected
} = sentManufacturingItemsSlice.actions;

const selectBase = (state: RootState) => state.sentManufacturingItems;

export const selectSentManufacturingItems = createSelector(selectBase, (slice) => slice.pagination);
export const selectSentManufacturingItemsTreatment = createSelector(selectBase, (slice) => slice.treatment);
export const selectSentManufacturingItemsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectSentManufacturingItemsSelected = createSelector(selectBase, (slice) => slice.selected);
export const selectSentManufacturingItemsSelectedArray = createSelector(selectSentManufacturingItemsSelected, (set) => Array.from(set.values()));
export const selectSentManufacturingItemsAllSelected = createSelector(
  selectSentManufacturingItems,
  selectSentManufacturingItemsSelected,
  (items, selected) => items?.results.every((i) => selected.has(i.id))
);

export const selectSentManufacturingItemsPaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export default sentManufacturingItemsSlice.reducer;
