import { spacing } from "./spacing";
import { px2rem } from "./utils";

const loaderHeights = {
  fullscreen: "100vh",
  "70vh": "70vh",
  basic: spacing.xxxl,
  small: spacing.l,
  table: px2rem(549)
};
export type LoaderHeight = keyof typeof loaderHeights;

export const getLoaderHeight = (height?: LoaderHeight) => loaderHeights[height ?? "basic"];
