import type { PaginationModel } from "@/src/core/app/domain/models/pagination.model";
import type { PaginationState } from "@tanstack/react-table";
import { getInitialPaginationState } from "./table_pagination";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";
import type { LoadingState } from "./loading_state";
import type { Pagination } from "@/src/core/app/domain/models/pagination";

export interface ListSlice<T> {
  isLoading: boolean;
  hasError: boolean;
  results: T[];
}
export interface ListSliceV2<T> {
  results: T[];
  loadingState: LoadingState;
}

export interface DetailSlice<T> {
  isLoading: boolean;
  hasError: boolean;
  detail: T | null;
}
export interface DetailSliceV2<T> {
  detail: T | null;
  loadingState: LoadingState;
}

export const getInitialDetailSlice = <T>(): DetailSliceV2<T> => ({ detail: null, loadingState: "loaded" });

/**
 * @deprecated Use PaginationSliceV2 instead
 */
export interface PaginationSlice<T> {
  pagination: PaginationModel<T> | null;
  paginationState: PaginationState;
  loadingState: LoadingState;
}

export interface PaginationSliceV2<T> {
  pagination: Pagination<T> | null;
  paginationState: PaginationState;
  loadingState: LoadingState;
}

export const getInitialPaginationSlice = <T>(): PaginationSlice<T> => ({
  pagination: null,
  paginationState: getInitialPaginationState(),
  loadingState: "loaded"
});

export interface CachedPaginationSlice<T> {
  pagination: Record<string, PaginationModel<T>>;
  isLoading: boolean;
  hasError: boolean;
}
export const getInitialCachedPaginationSlice = <T>(): CachedPaginationSlice<T> => ({
  pagination: {},
  isLoading: false,
  hasError: false
});

export interface SortSlice {
  value: SortingModel;
}
