import Styled from "./checkbox_field.styled";
import type { CheckboxProps } from "@/src/ui/components/fields/checkbox/checkbox";
import Checkbox from "@/src/ui/components/fields/checkbox/checkbox";
import ErrorText from "../../error_displayers/error_text/error_text";

export interface CheckboxFieldProps extends CheckboxProps {
  error?: string;
}
export default function CheckboxField({ error, ...p }: CheckboxFieldProps) {
  return (
    <Styled.Field>
      <Checkbox {...p} />
      {error && (
        <Styled.ErrorText>
          <ErrorText error={error} />
        </Styled.ErrorText>
      )}
    </Styled.Field>
  );
}
