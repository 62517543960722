import type { GetTreatmentsHistoryUseCase } from "@/src/core/treatments/domain/use_cases/get_treatments_history_use_case";
import type { TreatmentsHistorySlice } from "@/src/ui/pages/treatments/view_models/treatments_history.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import { showErrorAlertThunk } from "@/src/ui/state/alerts.slice";

const initialState = (): TreatmentsHistorySlice => ({
  reference: null,
  detail: null,
  hasError: false,
  isLoading: true
});

export const getTreatmentsHistoryThunk = createAsyncThunk("treatmentsHistory.slice/getTreatmentsHistory", async (reference: string, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetTreatmentsHistoryUseCase>>(TYPES.GetTreatmentsHistoryUseCase)();
    return await useCase.execute(reference);
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

const treatmentsHistorySlice = createSlice({
  name: "treatmentsHistory.slice",
  initialState: initialState(),
  reducers: {
    resetTreatmentsHistory: initialState
  },
  extraReducers(builder) {
    builder.addCase(getTreatmentsHistoryThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getTreatmentsHistoryThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getTreatmentsHistoryThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.reference = action.meta.arg;
      state.isLoading = false;
    });
  }
});

function selectTreatmentsHistoryBase(state: RootState) {
  return state.treatmentsHistory;
}

export const selectTreatmentsHistory = createSelector(selectTreatmentsHistoryBase, (slice) => slice.detail);
export const selectTreatmentsHistoryReference = createSelector(selectTreatmentsHistoryBase, (slice) => slice.reference);
export const selectTreatmentsHistoryError = createSelector(selectTreatmentsHistoryBase, (slice) => slice.hasError);
export const selectTreatmentsHistoryIsLoading = createSelector(selectTreatmentsHistoryBase, (slice) => slice.isLoading);

export default treatmentsHistorySlice.reducer;
