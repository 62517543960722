import { useField } from "formik";
import type { InputSizeProps } from "./input.styled";
import Styled from "./input.styled";
import type { HTMLInputTypeAttribute } from "react";
import ErrorText from "../../error_displayers/error_text/error_text";

interface InputProps extends InputSizeProps {
  label?: string;
  name: string;
  onChange(value: string): void;
  placeholder?: string;
  value: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  error?: string;
  autoComplete?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
}

export const Input = ({ label, onChange, placeholder, name, className, error, disabled, value, ...props }: InputProps) => {
  return (
    <Styled.Label className={className} htmlFor={name}>
      {label && <Styled.LabelText disabled={disabled}>{label}</Styled.LabelText>}
      <Styled.Input
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value ?? ""}
        disabled={disabled}
        {...props}
      />
      {error && (
        <Styled.ErrorText>
          <ErrorText error={error} />
        </Styled.ErrorText>
      )}
    </Styled.Label>
  );
};

type InputFormikProps = Omit<InputProps, "onChange" | "value">;

export const InputFormik = ({ name, ...props }: InputFormikProps) => {
  const [field, meta, helpers] = useField({ name });
  return <Input name={name} value={field.value} onChange={helpers.setValue} error={meta.touched ? meta.error : undefined} {...props} />;
};
