import type { ConstructorType } from "@/src/common/interfaces/constructor_type";

export class RemoveBillingAddressFromClinicInput {
  clinicId: string;
  billingAddressId: string;

  constructor(input: ConstructorType<RemoveBillingAddressFromClinicInput>) {
    this.clinicId = input.clinicId;
    this.billingAddressId = input.billingAddressId;
  }
}
