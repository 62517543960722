import { useAppDispatch, useAppSelector } from "@/src/ui/state";
import { useTranslation } from "react-i18next";
import { selectCaseDetailReference } from "../../../case_detail/state/case_detail.slice";
import {
  getCaseFilesThunk,
  selectCaseFiles,
  selectCaseFilesHasError,
  selectCaseFilesIsLoading,
  selectCaseFilesReference
} from "../../../case_files/state/case_files.slice";
import { useCallback, useEffect } from "react";
import Styled from "./case_stl_field.styled";
import BasicSpinner from "@/src/ui/components/loaders/basic_spinner/basic_spinner";
import BasicErrorMessage from "@/src/ui/components/error_displayers/error_messages/basic_error_message/basic_error_message";
import { NoResultsMessage } from "@/src/ui/components/loaders/no_results_message/no_results_message";
import CaseStlFieldInput from "@/src/ui/pages/cases/case_stl/components/case_stl_field_input/case_stl_field_input";
export default function CaseStlField() {
  const { t } = useTranslation("cases", { keyPrefix: "stlField" });

  const dispatch = useAppDispatch();
  const caseReference = useAppSelector(selectCaseDetailReference);
  const filesReference = useAppSelector(selectCaseFilesReference);
  const hasError = useAppSelector(selectCaseFilesHasError);
  const isLoading = useAppSelector(selectCaseFilesIsLoading);
  const results = useAppSelector(selectCaseFiles);

  const reload = useCallback((caseReference: string) => dispatch(getCaseFilesThunk(caseReference)), [dispatch]);

  useEffect(() => {
    if (caseReference && caseReference !== filesReference) reload(caseReference);
  }, [caseReference, filesReference, reload]);

  if (!caseReference) return null;
  return (
    <Styled.Container>
      <Styled.LabelText>{t("label")}</Styled.LabelText>
      {isLoading && <BasicSpinner ariaLabel={t("loading")} />}
      {hasError && <BasicErrorMessage retry={() => reload(caseReference)}>{t("error")}</BasicErrorMessage>}
      {!isLoading && !hasError && Boolean(results.length) && (
        <Styled.Grid>
          {results
            .filter((c) => c.isStl)
            .map((result) => (
              <CaseStlFieldInput key={result.key} file={result} reference={caseReference} />
            ))}
        </Styled.Grid>
      )}
      {!isLoading && !hasError && !results.length && <NoResultsMessage>{t("noResults")}</NoResultsMessage>}
    </Styled.Container>
  );
}
