import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CoursesPageSortSlice } from "@/src/ui/pages/courses/view_models/courses_page_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): CoursesPageSortSlice => ({ value: {} });

const coursesPageSortSlice = createSlice({
  name: "coursesPageSort.slice",
  initialState: initialState(),
  reducers: {
    resetCoursesPageSort: initialState,
    setCoursesPageSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.coursesPageSort;
}

export const selectCoursesPageSort = createSelector(selectBase, (slice) => slice.value);

export const { setCoursesPageSort, resetCoursesPageSort } = coursesPageSortSlice.actions;
export default coursesPageSortSlice.reducer;

export const coursesPageSortColumnNames = [null, "order[status]", "order[createdAt]", "order[updatedAt]"];
