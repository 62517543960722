import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { ClinicOpeningHoursSlice } from "../view_models/clinic_opening_hours.slice";
import type { GetClinicOpeningHoursUseCase } from "@/src/core/clinics/clinic_opening_hours/domain/use_cases/get_clinic_opening_hours_use_case";

const initialState = (): ClinicOpeningHoursSlice => ({
  results: {},
  loadingState: {}
});

export const getClinicOpeningHoursThunk = createAsyncThunk("clinicOpeningHours.slice/get", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetClinicOpeningHoursUseCase>>(TYPES.GetClinicOpeningHoursUseCase)();
  return await useCase.execute(id);
});

const clinicOpeningHoursSlice = createSlice({
  name: "clinicOpeningHours.slice",
  initialState: initialState(),
  reducers: {
    resetClinicOpeningHours: initialState
  },
  extraReducers(builder) {
    builder.addCase(getClinicOpeningHoursThunk.pending, (state, action) => {
      state.loadingState[action.meta.arg] = "loading";
    });
    builder.addCase(getClinicOpeningHoursThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState[action.meta.arg] = "error";
    });
    builder.addCase(getClinicOpeningHoursThunk.fulfilled, (state, action) => {
      state.results[action.meta.arg] = action.payload;
      state.loadingState[action.meta.arg] = "loaded";
    });
  }
});

const selectBase = (state: RootState) => state.clinicOpeningHours;

export const selectClinicOpeningHours = createSelector(selectBase, (slice) => slice.results);
export const selectClinicOpeningHoursLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const { resetClinicOpeningHours } = clinicOpeningHoursSlice.actions;
export default clinicOpeningHoursSlice.reducer;
