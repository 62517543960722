import { useAppSelector } from "@/src/ui/state";
import HorizontalPadding from "@/src/ui/components/spacing/horizontal_padding/horizontal_padding";
import CustomFormFields from "@/src/ui/pages/custom_forms/components/custom_form_fields/custom_form_fields";
import { selectNewCaseCustomFormGroupedFields } from "@/src/ui/pages/cases/publish_case/state/new_case_publish_form.selectors";

export default function NewCaseFormFields() {
  const groupedFields = useAppSelector(selectNewCaseCustomFormGroupedFields);
  return (
    <HorizontalPadding>
      <CustomFormFields groupedFields={groupedFields} />
    </HorizontalPadding>
  );
}
