import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { getInitialCachedPaginationSlice } from "@/src/ui/view_models/slices";
import type { CitiesSlice } from "../view_models/cities.slice";
import type { GetCitiesUseCase } from "@/src/core/cities/domain/use_cases/get_cities_use_case";

const initialState = (): CitiesSlice => getInitialCachedPaginationSlice();

export const getCitiesThunk = createAsyncThunk("cities.slice/get", async (province: string) => {
  const useCase = await locator.get<IocProvider<GetCitiesUseCase>>(TYPES.GetCitiesUseCase)();
  const itemsPerPage = 200;
  let pagination = await useCase.execute({ province, page: 1, itemsPerPage });
  while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({ province, page: pagination.nextPage, itemsPerPage }));
  return pagination;
});

const citiesSlice = createSlice({
  name: "cities.slice",
  initialState: initialState(),
  reducers: {
    resetCities: initialState
  },
  extraReducers(builder) {
    builder.addCase(getCitiesThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getCitiesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getCitiesThunk.fulfilled, (state, action) => {
      state.pagination[action.meta.arg] = action.payload;
      state.isLoading = false;
    });
  }
});

function selectBase(state: RootState) {
  return state.cities;
}

export const selectCities = createSelector(selectBase, (slice) => slice.pagination);
export const selectCitiesError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCitiesIsLoading = createSelector(selectBase, (slice) => slice.isLoading);

export const { resetCities } = citiesSlice.actions;
export default citiesSlice.reducer;
