import type { NewUserClinicSearchSlice } from "@/src/ui/pages/profile/new_profile/new_profile_clinic/view_models/new_profile_clinic_search.slice";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetClinicsUseCase } from "@/src/core/clinics/domain/use_cases/get_clinics_use_case";

const initialState = (): NewUserClinicSearchSlice => ({
  vatNumber: "",
  isLoading: false,
  hasError: false,
  showForm: false
});

export const newProfileClinicSearchThunk = createAsyncThunk("newUserClinicSearch.slice/search", async (_, { getState }) => {
  const useCase = await locator.get<IocProvider<GetClinicsUseCase>>(TYPES.GetClinicsUseCase)();
  return await useCase.execute({ type: "clinic", search: selectNewUserClinicSearchVatNumber(getState() as RootState) });
});

const newUserClinicSearchSlice = createSlice({
  name: "newUserClinicSearch.slice",
  initialState: initialState(),
  reducers: {
    setNewUserClinicSearchVatNumber(state, action: PayloadAction<string>) {
      state.vatNumber = action.payload;
    },
    setNewUserClinicSearchClinic(state, action: PayloadAction<string>) {
      state.clinic = action.payload;
    },
    setNewUserClinicSearchShowForm(state) {
      state.showForm = true;
    },
    resetNewProfileClinicSearch: initialState
  },
  extraReducers(builder) {
    builder.addCase(newProfileClinicSearchThunk.pending, (state) => {
      state.clinic = undefined;
      state.isLoading = true;
      state.hasError = false;
      state.showForm = false;
    });
    builder.addCase(newProfileClinicSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResults = action.payload.results;
      if (!state.searchResults.length) state.showForm = true;
    });
    builder.addCase(newProfileClinicSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.searchResults = undefined;
      state.hasError = true;
    });
  }
});

function selectBase(state: RootState) {
  return state.newUserClinicSearch;
}

export const selectNewUserClinicSearchResults = createSelector(selectBase, (e) => e.searchResults);
export const selectNewUserClinicSearchHasNoResults = createSelector(selectBase, (e) => e.searchResults && !e.searchResults.length);
export const selectNewUserClinicSearchIsLoading = createSelector(selectBase, (e) => e.isLoading);
export const selectNewUserClinicSearchHasError = createSelector(selectBase, (e) => e.hasError);
export const selectNewUserClinicSearchVatNumber = createSelector(selectBase, (e) => e.vatNumber);
export const selectNewUserClinicSearchClinic = createSelector(selectBase, (e) => e.clinic);
export const selectNewUserClinicSearchShowForm = createSelector(selectBase, (e) => e.showForm);

export const { resetNewProfileClinicSearch, setNewUserClinicSearchVatNumber, setNewUserClinicSearchClinic, setNewUserClinicSearchShowForm } =
  newUserClinicSearchSlice.actions;

export default newUserClinicSearchSlice.reducer;
