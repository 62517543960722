import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { Alert, AlertsSlice, ShowAlertPayload } from "@/src/ui/view_models/alerts.slice";
import { createIdFactory } from "@/src/common/utils/id_generator";
import { timeout } from "@front_web_mrmilu/utils";
import type { ReactNode } from "react";
import { createElement } from "react";
import Translate from "../components/text/translate/translate";

const initialState = (): AlertsSlice => ({ alerts: [] });

const createId = createIdFactory();

const defaultTimeout = 4000;

export const showErrorAlertThunk = createAsyncThunk("alerts/showErrorAlert", async (payload: ReactNode | undefined, { dispatch }) => {
  await dispatch(
    showAlertThunk({
      alertType: "error",
      timeout: defaultTimeout,
      message: payload ?? createElement(Translate, { ns: "alerts", i18nKey: "error" })
    })
  );
});

export const showWarningAlertThunk = createAsyncThunk("alerts/showWarningAlert", async (payload: ReactNode | undefined, { dispatch }) => {
  await dispatch(
    showAlertThunk({
      alertType: "warning",
      timeout: defaultTimeout,
      message: payload ?? createElement(Translate, { ns: "alerts", i18nKey: "error" })
    })
  );
});

export const showSuccessAlertThunk = createAsyncThunk("alerts/showSuccessAlert", async (payload: ReactNode | undefined, { dispatch }) => {
  await dispatch(
    showAlertThunk({
      alertType: "success",
      timeout: defaultTimeout,
      message: payload ?? createElement(Translate, { ns: "alerts", i18nKey: "success" })
    })
  );
});

export const showAlertThunk = createAsyncThunk("alerts/showAlert", async (payload: ShowAlertPayload, { dispatch }) => {
  const id = createId();
  dispatch(showAlert({ id, ...payload }));
  if (payload.timeout) {
    await timeout(payload.timeout);
    dispatch(hideAlert(id));
  }
});

const alertsSlice = createSlice({
  name: "alerts.slice",
  initialState: initialState(),
  reducers: {
    showAlert: (state, action: PayloadAction<Alert>) => {
      state.alerts.unshift(action.payload);
    },
    hideAlert: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.filter((a) => a.id !== action.payload);
    },
    clearAlerts: initialState
  }
});

function selectAlertsBase(state: RootState) {
  return state.alerts;
}

export const selectAlerts = createSelector(selectAlertsBase, (slice) => slice.alerts);

const { showAlert } = alertsSlice.actions;
export const { hideAlert, clearAlerts } = alertsSlice.actions;
export default alertsSlice.reducer;
