import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { BillingAddressesPageFiltersSlice } from "@/src/ui/pages/billing/billing_addresses/view_models/billing_addresses_page_filters.slice";
import type { BillingAddressFiltersModel } from "@/src/core/billing/domain/models/billing_address_filters_model";

const initialState = (): BillingAddressesPageFiltersSlice => ({
  show: false,
  filters: {
    name: "",
    dentist: [],
    province: [],
    country: ""
  }
});

const billingAddressesSlice = createSlice({
  name: "billingAddressesPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetBillingAddressesPageFilters: initialState,
    cleanBillingAddressesPageFilters(state) {
      state.filters = initialState().filters;
    },
    setBillingAddressesPageFilters(state, action: PayloadAction<Partial<BillingAddressFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleBillingAddressesPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectBillingAddressesPageFiltersBase(state: RootState) {
  return state.billingAddressesPageFilters;
}

export const selectShowBillingAddressesPageFilters = createSelector(selectBillingAddressesPageFiltersBase, (slice) => slice.show);
export const selectBillingAddressesPageFilters = createSelector(selectBillingAddressesPageFiltersBase, (slice) => slice.filters);
export const selectBillingAddressesPageFiltersName = createSelector(selectBillingAddressesPageFilters, (filters) => filters.name);
export const {
  resetBillingAddressesPageFilters,
  cleanBillingAddressesPageFilters,
  setBillingAddressesPageFilters,
  toggleBillingAddressesPageFilters
} = billingAddressesSlice.actions;
export default billingAddressesSlice.reducer;
