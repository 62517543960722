import FormTitle from "@/src/ui/components/text/form_title/form_title";
import { NoResultsMessage } from "@/src/ui/components/loaders/no_results_message/no_results_message";
import { colors } from "@/src/ui/styles/colors";
import { spacing } from "@/src/ui/styles/spacing";
import { typography } from "@/src/ui/styles/typography";
import { px2rem, truncateText } from "@/src/ui/styles/utils";
import styled from "styled-components";
const Container = styled.div`
  flex: 1 1 100%;
  min-width: 0;
  margin-bottom: ${px2rem(22)};
`;

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing.xs};
  margin-top: ${spacing.xs};
  @media (min-width: 1450px) {
    grid-template-columns: 1fr;
    position: absolute;
    width: ${px2rem(173)};
    transform: translateX(${px2rem(-215)});
  }
`;
const Section = styled.section`
  margin-top: ${spacing.xs};
`;
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${px2rem(7)};
  }
`;
const SectionTitle = styled.h4`
  flex: 1 1 100%;
  min-width: 0;
  ${typography.medium}
  ${truncateText}
`;
const TitleIcon = styled.div`
  flex: 0 0 min-content;
  > svg {
    height: ${px2rem(18)};
    width: ${px2rem(18)};
    * {
      color: ${colors.brand500};
    }
  }
`;
const HelpText = styled.p`
  margin-top: ${spacing.s};
  ${typography.small};
`;
const Title = styled(FormTitle)`
  margin-bottom: 0;
`;
const Spacer10 = styled.div`
  height: ${px2rem(10)};
`;
const SectionFieldsBox = styled.div`
  padding-left: ${px2rem(25)};
`;
const NoResults = styled(NoResultsMessage)`
  > p {
    ${typography.xSmall}
    color: ${colors.neutral700};
  }
  margin-top: ${px2rem(22)};
`;
export default {
  Container,
  Section,
  SectionFieldsBox,
  HelpText,
  Checkboxes,
  Title,
  TitleBox,
  SectionTitle,
  TitleIcon,
  Spacer10,
  NoResults
};
