import type { IEnvVars } from "../interfaces/env_vars";
import { injectable } from "inversify";
import type { NodeEnv } from "@/src/core/app/domain/models/node_env";

@injectable()
export class EnvVars implements IEnvVars {
  serverUrl: string = process.env.REACT_APP_API_URL ?? "localhost:3000";

  nodeEnv = process.env.NODE_ENV as NodeEnv;

  sentryDSN?: string = process.env.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;

  sentryEnabled: boolean = process.env.REACT_APP_SENTRY_ENABLED === "true";
  surgeryUrl = process.env.REACT_APP_SURGERY_URL;
}
