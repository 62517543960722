import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { OptionsSlice } from "@/src/ui/pages/options/view_models/options.slice";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetGenderOptionsUseCase } from "@/src/core/options/domain/use_cases/get_gender_options_use_case";
import type { GetReferralSourceOptionsUseCase } from "@/src/core/options/domain/use_cases/get_referral_source_options_use_case";
import type { GetClientTypesOptionsUseCase } from "@/src/core/options/domain/use_cases/get_client_types_options_use_case";
import type { GetPhaseOptionsUseCase } from "@/src/core/options/domain/use_cases/get_phase_options_use_case";
import type { GetStatusOptionsUseCase } from "@/src/core/options/domain/use_cases/get_status_options_use_case";
import type { GetBusinessNameOptionsUseCase } from "@/src/core/options/domain/use_cases/get_business_name_options_use_case";
import type { GetPaymentTypeOptionsUseCase } from "@/src/core/options/domain/use_cases/get_payment_type_options_use_case";
import type { GetPaymentTypeChildrenOptionsUseCase } from "@/src/core/options/domain/use_cases/get_payment_type_children_options_use_case";
import type { GetRangeAgeOptionsUseCase } from "@/src/core/options/domain/use_cases/get_range_age_options_use_case";

const initialState = (): OptionsSlice => ({
  gendersError: false,
  clientTypesError: false,
  referralSourcesError: false,
  phasesError: false,
  statusesError: false,
  businessNamesError: false,
  paymentTypesError: false,
  paymentTypeChildrenError: false,
  rangeAgesError: false,
  paymentTypeChildren: {}
});

export const getGenderOptionsThunk = createAsyncThunk("options.slice/getGenders", async () => {
  const useCase = await locator.get<IocProvider<GetGenderOptionsUseCase>>(TYPES.GetGenderOptionsUseCase)();
  return await useCase.execute();
});
export const getReferralSourcesOptionsThunk = createAsyncThunk("options.slice/getReferralSources", async () => {
  const useCase = await locator.get<IocProvider<GetReferralSourceOptionsUseCase>>(TYPES.GetReferralSourceOptionsUseCase)();
  return await useCase.execute();
});
export const getClientTypesOptionsThunk = createAsyncThunk("options.slice/getClientTypes", async () => {
  const useCase = await locator.get<IocProvider<GetClientTypesOptionsUseCase>>(TYPES.GetClientTypesOptionsUseCase)();
  return await useCase.execute();
});
export const getPhaseOptionsThunk = createAsyncThunk("options.slice/getPhases", async () => {
  const useCase = await locator.get<IocProvider<GetPhaseOptionsUseCase>>(TYPES.GetPhaseOptionsUseCase)();
  return await useCase.execute();
});

export const getStatusOptionsThunk = createAsyncThunk("options.slice/getStatuses", async () => {
  const useCase = await locator.get<IocProvider<GetStatusOptionsUseCase>>(TYPES.GetStatusOptionsUseCase)();
  return await useCase.execute();
});

export const getBusinessNameOptionsThunk = createAsyncThunk("options.slice/getBusinessNames", async () => {
  const useCase = await locator.get<IocProvider<GetBusinessNameOptionsUseCase>>(TYPES.GetBusinessNameOptionsUseCase)();
  return await useCase.execute();
});

export const getPaymentTypeOptionsThunk = createAsyncThunk("options.slice/getPaymentTypes", async () => {
  const useCase = await locator.get<IocProvider<GetPaymentTypeOptionsUseCase>>(TYPES.GetPaymentTypeOptionsUseCase)();
  return await useCase.execute();
});

export const getPaymentTypeChildrenOptionsThunk = createAsyncThunk("options.slice/getPaymentTypeChildren", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetPaymentTypeChildrenOptionsUseCase>>(TYPES.GetPaymentTypeChildrenOptionsUseCase)();
  return await useCase.execute(id);
});

export const getRangeAgeOptionsThunk = createAsyncThunk("options.slice/getRangeAges", async () => {
  const useCase = await locator.get<IocProvider<GetRangeAgeOptionsUseCase>>(TYPES.GetRangeAgeOptionsUseCase)();
  return await useCase.execute();
});

const optionsSlice = createSlice({
  name: "options.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getGenderOptionsThunk.pending, (state) => {
      state.gendersError = false;
    });
    builder.addCase(getGenderOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.gendersError = true;
    });
    builder.addCase(getGenderOptionsThunk.fulfilled, (state, action) => {
      state.genders = action.payload;
    });

    builder.addCase(getReferralSourcesOptionsThunk.pending, (state) => {
      state.referralSourcesError = false;
    });
    builder.addCase(getReferralSourcesOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.referralSourcesError = true;
    });
    builder.addCase(getReferralSourcesOptionsThunk.fulfilled, (state, action) => {
      state.referralSources = action.payload;
    });

    builder.addCase(getClientTypesOptionsThunk.pending, (state) => {
      state.clientTypesError = false;
    });
    builder.addCase(getClientTypesOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.clientTypesError = true;
    });
    builder.addCase(getClientTypesOptionsThunk.fulfilled, (state, action) => {
      state.clientTypes = action.payload;
    });

    builder.addCase(getPhaseOptionsThunk.pending, (state) => {
      state.phasesError = false;
    });
    builder.addCase(getPhaseOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.phasesError = true;
    });
    builder.addCase(getPhaseOptionsThunk.fulfilled, (state, action) => {
      state.phases = action.payload;
    });

    builder.addCase(getStatusOptionsThunk.pending, (state) => {
      state.statusesError = false;
    });
    builder.addCase(getStatusOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.statusesError = true;
    });
    builder.addCase(getStatusOptionsThunk.fulfilled, (state, action) => {
      state.statuses = action.payload;
    });

    builder.addCase(getBusinessNameOptionsThunk.pending, (state) => {
      state.businessNamesError = false;
    });
    builder.addCase(getBusinessNameOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.businessNamesError = true;
    });
    builder.addCase(getBusinessNameOptionsThunk.fulfilled, (state, action) => {
      state.businessNames = action.payload;
    });

    builder.addCase(getPaymentTypeOptionsThunk.pending, (state) => {
      state.paymentTypesError = false;
    });
    builder.addCase(getPaymentTypeOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.paymentTypesError = true;
    });
    builder.addCase(getPaymentTypeOptionsThunk.fulfilled, (state, action) => {
      state.paymentTypes = action.payload;
    });

    builder.addCase(getPaymentTypeChildrenOptionsThunk.pending, (state) => {
      state.paymentTypeChildrenError = false;
    });
    builder.addCase(getPaymentTypeChildrenOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.paymentTypeChildrenError = true;
    });
    builder.addCase(getPaymentTypeChildrenOptionsThunk.fulfilled, (state, action) => {
      state.paymentTypeChildren[action.meta.arg] = action.payload;
    });

    builder.addCase(getRangeAgeOptionsThunk.pending, (state) => {
      state.rangeAgesError = false;
    });
    builder.addCase(getRangeAgeOptionsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.rangeAgesError = true;
    });
    builder.addCase(getRangeAgeOptionsThunk.fulfilled, (state, action) => {
      state.rangeAges = action.payload;
    });
  }
});

function selectOptionsBase(state: RootState) {
  return state.options;
}

export const selectGenderOptions = createSelector(selectOptionsBase, (slice) => slice.genders);
export const selectGenderOptionsError = createSelector(selectOptionsBase, (slice) => slice.gendersError);
export const selectReferralSourcesOptions = createSelector(selectOptionsBase, (slice) => slice.referralSources);
export const selectReferralSourcesOptionsError = createSelector(selectOptionsBase, (slice) => slice.referralSourcesError);
export const selectClientTypesOptions = createSelector(selectOptionsBase, (slice) => slice.clientTypes);
export const selectClientTypesOptionsError = createSelector(selectOptionsBase, (slice) => slice.clientTypesError);

export const selectPhasesOptionsError = createSelector(selectOptionsBase, (slice) => slice.phasesError);
export const selectPhasesOptions = createSelector(selectOptionsBase, (slice) => slice.phases);

export const selectStatusesOptionsError = createSelector(selectOptionsBase, (slice) => slice.statusesError);
export const selectStatusesOptions = createSelector(selectOptionsBase, (slice) => slice.statuses);

export const selectBusinessNamesOptionsError = createSelector(selectOptionsBase, (slice) => slice.businessNamesError);
export const selectBusinessNamesOptions = createSelector(selectOptionsBase, (slice) => slice.businessNames);

export const selectPaymentTypesOptionsError = createSelector(selectOptionsBase, (slice) => slice.paymentTypesError);
export const selectPaymentTypesOptions = createSelector(selectOptionsBase, (slice) => slice.paymentTypes);

export const selectPaymentTypeChildrenOptionsError = createSelector(selectOptionsBase, (slice) => slice.paymentTypeChildrenError);
export const selectPaymentTypeChildrenOptions = createSelector(selectOptionsBase, (slice) => slice.paymentTypeChildren);

export const selectRangeAgesOptionsError = createSelector(selectOptionsBase, (slice) => slice.rangeAgesError);
export const selectRangeAgesOptions = createSelector(selectOptionsBase, (slice) => slice.rangeAges);

export default optionsSlice.reducer;
