import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { AddDentistSlice } from "@/src/ui/pages//dentists/add_dentist/view_models/add_dentist.slice";
import type { GetDentistsUseCase } from "@/src/core/dentists/domain/use_cases/get_dentists_use_case";

const initialState = (): AddDentistSlice => ({
  collegiateNumber: "",
  isLoading: false,
  hasError: false
});

export const addDentistSearchThunk = createAsyncThunk("addDentist.slice/search", async (_, { getState }) => {
  const {
    addDentist: { collegiateNumber }
  } = getState() as RootState;

  const useCase = await locator.get<IocProvider<GetDentistsUseCase>>(TYPES.GetDentistsUseCase)();
  return await useCase.execute({ collegiateNumber });
});

const addDentistSlice = createSlice({
  name: "newClinic.slice",
  initialState: initialState(),
  reducers: {
    setAddDentistCollegiateNumber(state, action: PayloadAction<string>) {
      state.collegiateNumber = action.payload;
    },
    resetAddDentist: initialState
  },
  extraReducers(builder) {
    builder.addCase(addDentistSearchThunk.pending, (state) => {
      state.searchResults = undefined;
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(addDentistSearchThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.searchResults = action.payload.results;
    });
    builder.addCase(addDentistSearchThunk.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
      state.searchResults = undefined;
      state.hasError = true;
    });
  }
});

function selectAddDentistBase(state: RootState) {
  return state.addDentist;
}

export const selectAddDentistSearchResults = createSelector(selectAddDentistBase, (e) => e.searchResults);
export const selectAddDentistSearchHasNoResults = createSelector(selectAddDentistSearchResults, (results) => results && results.length === 0);
export const selectAddDentistSearchIsLoading = createSelector(selectAddDentistBase, (e) => e.isLoading);
export const selectAddDentistHasError = createSelector(selectAddDentistBase, (e) => e.hasError);
export const selectAddDentistCollegiateNumber = createSelector(selectAddDentistBase, (e) => e.collegiateNumber);

export const { resetAddDentist, setAddDentistCollegiateNumber } = addDentistSlice.actions;

export default addDentistSlice.reducer;
