import Styled from "@/src/ui/components/fields/input/input.styled";
import DateStyled from "./date_field.styled";
import { renderToStaticMarkup } from "react-dom/server";
import { Calendar } from "@/src/ui/assets/icons";
import ErrorText from "../../error_displayers/error_text/error_text";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

export interface DateFieldProps {
  label?: string;
  error?: string;
  name: string;
  min?: Date;
  max?: Date;
  placeholder?: string;
  value: Date | null;
  onChange(value: Date | null): void;
  className?: string;
}

const svgCalendarString = encodeURIComponent(renderToStaticMarkup(<Calendar />));

function isValidDate(d: Date | null) {
  return d instanceof Date && !isNaN(d.getTime());
}

export default function DateField({ onChange, placeholder, value, label, error, name, className, min, max }: DateFieldProps) {
  const { i18n } = useTranslation();
  registerLocale("es", es);
  registerLocale("fr", fr);
  return (
    <Styled.Label className={className} htmlFor={name}>
      {label && <Styled.LabelText>{label}</Styled.LabelText>}
      <DateStyled.DateField icon={svgCalendarString}>
        <DatePicker
          onChange={(date) => {
            date && isValidDate(date) ? onChange(date) : onChange(null);
          }}
          name={name}
          placeholderText={placeholder}
          selected={isValidDate(value) ? value : null}
          minDate={min}
          maxDate={max}
          locale={i18n.language}
          dateFormat="dd MMMM yyyy"
          onChangeRaw={(e) => e.preventDefault()}
          isClearable
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
        />
      </DateStyled.DateField>
      {error && (
        <Styled.ErrorText>
          <ErrorText error={error} />
        </Styled.ErrorText>
      )}
    </Styled.Label>
  );
}
