import { Trans, useTranslation } from "react-i18next";

interface Props {
  ns: string;
  i18nKey: string;
  values?: object;
}
// Solves infinite type instantiation error
export default function Translate({ ns, ...props }: Props) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t } = useTranslation(ns);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Trans {...props} t={t} />;
}
