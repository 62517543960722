import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { ClinicDetailSlice } from "../view_models/clinic_detail.slice";
import type { GetClinicDetailUseCase } from "@/src/core/clinics/domain/use_cases/get_clinic_detail_use_case";
import type { ClinicDetailModel } from "@/src/core/clinics/domain/models/clinic_detail_model";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { serializeError } from "@/src/common/utils/rtk";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import type { RemoveClinicFromClinicGroupUseCase } from "@/src/core/clinics/domain/use_cases/remove_clinic_from_clinic_group_use_case";

const initialState = (): ClinicDetailSlice => getInitialDetailSlice();

export const getClinicDetailThunk = createAsyncThunk(
  "clinicDetail.slice/get",
  async (clinicId: string) => {
    const useCase = await locator.get<IocProvider<GetClinicDetailUseCase>>(TYPES.GetClinicDetailUseCase)();
    return useCase.execute(clinicId);
  },
  { serializeError }
);

export const removeClinicFromClinicGroupThunk = createAsyncThunk(
  "clinicDetail.slice/removeClinicFromClinicGroup",
  async (clinicId: string, { dispatch, getState }) => {
    try {
      const clinicGroupId = selectClinicDetail(getState() as RootState)?.id;
      if (!clinicGroupId) throw new Error("Clinic ID not found");

      const useCase = await locator.get<IocProvider<RemoveClinicFromClinicGroupUseCase>>(TYPES.RemoveClinicFromClinicGroupUseCase)();
      await useCase.execute({ clinic: clinicId, clinicGroup: clinicGroupId });

      await dispatch(getClinicDetailThunk(clinicGroupId));
      dispatch(showSuccessAlertThunk());
    } catch (error) {
      console.error(error);
      dispatch(showErrorAlertThunk());
    }
  }
);

const clinicDetailSlice = createSlice({
  name: "clinicDetail.slice",
  initialState: initialState(),
  reducers: {
    resetClinicDetail: initialState,
    setClinicDetail(state, action: PayloadAction<ClinicDetailModel>) {
      state.detail = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getClinicDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getClinicDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getClinicDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

export const selectClinicDetailBase = (state: RootState) => state.clinicDetail;
export const selectClinicDetailLoadingState = createSelector(selectClinicDetailBase, (slice) => slice.loadingState);

export const selectClinicDetail = createSelector(selectClinicDetailBase, (slice) => slice.detail);
export const selectClinicDetailIsOwn = createSelector(selectClinicDetail, (detail) => Boolean(detail?.isOwn));
export const { resetClinicDetail, setClinicDetail } = clinicDetailSlice.actions;
export default clinicDetailSlice.reducer;
