import { createElement, useEffect, useRef, useState } from "react";
import type { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import placeholder from "@/src/ui/assets/images/placeholder_s.jpg";

export interface SafeImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  // Image to be shown if `src` is empty or invalid
  placeholderSrc?: string;
}

/**
 * Image that shows `placeholderImage` if `src` is empty or invalid
 */
export default function SafeImage({ src, placeholderSrc = placeholder, ...props }: SafeImageProps) {
  const [invalid, setInvalid] = useState(false); // Controls whether current src is invalid

  const prevSrc = useRef(src);
  useEffect(() => {
    if (prevSrc.current !== src) {
      setInvalid(false); // if src changes, the new src might not be invalid.
      prevSrc.current = src;
    }
  }, [src]);

  return createElement("img", {
    src: invalid ? placeholderSrc : src || placeholderSrc,
    onError: () => setInvalid(true),
    ...props
  });
}
