import type { GetTreatmentsUseCase } from "@/src/core/treatments/domain/use_cases/get_treatments_use_case";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import type { TreatmentsSlice } from "@/src/ui/pages/treatments/view_models/treatments.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { ApproveTreatmentModel } from "@/src/core/treatments/domain/models/approve_treatment_model";
import type { ApproveTreatmentUseCase } from "@/src/core/treatments/domain/use_cases/approve_treatment_use_case";
import { showErrorAlertThunk, showSuccessAlertThunk } from "@/src/ui/state/alerts.slice";
import { getCaseDetailThunk } from "../../cases/case_detail/state/case_detail.slice";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import type { GetTreatmentInputModel } from "@/src/core/treatments/domain/models/get_treatment_input_model";
import type { GetTreatmentButtonsUseCase } from "@/src/core/treatments/domain/use_cases/get_treatment_buttons_use_case";
import { getTreatmentsHistoryThunk } from "./treatments_history.slice";
import { getCaseActivityLogsThunk } from "../../cases/case_detail/case_detail_activities/state/case_activity_logs.slice";

const initialState = (): TreatmentsSlice => ({
  ...getInitialPaginationSlice(),
  reference: null,
  shownTreatmentId: null,
  actionButtons: null
});

export const getTreatmentsThunk = createAsyncThunk("treatments.slice/get", async (reference: string, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetTreatmentsUseCase>>(TYPES.GetTreatmentsUseCase)();
    let pagination = await useCase.execute({ reference, page: 1 });
    while (pagination.hasNextPage) pagination = pagination.combine(await useCase.execute({ reference, page: pagination.nextPage }));
    return pagination;
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

export const approveTreatmentThunk = createAsyncThunk("treatments.slice/approve", async (input: ApproveTreatmentModel, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<ApproveTreatmentUseCase>>(TYPES.ApproveTreatmentUseCase)();
    await useCase.execute(input);
    await Promise.all([
      dispatch(getCaseDetailThunk(input.reference)),
      dispatch(getTreatmentsThunk(input.reference)),
      dispatch(getTreatmentsHistoryThunk(input.reference)),
      dispatch(getCaseActivityLogsThunk(input.reference)),
      dispatch(getTreatmentsButtonsThunk({ caseReference: input.reference, treatmentId: input.id }))
    ]);
    dispatch(showSuccessAlertThunk());
  } catch (e) {
    console.error(e);
    dispatch(showErrorAlertThunk());
  }
});

export const getTreatmentsButtonsThunk = createAsyncThunk("treatments.slice/buttons", async (input: GetTreatmentInputModel, { dispatch }) => {
  try {
    const useCase = await locator.get<IocProvider<GetTreatmentButtonsUseCase>>(TYPES.GetTreatmentButtonsUseCase)();
    return await useCase.execute(input);
  } catch (e) {
    dispatch(showErrorAlertThunk());
    throw e;
  }
});

const treatmentsSlice = createSlice({
  name: "treatments.slice",
  initialState: initialState(),
  reducers: {
    resetTreatments: initialState,
    setShownTreatmentId: (state, action: PayloadAction<string>) => {
      state.shownTreatmentId = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getTreatmentsThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getTreatmentsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getTreatmentsThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.reference = action.meta.arg;
      if (!state.shownTreatmentId) state.shownTreatmentId = action.payload.results[0]?.id ?? null;
      state.loadingState = "loaded";
    });
    builder.addCase(getTreatmentsButtonsThunk.fulfilled, (state, action) => {
      state.actionButtons = action.payload;
    });
  }
});

function selectBase(state: RootState) {
  return state.treatments;
}

export const selectTreatments = createSelector(selectBase, (slice) => slice.pagination);
export const selectTreatmentsHasResults = createSelector(selectTreatments, (p) => p?.hasResults);
export const selectTreatmentIds = createSelector(selectTreatments, (p) => p?.results.map((t) => t.id) ?? []);
export const selectTreatmentsReference = createSelector(selectBase, (slice) => slice.reference);
export const selectTreatmentsLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectShownTreatmentId = createSelector(selectBase, (slice) => slice.shownTreatmentId);
export const selectTreatmentsButtons = createSelector(selectBase, (slice) => slice.actionButtons);
export const selectShownTreatment = createSelector(
  selectShownTreatmentId,
  selectTreatments,
  (id, treatments) => treatments?.results.find((t) => t.id === id) ?? null
);

export const { setShownTreatmentId } = treatmentsSlice.actions;

export default treatmentsSlice.reducer;
