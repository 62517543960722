import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { DentistsPageFiltersSlice } from "@/src/ui/pages/dentists/view_models/dentists_page_filters.slice";
import type { DentistSearchModel } from "@/src/core/dentists/domain/models/dentist_search_model";

const initialState = (): DentistsPageFiltersSlice => ({
  show: false,
  filters: {
    name: "",
    collegiateNumber: "",
    email: "",
    telephone: "",
    "client-type": "",
    "referral-source": "",
    clinic: "",
    promoter: "",
    distributor: "",
    "createdAt[before]": null,
    "createdAt[after]": null
  }
});

const dentistsSlice = createSlice({
  name: "dentistsPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetDentistsPageFilters: initialState,
    cleanDentistsPageFilters(state) {
      state.filters = initialState().filters;
    },
    setDentistsPageFilters(state, action: PayloadAction<Partial<DentistSearchModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleDentistsPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectDentistsPageFiltersBase(state: RootState) {
  return state.dentistsPageFilters;
}

export const selectDentistsPageFilters = createSelector(selectDentistsPageFiltersBase, (slice) => slice.filters);
export const selectDentistsPageFiltersName = createSelector(selectDentistsPageFilters, (filters) => filters.name);
export const selectShowDentistsPageFilters = createSelector(selectDentistsPageFiltersBase, (slice) => slice.show);
export const { resetDentistsPageFilters, setDentistsPageFilters, cleanDentistsPageFilters, toggleDentistsPageFilters } = dentistsSlice.actions;
export default dentistsSlice.reducer;
