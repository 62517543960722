import ErrorText from "../../error_displayers/error_text/error_text";
import type { TextareaSizeProps } from "./textarea.styled";
import Styled from "./textarea.styled";

export interface TextareaProps extends TextareaSizeProps {
  label?: string;
  name: string;
  onChange(value: string): void;
  placeholder?: string;
  value: string;
  className?: string;
  error?: string;
  autoComplete?: string;
}

export const Textarea = ({ value, label, onChange, height, placeholder, name, className, error, autoComplete }: TextareaProps) => {
  return (
    <Styled.Label className={className} htmlFor={name}>
      {label && <Styled.LabelText>{label}</Styled.LabelText>}
      <Styled.Input
        name={name}
        placeholder={placeholder}
        value={value}
        height={height}
        onChange={(e) => onChange(e.target.value)}
        autoComplete={autoComplete}
      />
      {error && (
        <Styled.ErrorText>
          <ErrorText error={error} />
        </Styled.ErrorText>
      )}
    </Styled.Label>
  );
};
