import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import Styled from "./custom_field.styled";
import CustomTextField from "@/src/ui/pages/custom_forms/components/fields/custom_text_field";
import CustomTextareaField from "@/src/ui/pages/custom_forms/components/fields/custom_textarea_field";
import CustomCheckboxField from "@/src/ui/pages/custom_forms/components/fields/custom_checkbox_field";
import CustomCheckboxesField from "@/src/ui/pages/custom_forms/components/fields/custom_checkboxes_field";
import CustomDateField from "@/src/ui/pages/custom_forms/components/fields/custom_date_field";
import CustomNumberField from "@/src/ui/pages/custom_forms/components/fields/custom_number_field";
import CustomRadioField from "@/src/ui/pages/custom_forms/components/fields/custom_radio_field";
import CustomSelectField from "@/src/ui/pages/custom_forms/components/fields/custom_select_field";
import CustomSelectFieldMultiple from "@/src/ui/pages/custom_forms/components/fields/custom_select_field_multiple";
import CustomFilesField from "@/src/ui/pages/custom_forms/components/fields/custom_files_field";
import CustomTitleField from "@/src/ui/pages/custom_forms/components/fields/custom_title_field";
import CustomTeethField from "../fields/custom_teeth_field";
import CustomPathologySolutionField from "../fields/custom_pathology_solution_field";
import CustomStlField from "../fields/custom_stl_field";
import CustomFileField from "../fields/custom_file_field";
import CustomCheckboxUrlField from "../fields/custom_checkbox_url_field";

const componentMap: Record<CustomFieldModel["type"], unknown> = {
  "checkbox-url": CustomCheckboxUrlField,
  checkbox: CustomCheckboxField,
  checkboxes: CustomCheckboxesField,
  date: CustomDateField,
  files: CustomFilesField,
  number: CustomNumberField,
  radio: CustomRadioField,
  select: CustomSelectField,
  selectMultiple: CustomSelectFieldMultiple,
  teeth: CustomTeethField,
  text: CustomTextField,
  textarea: CustomTextareaField,
  title: CustomTitleField,
  pathologySolution: CustomPathologySolutionField,
  "files-stl": CustomStlField,
  file: CustomFileField
};

interface Props {
  field: CustomFieldModel;
}

export default function CustomField({ field }: Props) {
  const Component = componentMap[field.type];
  if (!Component) return null;
  return (
    <Styled.ColumnSpan columnSpan={field.display && ("edit" in field.display ? field.display.edit.columnSpan : field.display.columnSpan)}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Component field={field} />
    </Styled.ColumnSpan>
  );
}
