import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { DentistsPageSortSlice } from "../view_models/dentists_page_sort.slice";
import type { SortingModel } from "@/src/core/app/domain/models/sorting.model";

const initialState = (): DentistsPageSortSlice => ({ value: {} });

const dentistsPageSortSlice = createSlice({
  name: "dentistsPageSort.slice",
  initialState: initialState(),
  reducers: {
    resetDentistsPageSort: initialState,
    setDentistsPageSort(state, action: PayloadAction<SortingModel>) {
      state.value = action.payload;
    }
  }
});

function selectBase(state: RootState) {
  return state.dentistsPageSort;
}

export const selectDentistsPageSort = createSelector(selectBase, (slice) => slice.value);

export const { setDentistsPageSort, resetDentistsPageSort } = dentistsPageSortSlice.actions;
export default dentistsPageSortSlice.reducer;

export const dentistsPageSortColumnNames = [
  "order[profile.name]",
  "order[collegiateNumber]",
  "order[numCreatedCases]",
  "order[numFinishedCases]",
  "order[numInvoiceCases]",
  "order[amountInvoicedCases]",
  "order[updatedAt]"
];
