import styled from "styled-components";
import { spacing } from "@/src/ui/styles/spacing";
import { typography } from "@/src/ui/styles/typography";

const Label = styled.label`
  display: block;
  width: 100%;
`;
const Paragraph = styled.p`
  ${typography.medium}
  margin-bottom: ${spacing.xs};
`;

export default { Label, Paragraph };
