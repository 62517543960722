import { createSelector } from "@reduxjs/toolkit";
import { selectTeeth } from "./teeth.slice";

export const selectTeethNameToIdMap = createSelector(selectTeeth, (teeth) => {
  const map: Record<string, string> = {};
  for (const t of teeth) {
    map[t.name] = t.id;
  }
  return map;
});
export const selectTeethIdToNameMap = createSelector(selectTeeth, (teeth) => {
  const map: Record<string, string> = {};
  for (const t of teeth) {
    map[t.id] = t.name;
  }
  return map;
});
