import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { TreatmentDetailSlice } from "../view_models/treatment_detail.slice";
import { getInitialDetailSlice } from "@/src/ui/view_models/slices";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { serializeError } from "@/src/common/utils/rtk";
import type { GetTreatmentUseCase } from "@/src/core/treatments/domain/use_cases/get_treatment_use_case";
import type { GetTreatmentInputModel } from "@/src/core/treatments/domain/models/get_treatment_input_model";

const initialState = (): TreatmentDetailSlice => getInitialDetailSlice()

export const getTreatmentDetailThunk = createAsyncThunk("treatmentDetail.slice/get", async (input: GetTreatmentInputModel) => {
  const useCase = await locator.get<IocProvider<GetTreatmentUseCase>>(TYPES.GetTreatmentUseCase)();
  return useCase.execute(input);
}, { serializeError });

const treatmentDetailSlice = createSlice({
  name: "treatmentDetail.slice",
  initialState: initialState(),
  reducers: {
    resetTreatmentDetail: initialState,
  },
  extraReducers(builder) {
    builder.addCase(getTreatmentDetailThunk.pending, (state) => {
      state.loadingState = "loading";
    });
    builder.addCase(getTreatmentDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getTreatmentDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.loadingState = "loaded";
    });
  }
});

export const selectTreatmentDetailBase = (state: RootState) => state.treatmentDetail;
export const selectTreatmentDetail = createSelector(selectTreatmentDetailBase, (slice) => slice.detail);
export const selectTreatmentDetailLoadingState = createSelector(selectTreatmentDetailBase, (slice) => slice.loadingState);

export const { resetTreatmentDetail } = treatmentDetailSlice.actions;
export default treatmentDetailSlice.reducer;
