import Styled from "./teeth_select_field.styled";
import FormTitle from "@/src/ui/components/text/form_title/form_title";
import type { TeethSelectProps } from "@/src/ui/pages/teeth/components/teeth_select/teeth_select";
import TeethSelectFieldLoader from "../teeth_select_field_loader/teeth_select_field_loader";

interface Props extends Omit<TeethSelectProps, "teethNameToIdMap" | "teethIdToNameMap"> {
  name: string;
  label: string;
  paragraph: string;
}
export default function TeethSelectField({ name, label, paragraph, ...p }: Props) {
  return (
    <Styled.Label htmlFor={name}>
      <FormTitle>{label}</FormTitle>
      <Styled.Paragraph>{paragraph}</Styled.Paragraph>
      <TeethSelectFieldLoader {...p} />
    </Styled.Label>
  );
}
