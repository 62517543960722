import type { CustomCheckboxUrlFieldModel } from "@/src/core/custom_forms/domain/models/fields/custom_checkbox_url_field.model";
import CheckboxFieldFormik from "@/src/ui/components/fields/checkbox_field/checkbox_field.formik";
import { colors } from "@/src/ui/styles/colors";
import styled from "styled-components";

const A = styled.a`
  color: ${colors.neutral700};
  text-decoration: underline;
  &:hover {
    color: ${colors.brand500};
  }
`;

interface Props {
  field: CustomCheckboxUrlFieldModel;
}

export default function CustomCheckboxUrlField({ field: { name, display, label, helpText } }: Props) {
  const url = (display.edit && (display.edit.url)) ?? display.url
  return (
    <CheckboxFieldFormik
      name={name}
      label={
        <>
          {label}
          <A href={url} target="_blank" rel="noopener noreferrer">
            {helpText}
          </A>
        </>
      }
    />
  );
}
