import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CourseAttendeesSortSlice } from "../view_models/course_attendees_sort.slice";
import type { SortingState } from "@tanstack/react-table";

const initialState = (): CourseAttendeesSortSlice => ({ value: [] });

const courseAttendeesSortSlice = createSlice({
  name: "courseAttendeesSort.slice",
  initialState: initialState(),
  reducers: {
    resetCourseAttendeesSort: initialState,
    setCourseAttendeesSort(state, action: PayloadAction<SortingState>) {
      state.value = action.payload
    }
  }
});

function selectBase(state: RootState) {
  return state.courseAttendeesSort;
}

export const selectCourseAttendeesSort = createSelector(selectBase, (slice) => slice.value);
export const { setCourseAttendeesSort, resetCourseAttendeesSort } = courseAttendeesSortSlice.actions;
export default courseAttendeesSortSlice.reducer;
