import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";
import { borderRadius } from "@/src/ui/styles/border_radius";

export interface Props {
  isDisabled?: boolean;
}

const SelectMultipleInput = styled.div<Props>`
  .Select {
    &__control {
      background-color: ${colors.neutral200};
      border-radius: ${px2rem(4)} ${px2rem(4)} 0 0;
      border-top-style: unset;
      border-left-style: unset;
      border-right-style: unset;
      border-bottom: solid 1px ${colors.neutral700};
      box-shadow: 0 1px 0 ${colors.neutral700};
      cursor: pointer;
      &--is-focused {
        outline: none;
        border-top-style: unset;
        border-left-style: unset;
        border-right-style: unset;
        border-bottom: solid 1px ${colors.brand500};
        box-shadow: 0 1px 0 ${colors.brand500};
      }
      &:hover {
        border-bottom: solid 1px ${colors.neutral700};
      }
    }
    &__placeholder {
      ${typography.small}
      color: ${colors.neutral500};
    }
    &__indicators {
      width: ${px2rem(26)};
      > svg {
        width: ${px2rem(20)};
      }
    }
    &__control--is-disabled {
      pointer-events: none;
      color: ${colors.neutral400};
      background-color: ${colors.neutral200};
      border-bottom: solid 1px ${colors.neutral400};
      box-shadow: 0 1px 0 ${colors.neutral400};
    }
    &__single-value--is-disabled {
      color: ${colors.neutral400}!important;
    }
    &__menu {
      background-color: ${colors.neutral200};
      border-radius: ${px2rem(4)};
      box-shadow: unset;
      color: ${colors.neutral900};
    }
    &__multi-value {
      background-color: ${colors.brand500};
      padding: ${px2rem(4)} ${px2rem(2)};
      ${borderRadius.s}
      &__label {
        color: ${colors.white};
      }
      &__remove {
        * {
          color: ${colors.white};
        }
        &:hover {
          background-color: ${colors.brand300};
        }
      }
    }
    &__single-value,
    &__option {
      ${typography.small}
      color: ${colors.neutral900};
      &:hover {
        color: ${colors.white};
        background-color: ${colors.brand500};
      }
      &--is-selected {
        background-color: ${colors.neutral200};
        color: ${colors.neutral900};
      }
      &--is-focused {
        background-color: ${colors.neutral200};
      }
    }
  }
`;

export default { SelectMultipleInput };
