import styled from "styled-components";
import { typography } from "@/src/ui/styles/typography";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";

export interface TextareaSizeProps {
  height?: string;
}

const Label = styled.label`
  display: block;
  width: 100%;
`;
const LabelText = styled.p`
  ${typography.xSmall};
  margin-bottom: ${px2rem(4)};
  color: ${colors.neutral900};
`;
const ErrorText = styled.p`
  ${typography.xSmall};
  margin-top: ${px2rem(4)};
  color: ${colors.alert700};
`;

export const Input = styled.textarea<TextareaSizeProps>`
  ${typography.small};
  height: ${({ height = px2rem(119) }) => height};
  display: block;
  width: 100%;
  padding: ${px2rem(19)} ${px2rem(16)};
  border-radius: ${px2rem(4)} ${px2rem(4)} 0 0;
  background-color: ${colors.neutral200};
  border-top-style: unset;
  border-left-style: unset;
  border-right-style: unset;
  border-bottom: solid 1px ${colors.neutral700};
  box-shadow: 0 1px 0 ${colors.neutral700};
  position: relative;
  resize: vertical;
  ::placeholder {
    color: ${colors.neutral500};
  }
  :focus {
    outline: none;
    border-top-style: unset;
    border-left-style: unset;
    border-right-style: unset;
    border-bottom: solid 1px ${colors.brand500};
    box-shadow: 0 1px 0 ${colors.brand500};
  }
`;

export default { LabelText, ErrorText, Label, Input };
