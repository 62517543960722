import type { InvoicesSlice } from "@/src/ui/pages/billing/invoices/view_models/invoices.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetInvoicesUseCase } from "@/src/core/billing/invoices/domain/use_cases/get_invoices_use_case";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { selectInvoicesPageFilters } from "./invoices_page_filters.slice";
import { selectInvoicesPageSort } from "./invoices_page_sort.slice";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { isEmpty } from "lodash";

const initialState = (): InvoicesSlice => getInitialPaginationSlice();

const defaultSort = { "order[createdAt]": "desc" };

export const getInvoicesPageThunk = createAsyncThunk("invoicesPage.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const filters = selectInvoicesPageFilters(state);
  const selectedSort = selectInvoicesPageSort(state);
  const sort = isEmpty(selectedSort) ? defaultSort : selectedSort;

  const useCase = await locator.get<IocProvider<GetInvoicesUseCase>>(TYPES.GetInvoicesUseCase)();
  return await useCase.execute({ ...filters, ...sort, page: (page ?? 0) + 1, itemsPerPage: 10 });
});

const invoicesPageSlice = createSlice({
  name: "invoicesPage.slice",
  initialState: initialState(),
  reducers: {
    resetInvoicesPage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getInvoicesPageThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getInvoicesPageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getInvoicesPageThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.invoicesPage;
}

export const selectInvoicesPage = createSelector(selectBase, (slice) => slice.pagination);
export const selectInvoicesPageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);

export const selectInvoicesPagePaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export const { resetInvoicesPage } = invoicesPageSlice.actions;
export default invoicesPageSlice.reducer;
