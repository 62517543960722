export const TYPES = {
  SecretAlignerApiService: Symbol("SecretAlignerApiService"),
  IAuthTokenService: Symbol("IAuthTokenService"),
  IEnvVars: Symbol("IEnvVars"),
  ILanguageService: Symbol("ILanguageService"),
  IFileService: Symbol("IFileService"),
  // User
  IAuthRepository: Symbol("IAuthRepository"),
  IUsersRepository: Symbol("IUsersRepository"),
  IProfilesRepository: Symbol("IProfilesRepository"),
  LoginUseCase: Symbol("LoginUseCase"),
  LogoutUseCase: Symbol("LogoutUseCase"),
  RefreshSessionUseCase: Symbol("RefreshSessionUseCase"),
  RecoverPasswordUseCase: Symbol("RecoverPasswordUseCase"),
  RecoverPasswordChangeUseCase: Symbol("RecoverPasswordChangeUseCase"),
  GetMyUserUseCase: Symbol("GetMyUserUseCase"),
  GetUsersUseCase: Symbol("GetUsersUseCase"),
  GetUserDetailUseCase: Symbol("GetUserDetailUseCase"),
  GetMyProfileUseCase: Symbol("GetMyProfileUseCase"),
  ValidateRedirectSessionUrlUseCase: Symbol("ValidateRedirectSessionUrlUseCase"),
  CreateProfileUseCase: Symbol("CreateProfileUseCase"),
  GetMyProfilesUseCase: Symbol("GetMyProfilesUseCase"),
  EditProfileUseCase: Symbol("EditProfileUseCase"),
  SwitchProfileUseCase: Symbol("SwitchProfileUseCase"),
  GetProfileDetailUseCase: Symbol("GetProfileDetailUseCase"),
  GetProfilesUseCase: Symbol("GetProfilesUseCase"),
  RemoveSessionTokenUseCase: Symbol("RemoveSessionTokenUseCase"),
  ActivateUserUseCase: Symbol("ActivateUserUseCase"),

  // Cases
  ICasesRepository: Symbol("ICasesRepository"),
  ICaseFilesRepository: Symbol("ICaseFilesRepository"),
  GetCaseStatsUseCase: Symbol("GetCaseStatsUseCase"),
  GetCasesUseCase: Symbol("GetCasesUseCase"),
  CreateCaseUseCase: Symbol("CreateCaseUseCase"),
  GetCaseDetailUseCase: Symbol("GetCaseDetailUseCase"),
  UpdateProductCaseUseCase: Symbol("UpdateProductCaseUseCase"),
  MarkProductCaseAsUrgentUseCase: Symbol("MarkProductCaseAsUrgentUseCase"),
  CancelProductCaseUseCase: Symbol("CancelProductCaseUseCase"),
  GetCaseFilesUseCase: Symbol("GetCaseFilesUseCase"),
  GetCaseFileDetailUseCase: Symbol("GetCaseFileDetailUseCase"),
  UploadCaseFileUseCase: Symbol("UploadCaseFileUseCase"),
  DeleteCaseFileUseCase: Symbol("DeleteCaseFileUseCase"),
  ICaseDocumentationRepository: Symbol("ICaseDocumentationRepository"),
  GetCaseDocumentsUseCase: Symbol("GetCaseDocumentsUseCase"),
  UploadCaseDocumentationUseCase: Symbol("UploadCaseDocumentationUseCase"),
  UploadCaseFileDocumentationUseCase: Symbol("UploadCaseFileDocumentationUseCase"),
  GetCaseStageUseCase: Symbol("GetCaseStageUseCase"),
  TransferCaseUseCase: Symbol("TransferCaseUseCase"),
  FinishCaseUseCase: Symbol("FinishCaseUseCase"),
  GetCasesByStateStatsUseCase: Symbol("GetCasesByStateStatsUseCase"),

  // Activities
  IActivityLogsRepository: Symbol("IActivityLogsRepository"),
  GetActivityLogsByCaseUseCase: Symbol("IGetActivityLogsByCaseUseCase"),
  // Case notes
  ICaseNotesRepository: Symbol("ICaseNotesRepository"),
  CreateCaseNoteUseCase: Symbol("CreateCaseNoteUseCase"),
  DeleteCaseNoteUseCase: Symbol("DeleteCaseNoteUseCase"),
  GetCaseNoteUseCase: Symbol("GetCaseNoteUseCase"),
  GetCaseNotesUseCase: Symbol("GetCaseNotesUseCase"),
  EditCaseNoteUseCase: Symbol("EditCaseNoteUseCase"),
  // Case comments
  ICaseCommentsRepository: Symbol("ICaseCommentsRepository"),
  GetCaseCommentsUseCase: Symbol("GetCaseCommentsUseCase"),
  CreateCaseCommentUseCase: Symbol("CreateCaseCommentUseCase"),
  // Courses
  ICoursesRepository: Symbol("ICoursesRepository"),
  GetCourseDetailUseCase: Symbol("GetCourseDetailUseCase"),
  GetCoursesUseCase: Symbol("GetCoursesUseCase"),
  GetLatestCourseUseCase: Symbol("GetLatestCourseUseCase"),
  CreateCourseUseCase: Symbol("CreateCourseUseCase"),
  EditCourseUseCase: Symbol("EditCourseUseCase"),
  DeleteCourseUseCase: Symbol("DeleteCourseUseCase"),
  // Course attendees
  ICourseAttendeesRepository: Symbol("ICourseAttendeesRepository"),
  GetCourseAttendeesUseCase: Symbol("GetCourseAttendeesUseCase"),
  CreateCourseAttendeeUseCase: Symbol("CreateCourseAttendeeUseCase"),
  GetCourseAttendeeDetailUseCase: Symbol("GetCourseAttendeeDetailUseCase"),
  EditCourseAttendeeUseCase: Symbol("EditCourseAttendeeUseCase"),
  GetCourseAttendeeUseCase: Symbol("GetCourseAttendeeUseCase"),
  CreateCourseAttendeeInvoiceUseCase: Symbol("CreateCourseAttendeeInvoiceUseCase"),
  // Surgery
  GetSurgeryUrlUseCase: Symbol("GetSurgeryUrlUseCase"),
  ISurgeryRepository: Symbol("ISurgeryRepository"),
  // Dentists
  IDentistsRepository: Symbol("IDentistsRepository"),
  GetDentistsUseCase: Symbol("GetDentistsUseCase"),
  CreateDentistUseCase: Symbol("CreateDentistUseCase"),
  EditDentistUseCase: Symbol("EditDentistUseCase"),
  GetDentistDetailUseCase: Symbol("GetDentistDetailUseCase"),
  AddDentistToClinicUseCase: Symbol("AddDentistToClinicUseCase"),
  AddClinicToDentistUseCase: Symbol("AddClinicToDentistUseCase"),
  GetDentistsFromProfileUseCase: Symbol("GetDentistsFromProfileUseCase"),
  DeleteDentistUseCase: Symbol("DeleteDentistUseCase"),
  RemoveClinicFromDentistUseCase: Symbol("RemoveClinicFromDentistUseCase"),
  RemoveBillingAddressFromDentistUseCase: Symbol("RemoveBillingAddressFromDentistUseCase"),

  // Options
  IOptionsRepository: Symbol("IOptionsRepository"),
  GetGenderOptionsUseCase: Symbol("GetGenderOptionsUseCase"),
  GetReferralSourceOptionsUseCase: Symbol("GetReferralSourceOptionsUseCase"),
  GetClientTypesOptionsUseCase: Symbol("GetClientTypesOptionsUseCase"),
  GetPhaseOptionsUseCase: Symbol("GetPhaseOptionsUseCase"),
  GetStatusOptionsUseCase: Symbol("GetStatusOptionsUseCase"),
  GetBusinessNameOptionsUseCase: Symbol("GetBusinessNameOptionsUseCase"),
  GetPaymentTypeOptionsUseCase: Symbol("GetPaymentTypeOptionsUseCase"),
  GetPaymentTypeChildrenOptionsUseCase: Symbol("GetPaymentTypeChildrenOptionsUseCase"),
  GetRangeAgeOptionsUseCase: Symbol("GetRangeAgeOptionsUseCase"),
  // Province
  IProvincesRepository: Symbol("IProvincesRepository"),
  GetProvincesUseCase: Symbol("GetProvincesUseCase"),
  GetProvinceDetailUseCase: Symbol("GetProvinceDetailUseCase"),
  // Countries
  ICountriesRepository: Symbol("ICountriesRepository"),
  GetCountriesUseCase: Symbol("GetCountriesUseCase"),
  GetCountryDetailUseCase: Symbol("GetCountryDetailUseCase"),
  // Cities
  ICitiesRepository: Symbol("ICitiesRepository"),
  GetCitiesUseCase: Symbol("GetCitiesUseCase"),
  // Clinics
  IClinicsRepository: Symbol("IClinicsRepository"),
  CreateClinicUseCase: Symbol("CreateClinicUseCase"),
  EditClinicUseCase: Symbol("EditClinicUseCase"),
  DeleteClinicUseCase: Symbol("DeleteClinicUseCase"),
  GetClinicsUseCase: Symbol("GetClinicsUseCase"),
  GetClinicDetailUseCase: Symbol("GetClinicDetailUseCase"),
  AddClinicToClinicGroupUseCase: Symbol("AddClinicToClinicGroupUseCase"),
  GetClinicsByProfileUseCase: Symbol("GetClinicsByProfileUseCase"),
  RemoveClinicFromClinicGroupUseCase: Symbol("RemoveClinicFromClinicGroupUseCase"),
  RemoveDentistFromClinicUseCase: Symbol("RemoveDentistFromClinicUseCase"),
  RemoveBillingAddressFromClinicUseCase: Symbol("RemoveBillingAddressFromClinicUseCase"),

  // Clinic opening hours
  IClinicOpeningHoursRepository: Symbol("IClinicOpeningHoursRepository"),
  GetClinicOpeningHoursUseCase: Symbol("GetClinicOpeningHoursUseCase"),
  SetClinicOpeningHoursUseCase: Symbol("SetClinicOpeningHoursUseCase"),
  // Role groups
  IRoleGroupsRepository: Symbol("IRoleGroupsRepository"),
  GetRoleGroupsUseCase: Symbol("GetRoleGroupsUseCase"),
  // Billing
  IBillingAddressesRepository: Symbol("IBillingAddressesRepository"),
  CreateBillingAddressUseCase: Symbol("CreateBillingAddressUseCase"),
  GetBillingAddressesUseCase: Symbol("GetBillingAddressesUseCase"),
  GetBillingAddressesByDentistUseCase: Symbol("GetBillingAddressesByDentistUseCase"),
  GetBillingAddressDetailUseCase: Symbol("GetBillingAddressDetailUseCase"),
  EditBillingAddressUseCase: Symbol("EditBillingAddressUseCase"),
  AddClinicToBillingAddressUseCase: Symbol("AddClinicToBillingAddressUseCase"),
  DeleteBillingAddressUseCase: Symbol("DeleteBillingAddressUseCase"),
  // Invoices
  IInvoicesRepository: Symbol("IInvoicesRepository"),
  CreateInvoiceUseCase: Symbol("CreateInvoiceUseCase"),
  GetInvoicesUseCase: Symbol("GetInvoicesUseCase"),
  GetInvoicesByCaseUseCase: Symbol("GetInvoicesByCaseUseCase"),
  GetInvoiceDetailUseCase: Symbol("GetInvoiceDetailUseCase"),
  GetInvoicePdfUseCase: Symbol("GetInvoicePdfUseCase"),
  EditInvoiceUseCase: Symbol("EditInvoiceUseCase"),
  PayInvoiceUseCase: Symbol("PayInvoiceUseCase"),
  // Custom forms
  ICustomFormsRepository: Symbol("ICustomFormsRepository"),
  GetCaseCustomFormUseCase: Symbol("GetCaseCustomFormUseCase"),
  // Case stages
  ICaseStagesRepository: Symbol("ICaseStagesRepository"),
  TransitionCaseUseCase: Symbol("TransitionCaseUseCase"),
  // Treatments
  ITreatmentsRepository: Symbol("ITreatmentsRepository"),
  GetTreatmentsUseCase: Symbol("GetTreatmentsUseCase"),
  CreateTreatmentUseCase: Symbol("CreateTreatmentUseCase"),
  EditTreatmentUseCase: Symbol("EditTreatmentUseCase"),
  ApproveTreatmentUseCase: Symbol("ApproveTreatmentUseCase"),
  GetTreatmentUseCase: Symbol("GetTreatmentUseCase"),
  GetTreatmentViewerContentUseCase: Symbol("GetTreatmentViewerContentUseCase"),
  GetTreatmentsHistoryUseCase: Symbol("GetTreatmentsHistoryUseCase"),
  GetTreatmentButtonsUseCase: Symbol("GetTreatmentButtonsUseCase"),
  CreateTreatmentReplanningUseCase: Symbol("CreateTreatmentReplanningUseCase"),
  CreateTreatmentRefinementUseCase: Symbol("CreateTreatmentRefinementUseCase"),
  CreateTreatmentReplacementUseCase: Symbol("CreateTreatmentReplacementUseCase"),
  RequestNextPhaseOfTreatmentUseCase: Symbol("RequestNextPhaseOfTreatmentUseCase"),

  // Teeth
  ITeethRepository: Symbol("ITeethRepository"),
  GetTeethUseCase: Symbol("GetTeethUseCase"),
  // Notifications
  INotificationsRepository: Symbol("INotificationsRepository"),
  GetNotificationsUseCase: Symbol("GetNotificationsUseCase"),
  DeleteNotificationsUseCase: Symbol("DeleteNotificationsUseCase"),
  // Dentist specialities
  IDentistSpecialitiesRepository: Symbol("IDentistSpecialitiesRepository"),
  GetDentistSpecialitiesUseCase: Symbol("GetDentistSpecialitiesUseCase"),
  // Manufacturing items
  IManufacturingItemsRepository: Symbol("ManufacturingItemsRepository"),
  GetManufacturingItemsUseCase: Symbol("GetManufacturingItemsUseCase"),
  GetSentManufacturingItemsUseCase: Symbol("GetSentManufacturingItemsUseCase"),
  // Manufacturing lots
  IManufacturingLotsRepository: Symbol("ManufacturingLotsRepository"),
  GetManufacturingLotsUseCase: Symbol("GetManufacturingLotsUseCase"),
  GetManufacturingLotDetailUseCase: Symbol("GetManufacturingLotDetailUseCase"),
  CreateManufacturingLotUseCase: Symbol("CreateManufacturingLotUseCase"),
  UpdateManufacturingLotUseCase: Symbol("UpdateManufacturingLotUseCase"),
  TransitionManufacturingLotUseCase: Symbol("TransitionManufacturingLotUseCase"),
  AssignManufacturingItemsToLotUseCase: Symbol("AssignManufacturingItemsToLotUseCase"),
  // Promoters
  IPromotersRepository: Symbol("IPromotersRepository"),
  GetPromotersUseCase: Symbol("GetPromotersUseCase"),
  GetPromoterDetailUseCase: Symbol("GetPromoterDetailUseCase"),
  // Distributors
  IDistributorsRepository: Symbol("IDistributorsRepository"),
  GetDistributorsUseCase: Symbol("GetDistributorsUseCase"),
  GetDistributorDetailUseCase: Symbol("GetDistributorDetailUseCase")
};
