import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import CanEditProfileMiddleware from "@/src/ui/router/middlewares/can_edit_profile_middleware";
const HasRoleMiddleware = lazy(() => import("@/src/ui/router/middlewares/has_role_middleware"));
const ProfileDetailPage = lazy(() => import("../profile_detail/components/profile_detail_page/profile_detail_page"));
const EditProfilePage = lazy(() => import("@/src/ui/pages/profile/edit_profile/components/edit_profile_page/edit_profile_page"));

export default function ProfilePages() {
  const page = useRoutes([
    {
      path: "detail/:id",
      element: <ProfileDetailPage />
    },
    {
      path: "detail/:id/edit",
      element: (
        <CanEditProfileMiddleware>
          <EditProfilePage />
        </CanEditProfileMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole={["PROFILES_VIEW", "PROFILES_VIEW_OWN"]}>{page}</HasRoleMiddleware>;
}
