import type { CaseCustomFormSlice as CaseFormsSlice } from "@/src/ui/pages/cases/case_forms/view_models/case_forms.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetCaseCustomFormUseCase } from "@/src/core/custom_forms/domain/use_cases/get_new_case_custom_form_use_case";
import type { RootState } from "@/src/ui/state";
import type { GetCaseFormsInput } from "../view_models/get_case_forms_input";
import { selectLanguage } from "@/src/ui/state/language.slice";
import { ArraySet } from "@/src/common/utils/array_set";

export const initialState = (): CaseFormsSlice => ({
  forms: {},
  isLoading: new ArraySet(),
  hasError: false
});

export const getCaseFormsThunk = createAsyncThunk("caseForms.slice/get", async (input: GetCaseFormsInput) => {
  const useCase = await locator.get<IocProvider<GetCaseCustomFormUseCase>>(TYPES.GetCaseCustomFormUseCase)();
  return await useCase.execute(input.caseType);
});

const caseFormsSlice = createSlice({
  name: "caseForms.slice",
  initialState: initialState(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCaseFormsThunk.pending, (state, action) => {
      const { caseType, language } = action.meta.arg;
      state.isLoading.add([language, caseType]);
      state.hasError = false;
    });
    builder.addCase(getCaseFormsThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      const { caseType, language } = action.meta.arg;
      state.isLoading.delete([language, caseType]);
    });
    builder.addCase(getCaseFormsThunk.fulfilled, (state, action) => {
      const { language, caseType } = action.meta.arg;
      if (!state.forms[language]) state.forms[language] = {};
      state.forms[language][caseType] = action.payload;
      state.isLoading.delete([language, caseType]);
    });
  }
});

const selectBase = (state: RootState) => state.caseCustomForm;

export const selectCaseFormsError = createSelector(selectBase, (slice) => slice.hasError);
export const selectCaseFormsLoading = createSelector(selectBase, (slice) => slice.isLoading);
export const selectCaseFormLanguageMap = createSelector(selectBase, (slice) => slice.forms);
export const selectCaseFormTypeMap = createSelector(selectCaseFormLanguageMap, selectLanguage, (forms, language) => forms[language]);

export default caseFormsSlice.reducer;
