import { css } from "styled-components";

export const fonts = {
  openSans: css`
    font-family: "Open Sans", sans-serif;

    * {
      font-family: "Open Sans", sans-serif;
    }
  `
};
