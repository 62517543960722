import { useRef, useState } from "react";
import { useClickOutside } from "@front_web_mrmilu/hooks";
import { ThreeDots } from "@/src/ui/assets/icons";
import Styled from "@/src/ui/pages/cases/case_files/components/case_file_card/case_file_card.styled";
import type { UploadCaseFileModel } from "@/src/core/cases/domain/models/upload_case_file_model";
import { useTranslation } from "react-i18next";
import type { CaseFileModel } from "@/src/core/cases/domain/models/case_file_model";
import { useDropzone } from "react-dropzone";
import { downloadFile } from "@/src/common/utils/url";
import type { MouseEvent } from "react";

interface Props {
  caseFile: CaseFileModel;
  uploadFile(data: Omit<UploadCaseFileModel, "reference" | "key">): Promise<void>;
  deleteFile(): Promise<void>;
}

export default function CaseFileCardMenu({ caseFile, uploadFile, deleteFile }: Props) {
  const { t } = useTranslation("case_files", { keyPrefix: "card.menu" });
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, closeMenu);
  const onDropAccepted = (files: File[]) => {
    const file = files[0];
    closeMenu();
    void uploadFile({
      file,
      totalSize: file.size,
      originalName: file.name,
      mimeType: file.type
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    multiple: false,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".webp", ".avif"]
    }
  });

  const handleFileDownload = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!caseFile.url) return;
    downloadFile(caseFile.url, caseFile.originalName ?? "file");
  };

  return (
    <Styled.MenuWrapper>
      <Styled.IconButton type="button" onClick={showMenu ? undefined : openMenu} aria-label={t("open")}>
        <ThreeDots />
      </Styled.IconButton>
      {showMenu && (
        <Styled.Menu ref={ref}>
          <Styled.MenuButton as="label" {...getRootProps()}>
            {t("upload")}
            <Styled.FileInput {...getInputProps()} />
          </Styled.MenuButton>
          {caseFile.isUploaded && (
            <Styled.MenuButton as="a" href={caseFile.url} download onClick={handleFileDownload}>
              {t("download")}
            </Styled.MenuButton>
          )}
          {caseFile.isUploaded && (
            <Styled.MenuButton type="button" onClick={() => deleteFile().finally(closeMenu)}>
              {t("delete")}
            </Styled.MenuButton>
          )}
        </Styled.Menu>
      )}
    </Styled.MenuWrapper>
  );
}
