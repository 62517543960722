import { Outlet, useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
const CaseDetailPage = lazy(() => import("@/src/ui/pages/cases/case_detail/components/case_detail_page/case_detail_page"));
const CasesPage = lazy(() => import("@/src/ui/pages/cases/components/cases_page/cases_page"));
const PublishCasePage = lazy(() => import("@/src/ui/pages/cases/publish_case/components/publish_case_page/publish_case_page"));
const NewCasePage = lazy(() => import("@/src/ui/pages/cases/new_case/components/new_case_page/new_case_page"));
import CaseNotesPages from "../../../case_notes/components/case_notes_pages/case_notes_pages";
import TreatmentsPages from "../../../treatments/components/treatments_pages/treatments_pages";
import HasRoleMiddleware from "@/src/ui/router/middlewares/has_role_middleware";
import CaseDetailBillingPages from "../../case_detail/case_detail_billing/components/case_detail_billing_pages/case_detail_billing_pages";
import CaseDetailManufacturingPages from "../../case_detail/case_detail_manufacturing/components/case_detail_manufacturing_pages/case_detail_manufacturing_pages";
import CanEditCaseMiddleware from "@/src/ui/router/middlewares/can_edit_case_middleware";
import EditCasePage from "../../edit_case/components/edit_case_page/edit_case_page";

export default function CasesPages() {
  const page = useRoutes([
    {
      index: true,
      element: <CasesPage />
    },
    {
      path: "/detail/:reference",
      element: <Outlet />,
      children: [
        { index: true, element: <CaseDetailPage /> },
        {
          path: "edit",
          element: (
            <HasRoleMiddleware profileRole="CASES_EDIT">
              <EditCasePage />
            </HasRoleMiddleware>
          )
        },
        { path: "notes/*", element: <CaseNotesPages /> },
        { path: "treatments/*", element: <TreatmentsPages /> },
        { path: "billing/*", element: <CaseDetailBillingPages /> },
        { path: "manufacturing/*", element: <CaseDetailManufacturingPages /> }
      ]
    },
    {
      path: "/new",
      element: (
        <HasRoleMiddleware profileRole="CASES_CREATE">
          <NewCasePage />
        </HasRoleMiddleware>
      )
    },
    {
      path: "/publish/:reference",
      element: (
        <CanEditCaseMiddleware>
          <PublishCasePage />
        </CanEditCaseMiddleware>
      )
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <HasRoleMiddleware profileRole={["CASES_VIEW", "CASES_VIEW_OWN"]}>{page}</HasRoleMiddleware>;
}
