import type { PathologySolutionOptionModel } from "./pathology_solution_option_model";

export interface PathologySolutionValueModel {
  key: string;
  option: string;
  comments: string;
}

export function sortPathologySolutionValue(a: PathologySolutionValueModel, b: PathologySolutionValueModel) {
  return a.key?.localeCompare(b.key);
}

export function createPathologySolutionValue(option: PathologySolutionOptionModel): PathologySolutionValueModel {
  return { key: option.name, option: Object.keys(option.options)[0], comments: "" };
}

export function removePathologySolutionValue(values: PathologySolutionValueModel[], key: string) {
  return values.filter((v) => v.key !== key);
}

export function containsPathologySolutionValue(values: PathologySolutionValueModel[], key: string) {
  return values.some((v) => v.key === key);
}

export function setPathologySolutionValueComments(values: PathologySolutionValueModel[], key: string, comments: string) {
  return values.map((v) => (v.key === key ? { ...v, comments } : v));
}
export function setPathologySolutionValueOption(values: PathologySolutionValueModel[], key: string, option: string) {
  return values.map((v) => (v.key === key ? { ...v, option } : v));
}
