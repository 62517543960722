import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../state";
import type { PropsWithChildren } from "react";
import { useHasRole } from "../../pages/roles/hooks/use_has_role";
import { usePageId } from "../../hooks/use_page_id";
import { selectProfileId } from "../../state/profile.slice";

export default function CanEditProfileMiddleware({ children }: PropsWithChildren) {
  const canEdit = useHasRole("PROFILES_EDIT");
  const canEditOwn = useHasRole("PROFILES_EDIT_OWN");

  const profileId = useAppSelector(selectProfileId);
  const id = usePageId("CanEditProfileMiddleware");

  if (canEdit || (canEditOwn && id === profileId)) return <>{children}</>;
  return <Navigate to="/role-error" replace />;
}
