import { useRoutes } from "react-router-dom";
import { lazy } from "react";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
const EditCaseNotePage = lazy(() => import("../../edit_case_note/components/edit_case_note_page/edit_case_note_page"));
const CaseNoteDetailPage = lazy(() => import("@/src/ui/pages/case_notes/case_note_detail/components/case_note_detail_page/case_note_detail_page"));
const CreateCaseNotePage = lazy(() => import("@/src/ui/pages/case_notes/create_case_note/components/create_case_note_page/create_case_note_page"));

export default function CaseNotesPages() {
  const page = useRoutes([
    {
      path: "/new",
      element: <CreateCaseNotePage />
    },
    {
      path: "/detail/:id",
      element: <CaseNoteDetailPage />
    },
    {
      path: "/detail/:id/edit",
      element: <EditCaseNotePage />
    },
    { path: "*", element: <Page404 /> }
  ]);
  return <>{page}</>;
}
