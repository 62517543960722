import type { RoleModel } from "@/src/core/user/domain/models/role_model";
import { useAppSelector } from "@/src/ui/state";
import { useRef } from "react";
import { selectRoles } from "../state/roles.selectors";

/** Returns `true` if current profile has any role in `roles` */
export function useHasRole(roles: RoleModel | RoleModel[]) {
  const profileRoles = useAppSelector(selectRoles);
  const ref = useRef(roles);
  const rolesArray = typeof ref.current === "string" ? [ref.current] : ref.current;
  return rolesArray.some((role) => profileRoles.has(role));
}
