import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/src/ui/state";
import type { CasesPageFiltersSlice } from "../view_models/cases_page_filters.slice";
import type { CaseFiltersModel } from "@/src/core/cases/domain/models/case_filters_model";

const initialState = (): CasesPageFiltersSlice => ({
  show: false,
  filters: {
    search: "",
    dentist: [],
    status: [],
    phase: [],
    "age[after]": null,
    "age[before]": null,
    "createdAt[after]": null,
    "createdAt[before]": null,
    type: "all"
  }
});

const casesSlice = createSlice({
  name: "casesPageFilters.slice",
  initialState: initialState(),
  reducers: {
    resetCasesPageFilters: initialState,
    cleanCasesPageFilters(state) {
      state.filters = initialState().filters;
    },
    setCasesPageFilters(state, action: PayloadAction<Partial<CaseFiltersModel>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    toggleCasesPageFilters(state) {
      state.show = !state.show;
    }
  }
});

function selectBase(state: RootState) {
  return state.casesPageFilters;
}

export const selectShowCasesPageFilters = createSelector(selectBase, (slice) => slice.show);
export const selectCasesPageFilters = createSelector(selectBase, (slice) => slice.filters);
export const selectCasesPageFiltersSearch = createSelector(selectCasesPageFilters, (filters) => filters.search);
export const selectCasesPageFiltersType = createSelector(selectCasesPageFilters, (filters) => filters.type ?? "all");

export const { resetCasesPageFilters, cleanCasesPageFilters, setCasesPageFilters, toggleCasesPageFilters } = casesSlice.actions;
export default casesSlice.reducer;
