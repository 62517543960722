import type { UsersSlice } from "@/src/ui/pages/users/views/users_page/view_models/users_page.slice";
import { errorToLoadingState } from "@/src/ui/presenters/error_to_loading_state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { RootState } from "@/src/ui/state";
import type { GetUsersUseCase } from "@/src/core/user/domain/use_cases/get_users_use_case";
import { getInitialPaginationSlice } from "@/src/ui/view_models/slices";
import { selectUsersPageFilters } from "./users_page_filters.slice";
import { selectUsersPageSort } from "./users_page_sort.slice";

const initialState = (): UsersSlice => getInitialPaginationSlice();

export const getUsersPageThunk = createAsyncThunk("usersPage.slice/get", async (page: number | undefined, { getState }) => {
  const state = getState() as RootState;
  const filters = selectUsersPageFilters(state);
  const sort = selectUsersPageSort(state);
  const useCase = await locator.get<IocProvider<GetUsersUseCase>>(TYPES.GetUsersUseCase)();
  return await useCase.execute({ ...filters, page: (page ?? 0) + 1, itemsPerPage: 10, ...sort });
});

const usersPageSlice = createSlice({
  name: "usersPage.slice",
  initialState: initialState(),
  reducers: {
    resetUsersPage: initialState
  },
  extraReducers(builder) {
    builder.addCase(getUsersPageThunk.pending, (state, action) => {
      state.loadingState = "loading";
      state.paginationState.pageIndex = action.meta.arg ?? 0;
    });
    builder.addCase(getUsersPageThunk.rejected, (state, action) => {
      console.error(action.error);
      state.loadingState = errorToLoadingState(action.error);
    });
    builder.addCase(getUsersPageThunk.fulfilled, (state, action) => {
      state.pagination = action.payload;
      state.loadingState = "loaded";
    });
  }
});

function selectBase(state: RootState) {
  return state.usersPage;
}

export const selectUsersPage = createSelector(selectBase, (slice) => slice.pagination);
export const selectHasUsersPage = createSelector(selectUsersPage, (p) => p?.hasResults);
export const selectUsersPageLoadingState = createSelector(selectBase, (slice) => slice.loadingState);
export const selectUsersPagePaginationState = createSelector(selectBase, (slice) => slice.paginationState);

export const { resetUsersPage } = usersPageSlice.actions;
export default usersPageSlice.reducer;
