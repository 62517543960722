import { px2rem } from "@/src/ui/styles/utils";

export const spacing = {
  // 96px
  xxxl: px2rem(96),
  // 80px
  xxl: px2rem(80),
  // 54px
  xl: px2rem(54),
  // 36px
  l: px2rem(36),
  // 24px
  m: px2rem(24),
  // 20px
  s: px2rem(20),
  // 14px
  xs: px2rem(14)
};
