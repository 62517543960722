import type { RouteObject } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Page404 from "@/src/ui/components/error_displayers/error_pages/404";
import { lazy } from "react";
import { LoggedInMiddleware, LoggedOutMiddleware } from "@/src/ui/router/middlewares/auth_middleware";
import BillingAddressesPages from "@/src/ui/pages/billing/billing_addresses/components/billing_addresses_pages/billing_addresses_pages";
import InvoicesPages from "@/src/ui/pages/billing/invoices/components/invoices_pages/invoices_pages";
import ClinicsPages from "@/src/ui/pages/clinics/components/clinics_pages/clinics_pages";
import ProfilePages from "@/src/ui/pages/profile/components/profile_pages";
import CasesPages from "@/src/ui/pages/cases/components/cases_pages/cases_pages";
import UsersPages from "@/src/ui/pages/users/users_routes";
import DentistsPages from "@/src/ui/pages/dentists/components/dentists_pages/dentists_pages";
import ProfileIsSelectedMiddleware from "./middlewares/profile_is_selected_middleware";
import ProfileIsNotSelectedMiddleware from "./middlewares/profile_is_not_selected_middleware";
import { FullscreenErrorBoundary } from "../components/error_displayers/error_messages/fullscreen_error_boundary/fullscreen_error_boundary";
import { FullscreenSuspenseLoader } from "../components/loaders/suspense_loader/suspense_loader";
import { resetController } from "../controllers/reset_controller";
import CoursesPages from "@/src/ui/pages/courses/components/courses_pages/courses_pages";

const FaqPage = lazy(() => import("@/src/ui/pages/faq/components/faq_page/faq_page"));
const RoleErrorPage = lazy(() => import("../pages/roles/role_error/components/role_error_page/role_error_page"));
const AuthLayout = lazy(() => import("@/src/ui/pages/auth/components/auth_layout/auth_layout"));
const BaseLayout = lazy(() => import("@/src/ui/components/layouts/base_layout/base_layout"));
const LoginPage = lazy(() => import("@/src/ui/pages/auth/login/components/login_page/login_page"));
const RecoverPasswordPage = lazy(() => import("@/src/ui/pages/auth/recover_password/components/recover_password_page/recover_password_page"));
const RecoverPasswordEmailSentPage = lazy(
  () => import("@/src/ui/pages/auth/recover_password/components/recover_password_email_sent_page/recover_password_email_sent_page")
);
const RecoverPasswordSuccessPage = lazy(
  () => import("@/src/ui/pages/auth/recover_password/components/recover_password_success_page/recover_password_success_page")
);
const SelectProfilePage = lazy(() => import("../pages/profile/select_profile/components/select_profile_page/select_profile_page"));
const HomePage = lazy(() => import("@/src/ui/pages/home/components/home_page/home_page"));
const CaseViewerDetailPage = lazy(() => import("@/src/ui/pages/cases/case_viewer/components/case_viewer_detail_page/case_viewer_detail_page"));

const routes: Array<RouteObject> = [
  {
    path: "/auth",
    element: (
      <LoggedOutMiddleware>
        <AuthLayout />
      </LoggedOutMiddleware>
    ),
    children: [
      { path: "/auth/login", element: <LoginPage /> },
      { path: "/auth/recover-password", element: <RecoverPasswordPage /> },
      { path: "/auth/recover-password-email-sent", element: <RecoverPasswordEmailSentPage /> },
      { path: "/auth/recover-password-success", element: <RecoverPasswordSuccessPage /> }
    ]
  },
  {
    path: "/select-profile",
    element: (
      <LoggedInMiddleware>
        <ProfileIsNotSelectedMiddleware>
          <AuthLayout />
        </ProfileIsNotSelectedMiddleware>
      </LoggedInMiddleware>
    ),
    children: [{ index: true, element: <SelectProfilePage /> }]
  },
  {
    path: "/cases/detail/:reference/treatments/:treatment/viewer",
    element: (
      <LoggedInMiddleware>
        <ProfileIsSelectedMiddleware>
          <CaseViewerDetailPage />
        </ProfileIsSelectedMiddleware>
      </LoggedInMiddleware>
    )
  },
  {
    path: "/*",
    element: (
      <LoggedInMiddleware>
        <ProfileIsSelectedMiddleware>
          <BaseLayout />
        </ProfileIsSelectedMiddleware>
      </LoggedInMiddleware>
    )
  }
];

export const loggedInRoutes: Array<RouteObject> = [
  { index: true, element: <HomePage /> },
  { path: "/profiles/*", element: <ProfilePages /> },
  { path: "/clinics/*", element: <ClinicsPages /> },
  { path: "/billing", element: <Navigate to="/billing/addresses/" replace /> },
  { path: "/billing/addresses/*", element: <BillingAddressesPages /> },
  { path: "/billing/invoices/*", element: <InvoicesPages /> },
  { path: "/courses", element: <Navigate to="/courses/on-site" replace /> },
  { path: "/courses/*", element: <CoursesPages /> },
  { path: "/cases/*", element: <CasesPages /> },
  { path: "/users/*", element: <UsersPages /> },
  { path: "/dentists/*", element: <DentistsPages /> },
  { path: "/role-error", element: <RoleErrorPage /> },
  { path: "/faq", element: <FaqPage /> },
  { path: "*", element: <Page404 /> }
];

export default function Routes() {
  const page = useRoutes(routes);
  return (
    <FullscreenErrorBoundary retryFunction={resetController}>
      <FullscreenSuspenseLoader>{page}</FullscreenSuspenseLoader>
    </FullscreenErrorBoundary>
  );
}
