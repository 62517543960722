import type { MyProfilesSlice } from "@/src/ui/pages/auth/switch_profile/view_models/my_profiles.slice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { GetMyProfilesUseCase } from "@/src/core/user/domain/use_cases/get_my_profiles_use_case";
import type { RootState } from "@/src/ui/state";

const initialState = (): MyProfilesSlice => ({
  isLoading: false,
  hasError: false,
  results: []
});

// TODO: add pagination. Right now there is no priority to add pagination to this because it will return < 30 results.
export const getMyProfilesThunk = createAsyncThunk("myProfiles.slice/get", async (user: string) => {
  const useCase = await locator.get<IocProvider<GetMyProfilesUseCase>>(TYPES.GetMyProfilesUseCase)();
  return await useCase.execute({ user });
});

const myProfilesSlice = createSlice({
  name: "myProfiles.slice",
  initialState: initialState(),
  reducers: {
    resetMyProfiles: initialState
  },

  extraReducers(builder) {
    builder.addCase(getMyProfilesThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getMyProfilesThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getMyProfilesThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.results = action.payload?.results;
    });
  }
});

function selectMyProfilesBase(state: RootState) {
  return state.myProfiles;
}

export const selectMyProfiles = createSelector(selectMyProfilesBase, (slice) => slice.results);
export const selectMyProfilesIsLoading = createSelector(selectMyProfilesBase, (slice) => slice.isLoading);
export const selectMyProfilesHasError = createSelector(selectMyProfilesBase, (slice) => slice.hasError);

export const { resetMyProfiles } = myProfilesSlice.actions;

export default myProfilesSlice.reducer;
