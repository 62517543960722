import type { LoaderHeight } from "@/src/ui/styles/loader_height";
import type { LoadingState } from "@/src/ui/view_models/loading_state";
import type { PropsWithChildren } from "react";
import BasicSpinner from "../basic_spinner/basic_spinner";

interface Props extends PropsWithChildren {
  loadingState?: LoadingState;
  height?: LoaderHeight;
}

export default function LoadingStateLoadingWrapper({ loadingState, height,  children }: Props) {
  if (loadingState === "loading") return <BasicSpinner height={height} />;
  return <>{children}</>;
}
