import { copyClass } from "@/src/common/utils/transformers";
import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import { InvoiceDetailModel } from "@/src/core/billing/invoices/domain/models/invoice_detail.model";
import type { GetInvoiceDetailUseCase } from "@/src/core/billing/invoices/domain/use_cases/get_invoice_detail_use_case";
import type { PayInvoiceUseCase } from "@/src/core/billing/invoices/domain/use_cases/pay_invoice_use_case";
import type { RootState } from "@/src/ui/state";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import type { InvoiceDetailSlice } from "../view_models/invoice_detail.slice";

const initialState = (): InvoiceDetailSlice => ({
  hasError: false,
  isLoading: false,
  detail: null
});

export const getInvoiceDetailThunk = createAsyncThunk("invoiceDetail.slice/get", async (id: string) => {
  const useCase = await locator.get<IocProvider<GetInvoiceDetailUseCase>>(TYPES.GetInvoiceDetailUseCase)();
  return await useCase.execute(id);
});
export const payInvoiceThunk = createAsyncThunk("invoiceDetail.slice/pay", async (id: string) => {
  const useCase = await locator.get<IocProvider<PayInvoiceUseCase>>(TYPES.PayInvoiceUseCase)();
  return await useCase.execute(id);
});

const invoiceDetailSlice = createSlice({
  name: "invoiceDetail.slice",
  initialState: initialState(),
  reducers: {
    resetInvoiceDetail: initialState
  },
  extraReducers(builder) {
    builder.addCase(getInvoiceDetailThunk.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(getInvoiceDetailThunk.rejected, (state, action) => {
      console.error(action.error);
      state.hasError = true;
      state.isLoading = false;
    });
    builder.addCase(getInvoiceDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload;
      state.isLoading = false;
    });
    builder.addCase(payInvoiceThunk.fulfilled, (state, action) => {
      if (state.detail?.id === action.meta.arg) {
        state.detail.paid = true;
        state.detail = copyClass(InvoiceDetailModel, state.detail);
      }
      state.isLoading = false;
    });
  }
});

const selectBase = (state: RootState) => state.invoiceDetail;

export const selectInvoiceDetail = createSelector(selectBase, (slice) => slice.detail);
export const selectInvoiceDetailHasBillingAddress = createSelector(selectInvoiceDetail, (invoice) => Boolean(invoice?.billingAddress?.id));

export const selectInvoiceDetailError = createSelector(selectBase, (slice) => slice.hasError);
export const selectInvoiceDetailIsLoading = createSelector(selectBase, (slice) => slice.isLoading);

export const { resetInvoiceDetail } = invoiceDetailSlice.actions;
export default invoiceDetailSlice.reducer;
