import { getCustomFieldDisplayEdit } from "@/src/core/custom_forms/domain/models/custom_base_field.model";
import type { CustomFieldModel } from "@/src/core/custom_forms/domain/models/custom_field.model";
import FormRow from "@/src/ui/components/spacing/form_row/form_row";
import ContentMaxWidth from "@/src/ui/components/spacing/content_max_width/content_max_width";
import CustomField from "@/src/ui/pages/custom_forms/components/custom_field/custom_field";
import { CustomFieldHide } from "../custom_field_hide/custom_field_hide";

interface Props {
  fields: CustomFieldModel[];
}
export default function CustomFormFieldsRow({ fields }: Props) {
  return (
    <ContentMaxWidth width={fields[0]?.type === "files" ? "large" : "small"}>
      <FormRow>
        {fields.map((field) => {
          const Component = getCustomFieldDisplayEdit(field)?.field ? CustomFieldHide : CustomField;
          return <Component key={field.name} field={field} />;
        })}
      </FormRow>
    </ContentMaxWidth>
  );
}
