import Steps from "@/src/ui/components/forms/steps/steps";
import { useAppSelector } from "@/src/ui/state";
import { selectCaseDetailSteps, selectCaseDetailStep } from "@/src/ui/pages/cases/case_detail/state/case_detail.slice";

export default function NewCaseFormSteps() {
  const selected = useAppSelector(selectCaseDetailStep);
  const steps = useAppSelector(selectCaseDetailSteps);
  if (!steps) return null;
  return <Steps steps={steps} selected={selected} />;
}
