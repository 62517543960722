import type { DateFieldProps } from "@/src/ui/components/fields/date_field/date_field";
import DateField from "@/src/ui/components/fields/date_field/date_field";
import { useField } from "formik";

type DateFieldFormikProps = Omit<DateFieldProps, "onChange" | "value">;
export default function DateFieldFormik(props: DateFieldFormikProps) {
  const [field, meta, helpers] = useField(props.name);

  return <DateField value={field.value} error={meta.touched ? meta.error : undefined} onChange={helpers.setValue} {...props} />;
}
